import React, { Component } from "react";
import Slider from "react-slick";
import { Swiper, SwiperSlide } from "swiper/react";
import { } from "swiper/modules"
import "swiper/css"
import "swiper/css/autoplay"
import { Keyboard, Autoplay } from "swiper/modules"

const  Rivaslider = () => {


    return (

      <div className="mt-10"> 
           <Swiper
              keyboard = { true }
              autoplay = { { delay: 2000 } } 
              modules= { [ Keyboard, Autoplay] }
            >

            <div className="riva-slider__image"> 
             <SwiperSlide>
               <img src="/images/riva2.webp" alt="img" />
             </SwiperSlide>
            </div>

            <div>
             <SwiperSlide className="riva-slider__image">
               <img src="/images/riva1.webp" alt="img" />
             </SwiperSlide>
            </div>

            <div>
             <SwiperSlide className="riva-slider__image">
                   <img src="/images/riva3.webp" alt="img" />
             </SwiperSlide>
            </div>
            
          </Swiper>
      </div>
    );
  }
export default Rivaslider