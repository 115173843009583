import React, { useEffect, useState } from "react";
import Header from "./header/header";
import Nav from "./navbar/nav";
import Main from "./main/main";
import Features from "./features/features";
import ProductMain from "./productMain/productMain";
import Discount from "./discount/discount";
import Categories from "./categories/categories";
import Delivery from "./delivery/delivery";
import Footer from "./footer/footer";
import MultipleItem from "./newSlider/slider";
import { AxiosGet, AxiosPost } from "../../utilities/axioscall";
import { AppKeys } from "../../constants/AppKeys";
import { getuserid } from "../../utilities/sessionexpiry";
import HeaderV from "./header/headerV2";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { PK } from "../../constants/PepperKeys";

const Home = (props) => {
  const { count, setCount, pepper, setPepper, peppermint } = props;
  const [pageinit, setPageInit] = useState(false);

  useEffect(() => {}, [count, pepper]);

  const checkProductInCartData = (prod) => {
    // let prodid = prod?.id;
    // if (cartData.length > 0) {
    //   let checkin = cartData.filter((cart) => {
    //     return cart.id === prodid;
    //   });

    //   if (checkin.length > 0) {
    //     return checkin[0].quantity;
    //   }
    // }
    return 0;
  };

  const callpageInit = async () => {
    // await peppermint(PK.CATEGORYINFODATA);
    // setCount({ ...count, count: count + 1 });
    // console.log(pepper);
    // await peppermint(PK.PRODUCTSINFODATA);
    // setCount({ ...count, count: count + 1 });
    // console.log(pepper);
    // await peppermint(PK.CACHEDATAREAD);
    // setCount({ ...count, count: count + 1 });
    console.log(pepper);
    console.log(
      Object.keys(
        pepper?.cachedata?.cartitems === undefined
          ? {}
          : pepper?.cachedata?.cartitems
      ).length
    );
  };
  if (!pageinit) {
    setPageInit(true);
    callpageInit();
    if (getuserid()) {
      // setUserLoggedin(true);
      // callCartData();
    }

    //callProductsData();
  }
  return (
    <div>
      {pepper?.isUserLoggedin ? (
        <>
          <HeaderV
            count={count}
            setCount={setCount}
            pepper={pepper}
            setPepper={setPepper}
            peppermint={peppermint}
          />
        </>
      ) : (
        <>
          <Header
            count={count}
            setCount={setCount}
            pepper={pepper}
            setPepper={setPepper}
            peppermint={peppermint}
          />
        </>
      )}

      <Nav
        count={count}
        setCount={setCount}
        pepper={pepper}
        setPepper={setPepper}
        peppermint={peppermint}
      />
      <Main />
      <Features />
      <ProductMain
        count={count}
        setCount={setCount}
        pepper={pepper}
        setPepper={setPepper}
        peppermint={peppermint}
      />
      <Discount />
      <Categories />
      <Delivery />
      <MultipleItem />
      <Footer />
    </div>
  );
};

export default Home;
