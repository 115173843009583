import React, { useState } from 'react'
import "./payment.css"

const DebitCard = ( ) => {

    // const [isBottomBoxOpen, setIsBottomBoxOpen] = useState(false);
    // const [rotateIcon, setRotateIcon] = useState(false);
  
    // const handleBoxClick = () => {
    //   setIsBottomBoxOpen(!isBottomBoxOpen);
    //   setRotateIcon(!rotateIcon);
    // };




  return (
    <div className='creditCard__container mt-5'>

        {/* <div onClick={handleBoxClick} className='manufacturing__title flex items-center justify-between'>
              <div className='creditCard__heading w-full flex justify-between items-center p-3'>
                    <div className='creditCard__title'>
                        <span>Pay with Debit Card</span>
                    </div>
                    <div className='visa__container flex'>
                        <div className='visa mr-5'>
                            <img src='/images/visa.webp' alt='img'/>
                        </div>
                        <div className='visa__icon'>
                            <img src='/images/visaicon.webp' alt='img'/>
                        </div>
                    </div>
                </div>  
           </div>
            {isBottomBoxOpen && (
              <form className='p-3'>
              <div className='creditCard__input flex justify-between items-center mt-4 mb-2'>
                  <div className='creditCard__inputName'>
                      <label className='mb-1'>Name on card</label>
                      <input type='text' name='step3Field1' value={values.step3Field1} onChange={handleChange} />
                      <div style={{ color: 'red' }}>{errors.step3Field1}</div>
                  </div>
                  <div className='creditCard__expiry'>
                      <label className='mb-1'>Expiry</label>
                      <input type='text' name='step3Field2' value={values.step3Field2} onChange={handleChange}/>
                      <div style={{ color: 'red' }}>{errors.step3ield2}</div>
                  </div>
              </div>
              <div className='creditCard__input flex justify-between items-center'>
                  <div className='creditCard__inputName'>
                      <label className='mb-1'>Card number</label>
                      <input type='text' name='step3Field3' value={values.step3Field3} onChange={handleChange}/>
                      <div style={{ color: 'red' }}>{errors.step3Field3}</div>
                  </div>
                  <div className='creditCard__expiry'>
                      <label className='mb-1'>CVV</label>
                      <input type='text' name='step3Field4' value={values.step3Field4} onChange={handleChange}/>
                      <div style={{ color: 'red' }}>{errors.step3Field4}</div>
                  </div>
              </div>
             </form>    )}
                 */}


     <div className='creadit__check flex items-center p-3 gap-x-6'>
        <div>
            <input type='checkbox' />
        </div>
        <div className='flex items-center justify-between w-full'>
            <div className='checkbox__name'>
                <span>Pay with Credit Card</span>
            </div>
            <div className='checkbox__img'>
                <img src='/images/visa.webp' alt='img' />
            </div>
        </div>
      </div>



    </div>
  )
}

export default DebitCard