import React, { useState } from "react";
import "./header.css";
import { Link } from "react-router-dom";
import { IoSearchOutline } from "react-icons/io5";
import { MdOutlineShoppingCart } from "react-icons/md";
import { FiStar } from "react-icons/fi";
import { FaUser, FaQuestionCircle } from "react-icons/fa";
import { SlEnvolope } from "react-icons/sl";
import Mobilenav from "./mobileNav";

const HeaderV = (props) => {
  const { count, setCount, pepper, setPepper, peppermint } = props;
  let cartData = [];
  const [navOpen, setnavClose] = useState(false);

  const navOpenbtn = () => {
    setnavClose(true);
  };

  const navClosebtn = () => {
    setnavClose(false);
  };

  return (
    <div className="header__section flex justify-between items-center">
      <header className="container mx-auto flex justify-between lg:justify-normal items-center px-5 md:px-0">
        <div className="menuBtn block md:hidden mt-2">
          <button id="navBtn" onClick={navOpenbtn}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5M12 17.25h8.25"
              />
            </svg>
          </button>
        </div>


        <div className="martLogo">
          <Link to="/">
            <img src="/images/Logo.webp" alt="img" />
          </Link>
          <Mobilenav navOpen={navOpen} navClosebtn={navClosebtn} />

        </div>

        {/* <div className='headerV__searchContainer'>
                  <IoSearchOutline className="search__icon mr-3"/>
                  <div className='headerV__input'>
                    <input type='text' placeholder='Search by product' />
                  </div>
              </div> */}

        <div className="lg:ml-auto">
          <div className="header__detail hidden md:flex">
            <div className="message flex ml-2">
              <div className="envelope mr-2">
                <SlEnvolope className="icon shopIcon" />
                <span className="number">12</span>
              </div>
              <span>Messages</span>
            </div>
            <div className="sell ml-2">
              <Link to="/seller-registration">
                <i className="fa-solid fa-shop ico mr-2"></i>
                <span>Sell</span>
              </Link>
            </div>
            <div className="shopping flex items-center ml-2">
              <FiStar className="mr-2 icon" />
              <span>Favorites</span>
            </div>
            <a href="/cart" className="message flex items-center ml-2">
              <div className="envelope mr-2">
                <MdOutlineShoppingCart className="icon" />
                <span className="number">
                  {
                    Object.keys(
                      pepper?.cachedata?.cartitems === undefined
                        ? {}
                        : pepper?.cachedata?.cartitems
                    ).length
                  }
                </span>
              </div>
              <span>My Cart</span>
            </a>

            <div className="help ml-2 flex items-center">
              <FaQuestionCircle className="icon mr-2" />
              <span>Help</span>
            </div>
            <a className="header__btn flex items-center ml-2" href="/myaccount">
              <FaUser className="fauser" />
              <button className="ml-2">My Account</button>
            </a>
          </div>
          <div className="flex ml-auto md:hidden">
            <div className="envelope mr-4 mt-2">
              <MdOutlineShoppingCart className="icon" />
              <span className="numbers">12</span>
            </div>
          </div>
        </div>
      </header>
     
    </div>
  );
};

export default HeaderV;
