import React from 'react'
import "./empty-category.css"

const EmptyCategoryEmoji = () => {
  return (
    <div className='container mx-auto grid place-content-center my-10 md:my-20'>
        <div className='emptyCategory__emoji flex justify-center items-center flex-col'>
            <div className='emoji__Img'>
                <img src='/images/emoji.webp' alt='img' />
            </div>
            <div className='emptyCategory__content mt-9 text-center'>
                <span>Sorry, we couldn’t find any results</span>
            </div>
        </div>
    </div>
  )
}

export default EmptyCategoryEmoji