import React, { useState } from 'react';
import "./payment.css";

const CreditCard = ( { values, handleChange, errors }    ) => {

//   const [isBottomBoxOpen, setIsBottomBoxOpen] = useState(false);

//   const handleBoxClick = () => {
//     setIsBottomBoxOpen(!isBottomBoxOpen);
//   };

  return (
    <div className='creditCard__container mt-5 '>


      {/* <div onClick={handleBoxClick} className='manufacturing__title flex items-center justify-between'>
        <div className='creditCard__heading w-full flex justify-between items-center p-3'>
          <div className='creditCard__title'>
            <span>Pay with Credit Card</span>
          </div>
          <div className='visa__container flex'>
            <div className='visa mr-5'>
              <img src='/images/visa.webp' alt='img' />
            </div>
            <div className='visa__icon'>
              <img src='/images/visaicon.webp' alt='img' />
            </div>
          </div>
        </div>
      </div>
      {isBottomBoxOpen && (
        <form className='p-3'>
          <div className='creditCard__input flex justify-between items-center mt-4 mb-2'>
            <div className='creditCard__inputName'>
              <label className='mb-1'>Name on card</label>
              <input type='text' name='step2Field1' value={values.step2Field1} onChange={handleChange} />
              <div style={{ color: 'red' }}>{errors.step2Field1}</div>
            </div>
            <div className='creditCard__expiry'>
              <label className='mb-1'>Expiry</label>
              <input type='text' name='step2Field2' value={values.step2Field2} onChange={handleChange} />
              <div style={{ color: 'red' }}>{errors.step2Field2}</div>
            </div>
          </div>
          <div className='creditCard__input flex justify-between items-center'>
            <div className='creditCard__inputName'>
              <label className='mb-1'>Card number</label>
              <input type='text' name='step2Field3' value={values.step2Field3} onChange={handleChange} />
              <div style={{ color: 'red' }}>{errors.step2Field3}</div>
            </div>
            <div className='creditCard__expiry'>
              <label className='mb-1'>CVV</label>
              <input type='text' name='step2Field4' value={values.step2Field4} onChange={handleChange} />
              <div style={{ color: 'red' }}>{errors.step2Field4}</div>
            </div>
          </div>
        </form>
      )} */}

      <div className='creadit__check flex items-center p-3 gap-x-6'>
        <div>
            <input type='checkbox' name='step2Field1' value={values.step2Field1} onChange={handleChange}/>
        </div>

        <div className='flex items-center justify-between w-full'>
            <div className='checkbox__name'>
                <span>Pay with Debit Card</span>
            </div>
            <div className='checkbox__img'>
                <img src='/images/visa.webp' alt='img' />
            </div>
        </div>
      </div>
      <div style={{ color: 'red' }}>{errors.step2Field1}</div>


    </div>
  );
};

export default CreditCard;
