import React, { useState, memo } from "react";
import "./productView.css";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { MdStarOutline } from "react-icons/md";
import { AppKeys } from "../../../constants/AppKeys";

const ProductViewImg = memo(({ productsinfo }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [clickedIndex, setClickedIndex] = useState(null);
  const images = [
    `${AppKeys.IMGURL + "product/" + productsinfo.desktop_image}`,
    `${AppKeys.IMGURL + "product/" + productsinfo.desktop_image}`,
    `${AppKeys.IMGURL + "product/" + productsinfo.desktop_image}`,
    `${AppKeys.IMGURL + "product/" + productsinfo.desktop_image}`,
  ];

  const preBtn = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
    setClickedIndex(null);
  };

  const nextBtn = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
    setClickedIndex(null);
  };

  const changeImageOnClick = (index) => {
    setCurrentIndex(index);
    setClickedIndex(index);
  };

  return (
    <div className="flex flex-wrap-reverse sm:flex-nowrap	 gap-x-4 w-full lg:w-1/2 xl:justify-end sm:justify-center px-5 lg:mt-5 2xl:mt-0">
      <div className="productImg__Slider__Container w-full flex justify-center items-center sm:block">
        <div className="grid place-content-center">
          <button
            className="pre__btn grid place-content-center sm:w-full"
            onClick={preBtn}
          >
            <IoIosArrowUp className="productarrow" />
          </button>
        </div>
        <div className="productImg__slider flex sm:grid place-content-center gap-3 lg:gap-5">
          {images.map((image, index) => (
            <div
              key={index}
              className={`productImg__sliderImg ${
                clickedIndex === index ? "clicked" : ""
              }`}
              onClick={() => changeImageOnClick(index)}
            >
              <img src={image} alt={`Image ${index + 1}`} />
            </div>
          ))}
        </div>
        <div className="grid place-content-center">
          <button
            className="next__btn grid place-content-center sm:w-full"
            onClick={nextBtn}
          >
            <IoIosArrowDown className="productarrow" />
          </button>
        </div>
      </div>
      <div className="productImg__image">
        <div className="productImg__price flex justify-between w-full pr-4 py-4">
          <div className="productImg__discount grid place-content-center">
            <span>-30%</span>
          </div>
          <div className="productImg__star grid place-content-center">
            <MdStarOutline />
          </div>
        </div>
        <img src={images[currentIndex]} alt={`Image ${currentIndex + 1}`} />
      </div>
    </div>
  );
});

export default ProductViewImg;
