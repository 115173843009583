import React from "react";
import Header from "../home/header/header";
import Nav from "../home/navbar/nav";
import Emptycart from "./emptyCart";
import Footer from "../home/footer/footer";
import HeaderV from "../home/header/headerV2";

const EmptyCardPage = (props) => {
  const { count, setCount, pepper, setPepper, peppermint } = props;

  return (
    <div>
      {pepper?.isUserLoggedin ? (
        <>
          <HeaderV
            count={count}
            setCount={setCount}
            pepper={pepper}
            setPepper={setPepper}
            peppermint={peppermint}
          />
        </>
      ) : (
        <>
          <Header
            count={count}
            setCount={setCount}
            pepper={pepper}
            setPepper={setPepper}
            peppermint={peppermint}
          />
        </>
      )}

      <Nav
        count={count}
        setCount={setCount}
        pepper={pepper}
        setPepper={setPepper}
        peppermint={peppermint}
      />
      <Emptycart />
      <Footer />
    </div>
  );
};

export default EmptyCardPage;
