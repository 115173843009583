import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./header.css";
import { IoIosArrowDown } from "react-icons/io";
import { FaRegEnvelope } from "react-icons/fa";
import { IoIosStarOutline } from "react-icons/io";
import { GrCircleAlert } from "react-icons/gr";
import { IoClose } from "react-icons/io5";
import { HiOutlineShoppingCart } from "react-icons/hi";
import { FiTag } from "react-icons/fi";
import { IoHomeOutline } from "react-icons/io5";
import { FiStar } from "react-icons/fi";

const Mobilenav = ({ navOpen, navClosebtn }) => {
  const [isBottomBoxOpen, setIsBottomBoxOpen] = useState(false);
  const [rotateIcon, setRotateIcon] = useState(false);

  const handleBoxClick = () => {
    setIsBottomBoxOpen(!isBottomBoxOpen);
    setRotateIcon(!rotateIcon);
  };

  return (
    <div>
      <div
        className={`mobileNav__container ${
          navOpen ? "mobileNav__containerOpen" : ""
        }`}
      >
        <div className="mobileNav__closeIcon pt-3">
          <button onClick={navClosebtn}>
            <IoClose />
          </button>
        </div>
        <div
          onClick={handleBoxClick}
          className="home__nav flex items-center justify-between"
        >
          HOME & GARDEN          
          <IoIosArrowDown
            style={{
              transform: rotateIcon ? "rotate(180deg)" : "rotate(0deg)",
              transition: "transform 0.3s ease",
            }}
          />
        </div>
        {isBottomBoxOpen && (
          <div className="px-3">
            <ul className="mobile__ul px-3">
              <li>Kitchen, Dining & Bar</li>
              <li>Small Kitchen Appliances</li>
              <li>Kitchen Tools and Gadgets</li>
              <li>Cookware</li>
              <li>Bakeware</li>
              <li>Kitchen Storage</li>
              <li>Flatware</li>
              <li>Knives and cutlery</li>
              <li>Bar accessories</li>
            </ul>
          </div>
        )}

        <div className="clothing flex items-center justify-between">
          <div>
            <span>CLOTHING</span>
          </div>
          <div>
            <IoIosArrowDown />
          </div>
        </div>

        <div className="clothing flex items-center justify-between">
          <div>
            <span>ELECTRONICS</span>
          </div>
          <div>
            <IoIosArrowDown />
          </div>
        </div>

        <div className="clothing flex items-center justify-between">
          <div>
            <span>OFFICE EQUIPMENT</span>
          </div>
          <div>
            <IoIosArrowDown />
          </div>
        </div>

        <Link to="/marketplace">
          {" "}
          <div className="mabileNav__msg flex items-center">
            <FiTag className="mr-5" />
            Shopping
          </div>{" "}
        </Link>

        <Link to="/seller-registration">
          {" "}
          <div className="mabileNav__msg flex items-center">
            <IoHomeOutline className="mr-5" />
            Sell
          </div>{" "}
        </Link>

        <Link to="/cart">
          {" "}
          <div className="mabileNav__msg flex items-center">
            <HiOutlineShoppingCart className="mr-5" />
            My Cart
          </div>{" "}
        </Link>

        <div className="mabileNav__msg flex items-center">
          <FaRegEnvelope className="mr-5" />
          MESSAGES
        </div>

        <div className="mabileNav__msg flex items-center">
          <FiStar className="mr-5" />
          FAVORITES
        </div>

        <div className="mabileNav__msg flex items-center">
          <GrCircleAlert className="mr-5" />
          HELP
        </div>

        <div className="header__btn  header__btnMob flex items-center ml-2">
          <Link to="/login">
            <button>LOGIN /</button>
          </Link>
          <Link to="/register">
            <button className="ml-2">REGISTER</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Mobilenav;
