import React, {useState} from 'react'
import "./footer.css"
import { IoIosArrowDown } from "react-icons/io";
import { AppKeys } from '../../../constants/AppKeys';
import { memo } from 'react';
import { Link } from "react-router-dom"

const Footer = memo ( () => {

    const year = new Date();

  const [isBottomBoxOpen, setIsBottomBoxOpen] = useState(false);
  const [rotateIcon, setRotateIcon] = useState(false);

  const [isBottomBoxOpen2, setIsBottomBoxOpen2] = useState(false);
  const [rotateIcon2, setRotateIcon2] = useState(false);

  const [isBottomBoxOpen3, setIsBottomBoxOpen3] = useState(false);
  const [rotateIcon3, setRotateIcon3] = useState(false);
  
  const [isBottomBoxOpen4, setIsBottomBoxOpen4] = useState(false);
  const [rotateIcon4, setRotateIcon4] = useState(false);


  const handleBoxClick = () => {
    setIsBottomBoxOpen(!isBottomBoxOpen);
    setRotateIcon(!rotateIcon);
  };

  const handleBoxClick2 = () => {
    setIsBottomBoxOpen2(!isBottomBoxOpen2);
    setRotateIcon2(!rotateIcon2);
  };

  const handleBoxClick3 = () => {
    setIsBottomBoxOpen3(!isBottomBoxOpen3);
    setRotateIcon3(!rotateIcon3);
  };

  const handleBoxClick4 = () => {
    setIsBottomBoxOpen4(!isBottomBoxOpen4);
    setRotateIcon4(!rotateIcon4);
  };
    
  return (
    <section className="footer pt-12">
         <div className="footer__container container mx-auto  flex-wrap xl:flex-nowrap gap-x-20 pb-12 pl-5 md:pl-0 hidden sm:flex">
             <div className="sign">
                 <div className="sign__title">
                     <span>Sign up for news</span>
                 </div>
                 <div className="sign__para my-6">
                     <span>Keep up to date with the latest product launches and news. Find out more about our brands and get special promo codes.</span>
                 </div>
                 <div className="sign__input">
                     <input type="text" placeholder="Your e-mail address"/>
                 </div>
                 <div className="footer__btn my-6">
                     <button>Sign up for newsletter</button>
                 </div>
                 <div className="footer__accept">
                     <input type="checkbox"/>
                     <span className="ml-3">I accept <span className="blue">the personal data management.</span></span>
                 </div>
             </div>
             <div className="manufacturing mt-10 md:mt-0">
                 <div className="manufacturing__title">
                     <span>Manufacturing Industries</span>
                 </div>
                 <ul>
                     <li className="mt-6"><a href="">B2B Food & Beverage</a></li>
                     <li className="my-4"><a href="">B2B Leather Industries</a></li>
                     <li className="my-4"><a href="">B2B Machinery & Equipment</a></li>
                     <li className="my-4"><a href="">B2B Apparel</a></li>
                     <li><a href="">B2B Cosmetics</a></li>
                 </ul>
             </div>
             <div class="retail mt-10 lg:mt-0">
                 <div class="retail__title">
                     <span>Retail and E-commerce</span>
                 </div>
                 <ul>
                     <li className="mt-6"><a href="">B2B Wholesale Trade</a></li>
                     <li className="my-4"><a href="">B2B Import & Export</a></li>
                     <li className="my-4"><a href="">B2B Consumer Electronics</a></li>
                     <li className="my-4"><a href="">B2B Furniture & HomeImprovement</a></li>
                     <li><a href="">B2B Building Materials</a></li>
                 </ul>
             </div>
             <div className="finance mt-10 xl:mt-0 ">
                 <div className="finance__title">
                     <span>Financial Services</span>
                 </div>
                 <ul>
                     <li className="mt-6"><a href="">B2B Projects</a></li>
                     <li className="my-4"><a href="">B2B Bank & Finance</a></li>
                     <li className="my-4"><a href="">B2B Fintech (Financial Technology)</a></li>
                     <li className="my-4"><a href="">B2B Insurance</a></li>
                     <li><a href="">B2B E-Commerce</a></li>
                 </ul>
             </div>
             <div className="finance mt-10 xl:mt-0">
                 <div className="finance__title">
                     <span>Digital Services</span>
                 </div>
                 <ul>
                     <li className="mt-6"><a href="">Software Development</a></li>
                     <li className="my-4"><a href="">Mobile App Development</a></li>
                     <li className="my-4"><a href="">Digital Marketing</a></li>
                     <li className="my-4"><a href="">IT Consulting</a></li>
                     <li><a href="">Mentoring Online Classes</a></li>
                 </ul>
             </div>


         </div>



       



    <div className='block sm:hidden px-5'>
      <div className='manufacturing'>
        <div onClick={handleBoxClick} className='manufacturing__title flex items-center justify-between'>
            Manufacturing Industries
             <IoIosArrowDown           style={{
            transform: rotateIcon ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: 'transform 0.3s ease',
          }}
/>
         </div>
      {isBottomBoxOpen && (
        <div className='manufacturing px-5'>
           <ul>
               <li className="mt-6"><a href="">B2B Food & Beverage</a></li>
               <li className="my-4"><a href="">B2B Leather Industries</a></li>
               <li className="my-4"><a href="">B2B Machinery & Equipment</a></li>
               <li className="my-4"><a href="">B2B Pharmaceuticals</a></li>
               <li><a href="">B2B Chemicals</a></li>
           </ul>
        </div>
      )}
     </div>



     <div className='retail'>
        <div onClick={handleBoxClick2} className='retail__title flex items-center justify-between'>
           Retail and E-commerce
                     <IoIosArrowDown           style={{
            transform: rotateIcon2 ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: 'transform 0.3s ease',
          }}
/>
         </div>
      {isBottomBoxOpen2 && (
        <div className='retail px-5'>
           <ul>
               <li className="mt-2"><a href="">B2B Wholesale Trade</a></li>
               <li className="my-2"><a href="">B2B Import & Export</a></li>
               <li className="my-2"><a href="">B2B Consumer Electronics</a></li>
               <li className="my-2"><a href="">B2B Furniture & HomeImprovement</a></li>
               <li><a href="">B2B Building Materials</a></li>
           </ul>
        </div>
      )}
     </div>



     <div className='finance'>
        <div onClick={handleBoxClick3} className='finance__title flex items-center justify-between'>
        Retail and E-commerce
             <IoIosArrowDown           style={{
            transform: rotateIcon3 ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: 'transform 0.3s ease',
          }}
/>
         </div>
      {isBottomBoxOpen3 && (
        <div className='finance px-5'>
            <ul>
                <li className="mt-2"><a href="">B2B Projects</a></li>
                <li className="my-2"><a href="">B2B Bank & Finance</a></li>
                <li className="my-2"><a href="">B2B Fintech (Financial Technology)</a></li>
                <li className="my-2"><a href="">B2B Insurance</a></li>
                <li><a href="">B2B E-Commerce</a></li>
            </ul>
        </div>
      )}
     </div>


     <div className='finance'>
        <div onClick={handleBoxClick4} className='finance__title flex items-center justify-between'>
        Financial Services
             <IoIosArrowDown           style={{
            transform: rotateIcon4 ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: 'transform 0.3s ease',
          }}
/>
         </div>
      {isBottomBoxOpen4 && (
            <div className='finance px-5'>
               <ul>
                   <li className="mt-2"><a href="">Software Development</a></li>
                   <li className="my-2"><a href="">Mobile App Development</a></li>
                   <li className="my-2"><a href="">Digital Marketing</a></li>
                   <li className="my-2"><a href="">IT Consulting</a></li>
                   <li><a href="">Mentoring Online Classes</a></li>
               </ul>
          </div>
          )}
     </div>
     


    </div>
    <div className="footer__rights container mx-auto text-center sm:flex justify-center sm:justify-between flex-wrap sm:flex-nowrap items-center py-3 sm:py-0 mt-8 sm:mt-0">
             <div className="footer__mart"> 
                 <i class="fa-regular fa-copyright mr-3"></i><span>{year.getFullYear()} HaodaMart</span>
             </div>
             <div className="footer__icon sm:flex items-center justify-center">
                 <div className="footer__social mt-2 mr-3">
                     <i className="fa-brands fa-facebook mr-2"></i>
                     <i className="fa-brands fa-square-instagram mr-2"></i>
                     <i className="fa-brands fa-youtube mr-2"></i>
                     <i className="fa-brands fa-linkedin"></i>
                 </div>
                 <div className="haodaMart__logo grid place-content-center sm:block">
                     <div className="martLogo">
                       <Link to="/"><img src={`${AppKeys.IMGURL}website/landing-images/Logo.webp`} alt="img"/></Link>
                     </div>
                 </div>
             </div>
         </div>
    </section>

  )
} )

export default Footer;