import React, { useState } from 'react'

const Upi = ( { height, onClick } ) => {

    const [isBottomBoxOpen, setIsBottomBoxOpen] = useState(false);

    const handleBoxClick = () => {
      setIsBottomBoxOpen(!isBottomBoxOpen);
    };
  
  return (
    <div  className='upi__container  mt-4'>
        {/* <div onClick={handleBoxClick} className='upi__header flex justify-between items-center'>
            <div className='upi__title'>
                <span>UPI</span>
            </div>
            <div className='upi__image'>
                <img src='/images/upi.webp' alt='img'/>
            </div>
        </div>
        {isBottomBoxOpen && (

        <div className='upi__apps flex items-center gap-x-6 mt-3'>
            <div className='upi__gpay'>
                <img src='/images/gpay.webp' alt='img'/>
            </div>
            <div className='upi__phnpay'>
                <img src='/images/phonepe.webp' alt='img'/>
            </div>
            <div className='upi__paytm'>
                <img src='/images/paytm_icon.webp' alt='img' />
            </div>
        </div>
        )} */}


     <div className='creadit__check flex items-center p-3 gap-x-6'>
        <div>
            <input type='checkbox' />
        </div>
        <div className='flex items-center justify-between w-full'>
            <div className='checkbox__name'>
                <span>UPI</span>
            </div>
            <div className='checkbox__img'>
                <img src='/images/visa.webp' alt='img' />
            </div>
        </div>
      </div>


    </div>
  )
}

export default Upi