import React, { useState } from "react";
import "./payment.css";
import CreditCard from "./creditCard";
import DebitCard from "./debitCard";
import Upi from "./upi";
import CashDelivery from "./cashDelivery";

const Payment = ( {values, handleChange, errors} ) => {


  return (
    <div>
      <div className="payment__title">
        <span>Payment</span>
      </div>
      <CreditCard values={values} handleChange={handleChange} errors={errors} />
      <DebitCard />
      <Upi  />
      {/* <CashDelivery height={box4Height} onClick={handleBox4Click}/> */}
    </div>
  );
};

export default Payment;
