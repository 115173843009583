import React from 'react'
import "./riva.css"
import { FaLocationDot } from "react-icons/fa6";
import { IoCall } from "react-icons/io5";
import { FaEnvelope } from "react-icons/fa";
import { FaSearch } from "react-icons/fa";
import { FaStar } from "react-icons/fa";




const Rivaheader = () => {
  return (
    <div>
        <section className="riva__header">
            <div className="container mx-auto">
                <header className="flex flex-wrap justify-center lg:justify-normal	lg:flex-nowrap">
                    <div className="riva-header__logo">
                        <div className="riva-header__logo__img">
                            <img src="/images/riva.webp" alt="img"/>
                        </div>
                    </div>
                    <div className="riva-header__navbar w-full">
                        <div className="flex justify-center  lg:justify-between  items-center flex-wrap px-5 md:px-0">
                            <div className="riva-header__title my-3 lg:my-0">
                                <span>Riva Trading Private Limited </span>
                            </div>
                            <div className="riva-navbar__search lg:w-1/2 flex justify-center xl:justify-end items-center">
                                <div className="riva-search__input">
                                    <input type="search" placeholder="Search for items"/>
                                </div>
                                <div className="riva-search__icons">
                                  <FaSearch />
                                </div>
                            </div>
                        </div>
                        <div className="riva-header__contact lg:flex justify-center lg:justify-between items-center flex-wrap gap-y-2 mt-4 px-1 md:px-0">
                            <div className="riva-header__container flex flex-wrap lg:justify-start justify-center">
                                <div className="riva-header__location">
                                    <div className="riva-header__location__logo grid place-content-center">
                                        <FaLocationDot className='icon__ColrBlue'/>
                                    </div>
                                    <div className="riva-header__location__content ml-1 mr-1">
                                        <span>2nd Floor, 3rd Phase,Spencer Plaza, Chennai |</span>
                                    </div> 
                                </div>
                                <div className="riva__gst">
                                    <div className="riva-gst__content">
                                        <img src="./images/teenyicons_tick-circle-solid (1).png" alt=""/>
                                    </div>
                                    <div className="gst-_para ml-1 mr-1">
                                        <span>GST 33EWBPM8521F1ZO |</span>
                                    </div>
                                </div>
                                <div className="riva__trust">
                                    <div className="riva-trust__img">
                                        <img src="./images/mdi_shield-tick.png" alt=""/>
                                    </div>
                                    <div className="riva-trust__para ml-1 mr-1">
                                        <span>TrustSEAL <span>Verified |</span></span>
                                    </div>
                                </div>
                                <div className="riva__rate">
                                    <div className="riva-rate__no">
                                        <span> 4.1/5</span>
                                    </div>
                                    <div className="riva-rate__star ml-1">
                                      <FaStar />
                                      <FaStar />
                                      <FaStar />
                                      <FaStar />
                                      <FaStar className='dark__'/>
                                    </div>
                                </div>
                            
                            </div>
                            <div className="riva-contact__detail flex items-center justify-center">
                                <div className="riva-no mr-1 flex items-center">
                                    <div className="riva-no__icon mr-1">
                                       <IoCall className='icon__ColrBlue'/>
                                    </div>
                                    <div className="riva-phn__no"><span>9042521580 / </span></div>
                                </div>
                                <div className="riva-email ml-1">
                                    <div className="riva-email__icon mr-1">
                                      <FaEnvelope className='icon__ColrBlue'/>
                                    </div>
                                    <div className="riva-email__id ml-1">
                                        <span>abulovely@gmail.com </span>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                  </header>
            </div>
        </section>

    </div>
  )
}

export default Rivaheader