import React, { useEffect, useState } from "react";
import HeaderV from "../home/header/headerV2";
import NavV from "../home/navbar/navV2";
import AddCard from "./addCard";
import Footer from "../home/footer/footer";
import { AxiosGet, AxiosPost } from "../../utilities/axioscall";
import { AppKeys } from "../../constants/AppKeys";
import { getuserid } from "../../utilities/sessionexpiry";
import Header from "../home/header/header";
import Nav from "../home/navbar/nav";
import Emptycart from "../empty-cardPage/emptyCart";

const AddcardPage = (props) => {
  const { count, setCount, pepper, setPepper, peppermint } = props;
  const [pageinit, setPageInit] = useState(false);
  let cartData = [];

  useEffect(() => {
    //  console.log(`You clicked ${JSON.stringify(count)} times`);
  }, [count]);

  useEffect(() => {}, [cartData]);

  const checkProductInCartData = (prod) => {
    let prodid = prod?.id;
    if (cartData.length > 0) {
      let checkin = cartData.filter((cart) => {
        return cart.id === prodid;
      });
      console.log(checkin);
      if (checkin.length > 0) {
        return checkin[0].quantity;
      }
    }
    return 0;
  };

  const callpageInit = async () => {};
  if (!pageinit) {
    setPageInit(true);
    callpageInit();
    if (getuserid()) {
      // setUserLoggedin(true);
      //callCartData();
    }
    //callProductsData();
  }
  return (
    <div>
      {pepper?.isUserLoggedin ? (
        <>
          <HeaderV
            count={count}
            setCount={setCount}
            pepper={pepper}
            setPepper={setPepper}
            peppermint={peppermint}
          />
        </>
      ) : (
        <>
          <Header
            count={count}
            setCount={setCount}
            pepper={pepper}
            setPepper={setPepper}
            peppermint={peppermint}
          />
        </>
      )}
      <Nav
        count={count}
        setCount={setCount}
        pepper={pepper}
        setPepper={setPepper}
        peppermint={peppermint}
      />

      {Object.keys(
        pepper?.cachedata?.cartitems === undefined
          ? {}
          : pepper?.cachedata?.cartitems
      ).length > 0 ? (
        <>
          <AddCard
            count={count}
            setCount={setCount}
            pepper={pepper}
            setPepper={setPepper}
            peppermint={peppermint}
          />
        </>
      ) : (
        <>
          <Emptycart />
        </>
      )}

      <Footer />
    </div>
  );
};

export default AddcardPage;
