import React, { memo } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IoIosArrowForward } from "react-icons/io";
import "./Product.css";
import { AppKeys } from "../../constants/AppKeys";

const Product = memo((props) => {
  const { count, setCount, pepper, setPepper, peppermint } = props;
  var settings = {
    dots: true,
    infinite: true,
    autoplay: 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div>
      {/* CAROUSEL SLIDE  */}
      <Slider {...settings}>
        <section>
          <div className="slidee container mx-auto mt-20">
            <div className="flex justify-around flex-wrap md:flex-nowrap md:pt-0">
              <div className=" pt-10 md:pt-20 lg:pt-20 xl:pt-20">
                <div className="bestline">
                  Best Deal Online on smart watches
                </div>
                <div className="smart mt-5">SMART WEARABLE.</div>
                <div className="discount mt-5">UP to 80% OFF</div>
              </div>
              <div className="mt-5 watch hidden lg:block">
                <img
                  src={`${AppKeys.IMGURL}categories/top-category-img5.webp`}
                  alt="Watch"
                />
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="flex-wrap md:flex-nowrap slidee container mx-auto mt-20">
            <div className="flex justify-around">
              <div className="ml-3 pt-20">
                <div className="bestline">
                  Best Deal Online on smart watches
                </div>
                <div className="smart mt-5">SMART WEARABLE.</div>
                <div className="discount mt-5">UP to 80% OFF</div>
              </div>
              <div className="mt-5 watch hidden md:block lg:block xl:block">
                <img
                  src={`${AppKeys.IMGURL}categories/top-category-img5.webp`}
                  alt="Watch"
                />
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="flex-wrap md:flex-nowrap slidee container mx-auto mt-20">
            <div className="flex justify-around">
              <div className="ml-3 pt-20">
                <div className="bestline">
                  Best Deal Online on smart watches
                </div>
                <div className="smart mt-5">SMART WEARABLE.</div>
                <div className="discount mt-5">UP to 80% OFF</div>
              </div>
              <div className="mt-5 watch hidden md:block lg:block xl:block">
                <img
                  src={`${AppKeys.IMGURL}categories/top-category-img5.webp`}
                  alt="Watch"
                />
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="flex-wrap md:flex-nowrap slidee container mx-auto mt-20">
            <div className="flex justify-around">
              <div className="ml-3 pt-20">
                <div className="bestline">
                  Best Deal Online on smart watches
                </div>
                <div className="smart mt-5">SMART WEARABLE.</div>
                <div className="discount mt-5">UP to 80% OFF</div>
              </div>
              <div className="mt-5 watch hidden md:block lg:block xl:block">
                <img
                  src={`${AppKeys.IMGURL}categories/top-category-img5.webp`}
                  alt="Watch"
                />
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="flex-wrap md:flex-nowrap slidee container mx-auto mt-20">
            <div className="flex justify-around">
              <div className="ml-3 pt-20">
                <div className="bestline">
                  Best Deal Online on smart watches
                </div>
                <div className="smart mt-5">SMART WEARABLE.</div>
                <div className="discount mt-5">UP to 80% OFF</div>
              </div>
              <div className="mt-5 watch hidden md:block lg:block xl:block">
                <img
                  src={`${AppKeys.IMGURL}categories/top-category-img5.webp`}
                  alt="Watch"
                />
              </div>
            </div>
          </div>
        </section>
      </Slider>

      {/* SHOP CATEGORIES  */}
      <section>
        <div className="flex container mx-auto justify-between mt-10 px-3">
          <div className="shop">
            Shop from <span className="category">Top Categories</span>
          </div>
          <div className="view flex">
            View all
            <IoIosArrowForward />
          </div>
        </div>
        <div className="line w-full container mx-auto mt-10"></div>

        <div className="container mx-auto">
          <div className="mt-10 gap-5 flex-wrap xl:flex-nowrap xl:justify-between container mx-auto flex justify-center">
            <div className="ellipse flex justify-center items-center flex-col">
              <div className="image p-5">
                <img
                  src={`${AppKeys.IMGURL}categories/top-category-img1.webp`}
                  alt="Watch"
                />
              </div>
              <div>
                <div className="shoptext mt-5">Mobile</div>
              </div>
            </div>

            <div className="ellipse flex justify-center items-center flex-col">
              <div className="image p-5">
                <img
                  src={`${AppKeys.IMGURL}categories/top-category-img2.webp`}
                  alt="Watch"
                />
              </div>
              <div>
                <div className="shoptext mt-5">Cosmetics</div>
              </div>
            </div>
            <div className="ellipse flex justify-center items-center flex-col">
              <div className="image p-5">
                <img
                  src={`${AppKeys.IMGURL}categories/top-category-img3.webp`}
                  alt="Watch"
                />
              </div>

              <div>
                <div className="shoptext mt-5">Electronics</div>
              </div>
            </div>
            <div className="ellipse flex justify-center items-center flex-col">
              <div className="image p-5">
                <img
                  src={`${AppKeys.IMGURL}categories/top-category-img4.webp`}
                  alt="Watch"
                />
              </div>
              <div>
                <div className="shoptext mt-5">Furniture</div>
              </div>
            </div>
            <div className="ellipse flex justify-center items-center flex-col">
              <div className="image p-7">
                <img
                  src={`${AppKeys.IMGURL}categories/top-category-img5.webp`}
                  alt="Watch"
                />
              </div>
              <div>
                <div className="shoptext mt-5">Watches</div>
              </div>
            </div>
            <div className="ellipse flex justify-center items-center flex-col">
              <div className="image p-5">
                <img
                  src={`${AppKeys.IMGURL}categories/top-category-img6.webp`}
                  alt="Watch"
                />
              </div>
              <div>
                <div className="shoptext mt-5">Decor</div>
              </div>
            </div>
            <div className="ellipse flex justify-center items-center flex-col">
              <div className="image p-5">
                <img
                  src={`${AppKeys.IMGURL}categories/top-category-img7.webp`}
                  alt="Watch"
                />
              </div>
              <div>
                <div className="shoptext mt-5">Accessories</div>
              </div>
            </div>
            <div className="ellipse flex justify-center items-center flex-col">
              <div className="image p-5">
                <img
                  src={`${AppKeys.IMGURL}categories/top-category-img8.webp`}
                  alt="Watch"
                />
              </div>
              <div>
                <div className="shoptext mt-5">Handicraft</div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* GRAB THE BEST DEALS ON SMARTPHONES */}
      <section>
        <div className="flex container mx-auto justify-between mt-20 px-3">
          <div className="grab">
            Grab the best deal on <span className="smart1">Smartphones</span>
          </div>
          <div className="view flex">
            View all
            <IoIosArrowForward />
          </div>
        </div>
        <div className="line w-full container mx-auto mt-10"></div>
        <div className="flex-wrap md:flex-wrap lg:flex-wrap gap-10 md:gap-5 lg:gap-3 xl:gap-4 container mx-auto flex justify-center xl:justify-between ">
          <div className="product__phn bg-gray-100 w-60 mb-5 rounded overflow-hidden  mt-10">
            <div className="label ml-auto">
              <div className="text__offer text-center pt-1">56% OFF</div>
            </div>
            <div className="phone3 mx-auto">
              <img
                src={`${AppKeys.IMGURL}categories/top-category-img1.webp`}
                alt="Watch"
              />
            </div>
            <div class="bg-white mt-11 pb-5">
              <div className="galaxy pt-5 pl-3">Galaxy M33 (4GB | 64 GB )</div>
              <div className="flex gap-3 mt-3 pl-3">
                <div className="n1">₹16999</div>
                <div className="n2 line-through">₹24999</div>
              </div>
              <div className="line2 mt-5 ml-3"></div>
              <div className="save pt-3 ml-5">Save - ₹8000</div>
            </div>
          </div>

          <div className="product__phn bg-gray-100 w-60 mb-5 rounded overflow-hidden  mt-10">
            <div className="label ml-auto">
              <div className="text__offer text-center pt-1">56% OFF</div>
            </div>
            <div className="phone2 mx-auto">
              <img
                src={`${AppKeys.IMGURL}website/smart-phones-images/smart-phone-images2.webp`}
                alt="Watch"
              />
            </div>
            <div class="bg-white mt-14 pb-5">
              <div className="galaxy pt-5 pl-3">Galaxy M13 (4GB | 64 GB )</div>
              <div className="flex gap-3 mt-3 pl-3">
                <div className="n1">₹10499</div>
                <div className="n2 line-through">₹14999</div>
              </div>
              <div className="line2 mt-5 ml-3"></div>
              <div className="save pt-3 ml-5">Save - ₹4500</div>
            </div>
          </div>
          <div className="product__phn bg-gray-100 w-60 mb-5 rounded overflow-hidden  mt-10">
            <div className="label ml-auto">
              <div className="text__offer text-center pt-1">56% OFF</div>
            </div>
            <div className="phone3 mx-auto">
              <img
                src={`${AppKeys.IMGURL}website/smart-phones-images/smart-phone-images3.webp`}
                alt="Watch"
              />
            </div>
            <div class="bg-white mt-11 pb-5">
              <div className="galaxy pt-5 pl-3">Galaxy M33 (4GB | 64 GB )</div>
              <div className="flex gap-3 mt-3 pl-3">
                <div className="n1">₹16999</div>
                <div className="n2 line-through">₹24999</div>
              </div>
              <div className="line2 mt-5 ml-3"></div>
              <div className="save pt-3 ml-5">Save - ₹8000</div>
            </div>
          </div>
          <div className="product__phn bg-gray-100 w-60 mb-5 rounded overflow-hidden  mt-10">
            <div className="label ml-auto">
              <div className="text__offer text-center pt-1">56% OFF</div>
            </div>
            <div className="phone4 mx-auto">
              <img
                src={`${AppKeys.IMGURL}website/smart-phones-images/smart-phone-images4.webp`}
                alt="Watch"
              />
            </div>
            <div class="bg-white mt-11 pb-5">
              <div className="galaxy pt-5 pl-3">Galaxy M53 (4GB | 64 GB )</div>
              <div className="flex gap-3 mt-3 pl-3">
                <div className="n1">₹31999</div>
                <div className="n2 line-through">₹40999</div>
              </div>
              <div className="line2 mt-5 ml-3"></div>
              <div className="save pt-3 ml-5">Save - ₹9000</div>
            </div>
          </div>
          <div className="product__phn bg-gray-100 w-60 mb-5 rounded overflow-hidden mt-10">
            <div className="label ml-auto">
              <div className="text__offer text-center pt-1">56% OFF</div>
            </div>
            <div className="phone5 mx-auto">
              <img
                src={`${AppKeys.IMGURL}website/smart-phones-images/smart-phone-images5.webp`}
                alt="Watch"
              />
            </div>
            <div class="bg-white mt-5 pb-5">
              <div className="galaxy pt-5 pl-3">Galaxy S22 Ultra 4gb</div>
              <div className="flex gap-3 mt-3 pl-3">
                <div className="n1">₹57999</div>
                <div className="n2 line-through">₹85999</div>
              </div>
              <div className="line2 mt-5 ml-3"></div>
              <div className="save pt-3 ml-5">Save - ₹18000</div>
            </div>
          </div>
          <div className="product__phn bg-gray-100 w-60 mb-5 rounded overflow-hidden  mt-10">
            <div className="label ml-auto">
              <div className="text__offer text-center pt-1">56% OFF</div>
            </div>
            <div className="phone6 mx-auto">
              <img
                src={`${AppKeys.IMGURL}website/smart-phones-images/smart-phone-images6.webp`}
                alt="Watch"
              />
            </div>
            <div class=" bg-white mt-5 pb-5">
              <div className="galaxy pt-5 pl-3">Galaxy S22 Ultra</div>
              <div className="flex gap-3 mt-3 pl-3">
                <div className="n1">₹67999</div>
                <div className="n2 line-through">₹85999</div>
              </div>
              <div className="line2 mt-5 ml-3"></div>
              <div className="save pt-3 ml-5">Save - ₹18000</div>
            </div>
          </div>
        </div>
      </section>

      {/* DAILY ESSENTIALS */}
      <section>
        <div className="flex container mx-auto justify-between mt-10 xl:mt-32 px-3">
          <div className="daily">
            Daily <span className="essential">Essentials </span>
          </div>
          <div className="view flex">
            View all
            <IoIosArrowForward />
          </div>
        </div>
        <div className="line w-full container mx-auto mt-10"></div>
        <div className="flex-wrap md:flex-wrap lg:flex-wrap container mx-auto flex justify-center gap-5 md:gap-10 xl:flex-nowrap xl:justify-between mt-10 p-3">
          <div className="backimg">
            <div className="groceries p-3">
              <img
                src={`${AppKeys.IMGURL}website/daily-essentials-images/daily-ess-img4.webp`}
                alt="Watch"
              />
            </div>
            <div className="mt-5">
              <div className="dailytext">Groceries</div>
              <div className="dailydiscount">UP to 50% OFF</div>
            </div>
          </div>
          <div className="backimg mt-10 sm:mt-0">
            <div className="groceries p-3">
              <img
                src={`${AppKeys.IMGURL}website/daily-essentials-images/daily-ess-img5.webp`}
                alt="Watch"
              />
            </div>
            <div className="mt-5">
              <div className="dailytext">Vegetables</div>
              <div className="dailydiscount">UP to 50% OFF</div>
            </div>
          </div>
          <div className="backimg mt-10 sm:mt-0">
            <div className="groceries p-3">
              <img
                src={`${AppKeys.IMGURL}website/daily-essentials-images/daily-ess-img8.webp`}
                alt="Watch"
              />
            </div>
            <div className="mt-5">
              <div className="dailytext">Fruits</div>
              <div className="dailydiscount">UP to 50% OFF</div>
            </div>
          </div>
          <div className="backimg mt-10 sm:mt-0 lg:mt-0 xl:mt-0">
            <div className="groceries p-3">
              <img
                src={`${AppKeys.IMGURL}website/daily-essentials-images/daily-ess-img2.webp`}
                alt="Watch"
              />
            </div>
            <div className="mt-5">
              <div className="dailytext">Strawberry</div>
              <div className="dailydiscount">UP to 50% OFF</div>
            </div>
          </div>

          <div className="backimg mt-10 sm:mt-0 xl:mt-0">
            <div className="groceries p-3">
              <img
                src={`${AppKeys.IMGURL}website/daily-essentials-images/daily-ess-img1.webp`}
                alt="Watch"
              />
            </div>
            <div className="mt-5">
              <div className="dailytext">Mango</div>
              <div className="dailydiscount">UP to 50% OFF</div>
            </div>
          </div>
          <div className="backimg mt-10 sm:mt-0 xl:mt-0">
            <div className="groceries p-5">
              <img
                src={`${AppKeys.IMGURL}website/daily-essentials-images/daily-ess-img3.webp`}
                alt="Watch"
              />
            </div>
            <div className="mt-5">
              <div className="dailytext">Cherry</div>
              <div className="dailydiscount">UP to 50% OFF</div>
            </div>
          </div>
          <div className="backimg mt-10 sm:mt-0 xl:mt-0">
            <div className="groceries p-5">
              <img
                src={`${AppKeys.IMGURL}website/daily-essentials-images/daily-ess-img7.webp`}
                alt="Watch"
              />
            </div>
            <div className="mt-5">
              <div className="dailytext">Apple</div>
              <div className="dailydiscount">UP to 50% OFF</div>
            </div>
          </div>
        </div>
      </section>

      {/* POWER TOOLS  */}
      <section className="discount__section mt-28">
        <div className="discount__container container mx-auto flex items-center justify-center flex-wrap py-6">
          <div className="flex items-center justify-center flex-wrap md:flex-nowrap w-full lg:w-1/2">
            <div className="discount__image mr-10">
              <div className="discount__img">
                <img
                  src={`${AppKeys.IMGURL}website/daily-essentials-images/daily-ess-img6.webp`}
                  alt="Watch"
                />
              </div>
            </div>
            <div className="discount__word flex items-center px-5 lg:px-0">
              <span className="discount__num sm:mr-4">30%</span>
              <span className="discount__le">on power tools</span>
            </div>
          </div>
          <div className="flex items-center justify-center lg:justify-start flex-wrap lg:flex-nowrap w-full lg:w-1/2 mt-5 lg:mt-0 px-5 md:px-8 lg:px-0">
            <div className="discount__para text-justify lg:text-left lg:mr-2 xl:mr-2">
              <span>
                Grab your favuorite Product at an unbelievable 30% off! Act
                fast, these deals won't last forever.
              </span>
            </div>
            <div className="discount__btn mt-7">
              <button>Check offer</button>
            </div>
          </div>
        </div>
      </section>

      {/* ALL PRODUCTS  */}
      <section>
        <div className="flex container mx-auto justify-between mt-20 px-3">
          <div className="daily">All Products</div>
          <div className="view flex">
            View all
            <IoIosArrowForward />
          </div>
        </div>
        <div className="line w-full container mx-auto mt-10"></div>
        <div className="flex-wrap md:flex-wrap lg:flex-wrap container mx-auto flex justify-center gap-5  md:gap-10 xl:flex-nowrap xl:justify-between  p-3">
          <div className="backimg mt-20 ">
            <div className="groceries">
              <img
                src={`${AppKeys.IMGURL}website/product-images/produ-img3.webp`}
                alt="Watch"
              />
            </div>
            <div className="mt-10">
              <div className="dailytext">Liquid Cleanser</div>
            </div>
          </div>
          <div className="backimg mt-20">
            <div className="groceries ">
              <img
                src={`${AppKeys.IMGURL}website/product-images/produ-img5.webp`}
                alt="Watch"
              />
            </div>
            <div className="mt-10">
              <div className="dailytext">Baby Suits</div>
            </div>
          </div>
          <div className="backimg mt-20">
            <div className="groceries ">
              <img
                src={`${AppKeys.IMGURL}website/product-images/produ-img13.webp`}
                alt="Watch"
              />
            </div>
            <div className="mt-10">
              <div className="dailytext">Wood Chair</div>
            </div>
          </div>
          <div className="backimg mt-20">
            <div className="groceries ">
              <img
                src={`${AppKeys.IMGURL}website/product-images/produ-img8.webp`}
                alt="Watch"
              />
            </div>
            <div className="mt-10">
              <div className="dailytext">Baby Gift Set</div>
            </div>
          </div>
          <div className="backimg mt-20">
            <div className="groceries ">
              <img
                src={`${AppKeys.IMGURL}website/product-images/produ-img4.webp`}
                alt="Watch"
              />
            </div>
            <div className="mt-10">
              <div className="dailytext">Baby T-Shirt Set</div>
            </div>
          </div>
          <div className="backimg mt-20">
            <div className="groceries ">
              <img
                src={`${AppKeys.IMGURL}website/product-images/produ-img6.webp`}
                alt="Watch"
              />
            </div>
            <div className="mt-10">
              <div className="dailytext">Baby Wipes</div>
            </div>
          </div>
          <div className="backimg mt-20">
            <div className="groceries ">
              <img
                src={`${AppKeys.IMGURL}website/product-images/produ-img14.webp`}
                alt="Watch"
              />
            </div>
            <div className="mt-10">
              <div className="dailytext">Hand Bags</div>
            </div>
          </div>
        </div>
        <div className="flex-wrap md:flex-wrap lg:flex-wrap container mx-auto flex justify-center md:gap-10 xl:flex-nowrap xl:justify-between mt-3 p-3">
          <div className="backimg mt-20">
            <div className="groceries">
              <img
                src={`${AppKeys.IMGURL}website/product-images/produ-img11.webp`}
                alt="Watch"
              />
            </div>
            <div className="mt-10">
              <div className="dailytext">Grey Sofa</div>
            </div>
          </div>
          <div className="backimg mt-20">
            <div className="groceries">
              <img
                src={`${AppKeys.IMGURL}website/product-images/produ-img12.webp`}
                alt="Watch"
              />
            </div>
            <div className="mt-10">
              <div className="dailytext">Single Sofa</div>
            </div>
          </div>
          <div className="backimg mt-20">
            <div className="groceries">
              <img
                src={`${AppKeys.IMGURL}website/product-images/produ-img15.webp`}
                alt="Watch"
              />
            </div>
            <div className="mt-10">
              <div className="dailytext">Sport Shoes</div>
            </div>
          </div>
          <div className="backimg mt-20">
            <div className="groceries">
              <img
                src={`${AppKeys.IMGURL}website/product-images/produ-img16.webp`}
                alt="Watch"
              />
            </div>
            <div className="mt-10">
              <div className="dailytext">Men T-Shirt</div>
            </div>
          </div>
          <div className="backimg mt-20">
            <div className="groceries">
              <img
                src={`${AppKeys.IMGURL}website/product-images/produ-img10.webp`}
                alt="Watch"
              />
            </div>
            <div className="mt-10">
              <div className="dailytext">Baby Pants</div>
            </div>
          </div>
          <div className="backimg mt-20">
            <div className="groceries">
              <img
                src={`${AppKeys.IMGURL}website/product-images/produ-img17.webp`}
                alt="Watch"
              />
            </div>
            <div className="mt-10">
              <div className="dailytext">Handicrafts</div>
            </div>
          </div>
          <div className="backimg mt-20">
            <div className="groceries">
              <img
                src={`${AppKeys.IMGURL}website/product-images/produ-img18.webp`}
                alt="Watch"
              />
            </div>
            <div className="mt-10">
              <div className="dailytext">Teddy Bear</div>
            </div>
          </div>
        </div>
        <div className="flex-wrap prod-align md:flex-wrap lg:flex-wrap container mx-auto flex justify-center md:gap-10 xl:flex-nowrap xl:justify-between mt-10 p-3">
          <div className="backimg mt-5 xl:mt-5 text-center md:text-left ">
            <div className="groceries">
              <img
                src={`${AppKeys.IMGURL}website/product-images/produ-img1.webp`}
                alt="Watch"
              />
            </div>
            <div className="mt-16">
              <div className="dailytext">Watches</div>
            </div>
          </div>
          <div className="backimg mt-20 md:mt-5">
            <div className="groceries">
              <img
                src={`${AppKeys.IMGURL}website/product-images/produ-img9.webp`}
                alt="Watch"
              />
            </div>
            <div className="mt-16">
              <div className="dailytext">Baby Cloth</div>
            </div>
          </div>
          <div className="backimg mt-20 md:md:mt-5  ">
            <div className="groceries">
              <img
                src={`${AppKeys.IMGURL}website/daily-essentials-images/daily-ess-img8.webp`}
                alt="Watch"
              />
            </div>
            <div className="mt-16">
              <div className="dailytext">Fruits</div>
            </div>
          </div>
          <div className="backimg mt-20 md:md:mt-5  ">
            <div className="groceries">
              <img
                src={`${AppKeys.IMGURL}website/smart-phones-images/smart-phone-images1.webp`}
                alt="Watch"
              />
            </div>
            <div className="mt-16">
              <div className="dailytext">Mobile</div>
            </div>
          </div>
          <div className="backimg mt-20 md:mt-5 lg:mt-16 xl:mt-5 ">
            <div className="groceries">
              <img
                src={`${AppKeys.IMGURL}product/global-riva/desktop/global-riva-img-d4.webp`}
                alt="Watch"
              />
            </div>
            <div className="mt-16">
              <div className="dailytext">Laundry Detergent</div>
            </div>
          </div>
          <div className="backimg mt-20 md:mt-5 lg:mt-16 xl:mt-5">
            <div className="groceries">
              <img
                src={`${AppKeys.IMGURL}website/product-images/produ-img19.webp`}
                alt="Watch"
              />
            </div>
            <div className="mt-16">
              <div className="dailytext">Daily Essentials</div>
            </div>
          </div>
          <div className="backimg mt-20 md:mt-5 lg:mt-16 xl:mt-5">
            <div className="groceries">
              <img
                src={`${AppKeys.IMGURL}website/product-images/produ-img20.webp`}
                alt="Watch"
              />
            </div>
            <div className="mt-16">
              <div className="dailytext">Mobile Accessories</div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
});

export default Product;
