import React from 'react'
import './SellerDashboard.css';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useState } from 'react';
const BankDetails = () => {
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [error, setError] = useState('');

  const handleCheckboxChange = (accountType) => {
    setSelectedAccount(accountType);
    setError('');
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!selectedAccount) {
      setError('Please select an account type');
    } else {
      // Your form submission logic here
      console.log('Selected Account Type:', selectedAccount);
    }
  };
  const initialValues = {
    address: '',
    pincode: '',
    city: '',
    state: '',
  };

  const validationSchema = Yup.object({
    address: Yup.string().required('Address is required'),
    pincode: Yup.string()
      .matches(/^\d+$/, 'Pincode must only contain numbers')
      .required('Pincode is required'),
    city: Yup.string().required('City is required'),
    state: Yup.string().required('State is required'),
  });

  const onSubmit = (values, { resetForm }) => {
    console.log('Form submitted with values:', values);
    toast.success('Bank Details are Saved Successfully', {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 3000,
    });
    resetForm();
  };

  return (
    <div className='container mx-auto bg-white pb-5 rounded-xl mt-10'>
      <div className='p-5'>
        <div className='bankMain'>
          <div>
            <label className='bankDetailsLabel'>Bank Details</label> <br /> <br />
            <label className='bankTypeLabel'>Type of Bank Account</label>
          </div>
          <div className='flex gap-5 mt-5'>
            <div className='flex gap-2'>
              <input type='checkbox'
                id='currentAccount'
                name='accountType'
                checked={selectedAccount === 'current'}
                onChange={() => handleCheckboxChange('current')} />
              <div htmlFor='currentAccount' className='currentAccLabel'>Current</div>
            </div>
            <div className='flex gap-2'>
              <input type='checkbox'
                id='savingsAccount'
                name='accountType'
                checked={selectedAccount === 'savings'}
                onChange={() => handleCheckboxChange('savings')} />
              <div htmlFor='savingsAccount' className='currentAccLabel'>Savings</div>
            </div>
            {error && <div className='text-red-500'>{error}</div>}
          </div>
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            <Form>
              <div className='sm:grid grid-col-1 xl:grid grid-cols-2 gap-10 mt-5'>
                <div class="relative w-full mt-5 sm:mt-0">
                  <Field
                    type='text'
                    className='w-full px-4 py-5 border border-gray-500 rounded-lg duration-300 ease-out text-base outline-none'
                    id='myInput'
                    placeholder=''
                    name='address'
                  />
                  <ErrorMessage name='address' component='p' className='text-red-500' />
                  <label
                    class="absolute top-5 left-4 text-base text-gray-600 pointer-events-none  duration-300 ease-out accinputtxt"
                    for="myInput">
                    Address
                  </label>
                </div>
                <div class="relative w-full mt-5 sm:mt-0">
                  <Field
                    type='text'
                    className='w-full px-4 py-5 border border-gray-500 rounded-lg duration-300 ease-out text-base outline-none'
                    id='myInput'
                    placeholder=' '
                    name='pincode'
                  />
                  <ErrorMessage name='pincode' component='p' className='text-red-500' />
                  <label
                    class="absolute top-5 left-4 text-base text-gray-600 pointer-events-none  duration-300 ease-out accinputtxt"
                    for="myInput">
                    Pincode
                  </label>
                </div>
                <div class="relative w-full mt-5 sm:mt-0">
                  <Field
                    type="text"
                    class="w-full px-4 py-5  border border-gray-500 rounded-lg  duration-300 ease-out text-base outline-none"
                    id="myInput"
                    placeholder=" "
                    name='city'
                  />
                  <ErrorMessage name='city' component='p' className='text-red-500' />

                  <label
                    class="absolute top-5 left-4 text-base text-gray-600 pointer-events-none  duration-300 ease-out accinputtxt"
                    for="myInput">
                    City
                  </label>
                </div>
                <div class="relative w-full mt-5 sm:mt-0">
                  <Field
                    type="text"
                    class="w-full px-4 py-5  border border-gray-500 rounded-lg  duration-300 ease-out text-base outline-none"
                    id="myInput"
                    placeholder=" "
                    name='state'
                  />
                  <ErrorMessage name='state' component='p' className='text-red-500' />
                  <label class="absolute top-5 left-4 text-base text-gray-600 pointer-events-none  duration-300 ease-out accinputtxt" for="myInput">State</label>
                </div>
              </div>
              <div className='outline-none bg-blue-500 mt-5 w-full lg:w-56 lg:ml-auto md:w-52 md:mx-auto lg:mx-5 p-5 rounded-md xl:ml-auto 2xl:ml-[84%]'>
                <button type='submit' className='text-base saveBusinessAddr text-white  w-full h-full'>Save</button>
              </div>
            </Form>

          </Formik>
        </div>
      </div>
    </div>
  )
}

export default BankDetails
