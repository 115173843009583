import React, { useEffect, useState } from "react";
import "./LandingPage.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IoIosSearch } from "react-icons/io";
import Manufacture from "./Manufacture";
import Product from "./Product";
import HeaderV from "../home/header/headerV2";
import Nav from "../home/navbar/nav";
import Footer from "../home/footer/footer";
import { AxiosGet, AxiosPost } from "../../utilities/axioscall";
import { AppKeys } from "../../constants/AppKeys";
import { getuserid } from "../../utilities/sessionexpiry";
import Header from "../home/header/header";

const Products = (props) => {
  const { count, setCount, pepper, setPepper, peppermint } = props;
  const [pageinit, setPageInit] = useState(false);

  var settings = {
    dots: true,
    infinite: true,
    autoplay: 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const [activeTab, setActiveTab] = useState("products");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const getTablineStyles1 = (tab) => {
    return {
      backgroundColor: activeTab === tab ? "#FFFF" : "transparent",
      height: "6px",
      width: "136px",
      margin: "0 auto",
    };
  };
  const getTablineStyles = (tab) => {
    return {
      backgroundColor: activeTab === tab ? "#FFFF" : "transparent",
      height: "6px",
      width: "226px",
      margin: "0 auto",
    };
  };

  useEffect(() => {
    console.log(`You clicked ${JSON.stringify(count)} times`);
  }, [count]);

  const checkProductInCartData = (prod) => {
    // let prodid = prod?.id;
    // if (cartData.length > 0) {
    //   let checkin = cartData.filter((cart) => {
    //     return cart.id === prodid;
    //   });
    //   console.log(checkin);
    //   if (checkin.length > 0) {
    //     return checkin[0].quantity;
    //   }
    // }
    return 0;
  };

  const year = new Date();

  const callpageInit = async () => {};
  if (!pageinit) {
    setPageInit(true);
    callpageInit();
    if (getuserid()) {
      // setUserLoggedin(true);
      // callCartData();
    }
    //callProductsData();
  }

  return (
    <section>
      {/* NAVBAR  */}

      {pepper?.isUserLoggedin ? (
        <>
          <HeaderV
            count={count}
            setCount={setCount}
            pepper={pepper}
            setPepper={setPepper}
            peppermint={peppermint}
          />
        </>
      ) : (
        <>
          <Header
            count={count}
            setCount={setCount}
            pepper={pepper}
            setPepper={setPepper}
            peppermint={peppermint}
          />
        </>
      )}

      <Nav
        count={count}
        setCount={setCount}
        pepper={pepper}
        setPepper={setPepper}
        peppermint={peppermint}
      />

      {/* NAV DROPDOWNS  */}

      {/* BACKGROUND IMAGE  AND TAB NAVIGATIONS*/}
      <section className="">
        <div className="backgroundimg w-full h-auto pb-10">
          {/* <img src={Background} alt='Background' /> */}
          <div className="flex flex-wrap md:flex-nowrap gap-20 pt-10 justify-center items-center text-center ml-5">
            <div
              className={`products cursor-pointer text-sm`}
              onClick={() => handleTabClick("products")}
            >
              Products
              <div
                className="tabline"
                style={getTablineStyles1("products")}
              ></div>
              {/* <div className='tabline'></div> */}
            </div>

            <div
              className={`manufacturers cursor-pointer ml-6`}
              onClick={() => handleTabClick("manufacturers")}
            >
              Manufacturers
              {/* <div className='manufacturers'></div> */}
              <div
                className="tabline"
                style={getTablineStyles("manufacturers")}
              ></div>
            </div>
          </div>
          <div className="w-full grid place-content-center lg:p-5">
            <div className="box mt-10 sm:-mt-0 md:mt-16 lg:mt-16 xl:mt-16">
              <div className="product__SearchBox">
                <input
                  type="text"
                  placeholder="Search you product"
                  className="border-none outline-none w-full"
                />
              </div>
              <div className="searchbox cursor-pointer bg-white">
                <IoIosSearch className="mr-3 search__IconPro" />
                <button className="border-none outline-none flex gap-5 text-white searchtext text-center text-sm sm:text-xs md:text-md lg:text-xl xl:text-3xl">
                  Search
                </button>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap md:flex-wrap lg:flex-nowrap  gap-5 pt-16 justify-center itmes-center text-center">
            <div className="faqtext flex">Frequently searched :</div>
            <div className="faq cursor-pointer">
              <div className="faqtext ">kidstoys</div>
            </div>
            <div className="faq cursor-pointer">
              <div className="faqtext">menshoes</div>
            </div>
            <div className="faq cursor-pointer">
              <div className="faqtext">Watches</div>
            </div>
            <div className="faq cursor-pointer">
              <div className="faqtext">Watercan</div>
            </div>
            <div className="faq cursor-pointer">
              <div className="faqtext">Bags</div>
            </div>
          </div>
        </div>
      </section>
      {activeTab === "products" && (
        <div>
          <Product
            count={count}
            setCount={setCount}
            pepper={pepper}
            setPepper={setPepper}
            peppermint={peppermint}
          />
        </div>
      )}
      {activeTab === "manufacturers" && (
        <div>
          <Manufacture />
        </div>
      )}
      {/* <Slider {...settings}>
                <section>
                    <div className='slide container mx-auto mt-20'>
                        <div className='flex justify-around flex-wrap md:flex-nowrap md:pt-0'>
                            <div className='ml-3 pt-10 md:pt-20 lg:pt-20 xl:pt-20'>
                                <div className='bestline'>
                                    Best Deal Online on smart watches
                                </div>
                                <div className='smart mt-5'>
                                    SMART WEARABLE.
                                </div>
                                <div className='discount mt-5'>
                                    UP to 80% OFF
                                </div>
                            </div>
                            <div className='pt-5 hidden md:block lg:block xl:block'>
                                <img src={Watch} alt='Watch' />
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className='flex-wrap md:flex-nowrap slide container mx-auto mt-20'>
                        <div className='flex justify-around'>
                            <div className='ml-3 pt-20'>
                                <div className='bestline'>
                                    Best Deal Online on smart watches
                                </div>
                                <div className='smart mt-5'>
                                    SMART WEARABLE.
                                </div>
                                <div className='discount mt-5'>
                                    UP to 80% OFF
                                </div>
                            </div>
                            <div className='pt-5 hidden md:block lg:block xl:block'>
                                <img src={Watch} alt='Watch' />
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className='flex-wrap md:flex-nowrap slide container mx-auto mt-20'>
                        <div className='flex justify-around'>
                            <div className='ml-3 pt-20'>
                                <div className='bestline'>
                                    Best Deal Online on smart watches
                                </div>
                                <div className='smart mt-5'>
                                    SMART WEARABLE.
                                </div>
                                <div className='discount mt-5'>
                                    UP to 80% OFF
                                </div>
                            </div>
                            <div className='pt-5 hidden md:block lg:block xl:block'>
                                <img src={Watch} alt='Watch' />
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className='flex-wrap md:flex-nowrap slide container mx-auto mt-20'>
                        <div className='flex justify-around'>
                            <div className='ml-3 pt-20'>
                                <div className='bestline'>
                                    Best Deal Online on smart watches
                                </div>
                                <div className='smart mt-5'>
                                    SMART WEARABLE.
                                </div>
                                <div className='discount mt-5'>
                                    UP to 80% OFF
                                </div>
                            </div>
                            <div className='pt-5 hidden md:block lg:block xl:block'>
                                <img src={Watch} alt='Watch' />
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className='flex-wrap md:flex-nowrap slide container mx-auto mt-20'>
                        <div className='flex justify-around'>
                            <div className='ml-3 pt-20'>
                                <div className='bestline'>
                                    Best Deal Online on smart watches
                                </div>
                                <div className='smart mt-5'>
                                    SMART WEARABLE.
                                </div>
                                <div className='discount mt-5'>
                                    UP to 80% OFF
                                </div>
                            </div>
                            <div className='pt-5 hidden md:block lg:block xl:block'>
                                <img src={Watch} alt='Watch' />
                            </div>
                        </div>
                    </div>
                </section>
            </Slider> */}

      {/* SHOP CATEGORIES  */}
      {/* <section>
                <div className='flex container mx-auto justify-between mt-24 '>
                    <div className='shop'>Shop from
                        <span className='category'>Top Categories</span>
                    </div>
                    <div className='view flex'>View all
                        <IoIosArrowForward /></div>
                </div>
                <div className='line container mx-auto mt-10'></div>
                <div className='container mx-auto'>
                    <div className='mt-10 gap-5 flex-wrap md:flex-wrap lg:flex-wrap xl:flex-nowrap xl:justify-between container mx-auto flex justify-center'>
                        <div className='ellipse bg-gray-100 rounded-full'>
                            <div className='image ml-10 mt-3'>
                                <img src={Phone} alt='Phone' />
                            </div>
                            <div className='shoptext mt-10'>Mobile</div>
                        </div>

                        <div className='ellipse bg-gray-100 rounded-full'>
                            <div className='image ml-10 mt-3'>
                                <img src={Fairlovely} alt='Fairlovely' />
                            </div>
                            <div className='shoptext mt-10'>Cosmetics</div>
                        </div>
                        <div className='ellipse bg-gray-100 rounded-full mt-16 md:mt-0 xl:mt-0'>
                            <div className='image ml-10 mt-3'>
                                <img src={Phone} alt='Phone' />
                            </div>
                            <div className='shoptext mt-10'>Electronics</div>
                        </div>
                        <div className='ellipse bg-gray-100 rounded-full mt-16 md:mt-0 xl:mt-0'>
                            <div className='image ml-10 mt-3'>
                                <img src={Phone} alt='Phone' />
                            </div>
                            <div className='shoptext mt-10'>Furniture</div>
                        </div>
                        <div className='ellipse bg-gray-100 rounded-full mt-16 md:mt-0 xl:mt-0'>
                            <div className='watch2 ml-3 p-1 mt-3'>
                                <img src={Watch2} alt='Watch2' />
                            </div>
                            <div className='shoptext mt-10'>Watches</div>
                        </div>
                        <div className='ellipse bg-gray-100 rounded-full mt-16 lg:mt-0  xl:mt-0'>
                            <div className='plant ml-4 mt-3'>
                                <img src={Plant} alt='Plant' />
                            </div>
                            <div className='shoptext mt-10'>Decor</div>
                        </div>
                        <div className='ellipse bg-gray-100 rounded-full mt-16 xl:mt-0'>
                            <div className='necklace ml-4 mt-3'>
                                <img src={Necklace} alt='Necklace' />
                            </div>
                            <div className='shoptext mt-10'>Accessories</div>
                        </div>
                        <div className='ellipse bg-gray-100 rounded-full mt-16 xl:mt-0'>
                            <div className='necklace ml-4 mt-3'>
                                <img src={Necklace2} alt='Necklace2' />
                            </div>
                            <div className='shoptext mt-10'>Accessories</div>
                        </div>
                    </div>
                </div>
            </section> */}

      {/* GRAB THE BEST DEALS ON SMARTPHONES */}
      {/* <section>
                <div className='flex container mx-auto justify-between mt-32'>
                    <div className='grab'>Grab the best deal on
                        <span className='smart1'>Smartphones</span>
                    </div>
                    <div className='view flex'>View all
                        <IoIosArrowForward /></div>
                </div>
                <div className='line container mx-auto mt-10'></div>
                <div className='flex-wrap md:flex-wrap lg:flex-wrap md:gap-3 container mx-auto flex justify-center xl:justify-between '>
                    <div className="bg-gray-100 w-60 mb-5 rounded overflow-hidden shadow-lg mt-10">
                        <div className='label ml-auto'>
                            <div className='text pt-3'>56% OFF</div>
                        </div>
                        <div className='phone ml-16'>
                            <img className="w-full" src={Phone} alt="Phone" />

                        </div>
                        <div class="bg-white  mt-5 pb-5">
                            <div className='galaxy pt-5 pl-3'>Galaxy S22 Ultra</div>
                            <div className='flex gap-3 mt-3 pl-3'>
                                <div className='n1'>₹32999</div>
                                <div className='n2 line-through'>₹74999</div>
                            </div>
                            <div className='line2 mt-5 ml-3'></div>
                            <div className='save pt-3 ml-5'>Save - ₹32999</div>
                        </div>
                    </div>
                    <div className="bg-gray-100 w-60 mb-5 rounded overflow-hidden shadow-lg mt-10">
                        <div className='label ml-auto'>
                            <div className='text pt-3'>56% OFF</div>
                        </div>
                        <div className='phone2 ml-12'>
                            <img className="w-full" src={Phonem13} alt="Phone" />

                        </div>
                        <div class="bg-white mt-3 pb-5">
                            <div className='galaxy pt-5 pl-3'>Galaxy M13 (4GB | 64 GB )</div>
                            <div className='flex gap-3 mt-3 pl-3'>
                                <div className='n1'>₹10499</div>
                                <div className='n2 line-through'>₹14999</div>
                            </div>
                            <div className='line2 mt-5 ml-3'></div>
                            <div className='save pt-3 ml-5'>Save - ₹4500</div>
                        </div>
                    </div>
                    <div className="bg-gray-100 w-60 mb-5 rounded overflow-hidden shadow-lg mt-10">
                        <div className='label ml-auto'>
                            <div className='text pt-3'>56% OFF</div>
                        </div>
                        <div className='phone3 ml-10'>
                            <img className="w-full" src={Phonem33} alt="Phone" />

                        </div>
                        <div class="bg-white mt-1 pb-5">
                            <div className='galaxy pt-5 pl-3'>Galaxy M33 (4GB | 64 GB )</div>
                            <div className='flex gap-3 mt-3 pl-3'>
                                <div className='n1'>₹16999</div>
                                <div className='n2 line-through'>₹24999</div>
                            </div>
                            <div className='line2 mt-5 ml-3'></div>
                            <div className='save pt-3 ml-5'>Save - ₹8000</div>
                        </div>
                    </div>
                    <div className="bg-gray-100 w-60 mb-5 rounded overflow-hidden shadow-lg mt-10">
                        <div className='label ml-auto'>
                            <div className='text pt-3'>56% OFF</div>
                        </div>
                        <div className='phone4 ml-10'>
                            <img className="w-full" src={Phonegalaxy} alt="Phone" />

                        </div>
                        <div class="bg-white  pb-5">
                            <div className='galaxy pt-5 pl-3'>Galaxy M53 (4GB | 64 GB )</div>
                            <div className='flex gap-3 mt-3 pl-3'>
                                <div className='n1'>₹31999</div>
                                <div className='n2 line-through'>₹40999</div>
                            </div>
                            <div className='line2 mt-5 ml-3'></div>
                            <div className='save pt-3 ml-5'>Save - ₹9000</div>
                        </div>
                    </div>
                    <div className="bg-gray-100 w-60 mb-5 rounded overflow-hidden shadow-lg mt-10">
                        <div className='label ml-auto'>
                            <div className='text pt-3'>56% OFF</div>
                        </div>
                        <div className='phone5 ml-16'>
                            <img className="w-full" src={Phonegalaxy2} alt="Phone" />

                        </div>
                        <div class="bg-white pb-5">
                            <div className='galaxy pt-5 pl-3'>Galaxy S22 Ultra</div>
                            <div className='flex gap-3 mt-3 pl-3'>
                                <div className='n1'>₹67999</div>
                                <div className='n2 line-through'>₹85999</div>
                            </div>
                            <div className='line2 mt-5 ml-3'></div>
                            <div className='save pt-3 ml-5'>Save - ₹18000</div>
                        </div>
                    </div>
                    <div className="bg-gray-100 w-60 mb-5 rounded overflow-hidden shadow-lg mt-10">
                        <div className='label ml-auto'>
                            <div className='text pt-3'>56% OFF</div>
                        </div>
                        <div className='phone6 ml-16'>
                            <img className="w-full" src={Phonegalaxy3} alt="Phone" />

                        </div>
                        <div class=" bg-white pb-5">
                            <div className='galaxy pt-5 pl-3'>Galaxy S22 Ultra</div>
                            <div className='flex gap-3 mt-3 pl-3'>
                                <div className='n1'>₹67999</div>
                                <div className='n2 line-through'>₹85999</div>
                            </div>
                            <div className='line2 mt-5 ml-3'></div>
                            <div className='save pt-3 ml-5'>Save - ₹18000</div>
                        </div>
                    </div>
                </div>
            </section> */}

      {/* DAILY ESSENTIALS */}
      {/* <section>
                <div className='flex container mx-auto justify-between mt-32 xl:mt-32'>
                    <div className='daily'>Daily
                        <span className='essential'>Essentials</span>
                    </div>
                    <div className='view flex'>View all
                        <IoIosArrowForward /></div>
                </div>
                <div className='line container mx-auto mt-10'></div>
                <div className='flex-wrap md:flex-wrap lg:flex-wrap container mx-auto flex justify-center md:gap-10 xl:flex-nowrap xl:justify-between mt-10 p-3'>
                    <div className='backimg'>
                        <div className='groceries mt-5'>
                            <img src={Groceries} alt='Groceries' />
                        </div>
                        <div className='mt-16'>
                            <div className='dailytext'>Daily Essentials</div>
                            <div className='dailydiscount'>UP to 50% OFF</div>
                        </div>
                    </div>
                    <div className='backimg mt-24 md:mt-0 xl:mt-0'>
                        <div className='groceries  -mt-5 p-5'>
                            <img src={Vegetables} alt='Groceries' />
                        </div>
                        <div className='mt-7'>
                            <div className='dailytext'>Daily Essentials</div>
                            <div className='dailydiscount'>UP to 50% OFF</div>
                        </div>
                    </div>
                    <div className='backimg mt-24 md:mt-0 xl:mt-0'>
                        <div className='groceries p-3'>
                            <img src={Fruits} alt='Groceries' />
                        </div>
                        <div className='mt-12'>
                            <div className='dailytext'>Daily Essentials</div>
                            <div className='dailydiscount'>UP to 50% OFF</div>
                        </div>
                    </div>
                    <div className='backimg mt-24 lg:mt-0 xl:mt-0'>
                        <div className='groceries mt-10 p-3'>
                            <img src={Strawberry} alt='Groceries' />
                        </div>
                        <div className='mt-12'>
                            <div className='dailytext'>Daily Essentials</div>
                            <div className='dailydiscount'>UP to 50% OFF</div>
                        </div>
                    </div>
                    <div className='backimg mt-24 xl:mt-0'>
                        <div className='groceries mt-5 p-3'>
                            <img src={Mango} alt='Groceries' />
                        </div>
                        <div className='mt-10'>
                            <div className='dailytext'>Daily Essentials</div>
                            <div className='dailydiscount'>UP to 50% OFF</div>
                        </div>
                    </div>
                    <div className='backimg mt-24 xl:mt-0'>
                        <div className='groceries p-5'>
                            <img src={CherryFruit} alt='Groceries' />
                        </div>
                        <div className='mt-3'>
                            <div className='dailytext'>Daily Essentials</div>
                            <div className='dailydiscount'>UP to 50% OFF</div>
                        </div>
                    </div>
                    <div className='backimg mt-24 xl:mt-0'>
                        <div className='groceries p-5'>
                            <img src={CherryFruit} alt='Groceries' />
                        </div>
                        <div className='mt-3'>
                            <div className='dailytext'>Daily Essentials</div>
                            <div className='dailydiscount'>UP to 50% OFF</div>
                        </div>
                    </div>
                </div>
            </section> */}

      {/* POWER TOOLS  */}
      {/* <section className="discount__section mt-28">
                <div className="discount__container container mx-auto flex items-center justify-center flex-wrap py-6">
                    <div className="flex items-center justify-center flex-wrap md:flex-nowrap w-full lg:w-1/2">
                        <div className="discount__image mr-10">
                            <div className="discount__img">
                                <img src={Screw} alt="img" />
                            </div>
                        </div>
                        <div className="discount__word flex items-center px-5 lg:px-0">
                            <span className="discount__num sm:mr-4">30%</span>
                            <span className="discount__le">on power tools</span>
                        </div>
                    </div>
                    <div className="flex items-center justify-center lg:justify-start flex-wrap lg:flex-nowrap w-full lg:w-1/2 mt-5 lg:mt-0 px-5 md:px-8 lg:px-0">
                        <div className="discount__para text-justify lg:text-left lg:mr-2 xl:mr-2">
                            <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.</span>
                        </div>
                        <div className="discount__btn mt-7">
                            <button>Check offer</button>
                        </div>
                    </div>

                </div>
            </section> */}

      {/* ALL PRODUCTS  */}
      {/* <section>
                <div className='flex container mx-auto justify-between mt-32'>
                    <div className='daily'>All
                        Products
                    </div>
                    <div className='view flex'>View all
                        <IoIosArrowForward /></div>
                </div>
                <div className='line container mx-auto mt-10'></div>
                <div className='flex-wrap md:flex-wrap lg:flex-wrap container mx-auto flex justify-center md:gap-10 xl:flex-nowrap xl:justify-between mt-5 p-3'>
                    <div className='backimg mt-20 '>
                        <div className='groceries pt-5'>
                            <img src={Groceries} alt='Groceries' />
                        </div>
                        <div className='mt-16'>
                            <div className='dailytext'>Daily Essentials</div>

                        </div>
                    </div>
                    <div className='backimg mt-20'>
                        <div className='groceries pt-5'>
                            <img src={Groceries} alt='Groceries' />
                        </div>
                        <div className='mt-16'>
                            <div className='dailytext'>Daily Essentials</div>

                        </div>
                    </div>
                    <div className='backimg mt-20'>
                        <div className='groceries pt-5'>
                            <img src={Groceries} alt='Groceries' />
                        </div>
                        <div className='mt-16'>
                            <div className='dailytext'>Daily Essentials</div>

                        </div>
                    </div>
                    <div className='backimg mt-20'>
                        <div className='groceries pt-5'>
                            <img src={Groceries} alt='Groceries' />
                        </div>
                        <div className='mt-16'>
                            <div className='dailytext'>Daily Essentials</div>

                        </div>
                    </div>
                    <div className='backimg mt-20'>
                        <div className='groceries pt-5'>
                            <img src={Groceries} alt='Groceries' />
                        </div>
                        <div className='mt-16'>
                            <div className='dailytext'>Daily Essentials</div>

                        </div>
                    </div>
                    <div className='backimg mt-20'>
                        <div className='groceries pt-5'>
                            <img src={Groceries} alt='Groceries' />
                        </div>
                        <div className='mt-16'>
                            <div className='dailytext'>Daily Essentials</div>

                        </div>
                    </div>
                    <div className='backimg mt-20'>
                        <div className='groceries pt-5'>
                            <img src={Groceries} alt='Groceries' />
                        </div>
                        <div className='mt-16'>
                            <div className='dailytext'>Daily Essentials</div>

                        </div>
                    </div>
                </div>
                <div className='flex-wrap md:flex-wrap lg:flex-wrap container mx-auto flex justify-center md:gap-10 xl:flex-nowrap xl:justify-between mt-16 p-3'>
                    <div className='backimg mt-20'>
                        <div className='groceries pt-5'>
                            <img src={Groceries} alt='Groceries' />
                        </div>
                        <div className='mt-16'>
                            <div className='dailytext'>Daily Essentials</div>

                        </div>
                    </div>
                    <div className='backimg mt-20'>
                        <div className='groceries pt-5'>
                            <img src={Groceries} alt='Groceries' />
                        </div>
                        <div className='mt-16'>
                            <div className='dailytext'>Daily Essentials</div>

                        </div>
                    </div>
                    <div className='backimg mt-20'>
                        <div className='groceries pt-5'>
                            <img src={Groceries} alt='Groceries' />
                        </div>
                        <div className='mt-16'>
                            <div className='dailytext'>Daily Essentials</div>

                        </div>
                    </div>
                    <div className='backimg mt-20'>
                        <div className='groceries pt-5'>
                            <img src={Groceries} alt='Groceries' />
                        </div>
                        <div className='mt-16'>
                            <div className='dailytext'>Daily Essentials</div>

                        </div>
                    </div>
                    <div className='backimg mt-20'>
                        <div className='groceries pt-5'>
                            <img src={Groceries} alt='Groceries' />
                        </div>
                        <div className='mt-16'>
                            <div className='dailytext'>Daily Essentials</div>

                        </div>
                    </div>
                    <div className='backimg mt-20'>
                        <div className='groceries pt-5'>
                            <img src={Groceries} alt='Groceries' />
                        </div>
                        <div className='mt-16'>
                            <div className='dailytext'>Daily Essentials</div>

                        </div>
                    </div>
                    <div className='backimg mt-20'>
                        <div className='groceries pt-5'>
                            <img src={Groceries} alt='Groceries' />
                        </div>
                        <div className='mt-16'>
                            <div className='dailytext'>Daily Essentials</div>

                        </div>
                    </div>
                </div>
                <div className='flex-wrap md:flex-wrap lg:flex-wrap container mx-auto flex justify-center md:gap-10 xl:flex-nowrap xl:justify-between mt-24 p-3'>
                    <div className='backimg mt-5 xl:mt-5 text-center md:text-left '>
                        <div className='groceries md:mt-5 xl:pt-1'>
                            <img src={Groceries} alt='Groceries' />
                        </div>
                        <div className='mt-16'>
                            <div className='dailytext'>Daily Essentials</div>

                        </div>
                    </div>
                    <div className='backimg mt-20 md:mt-5'>
                        <div className='groceries pt-5'>
                            <img src={Groceries} alt='Groceries' />
                        </div>
                        <div className='mt-16'>
                            <div className='dailytext'>Daily Essentials</div>

                        </div>
                    </div>
                    <div className='backimg mt-20 md:md:mt-5  '>
                        <div className='groceries pt-5'>
                            <img src={Groceries} alt='Groceries' />
                        </div>
                        <div className='mt-16'>
                            <div className='dailytext'>Daily Essentials</div>

                        </div>
                    </div>
                    <div className='backimg mt-20 md:md:mt-5  '>
                        <div className='groceries pt-5'>
                            <img src={Groceries} alt='Groceries' />
                        </div>
                        <div className='mt-16'>
                            <div className='dailytext'>Daily Essentials</div>

                        </div>
                    </div>
                    <div className='backimg mt-20 md:mt-5 lg:mt-16 xl:mt-5 '>
                        <div className='groceries pt-5'>
                            <img src={Groceries} alt='Groceries' />
                        </div>
                        <div className='mt-16'>
                            <div className='dailytext'>Daily Essentials</div>

                        </div>
                    </div>
                    <div className='backimg mt-20 md:mt-5 lg:mt-16 xl:mt-5'>
                        <div className='groceries pt-5'>
                            <img src={Groceries} alt='Groceries' />
                        </div>
                        <div className='mt-16'>
                            <div className='dailytext'>Daily Essentials</div>

                        </div>
                    </div>
                    <div className='backimg mt-20 md:mt-5 lg:mt-16 xl:mt-5'>
                        <div className='groceries pt-5'>
                            <img src={Groceries} alt='Groceries' />
                        </div>
                        <div className='mt-16'>
                            <div className='dailytext'>Daily Essentials</div>

                        </div>
                    </div>
                </div>
            </section> */}

      {/* FOOTER  */}

      <Footer />
    </section>
  );
};

export default Products;
