import { React, memo } from "react";
import "./delivery.css";
import { AppBar } from "@material-ui/core";
import { AppKeys } from "../../../constants/AppKeys";

const Delivery = memo(() => {
  return (
    <section className="dpd container mx-auto mt-10 lg:mt-28">
      <div className="dpd__container flex justify-between flex-wrap lg:flex-nowrap px-5 lg:px-10 py-5 sm:py-10 lg:py-24">
        <div className="dpd__content w-full lg:w-2/5 text-center lg:text-left">
          <div className="dpd__title">
            <span>Delivery already available!</span>
          </div>
          <div className="dpd__para mt-6 mb-10">
            <span>
              Choose delivery for speed, ease and convenience. Track your parcel
              in real time. This will help you plan your work while waiting for
              the parcel.
            </span>
          </div>
          <div className="dpd__button">
            <div className="dpd__btn">
              <button>Show more</button>
            </div>
          </div>
        </div>
        <div className="dpd__image mt-10 lg:mt-0">
          <div className="dpd__img">
            <img
              src={`${AppKeys.IMGURL}website/landing-images/landing-img9.webp`}
              alt="img"
            />
          </div>
        </div>
      </div>
    </section>
  );
});

export default Delivery;
