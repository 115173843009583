import { React, memo } from "react";
import "./discount.css";
import { AppKeys } from "../../../constants/AppKeys";

const Discount = memo(() => {
  return (
    <section className="discount__section mt-28">
      <div className="discount__container container mx-auto flex items-center justify-center flex-wrap py-6">
        <div className="flex items-center justify-center flex-wrap md:flex-nowrap w-full lg:w-1/2">
          <div className="discount__image mr-10">
            <div className="discount__img">
              <img
                src={`${AppKeys.IMGURL}website/landing-images/landing-img8.webp`}
                alt="img"
              />
            </div>
          </div>
          <div className="discount__word flex items-center px-5 lg:px-0">
            <span className="discount__num sm:mr-4">30%</span>
            <span className="discount__le">on power tools</span>
          </div>
        </div>
        <div className="flex items-center justify-center lg:justify-start flex-wrap lg:flex-nowrap w-full lg:w-1/2 mt-5 lg:mt-0 px-5 md:px-8 lg:px-0">
          <div className="discount__para text-justify lg:text-left lg:mr-2 xl:mr-2">
            <span>
              Grab your favuorite Product at an unbelievable 30% off! Act fast,
              these deals won't last forever.{" "}
            </span>
          </div>
          <div className="discount__btn mt-7">
            <button>Check offer</button>
          </div>
        </div>
      </div>
    </section>
  );
});
Discount.displayName = "Discount";
export default Discount;
