import React, { useState } from "react";
import CreateForm from "../create/create";
import Business from "../business/business";
import "./seller.css";
import Product from "../product/product";
import Address from "../Address/address";
import Gst from "../GST/gst";
import { AxiosFilePost } from "../../../utilities/axioscall";
import { AppKeys } from "../../../constants/AppKeys";
import { AxiosError } from "../../../utilities/sessionexpiry";

const TabbedForm = (props) => {
  const { count, setCount, pepper, setPepper, peppermint } = props;
  const [activeTab, setActiveTab] = useState(pepper?.isUserLoggedin ? 1 : 0);
  const [createaccount, setcreateaccount] = useState({});
  const [businessdetails, setbusinessdetails] = useState({});
  const [productdetails, setproductdetails] = useState({});
  const [addressdetails, setaddressdetails] = useState({});
  const [addgst, setaddgst] = useState({});
  const [productdetailsfiles1, productdetailssetFiles1] = useState([]);
  const [productdetailsfiles2, productdetailssetFiles2] = useState([]);
  const [productdetailsfiles3, productdetailssetFiles3] = useState([]);
  const [form_errors, setFormErrors] = useState({});
  const handleTabChange = (Index) => {
    setActiveTab(Index);
  };

  const handleNextTab = async (formvalue) => {
    console.log(formvalue);
    if (activeTab === 0) {
      setcreateaccount(formvalue);
    }
    if (activeTab === 1) {
      setbusinessdetails(formvalue);
    }
    if (activeTab === 2) {
      setproductdetails(formvalue);
    }
    if (activeTab === 3) {
      setaddressdetails(formvalue);
    }
    if (activeTab === 4) {
      setaddgst(formvalue);
    }
    if (activeTab <= 3) setActiveTab(activeTab + 1);
    else {
      var formdata = new FormData();

      formdata.append("type", "1");
      formdata.append("name", businessdetails.name);
      formdata.append("password", createaccount.password);
      formdata.append("mobile", createaccount.mobileNumber);
      formdata.append("email", createaccount.email);
      formdata.append("business_name", businessdetails.companyName);
      formdata.append("business_number", businessdetails.mobileNumber);
      formdata.append("pincode", addressdetails.pincode);
      formdata.append("city", addressdetails.city);
      formdata.append("state", "1");
      formdata.append("gst", addgst.gstInput);
      formdata.append("pan", addgst.panInput);
      formdata.append("product1_name", productdetails.productname1);
      formdata.append("product2_name", productdetails.productname2);
      formdata.append("product3_name", productdetails.productname3);

      if (
        productdetailsfiles1 != null &&
        productdetailsfiles1.length > 0 &&
        productdetailsfiles1[0] != null
      )
        formdata.append("product1_image", productdetailsfiles1[0]);

      if (
        productdetailsfiles2 != null &&
        productdetailsfiles2.length > 0 &&
        productdetailsfiles2[0] != null
      )
        formdata.append("product2_image", productdetailsfiles2[0]);

      if (
        productdetailsfiles3 != null &&
        productdetailsfiles3.length > 0 &&
        productdetailsfiles3[0] != null
      )
        formdata.append("product3_image", productdetailsfiles3[0]);

      await AxiosFilePost(AppKeys.REGISTERACCOUNTAPI, formdata)
        .then((resp) => {
          setCount({ ...count, count: count + 1 });
          setActiveTab(activeTab + 1);
        })
        .catch((errresp) => {
          setActiveTab(6);
          let errors = AxiosError(errresp);
          setFormErrors(errors === null ? {} : errors);
        });
    }
  };

  return (
    <div className="">
      <div className="tab__container py-5 grid place-content-center">
        <div className="tab__navigation">
          {pepper?.isUserLoggedin ? (
            <></>
          ) : (
            <button
              className="tabBtn py-2 sm:py-5"
              onClick={() => handleTabChange(0)}
              style={{
                backgroundColor: activeTab === 0 ? "#1071FF" : "initial",
                color: activeTab === 0 ? "white" : "initial",
              }}
            >
              CREATE ACCOUNT
            </button>
          )}

          <button
            className="tabBtn py-2 sm:py-5"
            onClick={() => handleTabChange(1)}
            style={{
              backgroundColor: activeTab === 1 ? "#1071FF" : "initial",
              color: activeTab === 1 ? "white" : "initial",
            }}
          >
            BUSINESS DETAILS
          </button>
          <button
            className="tabBtn py-2 sm:py-5"
            onClick={() => handleTabChange(2)}
            style={{
              backgroundColor: activeTab === 2 ? "#1071FF" : "initial",
              color: activeTab === 2 ? "white" : "initial",
            }}
          >
            PRODUCT DETAILS
          </button>
          <button
            className="tabBtn py-2 sm:py-5"
            onClick={() => handleTabChange(3)}
            style={{
              backgroundColor: activeTab === 3 ? "#1071FF" : "initial",
              color: activeTab === 3 ? "white" : "initial",
            }}
          >
            ADDRESS DETAILS
          </button>
          <button
            className="tabBtn py-2 sm:py-5"
            onClick={() => handleTabChange(4)}
            style={{
              backgroundColor: activeTab === 4 ? "#1071FF" : "initial",
              color: activeTab === 4 ? "white" : "initial",
            }}
          >
            ADD GST
          </button>
        </div>
      </div>
      <div className="tab-content">
        {pepper?.isUserLoggedin ? (
          <></>
        ) : (
          activeTab === 0 && (
            <CreateForm
              onNextTab={handleNextTab}
              formData={createaccount}
              setFormData={setcreateaccount}
            />
          )
        )}
        {activeTab === 1 && (
          <Business
            onNextTab={handleNextTab}
            formData={businessdetails}
            setFormData={setbusinessdetails}
          />
        )}
        {activeTab === 2 && (
          <Product
            onNextTab={handleNextTab}
            formData={productdetails}
            setFormData={setproductdetails}
            files1={productdetailsfiles1}
            files2={productdetailsfiles2}
            files3={productdetailsfiles3}
            setFiles1={productdetailssetFiles1}
            setFiles2={productdetailssetFiles2}
            setFiles3={productdetailssetFiles3}
          />
        )}
        {activeTab === 3 && (
          <Address
            onNextTab={handleNextTab}
            formData={addressdetails}
            setFormData={setaddressdetails}
          />
        )}
        {activeTab === 4 && (
          <Gst
            onNextTab={handleNextTab}
            formData={addgst}
            setFormData={setaddgst}
          />
        )}
        {activeTab === 5 && (
          <>
            <center>
              <div>
                <br />
                <div className="grid place-content-center">
                  <div className="success__detailImg">
                    <img src="/images/Checkicon.webp" alt="img" />
                  </div>
                </div>
                <br />
                <h2>Seller Registerd Successfully</h2>
                <br />
                <br />
              </div>
            </center>
          </>
        )}
        {/* {activeTab === 6 && (
          <>
            <center>
              <div>
                <br />
                <div hidden={Object.keys(form_errors).length === 0}>
                  <b>
                    <span className="text-red-500">Errors:</span>
                  </b>
                  <br />

                  {Object.keys(form_errors).map((key) =>
                    key === "message" ? (
                      <>
                        <span className="text-red-500">
                          {form_errors?.message?.toString()}
                        </span>
                        <br />
                      </>
                    ) : (
                      form_errors[key].map((item) => (
                        <>
                          <span className="text-red-500">
                            {item?.toString()}
                          </span>
                          <br />
                        </>
                      ))
                    )
                  )}
                </div>
                <br />
                <br />
              </div>
            </center>
          </>
        )} */}
      </div>
    </div>
  );
};

export default TabbedForm;
