import React, { memo, useEffect } from "react";
import "./addCard.css";
import { MdKeyboardArrowUp, MdOutlineStarBorder } from "react-icons/md";
import { FaTrashAlt, FaRegCreditCard } from "react-icons/fa";
import { IoMdDownload } from "react-icons/io";
import { FaLocationDot } from "react-icons/fa6";
import { BsExclamationCircle } from "react-icons/bs";
import { AppKeys } from "../../constants/AppKeys";
import { useNavigate } from "react-router-dom";
import { PK } from "../../constants/PepperKeys";

const AddCard = memo((props) => {
  const { count, setCount, pepper, setPepper, peppermint } = props;

  let cartData = [];
  let cartInfoData = [];
  useEffect(() => {}, [cartData]);
  useEffect(() => {
    // console.log(`You clicked ${JSON.stringify(count)} times`);
  }, [count]);
  let navigate = useNavigate();
  return (
    <div className="addCard__container container mx-auto flex justify-between lg:justify-start 2xl:justify-between lg:gap-5 2xl:gap-0 my-20 flex-wrap px-5 lg:px-0">
      <div className="w-full lg:w-7/12 2xl:w-8/12">
        <div className="addCard__content p-3">
          <div className="addCard__contentDetail">
            <div className="addCard__productList flex items-center">
              <div>
                <span className="addCard__product mr-8">Product</span>
                <span className="addCard__items">
                  Items:{" "}
                  {
                    Object.keys(
                      pepper?.cachedata?.cartitems === undefined
                        ? {}
                        : pepper?.cachedata?.cartitems
                    ).length
                  }
                </span>
              </div>
              {/* <div className='ml-auto'><MdKeyboardArrowUp className='arrowUp'/></div> */}
            </div>

            {Object.keys(
              pepper?.cachedata?.cartitems === undefined
                ? {}
                : pepper?.cachedata?.cartitems
            ).map((key) => (
              <>
                <div className="addCard__productDetail flex p-4">
                  <div className="addCard__Image mr-3">
                    <div className="addCard__star grid place-content-center">
                      <MdOutlineStarBorder />
                    </div>
                    <div className="addCard__Img">
                      <img
                        src={`https://haodamart.blr1.cdn.digitaloceanspaces.com/HAODAMART/product/${pepper?.cachedata?.cartitems[key]?.desktop_image}`}
                        alt="Watch"
                      />
                    </div>
                  </div>
                  <div className="addCard__productName w-full">
                    <div className="flex justify-between flex-wrap md:flex-nowrap">
                      <div className="addCard__brandContent">
                        <div>
                          <span className="addCard__brand">
                            Brand Name | Part No. 234565
                          </span>
                        </div>
                        <div className="my-3">
                          <span className="addCard__heading">
                            {pepper?.cachedata?.cartitems[key]?.product_name}
                          </span>
                        </div>
                        <div className="addCard__delivery">
                          <span>Delivery date: April 4th</span>
                        </div>
                      </div>
                      <div className="addCard__title">
                        <div>
                          <span className="addCard__brandNet">
                            ₹{pepper?.cachedata?.cartitems[key]?.price} net
                          </span>
                        </div>
                        <div className="lg:my-3 xl:my-0">
                          <span className="addCard__brandPrice">
                            ₹{pepper?.cachedata?.cartitems[key]?.mrp} gross
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="addCard__remove flex items-center justify-between mt-4 flex-wrap md:flex-nowrap">
                      <div className="addCard__btns flex gap-x-4">
                        <button
                          className="addCard__minus"
                          onClick={async () => {
                            let item = pepper?.cachedata?.cartitems[key];
                            let quantity =
                              pepper?.cachedata?.cartitems[key]?.quantity;
                            item["quantity"] = quantity - 1;
                            if (item["quantity"] <= 0) item["quantity"] = 1;
                            await peppermint(PK.CACHEDATAWRITECARTITEMS, item);
                            setCount({ ...count, count: count + 1 });
                          }}
                        >
                          -
                        </button>
                        <button className="addCard__num">
                          {pepper?.cachedata?.cartitems[key]?.quantity}
                        </button>
                        <button
                          className="addCard__plus"
                          onClick={async () => {
                            let item = pepper?.cachedata?.cartitems[key];
                            let quantity =
                              pepper?.cachedata?.cartitems[key].quantity;
                            item["quantity"] = quantity + 1;
                            await peppermint(PK.CACHEDATAWRITECARTITEMS, item);
                            setCount({ ...count, count: count + 1 });
                          }}
                        >
                          +
                        </button>
                      </div>
                      <div className="addCard__deleteBtn">
                        <button
                          onClick={async () => {
                            let item = pepper?.cachedata?.cartitems[key];
                            await peppermint(PK.CACHEDATAREMOVECARTITEMS, item);
                            setCount({ ...count, count: count + 1 });
                          }}
                        >
                          <FaTrashAlt />
                          Remove product
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
      </div>

      <div className="summary__container w-full lg:w-2/6 2xl:w-3/12 p-6 mt-10 lg:mt-0">
        <div className="summary__title">
          <span>Summary</span>
        </div>
        <div className="summary__product p-3 my-4">
          <div className="summary__productList flex items-center justify-between">
            <div className="summary__productList__title">
              <span>Product</span>
            </div>
            <div className="summary__productList__price">
              <div className="summary__productList__priceNet">
                <span>Price net:</span>
                <span>₹{cartInfoData?.subtotal}</span>
              </div>
            </div>
          </div>

          <div className="summary__itemList flex items-center justify-between">
            <div className="summary__itemList__title">
              <span>
                Items:{" "}
                {
                  Object.keys(
                    pepper?.cachedata?.cartitems === undefined
                      ? {}
                      : pepper?.cachedata?.cartitems
                  ).length
                }
              </span>
            </div>
            <div className="summary__itemList__price">
              <div className="summary__itemList__priceNet">
                <span>Price gross:</span>
                <span>₹{pepper?.cachedata?.mrptotal}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="summaryPrice__net flex items-center justify-between">
          <div>
            <span className="text-base	font-bold">Price net</span>
          </div>
          <div>
            <span className="text-base font-semibold">
              ₹{pepper?.cachedata?.sptotal}
            </span>
          </div>
        </div>
        <div className="summary__shippingCost flex items-center justify-between my-3">
          <div>
            <span className="text-base font-normal">Shipping cost</span>
          </div>
          <div>
            <span className="text-base font-medium">from ₹49.99 </span>
          </div>
        </div>
        <div className="summary__discount flex items-center justify-between">
          <div>
            <span className="text-base font-normal">Discount:</span>
          </div>
          <div>
            <span className="text-base font-medium">-₹14.00</span>
          </div>
        </div>
        <div className="summary__totalPrice flex items-center justify-between my-3">
          <div className="summary__totalPrice__title">
            <span>Total price </span>
          </div>
          <div className="summary__totalPrice__rupees">
            <span>₹{pepper?.cachedata?.sptotal}</span>
          </div>
        </div>
        <div className="summary__checkoutBtn mt-7">
          <button
            onClick={() => {
              
              navigate("/checkout");
            }}
          >
            Go to checkout
          </button>
        </div>
        <div className="summary__shoppingBtn my-4">
          <button>Go back to shopping</button>
        </div>
        <div className="summary__paymentIcon">
          <div className="flex items-center">
            <FaRegCreditCard className="locationDot__icon mr-3" />
            <span>Safety payment</span>
          </div>
          <div className="flex items-center">
            <FaLocationDot className="locationDot__icon mr-3 my-4" />
            <span>More than 5000 stationary shop</span>
          </div>
          <div className="flex items-center">
            <BsExclamationCircle className="locationDot__icon mr-3" />
            <span>Free shippingand returns</span>
          </div>
        </div>
      </div>
    </div>
  );
});

export default AddCard;
