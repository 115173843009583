import React from "react";
import "./main.css";
import { AppKeys } from "../../../constants/AppKeys";
import { memo } from "react";

const Main = memo(() => {
  return (
    <section className="main__section">
      <div className="main__container container mx-auto flex justify-between p-5 lg:p-0 lg:py-10 2xl:py-20 flex-wrap">
        <div className="main__content w-full lg:w-3/5">
          <div className="main__title text-center lg:text-left">
            <span>The leading B2B ecommerce platform for global trade</span>
          </div>
          <div className="main__para mt-5 xl:mt-10 xl:pr-36 text-justify lg:text-left">
            <span>
              {" "}
              We have a business connection system, which is a simple,
              adjustable catalogue, making shopping comfortable and safe. Come
              to partake in unequalled potential of growth, partnerships and
              achievement.
            </span>
          </div>
        </div>
        <div className="main__image md:w-full lg:w-2/5 hidden md:grid place-content-center md:mt-10 lg:mt-0">
          <div className="main__img">
            <img
              src={`${AppKeys.IMGURL}website/landing-images/B2B.webp`}
              alt="img"
            />
          </div>
        </div>
      </div>
    </section>
  );
});

export default Main;
