import React from 'react'
import { useState } from 'react';
import { FaCheckCircle } from "react-icons/fa";
import { AiFillCloseCircle } from "react-icons/ai";
import './SellerDashboard.css';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
const Account = () => {
    const initialValues = {
        name: '',
        mobileNumber: '',
        email: '',
        businesstype: '',
        country: '',
        gst: '',
        contactemail: '',
        businesscategory: '',
        businessname: '',
        acceptingPaymentsUrl: '',
        appUrl: '',
        businessDescription: '',
    }
    const validationSchema = Yup.object({
        name: Yup.string().matches(/^[a-zA-Z\s]+$/, 'Enter a valid name').required('Name is required'),
        mobileNumber: Yup.string().matches(/^[6-9]\d{9}$/, 'Enter a valid mobile number').required('Mobile number is required'),
        email: Yup.string().email('Enter a valid email address').required('Email is required'),
        businesstype: Yup.string().matches(/^[a-zA-Z\s]+$/, 'Enter a valid Business Type').required('Business Type is required'),
        country: Yup.string().matches(/^[a-zA-Z\s]+$/, 'Enter a valid Country').required('Country Name is required'),
        gst: Yup.string().matches(/^d{15}$/, 'GST number requires 15 digits number').required('GST number is required'),
        contactemail: Yup.string().email('Enter a valid email address').required('Contact Email is required'),
        businesscategory: Yup.string().matches(/^[a-zA-Z\s]+$/, 'Enter a valid Business Category').required('Business Category is required'),
        businessname: Yup.string().matches(/^[a-zA-Z\s]+$/, 'Enter a valid Business Name').required('Business Name is required'),
        acceptingPaymentsUrl: Yup.string().url('Enter a valid URL').required('Accepting Payments URL is required'),
        appUrl: Yup.string().url('Enter a valid URL').required('App URL is required'),
        businessDescription: Yup.string().required('Business Description is required'),

    });

    const handleSubmit = (values, { setSubmitting }) => {
        console.log('Form submitted:', values);
        setSubmitting(false);
    };

    return (
        <div className='container mx-auto bg-white rounded-xl pb-5 p-5'>
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                <Form>
                    <div className='py-5'>
                        <div className='text-blue-500 accounttxt'>Account Information</div>

                        <div className='sm:grid grid-col-1 xl:grid grid-cols-2 gap-10 mt-5'>
                            <div class="relative w-full">
                                <Field
                                    type="text"
                                    name='name'
                                    maxLength={20}
                                    className="w-full px-4 py-5 border  border-gray-500
                                        rounded-lg duration-300 ease-out text-base outline-none"
                                    id="myInput"
                                    placeholder=" "
                                    required
                                />
                                <ErrorMessage name='name' component='p' className='text-red-500 text-sm mt-2' />
                                <label
                                    className="absolute top-5 left-4 text-base text-gray-600 pointer-events-none  duration-300 ease-out accinputtxt"
                                    for="myInput">
                                    Name
                                </label>
                            </div>
                            <div class="relative w-full mt-5 sm:mt-0">
                                <Field
                                    type="text"
                                    name='mobileNumber'
                                    maxLength={10}
                                    className="w-full px-4 py-5 border border-gray-500 rounded-lg  duration-300 ease-out text-base outline-none"
                                    id="myInput"
                                    placeholder=" "
                                    required />
                                <ErrorMessage name='mobileNumber' component='p' className='text-red-500 text-sm mt-2' />
                                <label class="absolute top-5 left-4 text-base text-gray-600 pointer-events-none  duration-300 ease-out accinputtxt"
                                    for="myInput">
                                    Mobile Number
                                </label>
                            </div>
                            <div className="relative w-full mt-5 sm:mt-0">
                                <Field
                                    type="text"
                                    name='email'
                                    className="w-full px-4 py-5 border border-gray-500 rounded-lg  duration-300 ease-out text-base outline-none"
                                    id="myInput"
                                    placeholder=" "
                                    required />
                                <ErrorMessage name='email' component='p' className='text-red-500 text-sm mt-2' />
                                <label
                                    className="absolute top-5 left-4 text-base text-gray-600 pointer-events-none  duration-300 ease-out accinputtxt"
                                    for="myInput">
                                    Email ID
                                </label>
                            </div>
                            <div className="relative w-full  mt-5 sm:mt-0">
                                <Field
                                    type="text"
                                    name='businesstype'
                                    className="w-full px-4 py-5 border border-gray-500 rounded-lg  duration-300 ease-out text-base outline-none"
                                    id="myInput"
                                    placeholder=" "
                                    required />
                                <ErrorMessage name='businesstype' component='p' className='text-red-500 text-sm mt-2' />
                                <label
                                    className="absolute top-5 left-4 text-base text-gray-600 pointer-events-none  duration-300 ease-out accinputtxt"
                                    for="myInput">
                                    Business Type*
                                </label>
                            </div>
                            <div className="relative w-full  mt-5 sm:mt-0">
                                <Field
                                    type="text"
                                    name='country'
                                    className="w-full px-4 py-5 border border-gray-500 rounded-lg  duration-300 ease-out text-base outline-none"
                                    id="myInput"
                                    placeholder=" "
                                    required />
                                <ErrorMessage name='country' component='p' className='text-red-500 text-sm mt-2' />
                                <label
                                    className="absolute top-5 left-4 text-base text-gray-600 pointer-events-none  duration-300 ease-out accinputtxt"
                                    for="myInput">
                                    Country*
                                </label>
                            </div>
                            <div className="relative flex w-full  mt-5 sm:mt-0 items-center gap-3">
                                <div className='w-5/6'>
                                    <Field
                                        type="text"
                                        name="gst"
                                        maxLength={15}
                                        className="w-full px-2 py-5 border border-gray-500 rounded-lg  duration-300 ease-out text-base outline-none"
                                        id="myInput"
                                        placeholder=""
                                        required />
                                    <ErrorMessage name='gst' component='p' className='text-red-500 text-sm mt-2' />
                                    <label
                                        className="absolute top-5 left-4 text-base text-gray-600 pointer-events-none  duration-300 ease-out accinputtxt"
                                        for="myInput">
                                        GST*</label>
                                </div>
                                <div className='w-[50%] md:flex-nowrap flex-wrap  h-10 gstverify flex justify-center items-center gap-3'>
                                    <div className='text-green-700 flex gap-2 text-xs md:text-base'>Verified
                                        <div className='mt-1'>
                                            <FaCheckCircle />
                                        </div>
                                    </div>
                                    <div className='text-red-700 flex gap-2 text-xs md:text-base'> Not Verified
                                        <div className='mt-1'>
                                            <AiFillCloseCircle />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="relative w-full  mt-5 sm:mt-0">
                                <Field
                                    type="text"
                                    name='contactemail'
                                    className="w-full px-4 py-5  border border-gray-500 rounded-lg  duration-300 ease-out text-base outline-none"
                                    id="myInput"
                                    placeholder=""
                                    required />
                                <ErrorMessage name='contactemail' component='p' className='text-red-500 text-sm mt-2' />
                                <label
                                    className="absolute top-5 left-4 text-base text-gray-600 pointer-events-none  duration-300 ease-out accinputtxt"
                                    for="myInput">
                                    Contact Mail*
                                </label>
                            </div>
                            <div className="relative w-full  mt-5 sm:mt-0">
                                <Field
                                    type="text"
                                    name="businesscategory"
                                    className="w-full px-4 py-5  border border-gray-500 rounded-lg  duration-300 ease-out text-base outline-none"
                                    id="myInput"
                                    placeholder=""
                                    required />
                                <ErrorMessage name='businesscategory' component='p' className='text-red-500 text-sm mt-2' />
                                <label className="absolute top-5 left-4 text-base text-gray-600 pointer-events-none  duration-300 ease-out accinputtxt"
                                    for="myInput">
                                    Business Category
                                </label>
                            </div>
                            <div className="relative w-full  mt-5 sm:mt-0">
                                <Field
                                    type="text"
                                    name='businessname'
                                    className="w-full px-4 py-5  border border-gray-500 rounded-lg  duration-300 ease-out text-base outline-none"
                                    id="myInput"
                                    placeholder=""
                                    required />
                                <ErrorMessage name='businessname' component='p' className='text-red-500 text-sm mt-2' />
                                <label className="absolute top-5 left-4 text-base text-gray-600 pointer-events-none  duration-300 ease-out accinputtxt"
                                    for="myInput">
                                    Buiness Name
                                </label>
                            </div>
                            <div className="relative w-full  mt-5 sm:mt-0">
                                <Field
                                    type="text"
                                    name='acceptingPaymentsUrl'
                                    className="w-full px-4 py-5  border border-gray-500 rounded-lg  duration-300 ease-out text-base outline-none"
                                    id="myInput"
                                    placeholder=" "
                                    required />
                                <ErrorMessage name='acceptingPaymentsUrl' component='p' className='text-red-500 text-sm mt-2' />
                                <label className="absolute top-5 left-4 text-base text-gray-600 pointer-events-none  duration-300 ease-out accinputtxt"
                                    for="myInput">
                                    Accepting Payments URL
                                </label>
                            </div>
                            <div className="relative w-full  mt-5 sm:mt-0">
                                <Field
                                    type="text"
                                    name='appUrl'
                                    className="w-full px-4 py-5  border border-gray-500 rounded-lg  duration-300 ease-out text-base outline-none"
                                    id="myInput"
                                    placeholder=" "
                                    required />
                                <ErrorMessage name='appUrl' component='p' className='text-red-500 text-sm mt-2' />
                                <label className="absolute top-5 left-4 text-base text-gray-600 pointer-events-none  duration-300 ease-out accinputtxt"
                                    for="myInput">
                                    App URL(Play Store)*
                                </label>
                            </div>
                            <div className="relative w-full  mt-5 sm:mt-0">
                                <Field
                                    type="text"
                                    name='businessDescription'
                                    className="w-full px-4 py-5  border border-gray-500 rounded-lg  duration-300 ease-out text-base outline-none"
                                    id="myInput"
                                    placeholder=" "
                                    required />
                                <ErrorMessage name='businessDescription' component='p' className='text-red-500 text-sm mt-2' />
                                <label className="absolute top-5 left-4 text-base text-gray-600 pointer-events-none  duration-300 ease-out accinputtxt"
                                    for="myInput">
                                    Business Description
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className=' mt-5 sm:mt-0 bg-blue-500 w-full lg:w-56 md:w-52 md:mx-auto lg:ml-auto lg:mx-5 p-5 rounded-md xl:ml-auto 2xl:ml-[84%]'>
                        <button type='submit' className='text-base savebtn text-white w-full h-full'>Save</button>
                    </div>
                </Form>
            </Formik>
        </div>
    )
}

export default Account
