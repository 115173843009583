import React, { useState } from "react";
import "./myaccount.css";
import AccountInform from "../myAccount/accountInfom";
import OrderHistory from "../orderHistory/orderHistory";

const Myaccount = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (index) => {
    setActiveTab(index);
  };

  const handleNextTab = () => {
    setActiveTab(activeTab + 1);
  };

  return (
    <div className="myaccount__content container mx-auto px-5 md:px-0 flex gap-10 flex-wrap lg:flex-nowrap my-10 lg:my-20 ">
      <div className="myaccount__container grid place-content-center lg:block">
        <div className="myaccount__navigation px-8 py-3">
          <div className="myaccount__title">
            <span>My Account</span>
          </div>
          <div className="myaccount__profileBtn">
            <button
              onClick={() => handleTabChange(0)}
              style={{ color: activeTab === 0 ? "#1071FF" : "#0C0C0C" }}
            >
              Profile
            </button>
          </div>
          <div className="myaccount__profileBtn">
            <button
              onClick={() => handleTabChange(1)}
              style={{ color: activeTab === 1 ? "#1071FF" : "#0C0C0C" }}
            >
              Order History
            </button>
          </div>
          <a className="myaccount__profileBtn" href="/login">
            <button>Log out</button>
          </a>
        </div>
      </div>
      <div className="w-full lg:w-8/12">
        {activeTab === 0 && (
          <div onNextTab={handleNextTab}>
            <AccountInform />
          </div>
        )}
        {activeTab === 1 && (
          <div onNextTab={handleNextTab}>
            <OrderHistory />
          </div>
        )}
      </div>
    </div>
  );
};

export default Myaccount
