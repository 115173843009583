import { React, memo } from "react";
import "./categories.css";
import { AppKeys } from "../../../constants/AppKeys";

const Categories = memo(() => {
  return (
    <section className="categories container mx-auto">
      <div className="categories__header md:flex justify-between items-center mt-10 md:mt-24 mb-6 md:mb-12">
        <div className="categories__title text-center md:text-left">
          <span>Recommended Categories</span>
        </div>
        <div className="categories__show text-center md:text-right mt-3 md:mt-0">
          <span>
            Show all categories <i class="fa-solid fa-arrow-right"></i>
          </span>
        </div>
      </div>
      <div className="categories__container grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-7">
        <div className="categories__content">
          <div className="categories__img">
            <img
              src={`${AppKeys.IMGURL}website/landing-images/landing-img3.webp`}
              alt="img"
            />
          </div>
          <div className="categories__name">
            <span>Apparel</span>
          </div>
        </div>
        <div className="categories__content">
          <div className="categories__img">
            <img
              src={`${AppKeys.IMGURL}website/landing-images/landing-img4.webp`}
              alt="img"
            />
          </div>
          <div className="categories__name">
            <span>Vegetables</span>
          </div>
        </div>
        <div className="categories__content">
          <div className="categories__img">
            <img
              src={`${AppKeys.IMGURL}website/landing-images/Furniture.webp`}
              alt="img"
            />
          </div>
          <div className="categories__name">
            <span>Furniture</span>
          </div>
        </div>
        <div className="categories__content">
          <div className="categories__img">
            <img
              src={`${AppKeys.IMGURL}website/landing-images/landing-img6.webp`}
              alt="img"
            />
          </div>
          <div className="categories__name">
            <span>Machinery & Equipment</span>
          </div>
        </div>
        <div className="categories__content">
          <div className="categories__img">
            <img
              src={`${AppKeys.IMGURL}website/landing-images/Leather Goods.webp`}
              alt="img"
            />
          </div>
          <div className="categories__name">
            <span>Leather Goods</span>
          </div>
        </div>
      </div>
    </section>
  );
});

export default Categories;
