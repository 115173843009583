export const AppKeys = Object.freeze({
  ACCESSKEY: "HaodaPaymentsMart",
  IPINFOURL: `https://api.ipify.org/?format=json`,
  APIURL: "https://mart-api.payhaoda.com/", //"https://earth.haodapay.com/",//https://earth.mypayhaoda.com/
  IMGURL: "https://haodamart.blr1.cdn.digitaloceanspaces.com/HAODAMART/",
  LOGINAPI: "api/login",
  CREATEORDERAPI: "api/create/order",
  PRODUCTSAPI: "api/products",
  CATEGORIESAPI: "api/categories",
  CARTAPI: "api/cart",
  CACHEWRITEAPI: "api/mart-cache?value=write&key=",
  CACHEREADAPI: "api/mart-cache?value=read&key=",
  CACHEDELETEAPI: "api/mart-cache?value=delete&key=",
  ADDCARTAPI: "api/cart/add",
  UPDATECARTAPI: "api/cart/update",
  REMOVECARTAPI: "api/cart/remove",
  ADDMULTIPLEITEMSINCART: "api/cart/add/multipleitems",
  TWOFACTORLOGINAPI: "api/twofactor/login",
  LOGOUTAPI: "api/logout",
  FORGOTPASSWORDAPI: "api/forgot/password",
  FORGOTPASSWORDVALIDATEDOTPAPI: "api/forgot/password/validateOTP",
  FORGOTPASSWORDCHANGEPASSWORDAPI: "api/reset/password",
  CHANGEPASSWORDAPI: "api/change/password",
  UPLOADPROFILEPICTUREAPI: "api/upload/profilepicture",
  REGISTERACCOUNTAPI: "api/register",
  DASHBOARDAPI: "api/dashboard",
  DEVELOPERTOOLSAPI: "api/developer-tool",
  COLLECTIONS_PAYINFORMATIONAPI: "api/collections/payin",
  BBPSCONSENTDOCSAPI: "api/bbpsconsentdocs",
  BBPSCONSENTDOCS_SUBMITAPI: "api/bbpsconsentdocs/submit",
  SETTLEMENTSAPI: "api/settlements",
  CHARGEBACKSAPI: "api/chargebacks",
  CHARGEBACKSACCEPTEDAPI: "api/chargebacks/accepted",
  INVOICESAPI: "api/invoices",
  VANFETCHDETAILSAPI: "api/van/accountdetails",
  VANACTIVATEAPI: "api/van/activate",
  VANPAYMENTSAPI: "api/van/payments",
  VANCOLLECTIONSTATUSUPDATE: "api/van/collectionstatus/update",
  PAYOUTSAPI: "api/payouts",
  REFUNDSAPI: "api/payout/refunds",
  VIEWBENEFICIARIESAPI: "api/payout/view/beneficiaries",
  DISABLEBENFICIARYAPI: "api/payout/beneficiary/updatestatus",
  NOTIFICATIONSAPI: "api/notifications",
  NOTIFICATIONSMARKREADAPI: "api/notification/update",
  MYACCOUNTAPI: "api/myaccount",
  UPDATETWOFACTORAUTHAPI: "api/update/twofactorauth",
  UPDATEREQUESTTWOFACTORAUTHAPI: "api/request/twofactorauth/update",
  RAISEDISPUTESAPI: "api/payout/raise/disputes",
  ADDBENEFICIARIESAPI: "api/payout/add/beneficiaries",
  INTIATEPAYOUTREQUESTAPI: "api/payout/initiate/request",
  INTIATEPAYOUTVALIDATEDOTPAPI: "api/payout/initiate/validateOTP",
  INTIATEPAYOUTQUERYAPI: "api/payout/initiate/page",
  KYCINDEXAPI: "api/kycs",
  KYCSUBMITAPI: "api/kycs/submit",
  SUPPORTTICKETS: "api/support/tickets",
  SUPPORTADDTICKET: "api/support/tickets/create",
  SUPPORTVIEWTICKETCHATS: "api/support/tickets/chats/view",
  SUPPORTUPDATETICKETCHATS: "api/support/tickets/chats/update",
  SUPPORTUPDATETICKETSTATUS: "api/support/tickets/update/status",
  MANAGEVIEWSUBUSERS: "api/subusers/list",
  MANAGEADDSUBUSERS: "api/subuser/add",
  MANAGEUPDATESUBUSERS: "api/subuser/edit",
  MANAGETOGGLEUSERSACTIVESTATUS: "api/subuser/activestatus/update",
  MANAGERESENDINVITEUSERS: "api/subuser/resent/passwordsetlink",
  MANAGEUSERSSETPASSWORD: "api/subuser/setpassword",
});
