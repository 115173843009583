import React from 'react';
import { useState } from 'react';
import Overview from './Overview';
import Listings from './Listings';
import Orders from './Orders';
import Inventory from './Inventory';
import MyAccount from './MyAccount';
const Sellerlanding = () => {
    const [activeTab, setActiveTab] = useState('overview');
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };
    return (
        <div className=''>
            <div className="w-full rounded shadow-lg pb-10 flex justify-around place-content-center">
                <div className="flex gap-7 mt-10 sellertab tab w-full flex-wrap md:flex-nowrap justify-center">
                    <div className={` ${activeTab === 'overview' ? '' : ''}`}>
                        <div
                            className={`overtab p-5 text-center cursor-pointer ${activeTab === 'overview' ? 'bg-blue-500 text-white' : ''}`} onClick={() => handleTabClick('overview')}
                        >
                            Overview
                        </div>
                    </div>
                    <div className="">
                        <div
                            className={`overtab p-5 text-center cursor-pointer ${activeTab === 'listings' ? 'bg-blue-500 text-white' : ''}`} onClick={() => handleTabClick('listings')}
                        >
                            Listings
                        </div>
                    </div>
                    <div className="">
                        <div className={`overtab p-5 text-center cursor-pointer ${activeTab === 'orders' ? 'bg-blue-500 text-white' : ''}`} onClick={() => handleTabClick('orders')}>
                            Orders
                        </div>
                    </div>
                    <div className="">
                        <div className={`overtab p-5 text-center cursor-pointer ${activeTab === 'inventory' ? 'bg-blue-500 text-white' : ''}`} onClick={() => handleTabClick('inventory')}>
                            Inventory
                        </div>
                    </div>
                    <div className="">
                        <div className={`overtab p-5 text-center cursor-pointer ${activeTab === 'myaccount' ? 'bg-blue-500 text-white' : ''}`} onClick={() => handleTabClick('myaccount')}>
                            Myaccount
                        </div>
                    </div>
                </div>
                <div className={`flex flex-wrap md:flex-nowrap gap-5 mt-12 ${['listings', 'orders', 'inventory', 'myaccount'].includes(activeTab) ? '' : 'hidden'}`}>
                    <div className='activebox w-full border border-black'>
                        <input type='text' placeholder='Search Order ID/ Product Number' className='px-2 w-full h-full outline-none' />
                    </div>
                    <div className='activesearch bg-blue-500 cursor-pointer rounded-md'>
                        <button className='text-white text-center w-full h-full text-base font-medium'>Search</button>
                    </div>
                </div>
            </div>
            {activeTab === 'overview' && <div><Overview /></div>}
            {activeTab === 'listings' && <div><Listings /></div>}
            {activeTab === 'orders' && <div><Orders /></div>}
            {activeTab === 'inventory' && <div><Inventory /></div>}
            {activeTab === 'myaccount' && <div><MyAccount /></div>}

        </div>
    );
};

export default Sellerlanding;
