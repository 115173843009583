import { CssBaseline, Container, Paper, Box } from "@material-ui/core";
import LinaerStepper from "./checkoutForm";
import HeaderV from "../home/header/headerV2";
import NavV from "../home/navbar/navV2";
import Footer from "../home/footer/footer";
import { AxiosGet, AxiosPost } from "../../utilities/axioscall";
import { AppKeys } from "../../constants/AppKeys";
import { getuserid } from "../../utilities/sessionexpiry";
import { useEffect, useState } from "react";
import Header from "../home/header/header";
import { useNavigate } from "react-router-dom";
import LinearStepper from "./checkoutForm";

function MultiForm(props) {
  const { count, setCount, pepper, setPepper, peppermint } = props;
  const [pageinit, setPageInit] = useState(false);
  let navigate = useNavigate();
  useEffect(() => {
    console.log(`You clicked ${JSON.stringify(count)} times`);
  }, [count]);

  const checkProductInCartData = (prodid) => {
    // console.log("checkProductInCartData");
    // if (cartData.length > 0) {
    //   let checkin = cartData.filter((cart) => {
    //     return cart.productid === prodid;
    //   });
    //   console.log(checkin);
    //   if (checkin.length > 0) {
    //     return true;
    //   }
    // }
    return false;
  };

  const callpageInit = async () => {};
  if (!pageinit) {
    setPageInit(true);
    callpageInit();
    if (!pepper?.isUserLoggedin) {
      window.location.href = "/login";
    }
    if (getuserid()) {
      //  callCartData();
    }
    //callProductsData();
  }
  return (
    <>
      {pepper?.isUserLoggedin ? (
        <>
          <HeaderV
            count={count}
            setCount={setCount}
            pepper={pepper}
            setPepper={setPepper}
            peppermint={peppermint}
          />
        </>
      ) : (
        <>
          <Header
            count={count}
            setCount={setCount}
            pepper={pepper}
            setPepper={setPepper}
            peppermint={peppermint}
          />
        </>
      )}
      <NavV />
      <div className="my-12">
        <LinearStepper />
      </div>
      <Footer />
    </>
  );
}

export default MultiForm;
