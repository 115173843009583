import React from 'react'
import "./productTab.css"
import { IoIosStar } from "react-icons/io";
import { MdAttachFile } from "react-icons/md";
import { BiDislike } from "react-icons/bi";
import { AiOutlineLike } from "react-icons/ai";

const ReviewTab = () => {
  return (
    <div className='reviewTab__container flex justify-between p-10 flex-wrap lg:flex-nowrap'>
        <div className='reviewTab__content w-full lg:w-1/2'>
            <div className='reviewTab__title'>
                <span>Opinions (18)</span>
            </div>
            <div className='reviewTab__Rating my-2'>
                <span>Overall rating</span>
            </div>
            <div className='reviewTab__star flex items-center gap-x-2'>
               <IoIosStar className='reviewTab__IosStar'/>
               <IoIosStar className='reviewTab__IosStar'/>
               <IoIosStar className='reviewTab__IosStar'/>
               <IoIosStar className='reviewTab__IosStar'/>
               <IoIosStar className='reviewTab__IosStar'/>
               <span className='text-sm	font-semibold'>5.0</span>
            </div>

            <div className='reviewTab__detail'>
                <div className='reviewTab__reviews p-6 flex flex-col justify-between mt-3'>
                    <div className='reviewTab__userDetail flex justify-between'>
                        <div className='reviewTab__userName'>
                            <span>User 1</span>
                        </div>
                        <div className='reviewTab__star flex items-center gap-x-2'>
                           <IoIosStar className='reviewTab__IosStar'/>
                           <IoIosStar className='reviewTab__IosStar'/>
                           <IoIosStar className='reviewTab__IosStar'/>
                           <IoIosStar className='reviewTab__IosStar'/>
                           <IoIosStar className='reviewTab__IosStar'/>
                           <span className='text-sm	font-semibold'>5.0</span>
                        </div>
                    </div>
                    <div className='reviews__date'>
                        <span>3th January 2020</span>
                    </div>
                    <div className='reviews__para'>
                        <span>Produkt jest świetny. Ze sklepu postanowiłem skorzystać ze względu na bardzo atrakcyjną cenę i duży wybór produktów. To był mój pierwszy i na pewno nie ostatni zakup u Was.</span>
                    </div>
                    <div className='reviewTab__reviewsFile flex items-center gap-x-2'>
                       <MdAttachFile className='like'/>1 file attached
                    </div>
                    <div className='reviews__comments flex items-center justify-between'>
                        <div className='reviews__addComment'>
                           Add comment
                        </div>
                        <div className='comment__icons flex items-center gap-x-4'>
                            <span className='flex items-center'><BiDislike className='like mr-2'/>5</span>
                            <span className='flex items-center'><AiOutlineLike className='like mr-2'/>114</span>
                        </div>
                    </div>
                </div>

                <div className='reviewTab__reviews p-6 flex flex-col justify-between mt-3'>
                    <div className='reviewTab__userDetail flex justify-between'>
                        <div className='reviewTab__userName'>
                            <span>User 1</span>
                        </div>
                        <div className='reviewTab__star flex items-center gap-x-2'>
                           <IoIosStar className='reviewTab__IosStar'/>
                           <IoIosStar className='reviewTab__IosStar'/>
                           <IoIosStar className='reviewTab__IosStar'/>
                           <IoIosStar className='reviewTab__IosStar'/>
                           <IoIosStar className='reviewTab__IosStar'/>
                           <span className='text-sm	font-semibold'>5.0</span>
                        </div>
                    </div>
                    <div className='reviews__date'>
                        <span>3th January 2020</span>
                    </div>
                    <div className='reviews__para'>
                        <span>Produkt jest świetny. Ze sklepu postanowiłem skorzystać ze względu na bardzo atrakcyjną cenę i duży wybór produktów. To był mój pierwszy i na pewno nie ostatni zakup u Was.</span>
                    </div>
                    <div className='reviewTab__reviewsFile flex items-center gap-x-2'>
                       <MdAttachFile className='like'/>1 file attached
                    </div>
                    <div className='reviews__comments flex items-center justify-between'>
                        <div className='reviews__addComment'>
                           Add comment
                        </div>
                        <div className='comment__icons flex items-center gap-x-4'>
                            <span className='flex items-center'><BiDislike className='like mr-2'/>5</span>
                            <span className='flex items-center'><AiOutlineLike className='like mr-2'/>114</span>
                        </div>
                    </div>
                </div>
                <div className='reviewTab__reviews p-6 flex flex-col justify-between mt-3'>
                    <div className='reviewTab__userDetail flex justify-between'>
                        <div className='reviewTab__userName'>
                            <span>User 1</span>
                        </div>
                        <div className='reviewTab__star flex items-center gap-x-2'>
                           <IoIosStar className='reviewTab__IosStar'/>
                           <IoIosStar className='reviewTab__IosStar'/>
                           <IoIosStar className='reviewTab__IosStar'/>
                           <IoIosStar className='reviewTab__IosStar'/>
                           <IoIosStar className='reviewTab__IosStar'/>
                           <span className='text-sm	font-semibold'>5.0</span>
                        </div>
                    </div>
                    <div className='reviews__date'>
                        <span>3th January 2020</span>
                    </div>
                    <div className='reviews__para'>
                        <span>Produkt jest świetny. Ze sklepu postanowiłem skorzystać ze względu na bardzo atrakcyjną cenę i duży wybór produktów. To był mój pierwszy i na pewno nie ostatni zakup u Was.</span>
                    </div>
                    <div className='reviewTab__reviewsFile flex items-center gap-x-2'>
                       <MdAttachFile className='like'/>1 file attached
                    </div>
                    <div className='reviews__comments flex items-center justify-between'>
                        <div className='reviews__addComment'>
                           Add comment
                        </div>
                        <div className='comment__icons flex items-center gap-x-4'>
                            <span className='flex items-center'><BiDislike className='like mr-2'/>5</span>
                            <span className='flex items-center'><AiOutlineLike className='like mr-2'/>114</span>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div className='technicalTab__form w-full lg:w-2/5 mt-10 lg:mt-0'>
            <div className='technicalTab__formTitle'>
                <span>Rate us</span>
            </div>
            <div className='flex items-center gap-x-4 my-6'>
                <IoIosStar className='reviewTab__formStar'/>
                <IoIosStar className='reviewTab__formStar'/>
                <IoIosStar className='reviewTab__formStar'/>
                <IoIosStar className='reviewTab__formStar'/>
                <IoIosStar className='reviewTab__formStar'/>
            </div>
            <form>
                <div className='technicalTab__input'>
                    <label className='form__labelName mb-3'>Name</label>
                    <input type='text'/>
                </div>
                <div className='technicalTab__input my-4'>
                    <label className='form__labelName mb-3'>Tell about the product in few words</label>
                    <input type='text'/>
                </div>
                <div>
                    <div><span className='form__labelName mb-3'>Attach file</span></div>
                    <div className='technical__dragDrop grid place-content-center'>
                        + Drag a file here
                    </div>
                </div>
                <button className='technicalTab__formBtn mt-5'>Save opinion</button>
            </form>
        </div>
    </div>
  )
}

export default ReviewTab