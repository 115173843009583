import React from 'react'
import { useState } from 'react';
import './SellerDashboard.css';
import Active from './Active';
import Hold from './Hold';
import { Link, useNavigate } from 'react-router-dom';
const Listings = () => {
  const [activeTab, setActiveTab] = useState('active');
  const [showSearchBar, setShowSearchBar] = useState(true);
  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setShowSearchBar(tab !== 'listings');
  };
  const navigate = useNavigate();
  const handleAddProductScreenClick = () => {
    navigate('/addproduct');
  };
  return (
    <div>
     
      <div className='bg-gray-100 pb-10 '>
        <div className='tab1  container mx-auto '>
          <div className='flex justify-between  gap-10 mx-auto pt-5 flex-wrap md:flex-nowrap'>
            <div className='flex justify-center gap-10 flex-wrap md:flex-nowrap'>
              <div className='listwidth '>
                <select id="dropdown1" name="dropdown1" class="mt-1 p-2 border rounded-md w-full border-black dropcateg outline-none text-gray-600 ">
                  <option value="option1" selected disabled hidden className="font-medium">Catergory</option>
                  <option value="option2">Option 2</option>
                  <option value="option3">Option 3</option>
                </select>
              </div>
              <div className='listwidth'>
                <select id="dropdown2" name="dropdown2" class="mt-1 p-2 border rounded-md w-full border-black dropcateg outline-none text-gray-600">
                  <option value="optionA" selected disabled hidden className='font-medium'>Low to High Price</option>
                  <option value="optionB">Option B</option>
                  <option value="optionC">Option C</option>
                </select>
              </div>
              <div className='button bg-blue-500 rounded-lg text-center p-3 cursor-pointer '>
                <button className='text-white font-medium'>Filter</button>
              </div>
            </div>
            <div className='addbutton bg-blue-500 rounded-lg text-center p-3 cursor-pointer'>
              <Link to={"./Addproduct.js"} >
                <button className='text-white font-medium' onClick={handleAddProductScreenClick}>Add New Product</button>
              </Link>
            </div>
          </div>
          <div className='flex justify-start container mx-auto mt-10'>
            <div className={` ${activeTab === 'overview' ? 'active' : ''}`}>
              <div
                className={`active hold rounded-tl-lg p-3 text-center ${activeTab === 'active' ? 'bg-green-700 text-white' : 'bg-blue-100 text-black'}  cursor-pointer`}
                onClick={() => handleTabClick('active')}
              >
                Active
              </div>
            </div>
            <div className="">
              <div
                className={`active hold  p-3 text-center ${activeTab === 'hold' ? 'bg-orange-500 text-white' : 'bg-blue-100 text-black'}  cursor-pointer`}
                onClick={() => handleTabClick('hold')}
              >
                Hold
              </div>
            </div>
          </div>

          {activeTab === 'active' && <div><Active /></div>}
          {activeTab === 'hold' && <div><Hold /></div>}
        </div>

      </div>
    </div>
  )
}

export default Listings
