import React from "react";
import "./emptyCart.css";
import { useNavigate } from "react-router-dom";

const Emptycart = () => {
  let navigation = useNavigate();
  return (
    <div className="emptyCart__container container mx-auto flex items-center justify-center mt-10 mb-10 lg:mb-20 px-5">
      <div className="empty__cart w-full md:w-3/4 xl:w-2/5">
        <div className="grid place-content-center">
          <div className="emptyCart__image">
            <img src="/images/emptyCart.webp" alt="img" />
          </div>
        </div>
        <div className="emptyCart__para text-center my-10">
          <span>
            Your cart is currently empty. Please add products to your cart and
            proceed to order.
          </span>
        </div>
        <div className="emptyCart__btn flex justify-center items-center">
          <div className="emptyCart__shopBtn">
            <button
              onClick={() => {
                navigation("/marketplace");
              }}
            >
              SHOP NOW
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Emptycart;
