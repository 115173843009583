import React, { useState } from "react";
import "./gst.css";
import { useNavigate } from "react-router-dom";

const Gst = ({ formData, setFormData }) => {

  const navigate = useNavigate();

  const [selectedOption, setSelectedOption] = useState("GST");

  const [errors, setErrors] = useState({
    gstoptions: "",
    gstInput: "",
    panInput: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" }); // Clear the error when the user types
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    handleInputChange(event);
  };

  const validateForm = () => {
    const newErrors = {};
  
    if (selectedOption === 'GST' && (!formData.gstInput || formData.gstInput.length !== 15 || !/^[0-9A-Z]+$/.test(formData.gstInput))) {
      newErrors.gstInput = "Valid GST number required";
    }
  
    if (selectedOption === 'PAN' && (!formData.panInput || formData.panInput.length !== 10 || !/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(formData.panInput))) {
      newErrors.panInput = "Valid PAN number required";
    }
  
    setErrors(newErrors);
  
    // Check if there are no errors
    return Object.keys(newErrors).length === 0;
  };


  const handleSubmit = (e) => {
    e.preventDefault();
  
    if (validateForm()) {
      setFormData({ gstInput: "", panInput: "" });
      navigate("/");
    }
  };

  return (
    <div className="gstt">
      <div className="gst__container container mx-auto grid place-content-center">
        <div className="gst__content mt-14">
          <div className="gst__title text-center lg:text-left">
            <span>GST Details</span>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="radio__container my-10 flex justify-between">
              <div>
                <input
                  type="radio"
                  id="gstOption"
                  name="gstoptions"
                  value="GST"
                  checked={selectedOption === "GST"}
                  onChange={handleOptionChange}
                />
                <label htmlFor="gstOption" className="ml-3">
                  I have GSTN
                </label>
              </div>
              <div>
                <input
                  type="radio"
                  id="panOption"
                  name="gstoptions"
                  value="PAN"
                  checked={selectedOption === "PAN"}
                  onChange={handleOptionChange}
                />
                <label htmlFor="panOption" className="ml-3">
                  I don’t have it
                </label>
              </div>
            </div>
            {selectedOption === "GST" && (
              <div>
                <label htmlFor="gstInput" className="block no mb-3">
                  GST Number
                </label>
                <input
                  type="text"
                  id="gstInput"
                  name="gstInput"
                  maxLength={15}
                  value={formData.gstInput}
                  onChange={handleInputChange}
                />
              </div>
            )}


            {errors.gstInput && (
                  <span className="text-red-500">{errors.gstInput}</span>
           )}


            {selectedOption === "PAN" && (
              <div>
                <label htmlFor="panInput" className="block no mb-3">
                  PAN Number
                </label>
                <input
                  type="text"
                  id="panInput"
                  name="panInput"
                  maxLength={10}
                  value={formData.panInput}
                  onChange={handleInputChange}
                />
              </div>
            )}

            {errors.panInput && (
              <span className="text-red-500">{errors.panInput}</span>
            )}

            <div className="subBtn mt-14">
              <button type="submit">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Gst;
