import { AppKeys } from "../constants/AppKeys";
import axios from "axios";
import { validateToken } from "./sessionexpiry";

let ipinfo = {};

export const AxiosIPINFO = async () => {
  return axios
    .get(AppKeys.IPINFOURL)
    .then((res) => {
      return res;
    })
    .catch(() => {});
};

export const AxiosPost = async (apiname, payload, checktoken = true) => {
  let token = checktoken ? validateToken() : null;

  const headers = {
    accesskey: AppKeys.ACCESSKEY,
    "Content-Type": "application/json",
    accesstoken: token,
  };

  return axios.post(AppKeys.APIURL + apiname, payload, {
    headers,
    maxContentLength: Infinity,
    maxBodyLength: Infinity,
  });
  // .then((res) => {
  //   if (res != typeof undefined && res.data != typeof undefined) {
  //     return res.data;
  //   }
  // })
  // .catch((error) => {
  //   return AxiosError(history, error);
  // });
};

export const AxiosGet = async (apiname) => {
  const headers = {
    accesskey: AppKeys.ACCESSKEY,
    "Content-Type": "application/json",
  };

  return axios.get(AppKeys.APIURL + apiname, {
    headers,
    maxContentLength: Infinity,
    maxBodyLength: Infinity,
  });
  // .then((res) => {
  //   if (res != typeof undefined && res.data != typeof undefined) {
  //     return res.data;
  //   }
  // })
  // .catch((error) => {
  //   return AxiosError(history, error);
  // });
};

export const AxiosFilePost = async (apiname, payload, checktoken = true) => {
  let token = checktoken ? validateToken() : null;
  const headers = {
    accesskey: AppKeys.ACCESSKEY,
    accesstoken: token,
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "multipart/form-data",
  };

  return await axios.post(AppKeys.APIURL + apiname, payload, {
    headers,
    maxContentLength: Infinity,
    maxBodyLength: Infinity,
  });
  // .then((res) => {
  //   if (res != typeof undefined && res.data != typeof undefined) {
  //     return res.data;
  //   }
  // })
  // .catch((error) => {
  //   return AxiosError(history, error);
  // });
};
