import React from "react";
import "./nav.css";
import { Link } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";
import { IoSearchOutline } from "react-icons/io5";

const NavV = () => {
  return (
    <section className="navV__section w-full grid place-content-center px-5 md:px-0">
      <nav className="container mx-auto w-full">
        <div className="navV__searchContainer flex md:hidden items-center">
          <IoSearchOutline className="navsearch__icon mr-3" />
          <div className="navV__input">
            <input type="text" placeholder="Search by product" />
          </div>
        </div>

        <ul className="gap-x-4 xl:gap-x-5 2xl:gap-x-11 hidden md:flex">
          <Link to="/product" className="flex items-center navV__text">
            HOME & GARDEN
            <IoIosArrowDown className="ml-2" />
          </Link>
          <Link to="/product" className="flex items-center navV__text">
            CLOTHING
            <IoIosArrowDown className="ml-2" />
          </Link>
          <Link to="/product" className="navV__text">
            ELECTRONICS
          </Link>
          <Link to="/product" className="navV__text">
            OFFICE EQUIPMENT
          </Link>
          <Link to="/product" className="navV__text">
            MACHINES
          </Link>
        </ul>
      </nav>
    </section>
  );
};

export default NavV;
