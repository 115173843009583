import React from 'react'
import "./billing.css"

const Billing = ( { values, handleChange, errors } ) => {
  return (
    <div>
        <div className='billing__title'>
            <span>Billing Address</span>
        </div>
        <form>
            <div className='first__content sm:flex justify-between items-center my-5'>
                <div className='firstName'>
                    <label htmlFor='first__name'>First Name</label>
                    <input type='text' id='first__name' name='step1Field1' value={values.step1Field1} onChange={handleChange} />
                    <div style={{ color: 'red',fontSize:"12px",marginTop:"10px" }}>{errors.step1Field1}</div>
                </div>
                <div className='firstName'>
                    <label htmlFor='last__name'>Last Name</label>
                    <input type='text' id='last__name 'name='step1Field2' value={values.step1Field2} onChange={handleChange}/>
                    <div style={{ color: 'red',fontSize:"12px",marginTop:"10px" }}>{errors.step1Field2}</div>
                </div>
            </div>
            <div className='billing__email'>
                <label htmlFor='email'>Email</label>
                <input type='email' id='email'name='step1Field3' value={values.step1Field3} onChange={handleChange} />
            </div>
            <div style={{ color: 'red',fontSize:"12px",marginTop:"10px" }}>{errors.step1Field3}</div>

            <div className='billing__address mt-5'>
                <label htmlFor='email'>Street Address</label>
                <input type='textl' id='email' name='step1Field4' value={values.step1Field4} onChange={handleChange} />
            </div>
            <div style={{ color: 'red',fontSize:"12px",marginTop:"10px" }}>{errors.step1Field4}</div>

            <div className='first__content sm:flex justify-between items-center'>
                <div className='firstName'>
                    <label htmlFor='city'>City</label>
                    <input type='text' id='city'name='step1Field5' value={values.step1Field5} onChange={handleChange} />
                    <div style={{ color: 'red',fontSize:"12px",marginTop:"10px" }}>{errors.step1Field5}</div>

                </div>

                <div className='firstName'>
                    <label>State</label>
                    <div className='billing__selection'>
                       <select id='select' name='step1Field6' value={values.step1Field6} onChange={handleChange}>
                           <option>Select state</option>
                           <option id="29">TAMIL NADU</option>
                           <option id="02">ANDHRA PRADESH</option>
                           <option id="04">ASSAM</option>
                           <option id="05">BIHAR</option>
                           <option id="33">CHHATTISGARH</option>
                           <option id="11">GUJARAT</option>
                           <option id="34">JHARKHAND</option>
                           <option id="15">KARNATAKA</option>
                           <option id="16">KERALA</option>
                           <option id="17">MADHYA PRADESH</option>
                           <option id="18">MAHARASHTRA</option>
                           <option id="24">ODISHA</option>
                           <option id="27">RAJASTHAN</option>
                           <option id="36">TELANGANA</option>
                           <option id="31">UTTAR PRADESH</option>
                           <option id="32">WEST BENGAL</option>
                           <option id="12">HARYANA</option>
                           <option id="13">HIMACHAL PRADESH</option>
                           <option id="14">JAMMU AND KASHMIR</option>
                           <option id="26">PUNJAB</option>
                           <option id="35">UTTARAKHAND</option>
                           <option id="03">ARUNACHAL PRADESH</option>
                           <option id="20">MANIPUR</option>
                           <option id="21">MEGHALAYA</option>
                           <option id="22">MIZORAM</option>
                           <option id="23">NAGALAND</option>
                           <option id="28">SIKKIM</option>
                           <option id="30">TRIPURA</option>
                           <option id="01">ANDAMAN AND NICOBAR ISLANDS</option>
                           <option id="10">GOA</option>
                           <option id="37">LADAKH</option>
                           <option id="19">LAKSHADWEEP</option>
                           <option id="25">PUDUCHERRY</option>
                       </select>
                    </div>
                    <div style={{ color: 'red',fontSize:"12px",marginTop:"10px" }}>{errors.step1Field6}</div>

                </div>

            </div>
            <div className='first__content sm:flex justify-between items-center mt-5'>
                <div className='firstName'>
                    <label htmlFor='postal'>Zip/ Postal Code</label>
                    <input type='text' id='postal' maxLength={6} name='step1Field7' value={values.step1Field7} onChange={handleChange}/>
                    <div style={{ color: 'red',fontSize:"12px",marginTop:"10px" }}>{errors.step1Field7}</div>
                </div>

                <div className='firstName'>
                    <label htmlFor='number'>Phone number</label>
                    <input type='text' id='number'name='step1Field8' value={values.step1Field8} onChange={handleChange}/>
                    <div style={{ color: 'red',fontSize:"12px",marginTop:"10px" }}>{errors.step1Field8}</div>
                </div>

            </div>
        </form>
    </div>
  )
}

export default Billing