import React from 'react'
import "./productFeatures.css"
import { FaRegCreditCard } from "react-icons/fa";
import { IoLocationOutline,IoAlertCircleOutline } from "react-icons/io5";
import { FaTruckFast } from "react-icons/fa6";


const ProductFeatures = () => {
  return (
    <div className='container mx-auto flex items-center justify-center gap-5 flex-wrap px-5 lg:px-0'>
        <div className='productFeatures__safePayment grid'>
            <div className='safePayment__icon w-full grid place-content-center'>
               <FaRegCreditCard />
            </div>
            <div className='safePayment__title text-center'>
                <span>Safety payment</span>
            </div>
        </div>
        <div className='productFeatures__safePayment grid'>
            <div className='safePayment__icon w-full grid place-content-center'>
               <IoLocationOutline />
            </div>
            <div className='safePayment__title text-center'>
                <span>More than 5k stationary shop</span>
            </div>
        </div>
        <div className='productFeatures__safePayment grid'>
            <div className='safePayment__icon w-full grid place-content-center'>
               <FaTruckFast />
            </div>
            <div className='safePayment__title text-center'>
                <span>Within 2-5 business days</span>
            </div>
        </div>
        <div className='productFeatures__safePayment grid'>
            <div className='safePayment__icon w-full grid place-content-center'>
               <IoAlertCircleOutline />
            </div>
            <div className='safePayment__title text-center'>
                <span>Free shipping and returns</span>
            </div>
        </div>
    </div>
  )
}

export default ProductFeatures