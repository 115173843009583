import React from 'react'
import { MdOutlineEdit, MdDeleteOutline } from "react-icons/md";
import './SellerDashboard.css';
const Inventory = () => {
  return (
    <div className='bg-gray-100 pt-10'>
      <div className='grid place-content-center'>
        <div className='flex gap-10 flex-wrap md:flex-nowrap'>
          <div className='listwidth'>
            <select id="dropdown1" name="dropdown1" class="mt-1 p-2 border rounded-md w-full border-black dropcateg outline-none text-gray-600 ">
              <option value="option1" selected disabled hidden className="font-medium">Catergory</option>
              <option value="option2">Option 2</option>
              <option value="option3">Option 3</option>
            </select>
          </div>
          <div className='button bg-blue-500 rounded-lg text-center p-3 cursor-pointer '>
            <button className='text-white font-medium'>Filter</button>
          </div>
        </div>
        <div className='hidden lg:flex justify-between bg-white  text-black active p-3 mt-10'>
          <div>Product Details</div>
          <div className='lg:flex  lg:gap-24  lg:mr-24'>
            <div>Current Stock</div>
            <div className=''>Category</div>
            <div className='ml-5'>Status</div>
            <div className=''>Action</div>
          </div>
        </div>
        <div className='w-full lg:w-full xl:w-full bg-blue-100 pt-1 pb-5 p-3 mt-1 sm:mt-0'>
          <div className='card w-72 md:w-auto lg:w-full xl:full bg-white mx-auto mt-1 p-5 rounded-lg'>
            <div className='w-full lg:flex lg:justify-between flex-wrap lg:flex-nowrap'>
              <div className='flex md:mr-20 lg:mr-0 sm:justify-center  lg:justify-normal'>
                <div className='lampimg'>
                <img src="/images/Lamp.css" alt='Lamp' />
                </div>
                <div className=''>
                  <div className='tabtext1 text-sm text-gray-500 font-medium'>
                    Brand Name | Part No.234565
                  </div>
                  <div className='tabtext2 text-base text-black font-medium'>AMBER DECOR Small Lamp 60W, E27 590</div>
                  <div className='tabtext1 text-base text-gray-500 font-medium'>
                    SKU ID - AP-KPN-09U-9765
                  </div>
                </div>
              </div>
              <div className='md:flex md:gap-10 items-center lg:ml-32 lg:gap-20 xl:gap-20  sm:justify-center  lg:justify-normal '>
                <div className='flex md:gap-1'>
                  <div className='tabrate sm:block md:block lg:hidden xl:hidden ml-16 md:ml-0'>CurrentStock:</div><span className='tabrate text-base text-black font-medium ml-10 md:ml-0  sm:ml-3 lg:mr-10 xl:mr-9'>128Nos</span>
                </div>
                <div className='flex md:gap-3'>
                  <span className='tabrate sm:block md:block lg:hidden xl:hidden ml-16 md:ml-0'>Category:</span>
                  <div className='tabcat text-base text-black font-medium ml-10 md:ml-0 lg:mr-5 xl:mr-7 sm:ml-10'>
                    Electric Equipment</div>
                </div>
                <div className='flex md:gap-3'>
                  <span className='tabrate sm:block md:block lg:hidden xl:hidden ml-16 md:ml-0'>Status:</span>
                  <div className='tabcat text-base text-green-500 font-medium ml-16 md:ml-0'>High</div>
                </div>
                <div className='flex md:gap-3'>
                  <span className='tabrate sm:block md:block lg:hidden xl:hidden ml-16 md:ml-0'>Action:</span>
                  <div className='flex gap-2 cursor-pointer'>
                    <div className='mt-1 ml-16 md:ml-0'>
                      <MdOutlineEdit style={{ color: '#1071FF' }} />
                    </div>
                    <span className='symtext text-base text-blue-500 font-medium'>Edit</span>
                  </div>
                </div>
                <div className='flex flex-wrap md:flex-nowrap sm:gap-0 lg:gap-10 xl:gap-0 mt-5'>
                </div>
              </div>
            </div>
          </div>
          <div className='card w-72 md:w-auto lg:w-full xl:full bg-white mx-auto mt-1 p-5 rounded-lg'>
            <div className='w-full lg:flex lg:justify-between flex-wrap lg:flex-nowrap'>
              <div className='flex md:mr-20 lg:mr-0 sm:justify-center  lg:justify-normal'>
                <div className='lampimg'>
                <img src="/images/Lamp.css" alt='Lamp' />
                </div>
                <div className=''>
                  <div className='tabtext1 text-sm text-gray-500 font-medium'>
                    Brand Name | Part No.234565
                  </div>
                  <div className='tabtext2 text-base text-black font-medium'>AMBER DECOR Small Lamp 60W, E27 590</div>
                  <div className='tabtext1 text-base text-gray-500 font-medium'>
                    SKU ID - AP-KPN-09U-9765
                  </div>
                </div>
              </div>
              <div className='md:flex md:gap-10 items-center lg:ml-32 lg:gap-20 xl:gap-20  sm:justify-center lg:justify-normal '>
                <div className='flex md:gap-1'>
                  <div className='tabrate sm:block md:block lg:hidden xl:hidden ml-16 md:ml-0'>CurrentStock:</div><span className='tabrate text-base text-black font-medium ml-10 md:ml-0 sm:ml-3 lg:mr-8 xl:mr-9'>128Nos</span>
                </div>
                <div className='flex md:gap-3'>
                  <span className='tabrate sm:block md:block lg:hidden xl:hidden ml-16 md:ml-0'>Category:</span>
                  <div className='tabcat text-base text-black font-medium ml-10 md:ml-0 lg:mr-0 xl:mr-0 sm:ml-10'>
                    Electric Equipment</div>
                </div>
                <div className='flex md:gap-3'>
                  <span className='tabrate sm:block md:block lg:hidden xl:hidden ml-16 md:ml-0'>Status:</span>
                  <div className='tabcat text-base text-orange-500 font-medium ml-16 md:ml-0'>Average</div>
                </div>
                <div className='flex md:gap-3'>
                  <span className='tabrate sm:block md:block lg:hidden xl:hidden ml-16 md:ml-0'>Action:</span>
                  <div className='flex gap-2 cursor-pointer'>
                    <div className='mt-1 ml-16 md:ml-0'>
                      <MdOutlineEdit style={{ color: '#1071FF' }} />
                    </div>
                    <span className='symtext text-base text-blue-500 font-medium'>Edit</span>
                  </div>
                </div>
                <div className='flex flex-wrap md:flex-nowrap sm:gap-0 lg:gap-10 xl:gap-0 mt-5'>
                </div>
              </div>
            </div>
          </div>
          <div className='card w-72 md:w-auto lg:w-full xl:full bg-white mx-auto mt-1 p-5 rounded-lg'>
            <div className='w-full lg:flex lg:justify-between flex-wrap lg:flex-nowrap'>
              <div className='flex md:mr-20 lg:mr-0 sm:justify-center  lg:justify-normal'>
                <div className='lampimg'>
                <img src="/images/Lamp.css" alt='Lamp' />
                </div>
                <div className=''>
                  <div className='tabtext1 text-sm text-gray-500 font-medium'>
                    Brand Name | Part No.234565
                  </div>
                  <div className='tabtext2 text-base text-black font-medium '>AMBER DECOR Small Lamp 60W, E27 590</div>
                  <div className='tabtext1 text-base text-gray-500 font-medium'>
                    SKU ID - AP-KPN-09U-9765
                  </div>
                </div>
              </div>
              <div className='md:flex md:gap-10 items-center lg:ml-32 lg:gap-20 xl:gap-20  sm:justify-center lg:justify-normal '>
                <div className='flex md:gap-1'>
                  <div className='tabrate sm:block md:block lg:hidden xl:hidden ml-16 md:ml-0'>CurrentStock:</div><span className='tabrate text-base text-black font-medium ml-10 sm:ml-3 md:ml-0 lg:mr-10 xl:mr-10'>128Nos</span>
                </div>
                <div className='flex md:gap-3'>
                  <span className='tabrate sm:block md:block lg:hidden xl:hidden ml-16 md:ml-0'>Category:</span>
                  <div className='tabcat text-base text-black font-medium ml-10 md:ml-0 lg:mr-5 xl:mr-7 sm:ml-10'>
                    Electric Equipment</div>
                </div>
                <div className='flex md:gap-3'>
                  <span className='tabrate sm:block md:block lg:hidden xl:hidden ml-16 md:ml-0'>Status:</span>
                  <div className='tabcat text-base text-red-500 font-medium ml-16 md:ml-0'>Low</div>
                </div>
                <div className='flex md:gap-3'>
                  <span className='tabrate sm:block md:block lg:hidden xl:hidden ml-16 md:ml-0'>Action:</span>
                  <div className='flex gap-2 cursor-pointer'>
                    <div className='mt-1 ml-16 md:ml-0'>
                      <MdOutlineEdit style={{ color: '#1071FF' }} />
                    </div>
                    <span className='symtext text-base text-blue-500 font-medium'>Edit</span>
                  </div>
                </div>
                <div className='flex flex-wrap md:flex-nowrap sm:gap-0 lg:gap-10 xl:gap-0 mt-5'>
                </div>
              </div>
            </div>
          </div>
          <div className='card w-72 md:w-auto lg:w-full xl:full bg-white mx-auto mt-1 p-5 rounded-lg'>
            <div className='w-full lg:flex lg:justify-between flex-wrap lg:flex-nowrap'>
              <div className='flex md:mr-20 lg:mr-0 sm:justify-center  lg:justify-normal'>
                <div className='lampimg'>
                <img src="/images/Lamp.css" alt='Lamp' />
                </div>
                <div className=''>
                  <div className='tabtext1 text-sm text-gray-500 font-medium'>
                    Brand Name | Part No.234565
                  </div>
                  <div className='tabtext2 text-base text-black font-medium '>AMBER DECOR Small Lamp 60W, E27 590</div>
                  <div className='tabtext1 text-base text-gray-500 font-medium'>
                    SKU ID - AP-KPN-09U-9765
                  </div>
                </div>
              </div>
              <div className='md:flex md:gap-10 items-center lg:ml-32 lg:gap-20 xl:gap-20  sm:justify-center lg:justify-normal '>
                <div className='flex md:gap-1'>
                  <div className='tabrate sm:block md:block lg:hidden xl:hidden ml-16 md:ml-0'>CurrentStock:</div><span className='tabrate text-base text-black font-medium ml-10 sm:ml-3 md:ml-0 lg:mr-7 xl:mr-9'>128Nos</span>
                </div>
                <div className='flex md:gap-3'>
                  <span className='tabrate sm:block md:block lg:hidden xl:hidden ml-16 md:ml-0'>Category:</span>
                  <div className='tabcat text-base text-black font-medium ml-10 md:ml-0 lg:mr-0 xl:mr-0 sm:ml-10'>
                    Electric Equipment</div>
                </div>
                <div className='flex md:gap-3'>
                  <span className='tabrate sm:block md:block lg:hidden xl:hidden ml-16 md:ml-0'>Status:</span>
                  <div className='tabcat text-base text-orange-500 font-medium ml-16 md:ml-0'>Average</div>
                </div>
                <div className='flex md:gap-3'>
                  <span className='tabrate sm:block md:block lg:hidden xl:hidden ml-16 md:ml-0'>Action:</span>
                  <div className='flex gap-2 cursor-pointer'>
                    <div className='mt-1 ml-16 md:ml-0'>
                      <MdOutlineEdit style={{ color: '#1071FF' }} />
                    </div>
                    <span className='symtext text-base text-blue-500 font-medium'>Edit</span>
                  </div>
                </div>
                <div className='flex flex-wrap md:flex-nowrap sm:gap-0 lg:gap-10 xl:gap-0 mt-5'>
                </div>
              </div>
            </div>
          </div>
          <div className='card w-72 md:w-auto lg:w-full xl:full bg-white mx-auto mt-1 p-5 rounded-lg'>
            <div className='w-full lg:flex lg:justify-between flex-wrap lg:flex-nowrap'>
              <div className='flex md:mr-20 lg:mr-0 sm:justify-center  lg:justify-normal'>
                <div className='lampimg'>
                <img src="/images/Lamp.css" alt='Lamp' />
                </div>
                <div className=''>
                  <div className='tabtext1 text-sm text-gray-500 font-medium'>
                    Brand Name | Part No.234565
                  </div>
                  <div className='tabtext2 text-base text-black font-medium '>AMBER DECOR Small Lamp 60W, E27 590</div>
                  <div className='tabtext1 text-base text-gray-500 font-medium'>
                    SKU ID - AP-KPN-09U-9765
                  </div>
                </div>
              </div>
              <div className='md:flex md:gap-10 items-center lg:ml-32 lg:gap-20 xl:gap-20  sm:justify-center lg:justify-normal '>
                <div className='flex md:gap-1'>
                  <div className='tabrate sm:block md:block lg:hidden xl:hidden ml-16 md:ml-0'>CurrentStock:</div><span className='tabrate text-base text-black font-medium ml-10 sm:ml-3 md:ml-0 lg:mr-10 xl:mr-10'>128Nos</span>
                </div>
                <div className='flex md:gap-3'>
                  <span className='tabrate sm:block md:block lg:hidden xl:hidden ml-16 md:ml-0'>Category:</span>
                  <div className='tabcat text-base text-black font-medium ml-10 md:ml-0 lg:mr-5 xl:mr-7 sm:ml-10'>
                    Electric Equipment</div>
                </div>
                <div className='flex md:gap-3'>
                  <span className='tabrate sm:block md:block lg:hidden xl:hidden ml-16 md:ml-0'>Status:</span>
                  <div className='tabcat text-base text-red-500 font-medium ml-16 md:ml-0'>Low</div>
                </div>
                <div className='flex md:gap-3'>
                  <span className='tabrate sm:block md:block lg:hidden xl:hidden ml-16 md:ml-0'>Action:</span>
                  <div className='flex gap-2 cursor-pointer'>
                    <div className='mt-1 ml-16 md:ml-0'>
                      <MdOutlineEdit style={{ color: '#1071FF' }} />
                    </div>
                    <span className='symtext text-base text-blue-500 font-medium'>Edit</span>
                  </div>
                </div>
                <div className='flex flex-wrap md:flex-nowrap sm:gap-0 lg:gap-10 xl:gap-0 mt-5'>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Inventory
