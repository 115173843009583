import React from "react";
import ProductViewImg from "./productViewImg";
import ProductDetail from "./productDetail";

const ProductView = ({
  count,
  setCount,
  pepper,
  setPepper,
  peppermint,
  productsinfo,
}) => {
  return (
    <div className="w-full">
      <div className="productView container mx-auto flex mt-14 mb-10 flex-wrap">
        <ProductViewImg productsinfo={productsinfo} />
        <ProductDetail
          productsinfo={productsinfo}
          count={count}
          setCount={setCount}
          pepper={pepper}
          setPepper={setPepper}
          peppermint={peppermint}
        />
      </div>
    </div>
  );
};

export default ProductView;
