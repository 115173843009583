import React from "react";
import "./successPage.css";
import { useNavigate } from "react-router-dom";

const SuccessPage = () => {
  let navigate = useNavigate();
  return (
    <div className="successPage grid place-content-center">
      <div className="successPage__container xl:container xl:mx-auto h-screen flex justify-center items-center">
        <div className="successPage__container w-4/12">
          <div className="success__orderContent px-20 mb-10">
            <div className="success__contentTitle text-center">
              <span>Yes, you’ve successfully ordered!</span>
            </div>
          </div>
          <div className="success__detail p-8">
            <div className="grid place-content-center">
              <div className="success__detailImg">
                <img src="/images/Checkicon.webp" alt="img" />
              </div>
            </div>
            <div className="success__msg text-center mt-5 mb-2">
              <span>Thanks a lot for putting up this order</span>
            </div>
            <div className="success__para text-center">
              <span>
                Your Verto Cutter order{" "}
                <span className="tokenId">ASK123456</span> has successfully been
                placed. You’ll fin all the details about your order below, and
                we’ll send you a shipping confrimation email as soon as your
                order ships. In the meantime, you can share Verto and earn store
                credit.
              </span>
            </div>
            <div className="order__review py-2 px-3 mt-5 mb-8">
              <div className="order__reviewTitle">
                <span>Order Review</span>
              </div>
              <div className="order__items flex justify-between items-center mt-1">
                <div>
                  <span className="text-xs font-normal">14 items in cart</span>
                </div>
                <div>
                  <span className="order__itemsRupees">₹ 30.00</span>
                </div>
              </div>
            </div>
            <div className="success__btn">
              <button
                onClick={() => {
                  navigate("/marketplace");
                }}
              >
                Back to shop
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessPage;
