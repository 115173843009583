import React from 'react'
import "./features.css"
import { FaTruck } from "react-icons/fa";
import { BsBarChartFill } from "react-icons/bs";
import { MdVerifiedUser } from "react-icons/md";



const Features = () => {
  return (
    <section className="features__section container mx-auto grid place-content-center grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 mt-10 sm:mt-20">
        <div className="features p-6">
            <div className="features__icon grid place-content-center">
                <i class="fa-solid fa-thumbs-up"></i>
            </div>
            <div className="features__title mt-7 mb-2">
                <span>Product Listings</span>
            </div>
            <div className="features__para mb-5">
                <span>Haoda Mart  allows sellers to generate elaborate lists of products complete with pictures, briefs, specifications</span>
            </div>
            <div className="features__more">
                <span>Learn More <i class="fa-solid fa-chevron-right ml-3"></i></span>
            </div>
        </div>
        <div className="features p-6">
            <div className="features__icon grid place-content-center">
               <MdVerifiedUser />            
            </div>
            <div className="features__title mt-7 mb-2">
                <span>Verified Suppliers</span>
            </div>
            <div className="features__para mb-5">
                <span>Haoda Mart  certifies its  suppliers which assure people’s security while trading. A verified badge implies that a supplier</span>
            </div>
            <div className="features__more">
                <span>Learn More <i class="fa-solid fa-chevron-right ml-3"></i></span>
            </div>
        </div>
        <div className="features p-6">
            <div className="features__icon grid place-content-center">
               <BsBarChartFill />
            </div>
            <div className="features__title mt-7 mb-2">
                <span>Data and Analytics</span>
            </div>
            <div className="features__para mb-5">
                <span>There are analytics tools which give sellers the idea behind their stores’ performance, the buying behaviour </span>
            </div>
            <div className="features__more">
                <span>Learn More <i class="fa-solid fa-chevron-right ml-3"></i></span>
            </div>
        </div>
        <div className="features p-6">
            <div className="features__icon grid place-content-center">
             <FaTruck />           
            </div>
            <div className="features__title mt-7 mb-2">
                <span>Logistics and Shipping Services</span>
            </div>
            <div className="features__para mb-5">
                <span>Haoda Mart works with logistic partners, which assist in the transportation of the products that buyers purchase</span>
            </div>
            <div className="features__more">
                <span>Learn More <i class="fa-solid fa-chevron-right ml-3"></i></span>
            </div>
        </div>
    </section>  
    )
}

export default Features;