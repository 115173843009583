import React from 'react'
import Account from './Account'
import BusinessAddr from './BusinessAddr'
import BankDetails from './BankDetails'
import KYCDetails from './KYCDetails'
const MyAccount = () => {
  return (
    <div className='bg-gray-100 pt-10 pb-10'>
     <Account/>
     <BusinessAddr/>
     <BankDetails/>
     <KYCDetails/>
    </div>
  )
}

export default MyAccount
