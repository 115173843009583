import React, { useState } from "react";
import "./create.css";

const CreateForm = ({ onNextTab, formData, setFormData }) => {
  const [errors, setErrors] = useState({
    email: "",
    mobileNumber: "",
    password: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" }); // Clear the error when the user types
  };

  const validateForm = () => {

    let formValid = true;
    
    const newErrors = {};

     // Email validation
     if (!formData.email) {
      formValid = false;
      newErrors.email = "Email is required";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      formValid = false;
      newErrors.email = "Invalid email address";
    }

    // Mobile number validation
    if (!formData.mobileNumber) {
      formValid = false;
      newErrors.mobileNumber = "Mobile number is required";
    } else if (!/^\d{10}$/.test(formData.mobileNumber)) {
      formValid = false;
      newErrors.mobileNumber = "Enter number only";
    } else if (formData.mobileNumber.length < 10){
      formValid = false;
      newErrors.mobileNumber = "Maximum 10 numbers required"
    }

    // Password validation
    if (!formData.password) {
      formValid = false;
      newErrors.password = "Password is required"; 
    } else if (formData.password.length < 6) {
      formValid = false;
      newErrors.password = "Password must be at least 6 characters";
    }


    setErrors(newErrors);

    // Check if there are no erro rs
    return formValid;
  };

  const handleSubmit = (e) => { 
    e.preventDefault();

    // Validate the form before moving to the next tab
    if (validateForm()) {
      onNextTab(formData);
      setFormData({
        email:"",
        mobileNumber:"",
        password:""
      })
    }
  };

  return (  
    <div className="create">
      <div className="create__account container mx-auto md:grid place-content-center">
        <div className="create__container py-5 lg:py-10">
          <div className="create__content px-4 lg:px-0">
            <div className="create__title mb-6 lg:mb-10 text-center lg:text-left">
              <span>Create your account</span>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="email mb-5">
                <label>Email</label>
                <input
                  type="text"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  className="mt-3"
                />
                {errors.email && (
                  <span className="text-red-500 mt-3 block">{errors.email}</span>
                )}
              </div>
              <div className="email mb-5">
                <label>Mobile Number</label>
                <input
                  type="text"
                  name="mobileNumber"
                  value={formData.mobileNumber}
                  onChange={handleInputChange}
                  className="mt-3"
                  maxLength={10}
                />
                {errors.mobileNumber && (
                  <span className="text-red-500 mt-3 block">{errors.mobileNumber}</span>
                )}
              </div>
              <div className="email mb-12">
                <label>Password</label>
                <input
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  className="mt-3"
                />
                {errors.password && (
                  <span className="text-red-500 mt-3 block">{errors.password}</span>
                )}
              </div>
              <div className="subBtn">
                <button type="submit">Next</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateForm;
