import React, { useState } from "react";
import "./header.css";
import { Link } from "react-router-dom";
import Mobilenav from "./mobileNav";
import { MdOutlineShoppingCart } from "react-icons/md";

const Header = (props) => {
  const { count, setCount, pepper, setPepper, peppermint } = props;
  let cartData = [];
  const [navOpen, setnavClose] = useState(false);

  const navOpenbtn = () => {
    setnavClose(true);
  };

  const navClosebtn = () => {
    setnavClose(false);
  };

  return (
    <div className="header__section flex justify-center items-center">
      <header className="container mx-auto flex justify-between lg:justify-normal	items-center px-5 md:px-0">
        <div className="menuBtn block lg:hidden mt-2">
          <button id="navBtn" onClick={navOpenbtn}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5M12 17.25h8.25"
              />
            </svg>
          </button>
        </div>

        <Mobilenav navOpen={navOpen} navClosebtn={navClosebtn} />

        <div className="martLogo">
          <Link to="/">
            <img src="/images/Logo.webp" alt="img" />
          </Link>
        </div>

        <div className="lg:ml-auto">
          <div className="header__detail hidden lg:flex">
            <div className="shopping">
              <Link to="/marketplace">
                <i className="fa-solid fa-tag ico mr-2"></i>
                <span>Shopping</span>
              </Link>
            </div>
            <div className="sell ml-2">
              <Link to="/seller-registration">
                <i className="fa-solid fa-shop ico mr-2"></i>
                <span>Sell</span>
              </Link>
            </div>
            <div className="message flex ml-2">
              <div className="envelope mr-2">
                <i className="fa-regular fa-envelope ico"></i>
                <span className="number">12</span>
              </div>
              <span>Messages</span>
            </div>
            <a href="/cart" className="message flex items-center ml-2">
              <div className="envelope mr-2">
                <MdOutlineShoppingCart className="icon" />
                <span className="number">
                  {
                    Object.keys(
                      pepper?.cachedata?.cartitems === undefined
                        ? {}
                        : pepper?.cachedata?.cartitems
                    ).length
                  }
                </span>
              </div>
              <span>My Cart</span>
            </a>
            <div className="help ml-2">
              <i class="fa-solid fa-circle-question ico mr-2"></i>
              <span>Help</span>
            </div>
            <div className="header__btn  flex items-center ml-2">
              <Link to="/login">
                <button>LOGIN /</button>
              </Link>
              <Link to="/register">
                <button className="ml-2">REGISTER</button>
              </Link>
            </div>
          </div>
        </div>

        <div className="flex lg:hidden">
          <div className="envelope mr-4 mt-2">
            <i className="fa-regular fa-envelope ico"></i>
            <span className="numbers">12</span>
          </div>
        </div>
      </header>
     
    </div>
  );
};

export default Header;
