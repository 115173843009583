import React from 'react'
import "./riva.css"
import { IoStar } from "react-icons/io5";

// Slider 

import Slider  from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";



const Rivadetail = () => {

    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        speed: 3500,
        autoplaySpeed: 3500,
        cssEase: "linear",
        responsive : [
           {
            breakpoint: 1024,
            settings:{
                slidesToShow:2,
                slidesToScroll: 1,
              }
           },
           {
            breakpoint: 700,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            }
          },
        ]
    };


  return (
    <div className='rivaDetail pb-2'>
        <div className='container mx-auto'>

            {/* About Section */}

            <div className='w-full grid place-content-center pt-24 lg:pt-32 lg:py-32 px-6 md:px-8'>
                <div className='riva-about__container'>
                    <div className='riva-about__title text-center mb-10'>
                        <span>About Us</span>
                    </div>
                    <div className='riva-about__para text-center'>
                        <span>Riva Trade is a Chennai-based vegetable buying website on a mission to provide convenient access to farm-fresh vegetables. We connect consumers with local farmers and suppliers, offering a diverse range of high-quality produce. Our commitment to quality, transparency, and supporting local communities sets us apart. Experience the ease of online vegetable shopping while contributing to sustainable agriculture and enjoying the best in taste and nutrition.</span>
                    </div>
                </div>
            </div>

            {/* Rating & Reviews Section */}

            <div>
                <div className='riva-about__title text-center mb-14 mt-10 lg:mt-0'>
                        <span>Rating & Reviews</span>
                </div>
                 <div className="riva-rating__conatainer hidden  lg:grid lg:grid-cols-2 xl:grid-cols-3 gap-5">
                      <div className="riva-rating__box">
                          <div className="riva-rating__imgDetails flex items-center">
                              <div className="riva-rating__img mr-5">
                                  <img src="/images/image(1).webp" alt="img" />
                              </div>
                              <div className="riva-rating__detail">
                                  <div className="riva-rating__detailTitle">
                                      <span>Kavya Ravichandran</span>
                                  </div>
                                  <div className="riva-rating__detailName">
                                      <span>"Freshness at Its Best"</span>
                                  </div>
                                  <div className="riva-rating__detailStar flex items-center gap-x-2">
                                     <IoStar />
                                     <IoStar />
                                     <IoStar />
                                     <IoStar />
                                     <IoStar />
                                  </div>
                              </div>
                         </div>
                         <div className="rivaa-rating__para">
                             <span>I love the variety of vegetables available here. It's a one-stop-shop for all my cooking needs. Plus, the prices are competitive, and the quality is outstanding. I couldn't ask for more.</span>
                         </div>
                      </div>
                      <div className="riva-rating__box">
                          <div className="riva-rating__imgDetails flex items-center">
                              <div className="riva-rating__img mr-5">
                                  <img src="/images/image(1).webp" alt="img" />
                              </div>
                              <div className="riva-rating__detail">
                                  <div className="riva-rating__detailTitle">
                                      <span>Kavya Ravichandran</span>
                                  </div>
                                  <div className="riva-rating__detailName">
                                      <span>"Freshness at Its Best"</span>
                                  </div>
                                  <div className="riva-rating__detailStar flex items-center gap-x-2">
                                     <IoStar />
                                     <IoStar />
                                     <IoStar />
                                     <IoStar />
                                     <IoStar />
                                  </div>
                              </div>
                         </div>
                         <div className="rivaa-rating__para">
                             <span>I love the variety of vegetables available here. It's a one-stop-shop for all my cooking needs. Plus, the prices are competitive, and the quality is outstanding. I couldn't ask for more.</span>
                         </div>
                      </div>
                      <div className="riva-rating__box">
                          <div className="riva-rating__imgDetails flex items-center">
                              <div className="riva-rating__img mr-5">
                                  <img src="/images/image(1).webp" alt="img" />
                              </div>
                              <div className="riva-rating__detail">
                                  <div className="riva-rating__detailTitle">
                                      <span>Kavya Ravichandran</span>
                                  </div>
                                  <div className="riva-rating__detailName">
                                      <span>"Freshness at Its Best"</span>
                                  </div>
                                  <div className="riva-rating__detailStar flex items-center gap-x-2">
                                     <IoStar />
                                     <IoStar />
                                     <IoStar />
                                     <IoStar />
                                     <IoStar />
                                  </div>
                              </div>
                         </div>
                         <div className="rivaa-rating__para">
                             <span>I love the variety of vegetables available here. It's a one-stop-shop for all my cooking needs. Plus, the prices are competitive, and the quality is outstanding. I couldn't ask for more.</span>
                         </div>
                      </div>
                      <div className="riva-rating__box">
                          <div className="riva-rating__imgDetails flex items-center">
                              <div className="riva-rating__img mr-5">
                                  <img src="/images/image(1).webp" alt="img" />
                              </div>
                              <div className="riva-rating__detail">
                                  <div className="riva-rating__detailTitle">
                                      <span>Kavya Ravichandran</span>
                                  </div>
                                  <div className="riva-rating__detailName">
                                      <span>"Freshness at Its Best"</span>
                                  </div>
                                  <div className="riva-rating__detailStar flex items-center gap-x-2">
                                     <IoStar />
                                     <IoStar />
                                     <IoStar />
                                     <IoStar />
                                     <IoStar />
                                  </div>
                              </div>
                         </div>
                         <div className="rivaa-rating__para">
                             <span>I love the variety of vegetables available here. It's a one-stop-shop for all my cooking needs. Plus, the prices are competitive, and the quality is outstanding. I couldn't ask for more.</span>
                         </div>
                      </div>
                      <div className="riva-rating__box">
                          <div className="riva-rating__imgDetails flex items-center">
                              <div className="riva-rating__img mr-5">
                                  <img src="/images/image(1).webp" alt="img" />
                              </div>
                              <div className="riva-rating__detail">
                                  <div className="riva-rating__detailTitle">
                                      <span>Kavya Ravichandran</span>
                                  </div>
                                  <div className="riva-rating__detailName">
                                      <span>"Freshness at Its Best"</span>
                                  </div>
                                  <div className="riva-rating__detailStar flex items-center gap-x-2">
                                     <IoStar />
                                     <IoStar />
                                     <IoStar />
                                     <IoStar />
                                     <IoStar />
                                  </div>
                              </div>
                         </div>
                         <div className="rivaa-rating__para">
                             <span>I love the variety of vegetables available here. It's a one-stop-shop for all my cooking needs. Plus, the prices are competitive, and the quality is outstanding. I couldn't ask for more.</span>
                         </div>
                      </div>
                      <div className="riva-rating__box">
                          <div className="riva-rating__imgDetails flex items-center">
                              <div className="riva-rating__img mr-5">
                                  <img src="/images/image(1).webp" alt="img" />
                              </div>
                              <div className="riva-rating__detail">
                                  <div className="riva-rating__detailTitle">
                                      <span>Kavya Ravichandran</span>
                                  </div>
                                  <div className="riva-rating__detailName">
                                      <span>"Freshness at Its Best"</span>
                                  </div>
                                  <div className="riva-rating__detailStar flex items-center gap-x-2">
                                     <IoStar />
                                     <IoStar />
                                     <IoStar />
                                     <IoStar />
                                     <IoStar />
                                  </div>
                              </div>
                         </div>
                         <div className="rivaa-rating__para">
                             <span>I love the variety of vegetables available here. It's a one-stop-shop for all my cooking needs. Plus, the prices are competitive, and the quality is outstanding. I couldn't ask for more.</span>
                         </div>
                      </div>
                   </div>
            </div>


            {/* Rating & Reviews Slider */}

            <div className='block lg:hidden'>
                <Slider {...settings}>
                    <div className="riva-rating__box">
                       <div className="riva-rating__imgDetails flex items-center">
                           <div className="riva-rating__img mr-5">
                               <img src="/images/image(1).webp" alt="img" />
                           </div>
                           <div className="riva-rating__detail">
                               <div className="riva-rating__detailTitle">
                                   <span>Kavya Ravichandran</span>
                               </div>
                               <div className="riva-rating__detailName">
                                   <span>"Freshness at Its Best"</span>
                               </div>
                               <div className="riva-rating__detailStar flex items-center gap-x-2">
                                  <IoStar />
                                  <IoStar />
                                  <IoStar />
                                  <IoStar />
                                  <IoStar />
                               </div>
                           </div>
                      </div>
                      <div className="rivaa-rating__para">
                          <span>I love the variety of vegetables available here. It's a one-stop-shop for all my cooking needs. Plus, the prices are competitive, and the quality is outstanding. I couldn't ask for more.</span>
                      </div>
                    </div>
    
                    <div className="riva-rating__box">
                       <div className="riva-rating__imgDetails flex items-center">
                           <div className="riva-rating__img mr-5">
                               <img src="/images/image(1).webp" alt="img" />
                           </div>
                           <div className="riva-rating__detail">
                               <div className="riva-rating__detailTitle">
                                   <span>Kavya Ravichandran</span>
                               </div>
                               <div className="riva-rating__detailName">
                                   <span>"Freshness at Its Best"</span>
                               </div>
                               <div className="riva-rating__detailStar flex items-center gap-x-2">
                                  <IoStar />
                                  <IoStar />
                                  <IoStar />
                                  <IoStar />
                                  <IoStar />
                               </div>
                           </div>
                      </div>
                      <div className="rivaa-rating__para">
                          <span>I love the variety of vegetables available here. It's a one-stop-shop for all my cooking needs. Plus, the prices are competitive, and the quality is outstanding. I couldn't ask for more.</span>
                      </div>
                    </div>

                    <div className="riva-rating__box">
                       <div className="riva-rating__imgDetails flex items-center">
                           <div className="riva-rating__img mr-5">
                               <img src="/images/image(1).webp" alt="img" />
                           </div>
                           <div className="riva-rating__detail">
                               <div className="riva-rating__detailTitle">
                                   <span>Kavya Ravichandran</span>
                               </div>
                               <div className="riva-rating__detailName">
                                   <span>"Freshness at Its Best"</span>
                               </div>
                               <div className="riva-rating__detailStar flex items-center gap-x-2">
                                  <IoStar />
                                  <IoStar />
                                  <IoStar />
                                  <IoStar />
                                  <IoStar />
                               </div>
                           </div>
                      </div>
                      <div className="rivaa-rating__para">
                          <span>I love the variety of vegetables available here. It's a one-stop-shop for all my cooking needs. Plus, the prices are competitive, and the quality is outstanding. I couldn't ask for more.</span>
                      </div>
                    </div>

                    <div className="riva-rating__box">
                       <div className="riva-rating__imgDetails flex items-center">
                           <div className="riva-rating__img mr-5">
                               <img src="/images/image(1).webp" alt="img" />
                           </div>
                           <div className="riva-rating__detail">
                               <div className="riva-rating__detailTitle">
                                   <span>Kavya Ravichandran</span>
                               </div>
                               <div className="riva-rating__detailName">
                                   <span>"Freshness at Its Best"</span>
                               </div>
                               <div className="riva-rating__detailStar flex items-center gap-x-2">
                                  <IoStar />
                                  <IoStar />
                                  <IoStar />
                                  <IoStar />
                                  <IoStar />
                               </div>
                           </div>
                      </div>
                      <div className="rivaa-rating__para">
                          <span>I love the variety of vegetables available here. It's a one-stop-shop for all my cooking needs. Plus, the prices are competitive, and the quality is outstanding. I couldn't ask for more.</span>
                      </div>
                    </div>

                    <div className="riva-rating__box">
                       <div className="riva-rating__imgDetails flex items-center">
                           <div className="riva-rating__img mr-5">
                               <img src="/images/image(1).webp" alt="img" />
                           </div>
                           <div className="riva-rating__detail">
                               <div className="riva-rating__detailTitle">
                                   <span>Kavya Ravichandran</span>
                               </div>
                               <div className="riva-rating__detailName">
                                   <span>"Freshness at Its Best"</span>
                               </div>
                               <div className="riva-rating__detailStar flex items-center gap-x-2">
                                  <IoStar />
                                  <IoStar />
                                  <IoStar />
                                  <IoStar />
                                  <IoStar />
                               </div>
                           </div>
                      </div>
                      <div className="rivaa-rating__para">
                          <span>I love the variety of vegetables available here. It's a one-stop-shop for all my cooking needs. Plus, the prices are competitive, and the quality is outstanding. I couldn't ask for more.</span>
                      </div>
                    </div>

                    <div className="riva-rating__box">
                       <div className="riva-rating__imgDetails flex items-center">
                           <div className="riva-rating__img mr-5">
                               <img src="/images/image(1).webp" alt="img" />
                           </div>
                           <div className="riva-rating__detail">
                               <div className="riva-rating__detailTitle">
                                   <span>Kavya Ravichandran</span>
                               </div>
                               <div className="riva-rating__detailName">
                                   <span>"Freshness at Its Best"</span>
                               </div>
                               <div className="riva-rating__detailStar flex items-center gap-x-2">
                                  <IoStar />
                                  <IoStar />
                                  <IoStar />
                                  <IoStar />
                                  <IoStar />
                               </div>
                           </div>
                      </div>
                      <div className="rivaa-rating__para">
                          <span>I love the variety of vegetables available here. It's a one-stop-shop for all my cooking needs. Plus, the prices are competitive, and the quality is outstanding. I couldn't ask for more.</span>
                      </div>
                    </div>
    
                </Slider>
            </div>


        </div>
    </div>
  )
}

export default Rivadetail