import "./App.css";
import Home from "./component/home/home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Sellerpage from "./component/seller/sellerpage";
import AddcardPage from "./component/addCard/addcardPage";
import ProductviewPage from "./component/productviewPage/productviewPage";
import Login from "./component/Login/Login";
import Register from "./component/Register/Register";
import MultiForm from "./component/checkout/checkout";
import Products from "./component/product/LandingPage";
import SuccessPage from "./component/checkout/successPage/successPage";
import EmptyCardPage from "./component/empty-cardPage/emptyCardPage";

import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { AxiosError, getuserid } from "./utilities/sessionexpiry";
import { AppKeys } from "./constants/AppKeys";
import { AxiosGet, AxiosPost } from "./utilities/axioscall";
import { setUser } from "./store/user/actions";
import {
  CacheParsingRead,
  CacheParsingWrite,
  handleGetInfoStorageItems,
  handleSetInfoStorageItems,
} from "./utilities/storageManager";
import { SK } from "./constants/StorageKeys";
import ListingPage from "./component/ListingPage/ListingPage";
import { PK } from "./constants/PepperKeys";
import Myaccount from "./component/myaccount-menu/myaccountTab/myaccount";
import Riva from "./component/businessProfile/riva/riva";
import Sellerlanding from "./component/SellerDashboard/SellerLanding";

function App() {
  const [pageinit, setPageInit] = useState(false);
  // const [isUserLoggedin, setUserLoggedin] = useState(false);
  // const [cartData, setCartData] = useState([]);
  // const [cartInfoData, setCartInfoData] = useState([]);
  // const [productsData, setProductsData] = useState([]);
  // const [categoriesData, setCategoriesData] = useState([]);
  const [count, setCount] = useState(1);
  let dispatch = useDispatch();

  const [pepper, setPepper] = useState({
    isUserLoggedin: false,
    productsinfoData: [],
    categoryinfoData: [],
    cachedata: {},
  });

  useEffect(() => {}, [
    count,
    pepper,
    pepper.cachedata,
    pepper.categoryinfoData,
    pepper.productsinfoData,
  ]);

  const peppermint = async (key, data = null) => {
    try {
      switch (key) {
        case PK.CARTINFODATA:
          break;
        case PK.ADDTOCART:
          break;
        case PK.REMOVECART:
          break;
        case PK.PRODUCTSINFODATA:
          var _pepper = pepper;
          _pepper.productsinfoData = await callProductsData();
          setPepper(_pepper);
          setCount({ ...count, count: count + 1 });
          return _pepper.productsinfoData;
          break;
        case PK.CATEGORYINFODATA:
          var _pepper = pepper;
          _pepper.categoryinfoData = await callCategoryData();
          setPepper(_pepper);
          setCount({ ...count, count: count + 1 });
          return _pepper.categoryinfoData;
          break;
        case PK.CACHEDATAREAD:
          var _pepper = pepper;
          _pepper.cachedata = pepper.isUserLoggedin
            ? await cachedataRead()
            : await localcachedataRead();
          setPepper(_pepper);
          setCount({ ...count, count: count + 1 });
          return _pepper.cachedata;
          break;
        case PK.CACHEDATAWRITE:
          //return await cachedataWrite("cartitems", data);
          break;
        case PK.CACHEDATAWRITECARTITEMS:
          var _pepper = pepper;
          _pepper.cachedata = pepper.isUserLoggedin
            ? await cachedataWrite("cartitems", data)
            : await localcachedataWrite("cartitems", data);

          setPepper(_pepper);
          setCount({ ...count, count: count + 1 });
          return _pepper.cachedata;
          break;
        case PK.CACHEDATAREMOVECARTITEMS:
          var _pepper = pepper;
          _pepper.cachedata = pepper.isUserLoggedin
            ? await cachedataWrite("removecartitems", data)
            : await localcachedataWrite("removecartitems", data);
          setPepper(_pepper);
          setCount({ ...count, count: count + 1 });
          return _pepper.cachedata;
          break;
        case PK.LOCALCACHEDATAREAD:
          break;
        case PK.LOCALCACHEDATAWRITE:
          break;
        case PK.LOCALCACHEDATACARTITEMSEMPTY:
          handleSetInfoStorageItems(SK.CARTINFODATA, null);
          break;
        case PK.checkProductInCartData:
          return checkProductInCartData(data);
          break;
        case PK.isUserLoggedin:
          var _pepper = pepper;
          _pepper.isUserLoggedin = data;
          setPepper(_pepper);
          setCount({ ...count, count: count + 1 });
          return _pepper.isUserLoggedin;
          break;

        case PK.LOCALSERVERCACHECARTITEMSMERGE:
          var _pepper = pepper;
          _pepper.cachedata = await cachedataMergeLocalServer(
            "mergeitems",
            null
          );
          setPepper(_pepper);
          setCount({ ...count, count: count + 1 });
          return _pepper.cachedata;
          break;
        default:
          break;
      }
    } catch (err) {}
  };

  const cachedataRead = async () => {
    //getuserid()
    return await AxiosPost(
      AppKeys.CACHEREADAPI + getuserid(),
      { data: null },
      true
    )
      .then(async (resp) => {
        if (resp.data?.datas != undefined && resp.data?.datas != null) {
          let _cachedatas = CacheParsingRead(await resp.data?.datas);
          return _cachedatas;
        }
        return {};
      })
      .catch(async (errresp) => {
        let error = AxiosError(errresp);
        if (error?.message === "Invalid access token") {
          dispatch(setUser(null));
          peppermint(PK.isUserLoggedin, false);
          setCount({ ...count, count: count + 1 });
          return null;
        }
      });
  };

  //cartproducts,
  const cachedataWrite = async (type = "cartitems", data) => {
    let _cachedata = await cachedataRead();
    if (_cachedata === null || _cachedata === undefined) {
      _cachedata = {};
    }
    //console.log(_cachedata);
    //console.log(data);
    if (type === "cartitems") {
      var dataid = data?.id.toString();
      //console.log("*******");
      //console.log("*******");
      //console.log(dataid);
      //console.log(_cachedata?.cartitems);

      var _cartitems = _cachedata?.cartitems;
      // _cachedata["cartitems"] = {};
      if (_cartitems !== null && _cartitems !== undefined) {
        Object.assign(_cartitems, { [dataid]: data });
        //console.log(_cartitems);
      } else {
        //console.log("true tjos os");
        _cartitems = {};
        Object.assign(_cartitems, { [dataid]: data });
        //console.log(_cartitems);
      }
      let itemslength = Object.keys(
        _cartitems === undefined ? {} : _cartitems
      ).length;
      let sptotal = 0;
      let mrptotal = 0;
      if (itemslength >= 0) {
        Object.keys(_cartitems === undefined ? {} : _cartitems).map((keyid) => {
          mrptotal =
            mrptotal + _cartitems[keyid].quantity * _cartitems[keyid].mrp;
          sptotal =
            sptotal + _cartitems[keyid].quantity * _cartitems[keyid].price;
        });
      }
      _cachedata["cartitems"] = _cartitems;
      _cachedata["mrptotal"] = mrptotal;
      _cachedata["sptotal"] = sptotal;
    } else if (type === "removecartitems") {
      var dataid = data?.id.toString();
      //console.log("*******");
      //console.log("*******");
      //console.log(dataid);
      //console.log(_cachedata?.cartitems);

      var _cartitems = _cachedata?.cartitems;
      // _cachedata["cartitems"] = {};
      if (_cartitems !== null && _cartitems !== undefined) {
        Object.assign(_cartitems, { [dataid]: undefined });
        //console.log(_cartitems);
      } else {
        //console.log("true tjos os");
        _cartitems = {};
        Object.assign(_cartitems, { [dataid]: undefined });
        //console.log(_cartitems);
      }
      //let cartlength =
      let itemslength = Object.keys(
        _cartitems === undefined ? {} : _cartitems
      ).length;
      let sptotal = 0;
      let mrptotal = 0;
      if (itemslength >= 0) {
        Object.keys(_cartitems === undefined ? {} : _cartitems).map((keyid) => {
          if (keyid !== dataid) {
            mrptotal =
              mrptotal + _cartitems[keyid].quantity * _cartitems[keyid].mrp;
            sptotal =
              sptotal + _cartitems[keyid].quantity * _cartitems[keyid].price;
          }
        });
      }
      _cachedata["cartitems"] = _cartitems;
      _cachedata["mrptotal"] = mrptotal;
      _cachedata["sptotal"] = sptotal;
      //  _cachedata["cartitems"] = _cartitems;
    }

    let _data = CacheParsingWrite(_cachedata);
    return await AxiosPost(
      AppKeys.CACHEWRITEAPI + getuserid(),
      { data: _data },
      true
    )
      .then(async (resp) => {
        if (resp.data?.datas != undefined && resp.data?.datas != null) {
          let _cachedatas = CacheParsingRead(resp.data?.datas);
          //console.log("output:");
          console.log(_cachedatas);
          return _cachedatas;
        }
        return {};
      })
      .catch(async (errresp) => {
        let error = AxiosError(errresp);
        if (error?.message === "Invalid access token") {
          dispatch(setUser(null));
          peppermint(PK.isUserLoggedin, false);
          peppermint(PK.isUserLoggedin, false);
          setCount({ ...count, count: count + 1 });
          return null;
        }
      });
  };

  const cachedataMergeLocalServer = async (type = "mergeitems", data) => {
    let _cachedata = await cachedataRead();
    if (_cachedata === null || _cachedata === undefined) {
      _cachedata = {};
    }
    let _cachedatalocal = await handleGetInfoStorageItems(SK.CARTINFODATA);
    if (_cachedatalocal === null || _cachedatalocal === undefined) {
      _cachedatalocal = {};
    }
    //console.log(_cachedata);
    //console.log(data);
    if (type === "mergeitems") {
      if (Object.keys(_cachedatalocal).length > 0) {
      }

      if (Object.keys(_cachedata).length === 0) {
        _cachedata = _cachedatalocal;
      }

      var _cartitems = _cachedata?.cartitems;
      // _cachedata["cartitems"] = {};
      if (_cartitems !== null && _cartitems !== undefined) {
      } else {
        _cartitems = {};
      }
      let itemslength = Object.keys(
        _cartitems === undefined ? {} : _cartitems
      ).length;
      let sptotal = 0;
      let mrptotal = 0;
      if (itemslength >= 0) {
        Object.keys(_cartitems === undefined ? {} : _cartitems).map((keyid) => {
          mrptotal =
            mrptotal + _cartitems[keyid].quantity * _cartitems[keyid].mrp;
          sptotal =
            sptotal + _cartitems[keyid].quantity * _cartitems[keyid].price;
        });
      }
      _cachedata["cartitems"] = _cartitems;
      _cachedata["mrptotal"] = mrptotal;
      _cachedata["sptotal"] = sptotal;
    }

    let _data = CacheParsingWrite(_cachedata);
    return await AxiosPost(
      AppKeys.CACHEWRITEAPI + getuserid(),
      { data: _data },
      true
    )
      .then(async (resp) => {
        if (resp.data?.datas != undefined && resp.data?.datas != null) {
          let _cachedatas = CacheParsingRead(resp.data?.datas);
          //console.log("output:");
          console.log(_cachedatas);
          return _cachedatas;
        }
        return {};
      })
      .catch(async (errresp) => {
        let error = AxiosError(errresp);
        if (error?.message === "Invalid access token") {
          dispatch(setUser(null));
          peppermint(PK.isUserLoggedin, false);
          peppermint(PK.isUserLoggedin, false);
          setCount({ ...count, count: count + 1 });
          return null;
        }
      });
  };

  const localcachedataRead = async () => {
    let _cartinfostroage = handleGetInfoStorageItems(SK.CARTINFODATA);
    if (_cartinfostroage === null || _cartinfostroage === undefined) {
      _cartinfostroage = {};
    }
    //   setCartData(_cartinfostroage);
    setCount({ ...count, count: count + 1 });
    console.log(_cartinfostroage);
    return _cartinfostroage;
  };

  const localcachedataWrite = async (type = "cartitems", data) => {
    let _cachedata = await localcachedataRead();
    if (_cachedata === null || _cachedata === undefined) {
      _cachedata = {};
    }
    console.log("localcachedataWrite");
    console.log("_cachedata");
    console.log(_cachedata);
    console.log(data);
    if (type === "cartitems") {
      var dataid = data?.id.toString();
      var _cartitems = _cachedata?.cartitems;

      if (_cartitems !== null && _cartitems !== undefined) {
        Object.assign(_cartitems, { [dataid]: data });
      } else {
        _cartitems = {};
        Object.assign(_cartitems, { [dataid]: data });
      }
      let itemslength = Object.keys(
        _cartitems === undefined ? {} : _cartitems
      ).length;
      let sptotal = 0;
      let mrptotal = 0;
      if (itemslength >= 0) {
        Object.keys(_cartitems === undefined ? {} : _cartitems).map((keyid) => {
          mrptotal =
            mrptotal + _cartitems[keyid].quantity * _cartitems[keyid].mrp;
          sptotal =
            sptotal + _cartitems[keyid].quantity * _cartitems[keyid].price;
        });
      }

      _cachedata["cartitems"] = _cartitems;
      _cachedata["mrptotal"] = mrptotal;
      _cachedata["sptotal"] = sptotal;
    } else if (type === "removecartitems") {
      var dataid = data?.id.toString();
      var _cartitems = _cachedata?.cartitems;
      if (_cartitems !== null && _cartitems !== undefined) {
        Object.assign(_cartitems, { [dataid]: undefined });
      } else {
        _cartitems = {};
        Object.assign(_cartitems, { [dataid]: undefined });
      }
      //let cartlength =
      let itemslength = Object.keys(
        _cartitems === undefined ? {} : _cartitems
      ).length;
      let sptotal = 0;
      let mrptotal = 0;
      if (itemslength >= 0) {
        Object.keys(_cartitems === undefined ? {} : _cartitems).map((keyid) => {
          if (keyid !== dataid) {
            mrptotal =
              mrptotal + _cartitems[keyid].quantity * _cartitems[keyid].mrp;
            sptotal =
              sptotal + _cartitems[keyid].quantity * _cartitems[keyid].price;
          }
        });
      }
      _cachedata["cartitems"] = _cartitems;
      _cachedata["mrptotal"] = mrptotal;
      _cachedata["sptotal"] = sptotal;
    }

    console.log("_cachedata");
    console.log(_cachedata);
    console.log(data);
    await handleSetInfoStorageItems(SK.CARTINFODATA, _cachedata);
    return type === "removeitems"
      ? handleGetInfoStorageItems(SK.CARTINFODATA)
      : _cachedata;
  };

  const checkProductInCartData = (prod) => {
    let prodid = prod?.id;
    let val = pepper?.cachedata?.cartitems?.[prodid];
    //console.log(pepper?.cachedata?.cartitems?.[prodid]);
    //console.log(val?.quantity);
    //console.log(
    //val?.quantity === null || val?.quantity === undefined ? 0 : val?.quantity
    // );
    return val?.quantity === null || val?.quantity === undefined
      ? 0
      : val?.quantity;
  };

  const callCacheCartDataRead = async () => {
    if (true) {
      return await AxiosPost(
        AppKeys.CACHEREADAPI + getuserid(),
        { data: null },
        true
      )
        .then((resp) => {
          if (resp.data?.datas != undefined && resp.data?.datas != null) {
            let _cartdatas = CacheParsingRead(resp.data?.datas);
            //setCartInfoData(resp.data?.datas);
            //setCartData(_cartdatas);
            setCount({ ...count, count: count + 1 });
            //console.log(_cartdatas);
            return _cartdatas;
          }

          return [];
        })
        .catch(async (errresp) => {
          let error = AxiosError(errresp);
          if (error?.message === "Invalid access token") {
            dispatch(setUser(null));
            peppermint(PK.isUserLoggedin, false);
            setCount({ ...count, count: count + 1 });
            return null;
          }
        });
    } else {
      let _cartinfostroage = handleGetInfoStorageItems(SK.CARTINFODATA);
      if (_cartinfostroage === null) {
        _cartinfostroage = [];
      }
      //   setCartData(_cartinfostroage);
      setCount({ ...count, count: count + 1 });
      //console.log(_cartinfostroage);
      return _cartinfostroage;
    }
  };

  const callCacheCartDataWrite = async (prod) => {
    let _cartinfostroage = await callCacheCartDataRead();
    if (_cartinfostroage === null || _cartinfostroage === undefined) {
      _cartinfostroage = [];
    }
    _cartinfostroage.push(prod);

    if (true) {
      let _data = CacheParsingWrite(_cartinfostroage);
      return await AxiosPost(
        AppKeys.CACHEWRITEAPI + getuserid(),
        { data: _data },
        true
      )
        .then((resp) => {
          if (resp.data?.datas != undefined && resp.data?.datas != null) {
            let _cartdatas = CacheParsingRead(resp.data?.datas);
            //setCartInfoData(resp.data?.datas);
            // setCartData(_cartdatas);
            setCount({ ...count, count: count + 1 });

            return _cartdatas;
          }
        })
        .catch((errresp) => {
          //console.log(errresp);
          return null;
        });
    } else {
      handleSetInfoStorageItems(SK.CARTINFODATA, _cartinfostroage);
      //console.log(_cartinfostroage);
      // setCartData(_cartinfostroage);
      setCount({ ...count, count: count + 1 });
      return _cartinfostroage;
    }
  };

  const callCartData = async () => {
    let _data = await callCacheCartDataRead();
    return _data === null || _data == undefined ? [] : _data;
    // if (isUserLoggedin) {
    //   return await AxiosPost(AppKeys.CARTAPI, {}, true)
    //     .then((resp) => {
    //       if (resp.data?.datas != undefined && resp.data?.datas != null) {
    //         setCartInfoData(resp.data?.datas);
    //         setCartData(resp.data?.datas?.cartdatas);
    //         setCount({ ...count, count: count + 1 });
    //         return resp.data?.datas;
    //       }
    //     })
    //     .catch(async (errresp) => {
    //       let error = AxiosError(errresp);
    //       if (error?.message === "Invalid access token") {
    //         dispatch(setUser(null));
    //         setUserLoggedin(false);
    //         setCount({ ...count, count: count + 1 });
    //         return null;
    //       }
    //     });
    // } else {
    //   let _cartinfostroage = handleGetInfoStorageItems(SK.CARTINFODATA);
    //   if (_cartinfostroage === null) {
    //     _cartinfostroage = [];
    //   }
    //   setCartData(_cartinfostroage);
    //   setCount({ ...count, count: count + 1 });
    //   return _cartinfostroage;
    // }
  };

  const callAddtoCart = async (
    product,
    prodid,
    quan = 1,
    increment = 0,
    decrement = 0
  ) => {
    if (increment === 0 && decrement === 0) {
      return await callCacheCartDataWrite(product);
    } else {
      // if (cartData.length > 0) {
      //   cartData.forEach((cart) => {
      //     if (cart.id === prodid) {
      //       if (increment === 1) {
      //         cart.quantity = cart.quantity + 1;
      //       } else {
      //         cart.quantity = cart.quantity - 1;
      //       }
      //     }
      //   });
      // }
      if (true) {
        // let _data = CacheParsingWrite(cartData);
        // return await AxiosPost(
        //   AppKeys.CACHEWRITEAPI + getuserid(),
        //   { data: _data },
        //   true
        // )
        //   .then((resp) => {
        //     if (resp.data?.datas != undefined && resp.data?.datas != null) {
        //       let _cartdatas = CacheParsingRead(resp.data?.datas);
        //       //setCartInfoData(resp.data?.datas);
        //       //setCartData(_cartdatas);
        //       setCount({ ...count, count: count + 1 });
        //       //console.log(_cartdatas);
        //       return _cartdatas;
        //     }
        //   })
        //   .catch((errresp) => {
        //     //console.log(errresp);
        //     return null;
        //   });
      } else {
        // handleSetInfoStorageItems(SK.CARTINFODATA, cartData);
        // setCartData(cartData);
        setCount({ ...count, count: count + 1 });
        return null;
      }
    }

    // if (increment === 0 && decrement === 0) {
    //   if (isUserLoggedin) {
    //     return await AxiosPost(
    //       AppKeys.ADDCARTAPI,
    //       { productid: prodid, quantity: quan },
    //       true
    //     )
    //       .then((resp) => {
    //         if (resp.data?.datas != undefined && resp.data?.datas != null) {
    //           //console.log(resp.data?.datas);
    //           setCartInfoData(resp.data?.datas);
    //           setCartData(resp.data?.datas?.cartdatas);
    //           setCount({ ...count, count: count + 1 });
    //           return resp.data?.datas;
    //         }
    //       })
    //       .catch((errresp) => {
    //         //console.log(errresp);
    //         return null;
    //       });
    //   } else {
    //     // "productid": 15,
    //     // "quantity": 1,
    //     // "price": "1139.00",
    //     // "total": "1139.00",
    //     // "created_at": "2023-12-12 12:03:30",
    //     // "product_name": "Wipro 30 W Standard B22 LED Bulb  (White, Pack of 2)",
    //     // "desktop_image": "vilva\/desktop\/vilva-img-m3.webp",
    //     // "mobile_image":
    //     let _product = productsData.filter((pro) => pro.id === prodid);
    //     let product_name = "";
    //     let desktop_image = "";
    //     let mobile_image = "";
    //     if (_product.length > 0) {
    //       product_name = _product[0].product_name;
    //       desktop_image = _product[0].desktop_image;
    //       mobile_image = _product[0].mobile_image;
    //     }
    //     let _cartinfostroage = handleGetInfoStorageItems(SK.CARTINFODATA);
    //     if (_cartinfostroage === null) {
    //       _cartinfostroage = [];
    //     }
    //     _cartinfostroage.push({
    //       productid: prodid,
    //       quantity: quan,
    //       product_name: product_name,
    //       desktop_image: desktop_image,
    //       mobile_image: mobile_image,
    //     });
    //     handleSetInfoStorageItems(SK.CARTINFODATA, _cartinfostroage);
    //     setCartData(_cartinfostroage);
    //     setCount({ ...count, count: count + 1 });
    //     return _cartinfostroage;
    //   }
    // } else {
    //   if (isUserLoggedin) {
    //     return await AxiosPost(
    //       AppKeys.UPDATECARTAPI,
    //       increment === 1
    //         ? { productid: prodid, quantity: quan + 1, increment: 1 }
    //         : { productid: prodid, quantity: quan - 1, increment: 1 },
    //       true
    //     )
    //       .then((resp) => {
    //         if (resp.data?.datas != undefined && resp.data?.datas != null) {
    //           setCartInfoData(resp.data?.datas);
    //           setCartData(resp.data?.datas?.cartdatas);
    //           setCount({ ...count, count: count + 1 });
    //           return resp.data?.datas;
    //         }
    //       })
    //       .catch((errresp) => {
    //         //console.log(errresp);
    //         return null;
    //       });
    //   } else {

    //     let _product = productsData.filter((pro) => pro.id === prodid);
    //     let product_name = "";
    //     let desktop_image = "";
    //     let mobile_image = "";
    //     if (_product.length > 0) {
    //       product_name = _product[0].product_name;
    //       desktop_image = _product[0].desktop_image;
    //       mobile_image = _product[0].mobile_image;
    //     }
    //     let _cartinfostroage = handleGetInfoStorageItems(SK.CARTINFODATA);
    //     if (_cartinfostroage === null) {
    //       _cartinfostroage = [];
    //     }
    //     _cartinfostroage.push({
    //       productid: prodid,
    //       quantity: quan,
    //       product_name: product_name,
    //       desktop_image: desktop_image,
    //       mobile_image: mobile_image,
    //     });
    //     handleSetInfoStorageItems(SK.CARTINFODATA, _cartinfostroage);
    //     setCartData(_cartinfostroage);
    //     setCount({ ...count, count: count + 1 });
    //     return _cartinfostroage;
    //   }
    // }
  };

  const callAddMutlipleItemstoCart = async (productcartdatas) => {
    return await AxiosPost(
      AppKeys.ADDMULTIPLEITEMSINCART,
      { cartdatas: productcartdatas },
      true
    )
      .then((resp) => {
        if (resp.data?.datas != undefined && resp.data?.datas != null) {
          //setCartInfoData(resp.data?.datas);
          //setCartData(resp.data?.datas?.cartdatas);
          setCount({ ...count, count: count + 1 });
          return resp.data?.datas;
        }
      })
      .catch((errresp) => {
        //console.log(errresp);
        return null;
      });
  };

  const callRemovefromCart = async (prod, prodid) => {
    // if (true) {
    //   if (cartData.length > 0) {
    //     let _cartData = cartData.filter((cart) => {
    //       return cart.id !== prodid;
    //     });
    //     let _data = CacheParsingWrite(_cartData);
    //     return await AxiosPost(
    //       AppKeys.CACHEWRITEAPI + getuserid(),
    //       { data: _data },
    //       true
    //     )
    //       .then((resp) => {
    //         if (resp.data?.datas != undefined && resp.data?.datas != null) {
    //           let _cartdatas = CacheParsingRead(resp.data?.datas);
    //           //setCartInfoData(resp.data?.datas);
    //           //setCartData(_cartdatas);
    //           setCount({ ...count, count: count + 1 });
    //           //console.log(_cartdatas);
    //           return _cartdatas;
    //         }
    //       })
    //       .catch((errresp) => {
    //         //console.log(errresp);
    //         return null;
    //       });
    //   }
    // } else {
    //   let _cartinfostroage = handleGetInfoStorageItems(SK.CARTINFODATA);
    //   if (_cartinfostroage === null) {
    //     _cartinfostroage = [];
    //   } else {
    //     _cartinfostroage = _cartinfostroage.filter((obj) => {
    //       return obj.id !== prodid;
    //     });
    //   }
    //   handleSetInfoStorageItems(SK.CARTINFODATA, _cartinfostroage);
    //   //setCartData(_cartinfostroage);
    //   setCount({ ...count, count: count + 1 });
    // }
  };

  const callProductsData = async () => {
    return await AxiosGet(AppKeys.PRODUCTSAPI)
      .then((resp) => {
        if (resp.data?.datas != undefined && resp.data?.datas != null) {
          let products = resp.data?.datas;

          return products;
        }
      })
      .catch((errresp) => {
        //console.log(errresp);
        return null;
      });
  };

  const callCategoryData = async () => {
    return await AxiosGet(AppKeys.CATEGORIESAPI)
      .then((resp) => {
        if (resp.data?.datas != undefined && resp.data?.datas != null) {
          let _cateogries = resp.data?.datas;
          return _cateogries;
        }
      })
      .catch((errresp) => {
        //console.log(errresp);
        return null;
      });
  };

  const callpageInit = async () => {
    await peppermint(PK.CATEGORYINFODATA);
    setCount({ ...count, count: count + 1 });

    await peppermint(PK.PRODUCTSINFODATA);
    setCount({ ...count, count: count + 1 });
    await peppermint(PK.CACHEDATAREAD);

    setCount({ ...count, count: count + 1 });
    // let products = ;

    // products.slice(0, 5).map(async (prod) => {
    //   //console.log(await peppermint(PK.CACHEDATAWRITECARTITEMS, prod));
    //   setCount({ ...count, count: count + 1 });
    // });

    // for (const prod of products) {
    //   //console.log(await peppermint(PK.CACHEDATAWRITECARTITEMS, prod));
    //   setCount({ ...count, count: count + 1 });
    // }
    // //console.log(await peppermint(PK.PRODUCTSINFODATA));
    // setCount({ ...count, count: count + 1 });
    // //console.log(await peppermint(PK.CATEGORYINFODATA));
    setCount({ ...count, count: count + 1 });
  };
  if (!pageinit) {
    setPageInit(true);
    callpageInit();
    setCount({ ...count, count: count + 1 });
    console.log(getuserid());
    if (getuserid()) {
      peppermint(PK.isUserLoggedin, true);
      // setUserLoggedin(true);
      // callCartData();
      //callCacheCartDataRead();
    } else {
      peppermint(PK.isUserLoggedin, false);
    }
    // callCategoryData();
    // callProductsData();
  }

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <Home
                count={count}
                setCount={setCount}
                pepper={pepper}
                setPepper={setPepper}
                peppermint={peppermint}
              />
            }
          />
          <Route
            path="/login"
            element={
              <Login
                count={count}
                setCount={setCount}
                pepper={pepper}
                setPepper={setPepper}
                peppermint={peppermint}
              />
            }
          />
          <Route
            path="/register"
            element={
              <Register
                count={count}
                setCount={setCount}
                pepper={pepper}
                setPepper={setPepper}
                peppermint={peppermint}
              />
            }
          />
          <Route
            path="/seller-registration"
            element={
              <Sellerpage
                count={count}
                setCount={setCount}
                pepper={pepper}
                setPepper={setPepper}
                peppermint={peppermint}
              />
            }
          />
          <Route
            path="/product/:productname"
            element={
              <ProductviewPage
                count={count}
                setCount={setCount}
                pepper={pepper}
                setPepper={setPepper}
                peppermint={peppermint}
              />
            }
          />
          <Route
            path="/cart"
            element={
              <AddcardPage
                count={count}
                setCount={setCount}
                pepper={pepper}
                setPepper={setPepper}
                peppermint={peppermint}
              />
            }
          />

          <Route
            path="/cart-noitems"
            element={
              <EmptyCardPage
                count={count}
                setCount={setCount}
                pepper={pepper}
                setPepper={setPepper}
                peppermint={peppermint}
              />
            }
          />

          <Route
            path="/marketplace"
            element={
              <Products
                count={count}
                setCount={setCount}
                pepper={pepper}
                setPepper={setPepper}
                peppermint={peppermint}
              />
            }
          />
          <Route
            path="/checkout"
            element={
              <MultiForm
                count={count}
                setCount={setCount}
                pepper={pepper}
                setPepper={setPepper}
                peppermint={peppermint}
              />
            }
          />

          <Route
            path="/category/:categoryname"
            element={
              <ListingPage
                count={count}
                setCount={setCount}
                pepper={pepper}
                setPepper={setPepper}
                peppermint={peppermint}
              />
            }
          />

          <Route
            path="/myaccount"
            element={
              <Myaccount
                count={count}
                setCount={setCount}
                pepper={pepper}
                setPepper={setPepper}
                peppermint={peppermint}
              />
            }
          />

          <Route
            path="/success"
            element={
              <SuccessPage
                count={count}
                setCount={setCount}
                pepper={pepper}
                setPepper={setPepper}
                peppermint={peppermint}
              />
            }
          />

          <Route 
            path="/riva" 
            element={ 
              <Riva                 
                count={count}
                setCount={setCount}
                pepper={pepper}
                setPepper={setPepper}
                peppermint={peppermint}
              />}/>


          <Route path="/seller" element = { <Sellerlanding /> } />

        </Routes>
      </BrowserRouter>
    </div>
  );
}
export default App;
