import React, { useEffect, useState } from "react";
import HeaderV from "../home/header/headerV2";
import ProductView from "./productView/productView";
import ProductFeatures from "./productFeatures/productFeatures";
import FrequentlyPage from "./frequently/frequentlyPage";
import ProductTab from "./productTab/productTab";
import Footer from "../home/footer/footer";
import Nav from "../home/navbar/nav";
import { AxiosGet, AxiosPost } from "../../utilities/axioscall";
import { AppKeys } from "../../constants/AppKeys";
import { getuserid } from "../../utilities/sessionexpiry";
import Header from "../home/header/header";
import { useLocation, useNavigate } from "react-router-dom";

const ProductviewPage = (props) => {
  const { count, setCount, pepper, setPepper, peppermint } = props;
  const [pageinit, setPageInit] = useState(false);
  const [productsinfo, setproductsinfo] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    console.log(`You clicked ${JSON.stringify(count)} times`);
  }, [count]);

  useEffect(() => {}, []);

  const checkProductInCartData = (prod) => {
    // let prodid = prod?.id;
    // if (cartData.length > 0) {
    //   let checkin = cartData.filter((cart) => {
    //     return cart.id === prodid;
    //   });
    //   console.log(checkin);
    //   if (checkin.length > 0) {
    //     return checkin[0].quantity;
    //   }
    // }
    return 0;
  };

  const callpageInit = async () => {};
  if (!pageinit) {
    window.scrollTo(0, 0);
    if (location.state != null && location?.state?.productinfo != null) {
      setCount((count) => (count = count + 1));
      setproductsinfo(location.state.productinfo);
      console.log(location.state.productinfo);
    }
    setPageInit(true);
    callpageInit();
    if (getuserid()) {
      //setUserLoggedin(true);
      // callCartData();
    }
    //callProductsData();
  }

  return (
    <div>
      {pepper?.isUserLoggedin ? (
        <>
          <HeaderV
            count={count}
            setCount={setCount}
            pepper={pepper}
            setPepper={setPepper}
            peppermint={peppermint}
          />
        </>
      ) : (
        <>
          <Header
            count={count}
            setCount={setCount}
            pepper={pepper}
            setPepper={setPepper}
            peppermint={peppermint}
          />
        </>
      )}
      <Nav
        count={count}
        setCount={setCount}
        pepper={pepper}
        setPepper={setPepper}
        peppermint={peppermint}
      />
      <ProductView
        count={count}
        setCount={setCount}
        pepper={pepper}
        setPepper={setPepper}
        peppermint={peppermint}
        productsinfo={productsinfo}
      />
      <ProductFeatures />
      <FrequentlyPage />
      <ProductTab />
      <Footer />
    </div>
  );
};

export default ProductviewPage;
