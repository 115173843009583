import React, { useState } from "react";
import { useDropzone } from "react-dropzone";

const ProductImg = ({ handleInputChange, fieldname, files, setFiles }) => {
  const [error, setError] = useState("");

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      const validatedFiles = acceptedFiles.filter((file) => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.src = URL.createObjectURL(file);

          img.onload = () => {
            if (img.width < 250 || img.height < 250) {
              setError("Image must be at least 500x500 pixels.");
            } else {
              setError("");
            }
          };

          img.onerror = () => {
            setError("Error loading the image.");
            reject();
          };
        });
      });

      setFiles(
        validatedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const dropArea =
    files.length > 0
      ? files.map((file) => (
          <img
            key={file.name}
            src={file.preview}
            alt="image"
            style={{
              width: "250px",
              height: "250px",
              objectFit: "contain",
            }}
          />
        ))
      : null;

  return (
    <div>
      <div className="dropArea" {...getRootProps()}>
        {dropArea}
        {files.length === 0 && (
          <div className="px-7 text-center grid place-content-center h-full">
            <div>
              <i className="fa-solid fa-upload"></i>
            </div>
            <p className="dropArea__title my-3">Click here to upload image</p>
            <span className="dropArea__para">
              Recommended image size <br /> 500 X 500
            </span>
            {error && <p className="dropArea__error">{error}</p>}
          </div>
        )}
        <input {...getInputProps()} />
      </div>

      <div className="product__input mt-9 mb-12">
        <input
          placeholder="Product Name"
          name={fieldname}
          onChange={handleInputChange}
        />
      </div>
    </div>
  );
};

export default ProductImg;
