import React, { useState, memo } from "react";
import ProductImg from "./productslist/productImg";
import "./product.css";
import { AppKeys } from "../../../constants/AppKeys";

const Product = memo(
  ({
    onNextTab,
    formData,
    setFormData,
    files1,
    setFiles1,
    files2,
    setFiles2,
    files3,
    setFiles3,
  }) => {
    // const [formData, setFormData] = useState({
    //   productname1: "",
    //   productname2: "",
    //   productname3: "",
    //   productfile1: [],
    //   productfile2: [],
    //   productfile3: [],
    // });

    const [errors, setErrors] = useState({
      productname1: "",
      productname2: "",
      productname3: "",
    });

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
      setErrors({ ...errors, [name]: "" });
    };

   

    const handleSubmit = (e) => {
      e.preventDefault();
      formData.productfile1 = files1;
      formData.productfile2 = files2;
      formData.productfile3 = files3;
      setFormData({});
      // Validate the form before moving to the next tab
      onNextTab(formData);
    };

    return (
      <div className="product">
        <div className="product__container pb-24">
          <div className="product__content lg:p-12 p-5 sm:p-0 sm:py-12 md:px-10">
            <div className="product__titlee text-center lg:text-left">
              <span>Product Details</span>
            </div>
            <div className="product__para mt-3 mb-10 text-center lg:text-left">
              <span>
                Add 3 product you wish to sell, you can add more later
              </span>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="flex flex-wrap justify-center gap-5 lg:gap-9">
                <ProductImg
                  handleInputChange={handleInputChange}
                  fieldname="productname1"
                  files={files1}
                  setFiles={setFiles1}
                />
                <ProductImg
                  handleInputChange={handleInputChange}
                  fieldname="productname2"
                  files={files2}
                  setFiles={setFiles2}
                />
                <ProductImg
                  handleInputChange={handleInputChange}
                  fieldname="productname3"
                  files={files3}
                  setFiles={setFiles3}
                />
              </div>
              <div className="grid place-content-center">
                <div className="product__btn">
                  <button>Next</button>
                </div>
              </div>
            </form>
          </div>
          <div className="product__img md:py-6 md:px-8">
            <div className="t-shirt__container flex">
              <div className="t-shirt__img">
                <img
                  src={`${AppKeys.IMGURL}website/product-images/t-shirt2.webp`}
                  alt="img"
                />
              </div>
              <div className="cross__icon ml-5">
                <i class="fa-solid fa-circle-xmark"></i>
              </div>
            </div>
            <div className="t-shirt__container flex 2xl:mt-5">
              <div className="t-shirt__img">
                <img
                  src={`${AppKeys.IMGURL}website/product-images/t-shirt1.webp`}
                  alt=""
                />
              </div>
              <div className="cross__icon ml-5">
                <i class="fa-solid fa-circle-check"></i>
              </div>
            </div>
            <div className="text-center mt-3">
              <span>
                Photo must be bright and clear with a preferably white
                background
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default Product;
