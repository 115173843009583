import React from 'react'
import "./frequentlyProduct.css"
import { IoVideocamOutline } from "react-icons/io5";
import { FaPhone } from "react-icons/fa6";
import { BsChatLeftText } from "react-icons/bs";
import { FaUser } from "react-icons/fa";



const Contact = () => {
  return (
    <div className=''>
        <div className='contact__container lg:w-2/6 xl:w-0 p-3 sm:p-6'>
            <div className='contact__title'>
                <span>Contact our Expert!</span>
            </div>
            <div className='contact__detail flex my-4 gap-x-2'>
                <div className='contact__user grid place-content-center'>
                   <FaUser />
                </div>
                <div className='contact__detail'>
                    <div className='contact__name'>
                        <span>Name and Surname</span>
                    </div>
                    <div className='contact__profession my-2'>
                        <span>Specialist - name of profession</span>
                    </div>
                    <div className='contact__product'>
                        <span>Company Product Group</span>
                    </div>
                </div>
            </div>
            <button className='schedule__meeting flex items-center justify-center'>
                <IoVideocamOutline className='video__icon mr-2'/>Schedule a meeting
            </button>
            <button className='contact__call flex items-center justify-center my-3'>
                <FaPhone className='mr-2'/>Call: +65 553 123 543
            </button>
            <button className='contact__chat flex items-center justify-center'>
                <BsChatLeftText className='mr-2'/>Chat
            </button>
        </div>
    </div>
  )
}

export default Contact