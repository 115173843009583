import React from "react";
import TabbedForm from "./sellerTabs/seller";
import Header from "../home/header/header";
import Footer from "../home/footer/footer";
import HeaderV from "../home/header/headerV2";

const Sellerpage = (props) => {
  const { count, setCount, pepper, setPepper, peppermint } = props;

  return (
    <div>
      {pepper?.isUserLoggedin ? (
        <>
          <HeaderV
            count={count}
            setCount={setCount}
            pepper={pepper}
            setPepper={setPepper}
            peppermint={peppermint}
          />
        </>
      ) : (
        <>
          <Header
            count={count}
            setCount={setCount}
            pepper={pepper}
            setPepper={setPepper}
            peppermint={peppermint}
          />
        </>
      )}
      <TabbedForm
        count={count}
        setCount={setCount}
        pepper={pepper}
        setPepper={setPepper}
        peppermint={peppermint}
      />
      <Footer />
    </div>
  );
};

export default Sellerpage;
