import React from 'react'
import "./riva.css"

// icons

import { MdArrowForward } from "react-icons/md";
import { FaRegStar } from "react-icons/fa6";
import { FaRegCheckCircle } from "react-icons/fa";
import { BsFillShareFill } from "react-icons/bs";
import { BsCurrencyRupee } from "react-icons/bs";

// Slider 

import Slider  from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const Rivaproduct = () => {

    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 2000,
        cssEase: "linear",
        responsive : [
           {
            breakpoint: 1024,
            settings:{
                slidesToShow:2,
                slidesToScroll: 1,
              }
           },
           {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            }
          },
        ]
    };


  return (
    <div className='rivaProduct'>
        <div className='container mx-auto'>
            <div className='riva-ourProduct flex justify-between items-center pt-20 px-5'>
                <div className='riva-ourProduct__title'>
                    <span>Our Products</span>
                </div>
                <div className='riva-ourProduct__list'>
                    <button className='flex items-center'>Show all products <MdArrowForward className='arrow-prod'/></button>
                </div>
            </div>
    
            {/***** Riva product ******/}
    
            <div className='riva-product__container hidden lg:grid lg:grid-cols-3  xl:grid-cols-4 2xl:grid-cols-5  gap-5 pt-12 py-2'>
                <div className='riva-product py-4'>
                    <div className='riva-product__fav flex items-center justify-between'>
                        <div className='riva-product__dis'>
                            <span>- 30%</span>
                        </div>
                        <div className='riva-product__star'>
                            <FaRegStar className='riva-star'/>
                        </div>
                    </div>
                    <div className='riva-product__image'>
                        <img src='/images/tomato.webp' alt='img' />
                    </div>
                    <div className='px-5'>
                        <div className='riva-product__detail flex items-center justify-between'>
                            <div className='riva-product__stock flex items-center'>
                               <FaRegCheckCircle className='mr-2'/>In stock
                            </div>
                            <div className='riva-product__share'>
                               <BsFillShareFill />
                            </div>
                        </div>
                        <div className='riva-product__num my-2'>
                            <span>Riva | Part No. 2123532</span>
                        </div>
                        <div className='riva-product__title'>
                            <span>Redish Fresh Fruit 500g</span>
                        </div>
                        <div className='riva-product__Yourprice'>
                            <span>Your price</span>
                        </div>
                        <div className='riva-product__priceDetail flex items-center justify-between mt-2'>
                            <div className='riva-product__gross flex items-center'>
                                <span className='riva-product__grossNet flex items-center'><BsCurrencyRupee /> 350.00</span> <span className='riva-product__net'>net</span>
                            </div>
                            <div className='flex items-center'>
                                <strike className='riva-product__strike flex items-center'><BsCurrencyRupee /> 450.00 <span className='strike-net'>net</span></strike>
                            </div>
                        </div>
                        <div className='riva-product__btn mt-5'>
                            <button>BUY NOW</button>
                        </div>
                    </div>
                </div>

                <div className='riva-product py-4'>
                    <div className='riva-product__fav flex items-center justify-between'>
                        <div className='riva-product__dis'>
                            <span>- 30%</span>
                        </div>
                        <div className='riva-product__star'>
                            <FaRegStar className='riva-star'/>
                        </div>
                    </div>
                    <div className='riva-product__image'>
                        <img src='/images/tomato.webp' alt='img' />
                    </div>
                    <div className='px-5'>
                        <div className='riva-product__detail flex items-center justify-between'>
                            <div className='riva-product__stock flex items-center'>
                               <FaRegCheckCircle className='mr-2'/>In stock
                            </div>
                            <div className='riva-product__share'>
                               <BsFillShareFill />
                            </div>
                        </div>
                        <div className='riva-product__num my-2'>
                            <span>Riva | Part No. 2123532</span>
                        </div>
                        <div className='riva-product__title'>
                            <span>Redish Fresh Fruit 500g</span>
                        </div>
                        <div className='riva-product__Yourprice'>
                            <span>Your price</span>
                        </div>
                        <div className='riva-product__priceDetail flex items-center justify-between mt-2'>
                            <div className='riva-product__gross flex items-center'>
                                <span className='riva-product__grossNet flex items-center'><BsCurrencyRupee /> 350.00</span> <span className='riva-product__net'>net</span>
                            </div>
                            <div className='flex items-center'>
                                <strike className='riva-product__strike flex items-center'><BsCurrencyRupee /> 450.00 <span className='strike-net'>net</span></strike>
                            </div>
                        </div>
                        <div className='riva-product__btn mt-5'>
                            <button>BUY NOW</button>
                        </div>
                    </div>
                </div>

                <div className='riva-product py-4'>
                    <div className='riva-product__fav flex items-center justify-between'>
                        <div className='riva-product__dis'>
                            <span>- 30%</span>
                        </div>
                        <div className='riva-product__star'>
                            <FaRegStar className='riva-star'/>
                        </div>
                    </div>
                    <div className='riva-product__image'>
                        <img src='/images/tomato.webp' alt='img' />
                    </div>
                    <div className='px-5'>
                        <div className='riva-product__detail flex items-center justify-between'>
                            <div className='riva-product__stock flex items-center'>
                               <FaRegCheckCircle className='mr-2'/>In stock
                            </div>
                            <div className='riva-product__share'>
                               <BsFillShareFill />
                            </div>
                        </div>
                        <div className='riva-product__num my-2'>
                            <span>Riva | Part No. 2123532</span>
                        </div>
                        <div className='riva-product__title'>
                            <span>Redish Fresh Fruit 500g</span>
                        </div>
                        <div className='riva-product__Yourprice'>
                            <span>Your price</span>
                        </div>
                        <div className='riva-product__priceDetail flex items-center justify-between mt-2'>
                            <div className='riva-product__gross flex items-center'>
                                <span className='riva-product__grossNet flex items-center'><BsCurrencyRupee /> 350.00</span> <span className='riva-product__net'>net</span>
                            </div>
                            <div className='flex items-center'>
                                <strike className='riva-product__strike flex items-center'><BsCurrencyRupee /> 450.00 <span className='strike-net'>net</span></strike>
                            </div>
                        </div>
                        <div className='riva-product__btn mt-5'>
                            <button>BUY NOW</button>
                        </div>
                    </div>
                </div>

                <div className='riva-product py-4'>
                    <div className='riva-product__fav flex items-center justify-between'>
                        <div className='riva-product__dis'>
                            <span>- 30%</span>
                        </div>
                        <div className='riva-product__star'>
                            <FaRegStar className='riva-star'/>
                        </div>
                    </div>
                    <div className='riva-product__image'>
                        <img src='/images/tomato.webp' alt='img' />
                    </div>
                    <div className='px-5'>
                        <div className='riva-product__detail flex items-center justify-between'>
                            <div className='riva-product__stock flex items-center'>
                               <FaRegCheckCircle className='mr-2'/>In stock
                            </div>
                            <div className='riva-product__share'>
                               <BsFillShareFill />
                            </div>
                        </div>
                        <div className='riva-product__num my-2'>
                            <span>Riva | Part No. 2123532</span>
                        </div>
                        <div className='riva-product__title'>
                            <span>Redish Fresh Fruit 500g</span>
                        </div>
                        <div className='riva-product__Yourprice'>
                            <span>Your price</span>
                        </div>
                        <div className='riva-product__priceDetail flex items-center justify-between mt-2'>
                            <div className='riva-product__gross flex items-center'>
                                <span className='riva-product__grossNet flex items-center'><BsCurrencyRupee /> 350.00</span> <span className='riva-product__net'>net</span>
                            </div>
                            <div className='flex items-center'>
                                <strike className='riva-product__strike flex items-center'><BsCurrencyRupee /> 450.00 <span className='strike-net'>net</span></strike>
                            </div>
                        </div>
                        <div className='riva-product__btn mt-5'>
                            <button>BUY NOW</button>
                        </div>
                    </div>
                </div>

                <div className='riva-product py-4'>
                    <div className='riva-product__fav flex items-center justify-between'>
                        <div className='riva-product__dis'>
                            <span>- 30%</span>
                        </div>
                        <div className='riva-product__star'>
                            <FaRegStar className='riva-star'/>
                        </div>
                    </div>
                    <div className='riva-product__image'>
                        <img src='/images/tomato.webp' alt='img' />
                    </div>
                    <div className='px-5'>
                        <div className='riva-product__detail flex items-center justify-between'>
                            <div className='riva-product__stock flex items-center'>
                               <FaRegCheckCircle className='mr-2'/>In stock
                            </div>
                            <div className='riva-product__share'>
                               <BsFillShareFill />
                            </div>
                        </div>
                        <div className='riva-product__num my-2'>
                            <span>Riva | Part No. 2123532</span>
                        </div>
                        <div className='riva-product__title'>
                            <span>Redish Fresh Fruit 500g</span>
                        </div>
                        <div className='riva-product__Yourprice'>
                            <span>Your price</span>
                        </div>
                        <div className='riva-product__priceDetail flex items-center justify-between mt-2'>
                            <div className='riva-product__gross flex items-center'>
                                <span className='riva-product__grossNet flex items-center'><BsCurrencyRupee /> 350.00</span> <span className='riva-product__net'>net</span>
                            </div>
                            <div className='flex items-center'>
                                <strike className='riva-product__strike flex items-center'><BsCurrencyRupee /> 450.00 <span className='strike-net'>net</span></strike>
                            </div>
                        </div>
                        <div className='riva-product__btn mt-5'>
                            <button>BUY NOW</button>
                        </div>
                    </div>
                </div>

                <div className='riva-product py-4'>
                    <div className='riva-product__fav flex items-center justify-between'>
                        <div className='riva-product__dis'>
                            <span>- 30%</span>
                        </div>
                        <div className='riva-product__star'>
                            <FaRegStar className='riva-star'/>
                        </div>
                    </div>
                    <div className='riva-product__image'>
                        <img src='/images/tomato.webp' alt='img' />
                    </div>
                    <div className='px-5'>
                        <div className='riva-product__detail flex items-center justify-between'>
                            <div className='riva-product__stock flex items-center'>
                               <FaRegCheckCircle className='mr-2'/>In stock
                            </div>
                            <div className='riva-product__share'>
                               <BsFillShareFill />
                            </div>
                        </div>
                        <div className='riva-product__num my-2'>
                            <span>Riva | Part No. 2123532</span>
                        </div>
                        <div className='riva-product__title'>
                            <span>Redish Fresh Fruit 500g</span>
                        </div>
                        <div className='riva-product__Yourprice'>
                            <span>Your price</span>
                        </div>
                        <div className='riva-product__priceDetail flex items-center justify-between mt-2'>
                            <div className='riva-product__gross flex items-center'>
                                <span className='riva-product__grossNet flex items-center'><BsCurrencyRupee /> 350.00</span> <span className='riva-product__net'>net</span>
                            </div>
                            <div className='flex items-center'>
                                <strike className='riva-product__strike flex items-center'><BsCurrencyRupee /> 450.00 <span className='strike-net'>net</span></strike>
                            </div>
                        </div>
                        <div className='riva-product__btn mt-5'>
                            <button>BUY NOW</button>
                        </div>
                    </div>
                </div>

                <div className='riva-product py-4'>
                    <div className='riva-product__fav flex items-center justify-between'>
                        <div className='riva-product__dis'>
                            <span>- 30%</span>
                        </div>
                        <div className='riva-product__star'>
                            <FaRegStar className='riva-star'/>
                        </div>
                    </div>
                    <div className='riva-product__image'>
                        <img src='/images/tomato.webp' alt='img' />
                    </div>
                    <div className='px-5'>
                        <div className='riva-product__detail flex items-center justify-between'>
                            <div className='riva-product__stock flex items-center'>
                               <FaRegCheckCircle className='mr-2'/>In stock
                            </div>
                            <div className='riva-product__share'>
                               <BsFillShareFill />
                            </div>
                        </div>
                        <div className='riva-product__num my-2'>
                            <span>Riva | Part No. 2123532</span>
                        </div>
                        <div className='riva-product__title'>
                            <span>Redish Fresh Fruit 500g</span>
                        </div>
                        <div className='riva-product__Yourprice'>
                            <span>Your price</span>
                        </div>
                        <div className='riva-product__priceDetail flex items-center justify-between mt-2'>
                            <div className='riva-product__gross flex items-center'>
                                <span className='riva-product__grossNet flex items-center'><BsCurrencyRupee /> 350.00</span> <span className='riva-product__net'>net</span>
                            </div>
                            <div className='flex items-center'>
                                <strike className='riva-product__strike flex items-center'><BsCurrencyRupee /> 450.00 <span className='strike-net'>net</span></strike>
                            </div>
                        </div>
                        <div className='riva-product__btn mt-5'>
                            <button>BUY NOW</button>
                        </div>
                    </div>
                </div>

                <div className='riva-product py-4'>
                    <div className='riva-product__fav flex items-center justify-between'>
                        <div className='riva-product__dis'>
                            <span>- 30%</span>
                        </div>
                        <div className='riva-product__star'>
                            <FaRegStar className='riva-star'/>
                        </div>
                    </div>
                    <div className='riva-product__image'>
                        <img src='/images/tomato.webp' alt='img' />
                    </div>
                    <div className='px-5'>
                        <div className='riva-product__detail flex items-center justify-between'>
                            <div className='riva-product__stock flex items-center'>
                               <FaRegCheckCircle className='mr-2'/>In stock
                            </div>
                            <div className='riva-product__share'>
                               <BsFillShareFill />
                            </div>
                        </div>
                        <div className='riva-product__num my-2'>
                            <span>Riva | Part No. 2123532</span>
                        </div>
                        <div className='riva-product__title'>
                            <span>Redish Fresh Fruit 500g</span>
                        </div>
                        <div className='riva-product__Yourprice'>
                            <span>Your price</span>
                        </div>
                        <div className='riva-product__priceDetail flex items-center justify-between mt-2'>
                            <div className='riva-product__gross flex items-center'>
                                <span className='riva-product__grossNet flex items-center'><BsCurrencyRupee /> 350.00</span> <span className='riva-product__net'>net</span>
                            </div>
                            <div className='flex items-center'>
                                <strike className='riva-product__strike flex items-center'><BsCurrencyRupee /> 450.00 <span className='strike-net'>net</span></strike>
                            </div>
                        </div>
                        <div className='riva-product__btn mt-5'>
                            <button>BUY NOW</button>
                        </div>
                    </div>
                </div>

                <div className='riva-product py-4'>
                    <div className='riva-product__fav flex items-center justify-between'>
                        <div className='riva-product__dis'>
                            <span>- 30%</span>
                        </div>
                        <div className='riva-product__star'>
                            <FaRegStar className='riva-star'/>
                        </div>
                    </div>
                    <div className='riva-product__image'>
                        <img src='/images/tomato.webp' alt='img' />
                    </div>
                    <div className='px-5'>
                        <div className='riva-product__detail flex items-center justify-between'>
                            <div className='riva-product__stock flex items-center'>
                               <FaRegCheckCircle className='mr-2'/>In stock
                            </div>
                            <div className='riva-product__share'>
                               <BsFillShareFill />
                            </div>
                        </div>
                        <div className='riva-product__num my-2'>
                            <span>Riva | Part No. 2123532</span>
                        </div>
                        <div className='riva-product__title'>
                            <span>Redish Fresh Fruit 500g</span>
                        </div>
                        <div className='riva-product__Yourprice'>
                            <span>Your price</span>
                        </div>
                        <div className='riva-product__priceDetail flex items-center justify-between mt-2'>
                            <div className='riva-product__gross flex items-center'>
                                <span className='riva-product__grossNet flex items-center'><BsCurrencyRupee /> 350.00</span> <span className='riva-product__net'>net</span>
                            </div>
                            <div className='flex items-center'>
                                <strike className='riva-product__strike flex items-center'><BsCurrencyRupee /> 450.00 <span className='strike-net'>net</span></strike>
                            </div>
                        </div>
                        <div className='riva-product__btn mt-5'>
                            <button>BUY NOW</button>
                        </div>
                    </div>
                </div>

                <div className='riva-product py-4'>
                    <div className='riva-product__fav flex items-center justify-between'>
                        <div className='riva-product__dis'>
                            <span>- 30%</span>
                        </div>
                        <div className='riva-product__star'>
                            <FaRegStar className='riva-star'/>
                        </div>
                    </div>
                    <div className='riva-product__image'>
                        <img src='/images/tomato.webp' alt='img' />
                    </div>
                    <div className='px-5'>
                        <div className='riva-product__detail flex items-center justify-between'>
                            <div className='riva-product__stock flex items-center'>
                               <FaRegCheckCircle className='mr-2'/>In stock
                            </div>
                            <div className='riva-product__share'>
                               <BsFillShareFill />
                            </div>
                        </div>
                        <div className='riva-product__num my-2'>
                            <span>Riva | Part No. 2123532</span>
                        </div>
                        <div className='riva-product__title'>
                            <span>Redish Fresh Fruit 500g</span>
                        </div>
                        <div className='riva-product__Yourprice'>
                            <span>Your price</span>
                        </div>
                        <div className='riva-product__priceDetail flex items-center justify-between mt-2'>
                            <div className='riva-product__gross flex items-center'>
                                <span className='riva-product__grossNet flex items-center'><BsCurrencyRupee /> 350.00</span> <span className='riva-product__net'>net</span>
                            </div>
                            <div className='flex items-center'>
                                <strike className='riva-product__strike flex items-center'><BsCurrencyRupee /> 450.00 <span className='strike-net'>net</span></strike>
                            </div>
                        </div>
                        <div className='riva-product__btn mt-5'>
                            <button>BUY NOW</button>
                        </div>
                    </div>
                </div>
            </div>


            {/****  Riva product Slider  *****/}
           
            <div className='block lg:hidden rivaProduct-slider py-5'>
              <Slider {...settings}>
                  <div className='riva-product py-4 mr-5'>
                        <div className='riva-product__fav flex items-center justify-between'>
                            <div className='riva-product__dis'>
                                <span>- 30%</span>
                            </div>
                            <div className='riva-product__star'>
                                <FaRegStar className='riva-star'/>
                            </div>
                        </div>
                        <div className='riva-product__image'>
                            <img src='/images/tomato.webp' alt='img' />
                        </div>
                        <div className='px-5'>
                            <div className='riva-product__detail flex items-center justify-between'>
                                <div className='riva-product__stock flex items-center'>
                                   <FaRegCheckCircle className='mr-2'/>In stock
                                </div>
                                <div className='riva-product__share'>
                                   <BsFillShareFill />
                                </div>
                            </div>
                            <div className='riva-product__num my-2'>
                                <span>Riva | Part No. 2123532</span>
                            </div>
                            <div className='riva-product__title'>
                                <span>Redish Fresh Fruit 500g</span>
                            </div>
                            <div className='riva-product__Yourprice'>
                                <span>Your price</span>
                            </div>
                            <div className='riva-product__priceDetail flex items-center justify-between mt-2'>
                                <div className='riva-product__gross flex items-center'>
                                    <span className='riva-product__grossNet flex items-center'><BsCurrencyRupee /> 350.00</span> <span className='riva-product__net'>net</span>
                                </div>
                                <div className='flex items-center'>
                                    <strike className='riva-product__strike flex items-center'><BsCurrencyRupee /> 450.00 <span className='strike-net'>net</span></strike>
                                </div>
                            </div>
                            <div className='riva-product__btn mt-5'>
                                <button>BUY NOW</button>
                            </div>
                        </div>
                  </div>    

                  <div className='riva-product py-4'>
                        <div className='riva-product__fav flex items-center justify-between'>
                            <div className='riva-product__dis'>
                                <span>- 30%</span>
                            </div>
                            <div className='riva-product__star'>
                                <FaRegStar className='riva-star'/>
                            </div>
                        </div>
                        <div className='riva-product__image'>
                            <img src='/images/tomato.webp' alt='img' />
                        </div>
                        <div className='px-5'>
                            <div className='riva-product__detail flex items-center justify-between'>
                                <div className='riva-product__stock flex items-center'>
                                   <FaRegCheckCircle className='mr-2'/>In stock
                                </div>
                                <div className='riva-product__share'>
                                   <BsFillShareFill />
                                </div>
                            </div>
                            <div className='riva-product__num my-2'>
                                <span>Riva | Part No. 2123532</span>
                            </div>
                            <div className='riva-product__title'>
                                <span>Redish Fresh Fruit 500g</span>
                            </div>
                            <div className='riva-product__Yourprice'>
                                <span>Your price</span>
                            </div>
                            <div className='riva-product__priceDetail flex items-center justify-between mt-2'>
                                <div className='riva-product__gross flex items-center'>
                                    <span className='riva-product__grossNet flex items-center'><BsCurrencyRupee /> 350.00</span> <span className='riva-product__net'>net</span>
                                </div>
                                <div className='flex items-center'>
                                    <strike className='riva-product__strike flex items-center'><BsCurrencyRupee /> 450.00 <span className='strike-net'>net</span></strike>
                                </div>
                            </div>
                            <div className='riva-product__btn mt-5'>
                                <button>BUY NOW</button>
                            </div>
                        </div>
                    </div>    

                   <div className='riva-product py-4'>
                        <div className='riva-product__fav flex items-center justify-between'>
                            <div className='riva-product__dis'>
                                <span>- 30%</span>
                            </div>
                            <div className='riva-product__star'>
                                <FaRegStar className='riva-star'/>
                            </div>
                        </div>
                        <div className='riva-product__image'>
                            <img src='/images/tomato.webp' alt='img' />
                        </div>
                        <div className='px-5'>
                            <div className='riva-product__detail flex items-center justify-between'>
                                <div className='riva-product__stock flex items-center'>
                                   <FaRegCheckCircle className='mr-2'/>In stock
                                </div>
                                <div className='riva-product__share'>
                                   <BsFillShareFill />
                                </div>
                            </div>
                            <div className='riva-product__num my-2'>
                                <span>Riva | Part No. 2123532</span>
                            </div>
                            <div className='riva-product__title'>
                                <span>Redish Fresh Fruit 500g</span>
                            </div>
                            <div className='riva-product__Yourprice'>
                                <span>Your price</span>
                            </div>
                            <div className='riva-product__priceDetail flex items-center justify-between mt-2'>
                                <div className='riva-product__gross flex items-center'>
                                    <span className='riva-product__grossNet flex items-center'><BsCurrencyRupee /> 350.00</span> <span className='riva-product__net'>net</span>
                                </div>
                                <div className='flex items-center'>
                                    <strike className='riva-product__strike flex items-center'><BsCurrencyRupee /> 450.00 <span className='strike-net'>net</span></strike>
                                </div>
                            </div>
                            <div className='riva-product__btn mt-5'>
                                <button>BUY NOW</button>
                            </div>
                        </div>
                   </div>    

                   <div className='riva-product py-4'>
                        <div className='riva-product__fav flex items-center justify-between'>
                            <div className='riva-product__dis'>
                                <span>- 30%</span>
                            </div>
                            <div className='riva-product__star'>
                                <FaRegStar className='riva-star'/>
                            </div>
                        </div>
                        <div className='riva-product__image'>
                            <img src='/images/tomato.webp' alt='img' />
                        </div>
                        <div className='px-5'>
                            <div className='riva-product__detail flex items-center justify-between'>
                                <div className='riva-product__stock flex items-center'>
                                   <FaRegCheckCircle className='mr-2'/>In stock
                                </div>
                                <div className='riva-product__share'>
                                   <BsFillShareFill />
                                </div>
                            </div>
                            <div className='riva-product__num my-2'>
                                <span>Riva | Part No. 2123532</span>
                            </div>
                            <div className='riva-product__title'>
                                <span>Redish Fresh Fruit 500g</span>
                            </div>
                            <div className='riva-product__Yourprice'>
                                <span>Your price</span>
                            </div>
                            <div className='riva-product__priceDetail flex items-center justify-between mt-2'>
                                <div className='riva-product__gross flex items-center'>
                                    <span className='riva-product__grossNet flex items-center'><BsCurrencyRupee /> 350.00</span> <span className='riva-product__net'>net</span>
                                </div>
                                <div className='flex items-center'>
                                    <strike className='riva-product__strike flex items-center'><BsCurrencyRupee /> 450.00 <span className='strike-net'>net</span></strike>
                                </div>
                            </div>
                            <div className='riva-product__btn mt-5'>
                                <button>BUY NOW</button>
                            </div>
                        </div>
                   </div>    

                   <div className='riva-product py-4'>
                        <div className='riva-product__fav flex items-center justify-between'>
                            <div className='riva-product__dis'>
                                <span>- 30%</span>
                            </div>
                            <div className='riva-product__star'>
                                <FaRegStar className='riva-star'/>
                            </div>
                        </div>
                        <div className='riva-product__image'>
                            <img src='/images/tomato.webp' alt='img' />
                        </div>
                        <div className='px-5'>
                            <div className='riva-product__detail flex items-center justify-between'>
                                <div className='riva-product__stock flex items-center'>
                                   <FaRegCheckCircle className='mr-2'/>In stock
                                </div>
                                <div className='riva-product__share'>
                                   <BsFillShareFill />
                                </div>
                            </div>
                            <div className='riva-product__num my-2'>
                                <span>Riva | Part No. 2123532</span>
                            </div>
                            <div className='riva-product__title'>
                                <span>Redish Fresh Fruit 500g</span>
                            </div>
                            <div className='riva-product__Yourprice'>
                                <span>Your price</span>
                            </div>
                            <div className='riva-product__priceDetail flex items-center justify-between mt-2'>
                                <div className='riva-product__gross flex items-center'>
                                    <span className='riva-product__grossNet flex items-center'><BsCurrencyRupee /> 350.00</span> <span className='riva-product__net'>net</span>
                                </div>
                                <div className='flex items-center'>
                                    <strike className='riva-product__strike flex items-center'><BsCurrencyRupee /> 450.00 <span className='strike-net'>net</span></strike>
                                </div>
                            </div>
                            <div className='riva-product__btn mt-5'>
                                <button>BUY NOW</button>
                            </div>
                        </div>
                    </div>    

                    <div className='riva-product py-4'>
                        <div className='riva-product__fav flex items-center justify-between'>
                            <div className='riva-product__dis'>
                                <span>- 30%</span>
                            </div>
                            <div className='riva-product__star'>
                                <FaRegStar className='riva-star'/>
                            </div>
                        </div>
                        <div className='riva-product__image'>
                            <img src='/images/tomato.webp' alt='img' />
                        </div>
                        <div className='px-5'>
                            <div className='riva-product__detail flex items-center justify-between'>
                                <div className='riva-product__stock flex items-center'>
                                   <FaRegCheckCircle className='mr-2'/>In stock
                                </div>
                                <div className='riva-product__share'>
                                   <BsFillShareFill />
                                </div>
                            </div>
                            <div className='riva-product__num my-2'>
                                <span>Riva | Part No. 2123532</span>
                            </div>
                            <div className='riva-product__title'>
                                <span>Redish Fresh Fruit 500g</span>
                            </div>
                            <div className='riva-product__Yourprice'>
                                <span>Your price</span>
                            </div>
                            <div className='riva-product__priceDetail flex items-center justify-between mt-2'>
                                <div className='riva-product__gross flex items-center'>
                                    <span className='riva-product__grossNet flex items-center'><BsCurrencyRupee /> 350.00</span> <span className='riva-product__net'>net</span>
                                </div>
                                <div className='flex items-center'>
                                    <strike className='riva-product__strike flex items-center'><BsCurrencyRupee /> 450.00 <span className='strike-net'>net</span></strike>
                                </div>
                            </div>
                            <div className='riva-product__btn mt-5'>
                                <button>BUY NOW</button>
                            </div>
                        </div>
                   </div>       


                   <div className='riva-product py-4'>
                        <div className='riva-product__fav flex items-center justify-between'>
                            <div className='riva-product__dis'>
                                <span>- 30%</span>
                            </div>
                            <div className='riva-product__star'>
                                <FaRegStar className='riva-star'/>
                            </div>
                        </div>
                        <div className='riva-product__image'>
                            <img src='/images/tomato.webp' alt='img' />
                        </div>
                        <div className='px-5'>
                            <div className='riva-product__detail flex items-center justify-between'>
                                <div className='riva-product__stock flex items-center'>
                                   <FaRegCheckCircle className='mr-2'/>In stock
                                </div>
                                <div className='riva-product__share'>
                                   <BsFillShareFill />
                                </div>
                            </div>
                            <div className='riva-product__num my-2'>
                                <span>Riva | Part No. 2123532</span>
                            </div>
                            <div className='riva-product__title'>
                                <span>Redish Fresh Fruit 500g</span>
                            </div>
                            <div className='riva-product__Yourprice'>
                                <span>Your price</span>
                            </div>
                            <div className='riva-product__priceDetail flex items-center justify-between mt-2'>
                                <div className='riva-product__gross flex items-center'>
                                    <span className='riva-product__grossNet flex items-center'><BsCurrencyRupee /> 350.00</span> <span className='riva-product__net'>net</span>
                                </div>
                                <div className='flex items-center'>
                                    <strike className='riva-product__strike flex items-center'><BsCurrencyRupee /> 450.00 <span className='strike-net'>net</span></strike>
                                </div>
                            </div>
                            <div className='riva-product__btn mt-5'>
                                <button>BUY NOW</button>
                            </div>
                        </div>
                   </div>    


                   <div className='riva-product py-4'>
                        <div className='riva-product__fav flex items-center justify-between'>
                            <div className='riva-product__dis'>
                                <span>- 30%</span>
                            </div>
                            <div className='riva-product__star'>
                                <FaRegStar className='riva-star'/>
                            </div>
                        </div>
                        <div className='riva-product__image'>
                            <img src='/images/tomato.webp' alt='img' />
                        </div>
                        <div className='px-5'>
                            <div className='riva-product__detail flex items-center justify-between'>
                                <div className='riva-product__stock flex items-center'>
                                   <FaRegCheckCircle className='mr-2'/>In stock
                                </div>
                                <div className='riva-product__share'>
                                   <BsFillShareFill />
                                </div>
                            </div>
                            <div className='riva-product__num my-2'>
                                <span>Riva | Part No. 2123532</span>
                            </div>
                            <div className='riva-product__title'>
                                <span>Redish Fresh Fruit 500g</span>
                            </div>
                            <div className='riva-product__Yourprice'>
                                <span>Your price</span>
                            </div>
                            <div className='riva-product__priceDetail flex items-center justify-between mt-2'>
                                <div className='riva-product__gross flex items-center'>
                                    <span className='riva-product__grossNet flex items-center'><BsCurrencyRupee /> 350.00</span> <span className='riva-product__net'>net</span>
                                </div>
                                <div className='flex items-center'>
                                    <strike className='riva-product__strike flex items-center'><BsCurrencyRupee /> 450.00 <span className='strike-net'>net</span></strike>
                                </div>
                            </div>
                            <div className='riva-product__btn mt-5'>
                                <button>BUY NOW</button>
                            </div>
                        </div>
                  </div>    

                   <div className='riva-product py-4'>
                        <div className='riva-product__fav flex items-center justify-between'>
                            <div className='riva-product__dis'>
                                <span>- 30%</span>
                            </div>
                            <div className='riva-product__star'>
                                <FaRegStar className='riva-star'/>
                            </div>
                        </div>
                        <div className='riva-product__image'>
                            <img src='/images/tomato.webp' alt='img' />
                        </div>
                        <div className='px-5'>
                            <div className='riva-product__detail flex items-center justify-between'>
                                <div className='riva-product__stock flex items-center'>
                                   <FaRegCheckCircle className='mr-2'/>In stock
                                </div>
                                <div className='riva-product__share'>
                                   <BsFillShareFill />
                                </div>
                            </div>
                            <div className='riva-product__num my-2'>
                                <span>Riva | Part No. 2123532</span>
                            </div>
                            <div className='riva-product__title'>
                                <span>Redish Fresh Fruit 500g</span>
                            </div>
                            <div className='riva-product__Yourprice'>
                                <span>Your price</span>
                            </div>
                            <div className='riva-product__priceDetail flex items-center justify-between mt-2'>
                                <div className='riva-product__gross flex items-center'>
                                    <span className='riva-product__grossNet flex items-center'><BsCurrencyRupee /> 350.00</span> <span className='riva-product__net'>net</span>
                                </div>
                                <div className='flex items-center'>
                                    <strike className='riva-product__strike flex items-center'><BsCurrencyRupee /> 450.00 <span className='strike-net'>net</span></strike>
                                </div>
                            </div>
                            <div className='riva-product__btn mt-5'>
                                <button>BUY NOW</button>
                            </div>
                        </div>
                  </div>                  
                </Slider>
            </div>
        </div>
    </div>
  )
}

export default Rivaproduct