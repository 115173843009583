import React, { Component } from "react";
import Slider from "react-slick";
import "./slider.css";
import { FaUser } from "react-icons/fa";

export default class MultipleItem extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 2,
      slidesToScroll: 1,
      cssEase: "linear",
      responsive: [
        {
          breakpoint: 2024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 1280,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            dots: true,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div className="my-20 px-5 md:px-0">
        <Slider {...settings}>
          <div className="splide__slide">
            <div className="slide">
              <div className="slide__image flex justify-center items-center">
                <div className="user__img grid place-content-center">
                  <FaUser className="user__silder" />
                </div>
              </div>
              <div className="slider__content">
                <div className="slider__date">
                  <span>Nov 8, 2023</span>
                </div>
                <div className="slider__title">
                  <span>Sarika Enterprises</span>
                </div>
                <div className="slider__para">
                  <span>
                    Partnering with HaodaMart transformed our procurement
                    process! Seamless transactions, a vast product range, and
                    exceptional customer service. Our go-to for all business
                    needs.
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="splide__slide">
            <div className="slide">
              <div className="slide__image flex justify-center items-center">
                <div className="user__img grid place-content-center">
                  <FaUser className="user__silder" />
                </div>
              </div>
              <div className="slider__content">
                <div className="slider__date">
                  <span>Nov 12, 2023</span>
                </div>
                <div className="slider__title">
                  <span>TechVision Solutions</span>
                </div>
                <div className="slider__para">
                  <span>
                    Incredible experience with HaodaMart! The user-friendly
                    interface and efficient order management have significantly
                    boosted our operational efficiency. Highly recommend for any
                    B2B business.
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="splide__slide">
            <div className="slide">
              <div className="slide__image flex justify-center items-center">
                <div className="user__img grid place-content-center">
                  <FaUser className="user__silder" />
                </div>
              </div>
              <div className="slider__content">
                <div className="slider__date">
                  <span>Nov 16, 2023</span>
                </div>
                <div className="slider__title">
                  <span>Global Innovators Corp</span>
                </div>
                <div className="slider__para">
                  <span>
                    HaodaMart is reliable and trustworthy! This platform has not
                    only connected us with quality suppliers but has also
                    simplified complex transactions. A game-changer in the B2B
                    eCommerce landscape.
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="splide__slide">
            <div className="slide">
              <div className="slide__image flex justify-center items-center">
                <div className="user__img grid place-content-center">
                  <FaUser className="user__silder" />
                </div>
              </div>
              <div className="slider__content">
                <div className="slider__date">
                  <span>Nov 22, 2023</span>
                </div>
                <div className="slider__title">
                  <span>Alpha Electronics Ltd</span>
                </div>
                <div className="slider__para">
                  <span>
                    As a tech company, precision matters. HaodaMart exceeded our
                    expectations in delivering quality products on time. The
                    streamlined process saved us valuable resources.
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="splide__slide">
            <div className="slide">
              <div className="slide__image flex justify-center items-center">
                <div className="user__img grid place-content-center">
                  <FaUser className="user__silder" />
                </div>
              </div>
              <div className="slider__content">
                <div className="slider__date">
                  <span>Nov 30, 2023</span>
                </div>
                <div className="slider__title">
                  <span>Aruna Textiles</span>
                </div>
                <div className="slider__para">
                  <span>
                    HaodaMart revolutionized our sourcing process! The diverse
                    range of suppliers and the transparent communication system
                    have made our B2B transactions efficient and hassle-free.
                    10/10
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    );
  }
}
