import React from 'react'
import "./frequentlyProduct.css"
import FrequentlyProduct from './frequentlyProduct'
import Contact from './contact'

const FrequentlyPage = () => {
  return (
    <div className='container mx-auto my-20'>
      {/* <div className='grid place-content-center mb-10'>
        <span className='frequentlyPage__heading text-center'>Frequently bought together</span>
      </div> */}

      <div className='container mx-auto flex justify-center items-center gap-10 flex-wrap px-5 md:px-0'>
        <FrequentlyProduct />
        <Contact />
      </div>
    </div>
  )
}

export default FrequentlyPage