import React, { useState } from 'react';
import ActiveOrder from './ActiveOrder';
import Returns from './Returns';
import Completed from './Completed';
import Cancellations from './Cancellations';

const Orders = () => {
  const [activeTab, setActiveTab] = useState('completed');

  const handleTabClick = (tab) => {
    console.log('Clicked tab:', tab);
    setActiveTab(tab);
  };

  return (
    <div>
      <div className='bg-gray-100 pb-10'>
        <div className='flex flex-wrap justify-center md:justify-start mx-56 pt-10'>
          <div className={` ${activeTab === 'completed' ? 'active' : 'white'}`}>
            <div
              className={`active rounded-tl-lg p-3 text-center ${activeTab === 'completed' ? 'bg-green-700 text-white' : 'bg-blue-100 text-black'} cursor-pointer w-full md:w-auto`}
              onClick={() => handleTabClick('completed')}
            >
              Completed
            </div>
          </div>

          <div className="">
            <div
              className={`active hold p-3 text-center ${activeTab === 'active' ? 'bg-green-700 text-white' : 'bg-blue-100 text-black'} cursor-pointer w-full md:w-auto`}
              onClick={() => handleTabClick('active')}
            >
              Active Order
            </div>
          </div>

          <div className="">
            <div
              className={`active hold p-3 text-center ${activeTab === 'returns' ? 'bg-green-700 text-white' : 'bg-blue-100 text-black'} cursor-pointer w-full md:w-auto`}
              onClick={() => handleTabClick('returns')}
            >
              Returns
            </div>
          </div>

          <div className="">
            <div
              className={`active hold p-3 text-center ${activeTab === 'cancellations' ? 'bg-green-700 text-white' : 'bg-blue-100 text-black'} cursor-pointer w-full md:w-auto`}
              onClick={() => handleTabClick('cancellations')}
            >
              Cancellations
            </div>
          </div>
        </div>

        <div>
          {activeTab === 'completed' && <Completed />}
          {activeTab === 'active' && <ActiveOrder />}
          {activeTab === 'returns' && <Returns />}
          {activeTab === 'cancellations' && <Cancellations />}
        </div>
      </div>
    </div>
  );
};

export default Orders;
