import React, { useState } from "react";
import "./productView.css";
import { IoMdStar } from "react-icons/io";
import { BiCheckCircle } from "react-icons/bi";
import { FiShare2 } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import { PK } from "../../../constants/PepperKeys";
import Loader from "../../loader/loader";

const ProductDetail = ({
  count,
  setCount,
  pepper,
  setPepper,
  peppermint,
  productsinfo,
}) => {
  let navigate = useNavigate();

  const [pageinit, setPageInit] = useState(false);

  const [viewCartLoader, setViewCartLoader] = useState(false);
  const [addToCartLoader, setAddToCartLoader] = useState(false);
  const [buyNowLoader, setBuyNowLoader] = useState(false);
  
  const callpageInit = async () => {};
  if (!pageinit) {    
    window.scrollTo(0, 0);

    let quantity = peppermint(PK.checkProductInCartData, productsinfo);
    if (quantity >= 1) {
      productsinfo["quantity"] = quantity;
      productsinfo["buynow"] = true;
    }
    setPageInit(true);
    callpageInit();
  }

  return (
    <div className="w-full lg:w-1/2 px-5 mt-4 sm:mt-10 lg:mt-0 flex justify-center items-center">
      <div className="productDetail__container w-full sm:w-8/12 lg:w-full">
        <div className="productDetail__title">
          <span>{productsinfo.product_name}</span>
        </div>
        <div className="productDetail__partNo">
          <span>Omnires | Part No. 2123532</span>
        </div>
        <div className="productDetail__star flex items-center gap-1 my-3">
          <IoMdStar className="rating__star" />
          <IoMdStar className="rating__star" />
          <IoMdStar className="rating__star" />
          <IoMdStar className="rating__star" />
          <IoMdStar className="rating__star" />
          <span className="text-sm	font-semibold">
            5.0 <span className="text-sm font-normal">(24)</span>
          </span>
        </div>
        <div className="productDetail__stock flex items-center gap-x-1">
          <BiCheckCircle />
          <span>In stock </span>
        </div>
        <div className="productDetail__priceContent flex items-center mt-3 gap-x-6">
          <div className="productDetail__price">
            <span>Price ₹{productsinfo.price} net </span>
          </div>
          <div className="productDetail__strick">
            <strike>₹{productsinfo.mrp}net </strike>
          </div>
        </div>

        <div className="productDetail__buttons flex items-center gap-x-5 my-5">
          {productsinfo?.buynow ? (
            <>
              <button
                className="add__btn"
                type="button"
                onClick={async () => {

                  setViewCartLoader(true);

                  navigate("/cart");

                  setViewCartLoader(false);

                }}
              >
                {viewCartLoader ? <Loader /> : "VIEW CART"}
              </button>
            </>
          ) : (
            <>
              <button
                className="add__btn"
                type="button"
                onClick={async () => {

                  setAddToCartLoader(true);

                  productsinfo["buynow"] = true;
                  productsinfo["quantity"] = 1;

                  await peppermint(PK.CACHEDATAWRITECARTITEMS, productsinfo);
                  //await callAddtoCart(prod, prod.id);
                  //await callCacheCartDataWrite(prod);
                  //await callCartData();
                  setCount({ ...count, count: count + 1 });
                  // await callAddtoCart(productsinfo, productsinfo.id);
                  //await callCartData();
                  //setCount({ ...count, count: count + 1 });
                  //console.log(prod);
                  setAddToCartLoader(false);
                }}
              >
                { addToCartLoader ? <Loader /> : "ADD TO CART"}
              </button>
            </>
          )}

          <button
            className="buy__btn"
            onClick={async () => {

              setBuyNowLoader(true);

              if (productsinfo?.buynow) {
                navigate("/cart");
              }
              productsinfo["buynow"] = true;
              productsinfo["quantity"] = 1;

              await peppermint(PK.CACHEDATAWRITECARTITEMS, productsinfo);
              //await callAddtoCart(prod, prod.id);
              //await callCacheCartDataWrite(prod);
              //await callCartData();
              setCount({ ...count, count: count + 1 });
              setCount({ ...count, count: count + 1 });
              navigate("/cart");
              //console.log(prod);

             setBuyNowLoader(false);

            }}
          >
            { buyNowLoader ? <Loader /> : "Buy now"}
          </button>
        
          <button className="share__btn flex items-center justify-center">
            <FiShare2 />
          </button>
        </div>
        <div className="productDetail__increBtn flex items-center gap-x-4">
          <button
            className="minus__btn"
            onClick={async () => {
              let item = productsinfo;
              let quantity = productsinfo?.quantity;
              item["quantity"] = quantity - 1;
              if (item["quantity"] <= 0) item["quantity"] = 1;
              await peppermint(PK.CACHEDATAWRITECARTITEMS, item);
              setCount({ ...count, count: count + 1 });
            }}
          >
            -
          </button>

          <button className="count__no">
            {productsinfo?.quantity ? productsinfo?.quantity : 1}
          </button>
          <button
            className="plus__btn"
            onClick={async () => {
              let item = productsinfo;
              let quantity = productsinfo.quantity;
              item["quantity"] = quantity + 1;
              await peppermint(PK.CACHEDATAWRITECARTITEMS, item);
              setCount({ ...count, count: count + 1 });
            }}
          >
            +
          </button>
        </div>
        <div className="productDetail__description">
          <div className="description__title mt-7 mb-4">
            <span>Description</span>
          </div>
          <div className="description__para">
            <span>{productsinfo.description}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
