import React, { memo } from "react";
import "./Manufacture.css";
import { AppKeys } from "../../constants/AppKeys";

const Manufacture = memo(() => {
  return (
    <div className="my-10 lg:my-20">
      <div className="w-full rounded overflow-hidden shadow-lg container mx-auto mt-5 p-5">
        <div className="sm:flex sm:justify-between items-center gap-2">
          <div className="standard gap-1 md:gap-2 flex items-center">
            <img src="/images/vilva.webp" alt="Watch" />
            <div className="-mt-2">
              <div className="company">Vilva Tamizh T-Shirt </div>
              <div className="verified flex gap-1 md:gap-2 items-center">
                <img src="/images/v.webp" alt="Watch" />
                <div className="gst mt-3">| GSTN-24576564584568648</div>
              </div>
            </div>
          </div>
          <div className="rounded-full hidden sm:block contact">
            <button className="contacttext">ViewStore</button>
          </div>
        </div>
        {/* RATING AND REVIEWS  */}
        <div className="flex flex-wrap xl:flex-nowrap justify-between mt-10">
          <div className="flex flex-wrap sm:justify-around xl:justify-normal w-full">
            <div className="list block xl:hidden">
              <img src="/images/riva.webp" alt="Watch" />
            </div>
            <div className="mt-3 sm:mt-0">
              <div className="rating">Rating and reviews</div>
              <div className="flex gap-1">
                <div className="review">4.8</div>
                <div className="count">/5</div>
                <div className="count">
                  (100+ <span>reviews</span>)
                </div>
              </div>
              <div className="factory mt-5">Factory capabilities</div>
              <ul className="mt-3 ullist">
                <li>. Competitive OEM factory</li>
                <li className="mt-2">. Customer cases (1)</li>
                <li className="mt-2">. Full customization</li>
                <li className="mt-2">. Finished product inspection</li>
              </ul>
            </div>
          </div>
          <div className="flex justify-center w-full gap-1 md:gap-5 my-5 mb-10 xl:my-0 ">
            <div className="shirts">
              <img
                src={`${AppKeys.IMGURL}product/vilva/desktop/vilva-img-m2.webp`}
                alt="Watch"
              />
              <div className="rate mt-1 sm:mt-3">₹280 - ₹480</div>
            </div>

            <div className="shirts">
              <img
                src={`${AppKeys.IMGURL}product/vilva/desktop/vilva-img-m3.webp`}
                alt="Watch"
              />
              <div className="rate mt-1 sm:mt-3">₹280 - ₹480</div>
            </div>
            <div className="shirts">
              <img
                src={`${AppKeys.IMGURL}product/vilva/desktop/vilva-img-m4.webp`}
                alt="Watch"
              />
              <div className="rate mt-1 sm:mt-3">₹280 - ₹480</div>
            </div>
            <div className="list hidden xl:block">
              <img
                src={`${AppKeys.IMGURL}product/vilva/desktop/vilva-img-m1.webp`}
                alt="Watch"
              />
            </div>
          </div>
        </div>
        <div className="rounded-full block sm:hidden contact2 text-center">
          <button className="contacttext2">ViewStore</button>
        </div>
      </div>
      <div className="w-full rounded overflow-hidden shadow-lg container mx-auto mt-5 p-5">
        <div className="sm:flex sm:justify-between items-center gap-2">
          <div className="standard gap-1 md:gap-2 flex items-center">
            <img src="/images/global.webp" alt="Watch" />
            <div className="-mt-2">
              <div className="company">Global Riva Enterprises</div>
              <div className="verified flex gap-1 md:gap-2 items-center">
                <img src="/images/v.webp" alt="Watch" />
                <div className="gst mt-3">| GSTN-24576564584568648</div>
              </div>
            </div>
          </div>
          <div className="rounded-full hidden sm:block contact">
            <button className="contacttext">ViewStore</button>
          </div>
        </div>
        {/* RATING AND REVIEWS  */}
        <div className="flex flex-wrap xl:flex-nowrap justify-between mt-10">
          <div className="flex flex-wrap sm:justify-around xl:justify-normal w-full">
            <div className="list block xl:hidden">
              <img
                src={`${AppKeys.IMGURL}website/product-images/produ-img11.webp`}
                alt="Watch"
              />
            </div>
            <div className="mt-3 sm:mt-0">
              <div className="rating">Rating and reviews</div>
              <div className="flex gap-1">
                <div className="review">4.8</div>
                <div className="count">/5</div>
                <div className="count">
                  (100+ <span>reviews</span>)
                </div>
              </div>
              <div className="factory mt-5">Factory capabilities</div>
              <ul className="mt-3 ullist">
                <li>. Competitive OEM factory</li>
                <li className="mt-2">. Customer cases (1)</li>
                <li className="mt-2">. Full customization</li>
                <li className="mt-2">. Finished product inspection</li>
              </ul>
            </div>
          </div>
          <div className="flex justify-center w-full gap-1 md:gap-5 my-5 mb-10 xl:my-0 ">
            <div className="shirts">
              <img
                src={`${AppKeys.IMGURL}product/global-riva/desktop/global-riva-img-d2.webp`}
                alt="Watch"
              />
              <div className="rate mt-1 sm:mt-3">₹280 - ₹480</div>
            </div>

            <div className="shirts">
              <img
                src={`${AppKeys.IMGURL}product/global-riva/desktop/global-riva-img-d3.webp`}
                alt="Watch"
              />
              <div className="rate mt-1 sm:mt-3">₹280 - ₹480</div>
            </div>
            <div className="shirts">
              <img
                src={`${AppKeys.IMGURL}product/global-riva/desktop/global-riva-img-d4.webp`}
                alt="Watch"
              />
              <div className="rate mt-1 sm:mt-3">₹280 - ₹480</div>
            </div>
            <div className="list hidden xl:block">
              <img
                src={`${AppKeys.IMGURL}product/global-riva/desktop/global-riva-img-d1.webp`}
                alt="Watch"
              />
            </div>
          </div>
        </div>
        <div className="rounded-full block sm:hidden contact2 text-center">
          <button className="contacttext2">ViewStore</button>
        </div>
      </div>
      <div className="w-full rounded overflow-hidden shadow-lg container mx-auto mt-5 p-5">
        <div className="sm:flex sm:justify-between items-center gap-2">
          <div className="standard gap-1 md:gap-2 flex items-center">
            <img src="/images/riva.webp" alt="Watch" />
            <div className="-mt-2">
              <div className="company">Riva Trading</div>
              <div className="verified flex gap-1 md:gap-2 items-center">
                <img src="/images/v.webp" alt="Watch" />
                <div className="gst mt-3">| GSTN-24576564584568648</div>
              </div>
            </div>
          </div>
          <div className="rounded-full hidden sm:block contact">
            <button className="contacttext">ViewStore</button>
          </div>
        </div>
        {/* RATING AND REVIEWS  */}
        <div className="flex flex-wrap xl:flex-nowrap justify-between mt-10">
          <div className="flex flex-wrap sm:justify-around xl:justify-normal w-full">
            <div className="list block xl:hidden">
              <img
                src={`${AppKeys.IMGURL}website/product-images/produ-img11.webp`}
                alt="Watch"
              />
            </div>
            <div className="mt-3 sm:mt-0">
              <div className="rating">Rating and reviews</div>
              <div className="flex gap-1">
                <div className="review">4.8</div>
                <div className="count">/5</div>
                <div className="count">
                  (100+ <span>reviews</span>)
                </div>
              </div>
              <div className="factory mt-5">Factory capabilities</div>
              <ul className="mt-3 ullist">
                <li>. Competitive OEM factory</li>
                <li className="mt-2">. Customer cases (1)</li>
                <li className="mt-2">. Full customization</li>
                <li className="mt-2">. Finished product inspection</li>
              </ul>
            </div>
          </div>
          <div className="flex justify-center w-full gap-1 md:gap-5 my-5 mb-10 xl:my-0 ">
            <div className="shirts">
              <img
                src={`${AppKeys.IMGURL}website/daily-essentials-images/daily-ess-img5.webp`}
                alt="Watch"
              />
              <div className="rate mt-1 sm:mt-3">₹280 - ₹480</div>
            </div>

            <div className="shirts">
              <img
                src={`${AppKeys.IMGURL}website/daily-essentials-images/daily-ess-img1.webp`}
                alt="Watch"
              />
              <div className="rate mt-1 sm:mt-3">₹280 - ₹480</div>
            </div>
            <div className="shirts">
              <img
                src={`${AppKeys.IMGURL}website/daily-essentials-images/daily-ess-img8.webp`}
                alt="Watch"
              />
              <div className="rate mt-1 sm:mt-3">₹280 - ₹480</div>
            </div>

            <div className="list hidden xl:block">
              <img
                src={`${AppKeys.IMGURL}product/riva/desktop/riva-img-d1.webp`}
                alt="Watch"
              />
            </div>
          </div>
        </div>
        <div className="rounded-full block sm:hidden contact2 text-center">
          <button className="contacttext2">ViewStore</button>
        </div>
      </div>
      <div className="w-full rounded overflow-hidden shadow-lg container mx-auto mt-5 p-5">
        <div className="sm:flex sm:justify-between items-center gap-2">
          <div className="standard gap-1 md:gap-2 flex items-center">
            <img src="/images/mrm.webp" alt="Watch" />
            <div className="-mt-2">
              <div className="company">MRM Enterprises</div>
              <div className="verified flex gap-1 md:gap-2 items-center">
                <img src="/images/v.webp" alt="Watch" />
                <div className="gst mt-3">| GSTN-24576564584568648</div>
              </div>
            </div>
          </div>
          <div className="rounded-full hidden sm:block contact">
            <button className="contacttext">ViewStore</button>
          </div>
        </div>
        {/* RATING AND REVIEWS  */}
        <div className="flex flex-wrap xl:flex-nowrap justify-between mt-10">
          <div className="flex flex-wrap sm:justify-around xl:justify-normal w-full">
            <div className="list block xl:hidden">
              <img
                src={`${AppKeys.IMGURL}website/product-images/produ-img11.webp`}
                alt="Watch"
              />
            </div>
            <div className="mt-3 sm:mt-0">
              <div className="rating">Rating and reviews</div>
              <div className="flex gap-1">
                <div className="review">4.8</div>
                <div className="count">/5</div>
                <div className="count">
                  (100+ <span>reviews</span>)
                </div>
              </div>
              <div className="factory mt-5">Factory capabilities</div>
              <ul className="mt-3 ullist">
                <li>. Competitive OEM factory</li>
                <li className="mt-2">. Customer cases (1)</li>
                <li className="mt-2">. Full customization</li>
                <li className="mt-2">. Finished product inspection</li>
              </ul>
            </div>
          </div>
          <div className="flex justify-center w-full gap-1 md:gap-5 my-5 mb-10 xl:my-0 ">
            <div className="shirts">
              <img
                src={`${AppKeys.IMGURL}website/product-images/produ-img12.webp`}
                alt="Watch"
              />
              <div className="rate mt-1 sm:mt-3">₹280 - ₹480</div>
            </div>

            <div className="shirts">
              <img
                src={`${AppKeys.IMGURL}website/product-images/produ-img13.webp`}
                alt="Watch"
              />
              <div className="rate mt-1 sm:mt-3">₹280 - ₹480</div>
            </div>
            <div className="shirts">
              <img
                src={`${AppKeys.IMGURL}website/product-images/produ-img11.webp`}
                alt="Watch"
              />
              <div className="rate mt-1 sm:mt-3">₹280 - ₹480</div>
            </div>
            <div className="list hidden xl:block">
              <img
                src={`${AppKeys.IMGURL}product/mrm-enterprises/desktop/mrm-img-d3.webp`}
                alt="Watch"
              />
            </div>
          </div>
        </div>
        <div className="rounded-full block sm:hidden contact2 text-center">
          <button className="contacttext2">ViewStore</button>
        </div>
      </div>
    </div>
  );
});

export default Manufacture;
