import React, { memo, useEffect } from "react";
import {
  IoIosSearch,
  IoIosArrowDown,
  IoIosArrowBack,
  IoIosArrowForward,
} from "react-icons/io";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import "./ListingPage.css";
import Header from "../home/header/header";
import Nav from "../home/navbar/nav";
import { AppKeys } from "../../constants/AppKeys";
import Footer from "../home/footer/footer";
import HeaderV from "../home/header/headerV2";
import { AxiosGet, AxiosPost } from "../../utilities/axioscall";
import { getuserid } from "../../utilities/sessionexpiry";
import EmptyCategoryEmoji from "../emptyCategoryView/empty-category";
import { PK } from "../../constants/PepperKeys";

const ListingPage = memo((props) => {
  const { count, setCount, pepper, setPepper, peppermint } = props;
  const year = new Date();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [pageinit, setPageInit] = useState(false);
  let navigate = useNavigate();
  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };
  const [priceRange, setPriceRange] = useState([0, 100]);
  const [productsDatabyCategory, setProductsDatabyCategory] = useState([]);
  const [subcategories, setsubcategories] = useState([]);

  const handleSliderChange = (value) => {
    setPriceRange(value);
  };

  useEffect(() => {
    let products = productsDatabyCategory;

    // let _cartdata = cartData;
    // if (_cartdata.length > 0) {
    //   _cartdata.map((cartpro) => {
    //     products.filter((prod) => {
    //       if (prod.id === cartpro.id) {
    //         prod["buynow"] = true;
    //         prod["quantity"] = cartpro.quantity;
    //         return true;
    //       }
    //       return false;
    //     });
    //   });
    // }

    setProductsDatabyCategory(products);
    setCount({ ...count, count: count + 1 });
  }, [productsDatabyCategory]);

  const callpageInit = async (categoryid) => {
    // await callCartData();

    await AxiosGet(AppKeys.PRODUCTSAPI + "?category=" + categoryid)
      .then((resp) => {
        if (resp.data?.datas != undefined && resp.data?.datas != null) {
          let products = resp.data?.datas;
          console.log(products);
          setProductsDatabyCategory(products);
          setCount({ ...count, count: count + 1 });
        }
      })
      .catch((errresp) => {
        console.log(errresp);
        return null;
      });
  };

  if (!pageinit) {
    window.scrollTo(0, 0);
    let categoryid = null;

    if (location.state != null && location?.state?.categoryinfo != null) {
      setCount((count) => (count = count + 1));
      categoryid = location.state.categoryinfo.id;
      setsubcategories(location.state.categoryinfo.subcategories);
    }
    setPageInit(true);
    callpageInit(categoryid);
  }

  return (
    <div>
      <section>
        {pepper?.isUserLoggedin ? (
          <>
            <HeaderV
              count={count}
              setCount={setCount}
              pepper={pepper}
              setPepper={setPepper}
              peppermint={peppermint}
            />
          </>
        ) : (
          <>
            <Header
              count={count}
              setCount={setCount}
              pepper={pepper}
              setPepper={setPepper}
              peppermint={peppermint}
            />
          </>
        )}
        <Nav
          count={count}
          setCount={setCount}
          pepper={pepper}
          setPepper={setPepper}
          peppermint={peppermint}
        />

        <div hidden={productsDatabyCategory.length === 0}>
          {/* FILTER OPTIONS */}

          <div className="flex flex-wrap md:flex-wrap lg:flex-nowrap ">
            {/* SUB CATEGORIES  */}

            <section className="xl:ml-20 mx-auto max-w-md md:w-full ">
              <section className="lg:hidden sm:w-full md:w-full">
                <div className="relative p-3">
                  <input
                    type="text"
                    placeholder="Search filter"
                    className="pl-8 pr-4 py-2 filtersearch w-full border rounded-md"
                  />
                  <IoIosSearch className="absolute left-5 top-6 text-gray-500" />
                </div>
                <div className="p-3">
                  <button
                    onClick={toggleAccordion}
                    className=" text-white filtersbtn w-full py-2  rounded mt-4 border border-blue-500"
                  >
                    FILTERS
                  </button>
                </div>

                <div className={`lg:hidden ${isOpen ? "" : "hidden"}`}>
                  <div className="subbg w-full lg:ml-5 pb-10">
                    <div className="">
                      <div className="title text-sm sm:text-xs md:text-md lg:text-xl xl:text-3xl">
                        Subcategories
                      </div>
                      <div className="back pt-5 text-sm sm:text-xs md:text-md lg:text-xl xl:text-3xl">
                        back to &nbsp;
                        <span className="category1 text-sm sm:text-xs md:text-md lg:text-xl xl:text-3xl">
                          Category 1
                        </span>
                      </div>

                      {subcategories.map((subc) => (
                        <>
                          <div className="flex items-center mt-3">
                            <input
                              id="filter-mobile-color-0"
                              name="color[]"
                              value="white"
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                            />
                            <div
                              htmlFor="filter-mobile-color-0"
                              className="ml-3 min-w-0 flex-1 text-gray-500 filloption"
                            >
                              {subc.name}
                            </div>
                            <div className="filternumber">149</div>
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                  {/* Filters group  */}
                  <section>
                    <div className="filtersbg w-full md:flex justify-center xl:w-full mt-10 text-center text-sm sm:text-xs md:text-md lg:text-xl xl:text-xl md:text-start lg:text-start xl:text-start">
                      <div>
                        <div className="filtertext text-sm sm:text-xs md:text-md lg:text-xl xl:text-3xl">
                          Filters
                        </div>
                        <div className="clearall text-sm sm:text-xs md:text-md lg:text-xl xl:text-3xl mt-5">
                          Clear All
                        </div>
                        <div className="relative w-full md:w-full mt-5">
                          <input
                            type="text"
                            placeholder="Search filter"
                            className="pl-8 pr-4 py-2 filtersearch w-full border rounded-md border-none outline-none text-sm sm:text-xs md:text-md lg:text-xl xl:text-3xl"
                          />
                          <IoIosSearch className="absolute left-2 top-3 text-gray-500" />
                        </div>
                        <div className="border-t border-gray-200 border-none outline-none px-4 py-6">
                          <h3 className="-mx-2 -my-3">
                            <button
                              onClick={toggleAccordion}
                              type="button"
                              className="flex w-full items-center justify-between  px-2 py-3 text-gray-400 hover:text-gray-500 text-sm sm:text-xs md:text-md lg:text-xl xl:text-3xl"
                              aria-controls="filter-section-mobile-2"
                              aria-expanded={isOpen ? "true" : "false"}
                            >
                              <span className="filtergroup text-sm sm:text-xs md:text-md lg:text-xl xl:text-3xl">
                                Filter groups
                              </span>
                              <span
                                className={`ml-6 flex items-center transform transition-transform duration-300 ${
                                  isOpen ? "rotate-180" : ""
                                }`}
                              >
                                <IoIosArrowDown />
                              </span>
                            </button>
                          </h3>
                          <div
                            className={`pt-6 ${isOpen ? "" : "hidden"}`}
                            id="filter-section-mobile-2"
                          >
                            <div className="space-y-6">
                              <div className="flex items-center">
                                <input
                                  id="filter-mobile-color-0"
                                  name="color[]"
                                  value="white"
                                  type="checkbox"
                                  className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500 text-sm sm:text-xs md:text-md lg:text-xl xl:text-3xl"
                                />
                                <div
                                  htmlFor="filter-mobile-color-0"
                                  className="ml-3 min-w-0 flex-1 text-gray-500 filloption text-sm sm:text-xs md:text-md lg:text-xl xl:text-3xl"
                                >
                                  Filter 1
                                </div>
                                <div className="filternumber text-sm sm:text-xs md:text-md lg:text-xl xl:text-3xl">
                                  48
                                </div>
                              </div>
                              <div className="flex items-center">
                                <input
                                  id="filter-mobile-color-0"
                                  name="color[]"
                                  value="white"
                                  type="checkbox"
                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                />
                                <label
                                  htmlFor="filter-mobile-color-0"
                                  className="ml-3 min-w-0 flex-1 text-gray-500 filloption text-sm sm:text-xs md:text-md lg:text-xl xl:text-3xl"
                                >
                                  Filter 2
                                </label>
                                <div className="filternumber text-sm sm:text-xs md:text-md lg:text-xl xl:text-3xl">
                                  48
                                </div>
                              </div>
                              <div className="flex items-center">
                                <input
                                  id="filter-mobile-color-0"
                                  name="color[]"
                                  value="white"
                                  type="checkbox"
                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                />
                                <div
                                  htmlFor="filter-mobile-color-0"
                                  className="ml-3 min-w-0 flex-1 text-gray-500 filloption text-sm sm:text-xs md:text-md lg:text-xl xl:text-3xl"
                                >
                                  Filter 3
                                </div>
                                <div className="filternumber text-sm sm:text-xs md:text-md lg:text-xl xl:text-3xl">
                                  48
                                </div>
                              </div>
                              <div className="flex items-center">
                                <input
                                  id="filter-mobile-color-0"
                                  name="color[]"
                                  value="white"
                                  type="checkbox"
                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                />
                                <div
                                  htmlFor="filter-mobile-color-0"
                                  className="ml-3 min-w-0 flex-1 text-gray-500  filloption text-sm sm:text-xs md:text-md lg:text-xl xl:text-3xl"
                                >
                                  Filter 4
                                </div>
                                <div className="filternumber text-sm sm:text-xs md:text-md lg:text-xl xl:text-3xl">
                                  48
                                </div>
                              </div>
                              <div className="flex items-center">
                                <input
                                  id="filter-mobile-color-0"
                                  name="color[]"
                                  value="white"
                                  type="checkbox"
                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                />
                                <div
                                  htmlFor="filter-mobile-color-0"
                                  className="ml-3 min-w-0 flex-1 text-gray-500  filloption text-sm sm:text-xs md:text-md lg:text-xl xl:text-3xl"
                                >
                                  Filter 5
                                </div>
                                <div className="filternumber text-sm sm:text-xs md:text-md lg:text-xl xl:text-3xl">
                                  48
                                </div>
                              </div>
                              <div className="flex items-center">
                                <input
                                  id="filter-mobile-color-0"
                                  name="color[]"
                                  value="white"
                                  type="checkbox"
                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                />
                                <div
                                  htmlFor="filter-mobile-color-0"
                                  className="ml-3 min-w-0 flex-1 text-gray-500  filloption text-sm sm:text-xs md:text-md lg:text-xl xl:text-3xl"
                                >
                                  Filter 6
                                </div>
                                <div className="filternumber text-sm sm:text-xs md:text-md lg:text-xl xl:text-3xl">
                                  48
                                </div>
                              </div>
                              <div className="flex items-center">
                                <input
                                  id="filter-mobile-color-0"
                                  name="color[]"
                                  value="white"
                                  type="checkbox"
                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                />
                                <div
                                  htmlFor="filter-mobile-color-0"
                                  className="ml-3 min-w-0 flex-1 text-gray-500 filloption text-sm sm:text-xs md:text-md lg:text-xl xl:text-3xl"
                                >
                                  Filter 7
                                </div>
                                <div className="filternumber text-sm sm:text-xs md:text-md lg:text-xl xl:text-3xl">
                                  48
                                </div>
                              </div>
                              <div className="flex items-center">
                                <input
                                  id="filter-mobile-color-0"
                                  name="color[]"
                                  value="white"
                                  type="checkbox"
                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                />
                                <div
                                  htmlFor="filter-mobile-color-0"
                                  className="ml-3 min-w-0 flex-1 text-gray-500 filloption text-sm sm:text-xs md:text-md lg:text-xl xl:text-3xl"
                                >
                                  Filter 8
                                </div>
                                <div className="filternumber text-sm sm:text-xs md:text-md lg:text-xl xl:text-3xl">
                                  48
                                </div>
                              </div>
                              <div className="flex items-center">
                                <input
                                  id="filter-mobile-color-0"
                                  name="color[]"
                                  value="white"
                                  type="checkbox"
                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                />
                                <div
                                  htmlFor="filter-mobile-color-0"
                                  className="ml-3 min-w-0 flex-1 text-gray-500 filloption text-sm sm:text-xs md:text-md lg:text-xl xl:text-3xl"
                                >
                                  Filter 9
                                </div>
                                <div className="filternumber text-sm sm:text-xs md:text-md lg:text-xl xl:text-3xl">
                                  48
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="mt-3 showmore text-sm sm:text-xs md:text-md lg:text-xl xl:text-3xl">
                            Show More
                          </div>
                        </div>
                        <div className="border-t border-gray-200 border-none outline-none px-4 py-6">
                          <h3 className="-mx-2 -my-3">
                            <button
                              onClick={toggleAccordion}
                              type="button"
                              className="flex w-full items-center justify-between  px-2 py-3 text-gray-400 hover:text-gray-500"
                              aria-controls="filter-section-mobile-1"
                              aria-expanded={isOpen ? "true" : "false"}
                            >
                              <span className="filtergroup text-sm sm:text-xs md:text-md lg:text-xl xl:text-3xl">
                                Filter groups
                              </span>
                              <span
                                className={`ml-6 flex items-center transform transition-transform duration-300 ${
                                  isOpen ? "rotate-180" : ""
                                }`}
                              >
                                <IoIosArrowDown />
                              </span>
                            </button>
                          </h3>
                          <div
                            className={`pt-6 ${isOpen ? "" : "hidden"}`}
                            id="filter-section-mobile-1"
                          >
                            {/* SLIDER  */}

                            <div className="border p-4">
                              <h2
                                className="font-bold mb-4 text-sm sm:text-xs md:text-md lg:text-xl xl:text-3xl"
                                htmlFor="filter-mobile-color-1"
                              >
                                Price Range
                              </h2>
                              <Slider
                                range
                                min={0}
                                max={100}
                                value={priceRange}
                                onChange={handleSliderChange}
                                marks={{ 0: "0", 50: "50", 100: "100" }}
                              />
                              <div className="flex justify-between mt-4">
                                <span className="text-sm sm:text-xs md:text-md lg:text-xl xl:text-3xl">
                                  Min: ${priceRange[0]}
                                </span>
                                <span className="text-sm sm:text-xs md:text-md lg:text-xl xl:text-3xl">
                                  Max: ${priceRange[1]}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* Filters Group  */}

                        <div className="border-t border-gray-200 border-none outline-none px-4 py-6">
                          <h3 className="-mx-2 -my-3">
                            <button
                              onClick={toggleAccordion}
                              type="button"
                              className="flex w-full items-center justify-between  px-2 py-3 text-gray-400 hover:text-gray-500"
                              aria-controls="filter-section-mobile-1"
                              aria-expanded={isOpen ? "true" : "false"}
                            >
                              <span className="filtergroup text-sm sm:text-xs md:text-md lg:text-xl xl:text-3xl">
                                Filter groups
                              </span>
                              <span
                                className={`ml-6 flex items-center transform transition-transform duration-300 ${
                                  isOpen ? "rotate-180" : ""
                                }`}
                              >
                                <IoIosArrowDown />
                              </span>
                            </button>
                          </h3>
                          <div
                            className={`pt-6 ${isOpen ? "" : "hidden"}`}
                            id="filter-section-mobile-0"
                          >
                            <div className="space-y-6">
                              <div className="flex items-center">
                                <input
                                  id="filter-mobile-color-0"
                                  name="color[]"
                                  value="white"
                                  type="checkbox"
                                  className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                                />
                                <div
                                  htmlFor="filter-mobile-color-0"
                                  className="ml-3 min-w-0 flex-1 text-gray-500 filloption text-sm sm:text-xs md:text-md lg:text-xl xl:text-3xl"
                                >
                                  Subcategory 2
                                </div>
                                <div className="filternumber text-sm sm:text-xs md:text-md lg:text-xl xl:text-3xl">
                                  48
                                </div>
                              </div>
                              <div className="flex items-center">
                                <input
                                  id="filter-mobile-color-0"
                                  name="color[]"
                                  value="white"
                                  type="checkbox"
                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                />
                                <label
                                  htmlFor="filter-mobile-color-0"
                                  className="ml-3 min-w-0 flex-1 text-gray-500 filloption text-sm sm:text-xs md:text-md lg:text-xl xl:text-3xl"
                                >
                                  Subcategory 2
                                </label>
                                <div className="filternumber text-sm sm:text-xs md:text-md lg:text-xl xl:text-3xl">
                                  48
                                </div>
                              </div>
                              <div className="flex items-center">
                                <input
                                  id="filter-mobile-color-0"
                                  name="color[]"
                                  value="white"
                                  type="checkbox"
                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                />
                                <div
                                  htmlFor="filter-mobile-color-0"
                                  className="ml-3 min-w-0 flex-1 text-gray-500 filloption text-sm sm:text-xs md:text-md lg:text-xl xl:text-3xl"
                                >
                                  Subcategory 2
                                </div>
                                <div className="filternumber text-sm sm:text-xs md:text-md lg:text-xl xl:text-3xl">
                                  48
                                </div>
                              </div>
                              <div className="flex items-center">
                                <input
                                  id="filter-mobile-color-0"
                                  name="color[]"
                                  value="white"
                                  type="checkbox"
                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                />
                                <div
                                  htmlFor="filter-mobile-color-0"
                                  className="ml-3 min-w-0 flex-1 text-gray-500  filloption"
                                >
                                  Subcategory 2
                                </div>
                                <div className="filternumber">48</div>
                              </div>
                              <div className="flex items-center">
                                <input
                                  id="filter-mobile-color-0"
                                  name="color[]"
                                  value="white"
                                  type="checkbox"
                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                />
                                <div
                                  htmlFor="filter-mobile-color-0"
                                  className="ml-3 min-w-0 flex-1 text-gray-500  filloption"
                                >
                                  Subcategory 2
                                </div>
                                <div className="filternumber">48</div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* Filters Group  */}

                        <div className="border-t border-gray-200 border-none outline-none px-4 py-6">
                          <h3 className="-mx-2 -my-3">
                            <button
                              onClick={toggleAccordion}
                              type="button"
                              className="flex w-full items-center justify-between  px-2 py-3 text-gray-400 hover:text-gray-500"
                              aria-controls="filter-section-mobile-0"
                              aria-expanded={isOpen ? "true" : "false"}
                            >
                              <span className="filtergroup">Filter groups</span>
                              <span
                                className={`ml-6 flex items-center transform transition-transform duration-300 ${
                                  isOpen ? "rotate-180" : ""
                                }`}
                              >
                                <IoIosArrowDown />
                              </span>
                            </button>
                          </h3>
                          <div
                            className={`pt-6 ${isOpen ? "" : "hidden"}`}
                            id="filter-section-mobile-0"
                          >
                            <div className="space-y-6">
                              <div className="flex items-center">
                                <input
                                  id="filter-mobile-color-0"
                                  name="color[]"
                                  value="white"
                                  type="checkbox"
                                  className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                                />
                                <div
                                  htmlFor="filter-mobile-color-0"
                                  className="ml-3 min-w-0 flex-1 text-gray-500 filloption"
                                >
                                  Filter 1
                                </div>
                                <div className="filternumber">48</div>
                              </div>
                              <div className="flex items-center">
                                <input
                                  id="filter-mobile-color-0"
                                  name="color[]"
                                  value="white"
                                  type="checkbox"
                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                />
                                <label
                                  htmlFor="filter-mobile-color-0"
                                  className="ml-3 min-w-0 flex-1 text-gray-500 filloption"
                                >
                                  Filter 2
                                </label>
                                <div className="filternumber">48</div>
                              </div>
                              <div className="flex items-center">
                                <input
                                  id="filter-mobile-color-0"
                                  name="color[]"
                                  value="white"
                                  type="checkbox"
                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                />
                                <div
                                  htmlFor="filter-mobile-color-0"
                                  className="ml-3 min-w-0 flex-1 text-gray-500 filloption"
                                >
                                  Filter 3
                                </div>
                                <div className="filternumber">48</div>
                              </div>
                              <div className="flex items-center">
                                <input
                                  id="filter-mobile-color-0"
                                  name="color[]"
                                  value="white"
                                  type="checkbox"
                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                />
                                <div
                                  htmlFor="filter-mobile-color-0"
                                  className="ml-3 min-w-0 flex-1 text-gray-500  filloption"
                                >
                                  Filter 4
                                </div>
                                <div className="filternumber">48</div>
                              </div>
                              <div className="flex items-center">
                                <input
                                  id="filter-mobile-color-0"
                                  name="color[]"
                                  value="white"
                                  type="checkbox"
                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                />
                                <div
                                  htmlFor="filter-mobile-color-0"
                                  className="ml-3 min-w-0 flex-1 text-gray-500  filloption"
                                >
                                  Filter 5
                                </div>
                                <div className="filternumber">48</div>
                              </div>
                              <div className="flex items-center">
                                <input
                                  id="filter-mobile-color-0"
                                  name="color[]"
                                  value="white"
                                  type="checkbox"
                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                />
                                <div
                                  htmlFor="filter-mobile-color-0"
                                  className="ml-3 min-w-0 flex-1 text-gray-500  filloption"
                                >
                                  Filter 6
                                </div>
                                <div className="filternumber">48</div>
                              </div>
                              <div className="flex items-center">
                                <input
                                  id="filter-mobile-color-0"
                                  name="color[]"
                                  value="white"
                                  type="checkbox"
                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                />
                                <div
                                  htmlFor="filter-mobile-color-0"
                                  className="ml-3 min-w-0 flex-1 text-gray-500 filloption "
                                >
                                  Filter 7
                                </div>
                                <div className="filternumber">48</div>
                              </div>
                              <div className="flex items-center">
                                <input
                                  id="filter-mobile-color-0"
                                  name="color[]"
                                  value="white"
                                  type="checkbox"
                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                />
                                <div
                                  htmlFor="filter-mobile-color-0"
                                  className="ml-3 min-w-0 flex-1 text-gray-500 filloption"
                                >
                                  Filter 8
                                </div>
                                <div className="filternumber">48</div>
                              </div>
                              <div className="flex items-center">
                                <input
                                  id="filter-mobile-color-0"
                                  name="color[]"
                                  value="white"
                                  type="checkbox"
                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                />
                                <div
                                  htmlFor="filter-mobile-color-0"
                                  className="ml-3 min-w-0 flex-1 text-gray-500 filloption"
                                >
                                  Filter 9
                                </div>
                                <div className="filternumber">48</div>
                              </div>
                            </div>
                          </div>
                          <div className="mt-3 showmore ">Show More</div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </section>
              <div className="hidden lg:block subbg w-full md:w-4/6 xl:w-4/6  lg:ml-10  pb-10  text-sm sm:text-xs md:text-md lg:text-xl xl:text-xl md:text-start lg:text-start xl:text-start">
                <div className="">
                  <div className="title">Subcategories</div>
                  <div className="back pt-5">
                    back to &nbsp;
                    <span className="category1">Category 1</span>
                  </div>

                  {subcategories.map((subc) => (
                    <>
                      <div className="flex items-center mt-3">
                        <input
                          id="filter-mobile-color-0"
                          name="color[]"
                          value="white"
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                        />
                        <div
                          htmlFor="filter-mobile-color-0"
                          className="ml-3 min-w-0 flex-1 text-gray-500 filloption"
                        >
                          {subc.name}
                        </div>
                        <div className="filternumber">48</div>
                      </div>
                    </>
                  ))}
                </div>
              </div>

              {/* Filters group  */}
              <section>
                <div className="hidden md:hidden1 lg:block filtersbg w-full lg:w-4/6 lg:ml-10 xl:w-4/6 mt-10 overflow-y-scroll text-sm sm:text-xs md:text-md lg:text-xl xl:text-3xl">
                  <div>
                    <div className="filtertext">Filters</div>
                    <div className="clearall">Clear All</div>
                    <div className="relative w-full mt-3">
                      <input
                        type="text"
                        placeholder="Search filter"
                        className="pl-8 pr-4 py-2 filtersearch w-full border rounded-md border-none outline-none"
                      />
                      <IoIosSearch className="absolute left-2 top-3 text-gray-500" />
                    </div>
                    <div className="border-t border-gray-200 border-none outline-none px-4 py-6">
                      <h3 className="-mx-2 -my-3">
                        <button
                          onClick={toggleAccordion}
                          type="button"
                          className="flex w-full items-center justify-between  px-2 py-3 text-gray-400 hover:text-gray-500"
                          aria-controls="filter-section-mobile-0"
                          aria-expanded={isOpen ? "true" : "false"}
                        >
                          <span className="filtergroup">Filter groups</span>
                          <span
                            className={`ml-6 flex items-center transform transition-transform duration-300 ${
                              isOpen ? "rotate-180" : ""
                            }`}
                          >
                            <IoIosArrowDown />
                          </span>
                        </button>
                      </h3>
                      <div
                        className={`pt-6 ${isOpen ? "" : "hidden"}`}
                        id="filter-section-mobile-0"
                      >
                        <div className="space-y-6">
                          <div className="flex items-center">
                            <input
                              id="filter-mobile-color-0"
                              name="color[]"
                              value="white"
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                            />
                            <div
                              htmlFor="filter-mobile-color-0"
                              className="ml-3 min-w-0 flex-1 text-gray-500 filloption"
                            >
                              Filter 1
                            </div>
                            <div className="filternumber">48</div>
                          </div>
                          <div className="flex items-center">
                            <input
                              id="filter-mobile-color-0"
                              name="color[]"
                              value="white"
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                            />
                            <label
                              htmlFor="filter-mobile-color-0"
                              className="ml-3 min-w-0 flex-1 text-gray-500 filloption"
                            >
                              Filter 2
                            </label>
                            <div className="filternumber">48</div>
                          </div>
                          <div className="flex items-center">
                            <input
                              id="filter-mobile-color-0"
                              name="color[]"
                              value="white"
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                            />
                            <div
                              htmlFor="filter-mobile-color-0"
                              className="ml-3 min-w-0 flex-1 text-gray-500 filloption"
                            >
                              Filter 3
                            </div>
                            <div className="filternumber">48</div>
                          </div>
                          <div className="flex items-center">
                            <input
                              id="filter-mobile-color-0"
                              name="color[]"
                              value="white"
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                            />
                            <div
                              htmlFor="filter-mobile-color-0"
                              className="ml-3 min-w-0 flex-1 text-gray-500  filloption"
                            >
                              Filter 4
                            </div>
                            <div className="filternumber">48</div>
                          </div>
                          <div className="flex items-center">
                            <input
                              id="filter-mobile-color-0"
                              name="color[]"
                              value="white"
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                            />
                            <div
                              htmlFor="filter-mobile-color-0"
                              className="ml-3 min-w-0 flex-1 text-gray-500  filloption"
                            >
                              Filter 5
                            </div>
                            <div className="filternumber">48</div>
                          </div>
                          <div className="flex items-center">
                            <input
                              id="filter-mobile-color-0"
                              name="color[]"
                              value="white"
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                            />
                            <div
                              htmlFor="filter-mobile-color-0"
                              className="ml-3 min-w-0 flex-1 text-gray-500  filloption"
                            >
                              Filter 6
                            </div>
                            <div className="filternumber">48</div>
                          </div>
                          <div className="flex items-center">
                            <input
                              id="filter-mobile-color-0"
                              name="color[]"
                              value="white"
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                            />
                            <div
                              htmlFor="filter-mobile-color-0"
                              className="ml-3 min-w-0 flex-1 text-gray-500 filloption "
                            >
                              Filter 7
                            </div>
                            <div className="filternumber">48</div>
                          </div>
                          <div className="flex items-center">
                            <input
                              id="filter-mobile-color-0"
                              name="color[]"
                              value="white"
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                            />
                            <div
                              htmlFor="filter-mobile-color-0"
                              className="ml-3 min-w-0 flex-1 text-gray-500 filloption"
                            >
                              Filter 8
                            </div>
                            <div className="filternumber">48</div>
                          </div>
                          <div className="flex items-center">
                            <input
                              id="filter-mobile-color-0"
                              name="color[]"
                              value="white"
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                            />
                            <div
                              htmlFor="filter-mobile-color-0"
                              className="ml-3 min-w-0 flex-1 text-gray-500 filloption"
                            >
                              Filter 9
                            </div>
                            <div className="filternumber">48</div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-3 showmore ">Show More</div>
                    </div>
                    <div className="border-t border-gray-200 border-none outline-none px-4 py-6">
                      <h3 className="-mx-2 -my-3">
                        <button
                          onClick={toggleAccordion}
                          type="button"
                          className="flex w-full items-center justify-between  px-2 py-3 text-gray-400 hover:text-gray-500"
                          aria-controls="filter-section-mobile-1"
                          aria-expanded={isOpen ? "true" : "false"}
                        >
                          <span className="filtergroup">Filter groups</span>
                          <span
                            className={`ml-6 flex items-center transform transition-transform duration-300 ${
                              isOpen ? "rotate-180" : ""
                            }`}
                          >
                            <IoIosArrowDown />
                          </span>
                        </button>
                      </h3>
                      <div
                        className={`pt-6 ${isOpen ? "" : "hidden"}`}
                        id="filter-section-mobile-1"
                      >
                        <div className="border p-4">
                          <h2
                            className="text-xl font-bold mb-4"
                            htmlFor="filter-mobile-color-1"
                          >
                            Price Range
                          </h2>
                          <Slider
                            range
                            min={0}
                            max={100}
                            value={priceRange}
                            onChange={handleSliderChange}
                            marks={{ 0: "0", 50: "50", 100: "100" }}
                          />
                          <div className="flex justify-between mt-4">
                            <span>Min: ${priceRange[0]}</span>
                            <span>Max: ${priceRange[1]}</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="border-t border-gray-200 border-none outline-none px-4 py-6">
                      <h3 className="-mx-2 -my-3">
                        <button
                          onClick={toggleAccordion}
                          type="button"
                          className="flex w-full items-center justify-between  px-2 py-3 text-gray-400 hover:text-gray-500"
                          aria-controls="filter-section-mobile-1"
                          aria-expanded={isOpen ? "true" : "false"}
                        >
                          <span className="filtergroup">Filter groups</span>
                          <span
                            className={`ml-6 flex items-center transform transition-transform duration-300 ${
                              isOpen ? "rotate-180" : ""
                            }`}
                          >
                            <IoIosArrowDown />
                          </span>
                        </button>
                      </h3>
                      <div
                        className={`pt-6 ${isOpen ? "" : "hidden"}`}
                        id="filter-section-mobile-0"
                      >
                        <div className="space-y-6">
                          <div className="relative w-full mt-3">
                            <input
                              type="text"
                              placeholder="Search filter"
                              className="pl-8 pr-4 py-2 filtersearch w-full border rounded-md border-none outline-none"
                            />
                            <IoIosSearch className="absolute left-2 top-3 text-gray-500" />
                          </div>
                          <div className="flex items-center">
                            <input
                              id="filter-mobile-color-0"
                              name="color[]"
                              value="white"
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                            />
                            <div
                              htmlFor="filter-mobile-color-0"
                              className="ml-3 min-w-0 flex-1 text-gray-500 filloption"
                            >
                              Subcategory 2
                            </div>
                            <div className="filternumber">48</div>
                          </div>
                          <div className="flex items-center">
                            <input
                              id="filter-mobile-color-0"
                              name="color[]"
                              value="white"
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                            />
                            <label
                              htmlFor="filter-mobile-color-0"
                              className="ml-3 min-w-0 flex-1 text-gray-500 filloption"
                            >
                              Subcategory 2
                            </label>
                            <div className="filternumber">48</div>
                          </div>
                          <div className="flex items-center">
                            <input
                              id="filter-mobile-color-0"
                              name="color[]"
                              value="white"
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                            />
                            <div
                              htmlFor="filter-mobile-color-0"
                              className="ml-3 min-w-0 flex-1 text-gray-500 filloption"
                            >
                              Subcategory 2
                            </div>
                            <div className="filternumber">48</div>
                          </div>
                          <div className="flex items-center">
                            <input
                              id="filter-mobile-color-0"
                              name="color[]"
                              value="white"
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                            />
                            <div
                              htmlFor="filter-mobile-color-0"
                              className="ml-3 min-w-0 flex-1 text-gray-500  filloption"
                            >
                              Subcategory 2
                            </div>
                            <div className="filternumber">48</div>
                          </div>
                          <div className="flex items-center">
                            <input
                              id="filter-mobile-color-0"
                              name="color[]"
                              value="white"
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                            />
                            <div
                              htmlFor="filter-mobile-color-0"
                              className="ml-3 min-w-0 flex-1 text-gray-500  filloption"
                            >
                              Subcategory 2
                            </div>
                            <div className="filternumber">48</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="border-t border-gray-200 border-none outline-none px-4 py-6">
                      <h3 className="-mx-2 -my-3">
                        <button
                          onClick={toggleAccordion}
                          type="button"
                          className="flex w-full items-center justify-between  px-2 py-3 text-gray-400 hover:text-gray-500"
                          aria-controls="filter-section-mobile-0"
                          aria-expanded={isOpen ? "true" : "false"}
                        >
                          <span className="filtergroup">Filter groups</span>
                          <span
                            className={`ml-6 flex items-center transform transition-transform duration-300 ${
                              isOpen ? "rotate-180" : ""
                            }`}
                          >
                            <IoIosArrowDown />
                          </span>
                        </button>
                      </h3>
                      <div
                        className={`pt-6 ${isOpen ? "" : "hidden"}`}
                        id="filter-section-mobile-0"
                      >
                        <div className="space-y-6">
                          <div className="flex items-center">
                            <input
                              id="filter-mobile-color-0"
                              name="color[]"
                              value="white"
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                            />
                            <div
                              htmlFor="filter-mobile-color-0"
                              className="ml-3 min-w-0 flex-1 text-gray-500 filloption"
                            >
                              <i
                                class="fa-solid fa-star"
                                style={{ color: "#FF924A" }}
                              ></i>
                              <i
                                class="fa-solid fa-star"
                                style={{ color: "#FF924A" }}
                              ></i>
                              <i
                                class="fa-solid fa-star"
                                style={{ color: "#FF924A" }}
                              ></i>
                              <i
                                class="fa-solid fa-star"
                                style={{ color: "#FF924A" }}
                              ></i>
                              <i
                                class="fa-solid fa-star"
                                style={{ color: "#FF924A" }}
                              ></i>
                            </div>
                            <div className="filternumber">48</div>
                          </div>
                          <div className="flex items-center">
                            <input
                              id="filter-mobile-color-0"
                              name="color[]"
                              value="white"
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                            />
                            <label
                              htmlFor="filter-mobile-color-0"
                              className="ml-3 min-w-0 flex-1 text-gray-500 filloption"
                            >
                              <i
                                class="fa-solid fa-star"
                                style={{ color: "#FF924A" }}
                              ></i>
                              <i
                                class="fa-solid fa-star"
                                style={{ color: "#FF924A" }}
                              ></i>
                              <i
                                class="fa-solid fa-star"
                                style={{ color: "#FF924A" }}
                              ></i>
                              <i
                                class="fa-solid fa-star"
                                style={{ color: "#FF924A" }}
                              ></i>
                              <i
                                class="fa-solid fa-star"
                                style={{ color: "#737B7D;" }}
                              ></i>
                            </label>
                            <div className="filternumber">32</div>
                          </div>
                          <div className="flex items-center">
                            <input
                              id="filter-mobile-color-0"
                              name="color[]"
                              value="white"
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                            />
                            <div
                              htmlFor="filter-mobile-color-0"
                              className="ml-3 min-w-0 flex-1 text-gray-500 filloption"
                            >
                              <i
                                class="fa-solid fa-star"
                                style={{ color: "#FF924A" }}
                              ></i>
                              <i
                                class="fa-solid fa-star"
                                style={{ color: "#FF924A" }}
                              ></i>
                              <i
                                class="fa-solid fa-star"
                                style={{ color: "#FF924A" }}
                              ></i>
                              <i
                                class="fa-solid fa-star"
                                style={{ color: "#737B7D" }}
                              ></i>
                              <i
                                class="fa-solid fa-star"
                                style={{ color: "#737B7D;" }}
                              ></i>
                            </div>
                            <div className="filternumber">5</div>
                          </div>
                          <div className="flex items-center">
                            <input
                              id="filter-mobile-color-0"
                              name="color[]"
                              value="white"
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                            />
                            <div
                              htmlFor="filter-mobile-color-0"
                              className="ml-3 min-w-0 flex-1 text-gray-500  filloption"
                            >
                              <i
                                class="fa-solid fa-star"
                                style={{ color: "#FF924A" }}
                              ></i>
                              <i
                                class="fa-solid fa-star"
                                style={{ color: "#FF924A" }}
                              ></i>
                              <i
                                class="fa-solid fa-star"
                                style={{ color: "#737B7D" }}
                              ></i>
                              <i
                                class="fa-solid fa-star"
                                style={{ color: "#737B7D" }}
                              ></i>
                              <i
                                class="fa-solid fa-star"
                                style={{ color: "#737B7D;" }}
                              ></i>
                            </div>
                            <div className="filternumber">3</div>
                          </div>
                          <div className="flex items-center">
                            <input
                              id="filter-mobile-color-0"
                              name="color[]"
                              value="white"
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                            />
                            <div
                              htmlFor="filter-mobile-color-0"
                              className="ml-3 min-w-0 flex-1 "
                            >
                              <i
                                class="fa-solid fa-star"
                                style={{ color: "#FF924A" }}
                              ></i>
                              <i
                                class="fa-solid fa-star"
                                style={{ color: "#737B7D" }}
                              ></i>
                              <i
                                class="fa-solid fa-star"
                                style={{ color: "#737B7D" }}
                              ></i>
                              <i
                                class="fa-solid fa-star"
                                style={{ color: "#737B7D" }}
                              ></i>
                              <i
                                class="fa-solid fa-star"
                                style={{ color: "#737B7D;" }}
                              ></i>
                            </div>
                            <div className="filternumber">1</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </section>

            {/* LISTING PAGE  */}

            <section>
              <div className="md:p-5 text-center text-sm sm:text-xs md:text-md lg:text-xl xl:text-xl md:text-start lg:text-start xl:text-start">
                <div className="product__containerr grid place-content-center grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-5 gap-7">
                  {productsDatabyCategory.map((prod) => (
                    <>
                      <div className="productt py-4">
                        <a
                          className="product__discount"
                          href={() => {
                            return false;
                          }}
                          style={{ cursor: "pointer" }}
                          onClick={(event) => {
                            navigate("/product/" + prod.seourl, {
                              state: {
                                productinfo: prod,
                              },
                            });
                          }}
                        >
                          <div className="product__imgg">
                            <img
                              src={`https://haodamart.blr1.cdn.digitaloceanspaces.com/HAODAMART/product/${prod.desktop_image}`}
                              alt="img"
                            />
                          </div>
                          <div className="categoryimg flex w-full">
                            <div className="number__per grid place-content-center">
                              <span>-30%</span>
                            </div>
                            <div className="product__star grid place-content-center ml-auto">
                              <i class="fa-regular fa-star"></i>
                            </div>
                          </div>
                        </a>
                        <div className="product__contentt px-4">
                          <a
                            href={() => {
                              return false;
                            }}
                            style={{ cursor: "pointer" }}
                            onClick={(event) => {
                              navigate("/product/" + prod.seourl, {
                                state: {
                                  productinfo: prod,
                                },
                              });
                            }}
                          >
                            <div className="product__stock flex justify-between">
                              <div className="stock">
                                <i class="fa-solid fa-circle-check mr-2"></i>
                                <span>In stock </span>
                              </div>
                              <div className="share">
                                <button>
                                  <i class="fa-solid fa-share-nodes"></i>
                                </button>
                              </div>
                            </div>
                            <div className="product__para my-2">
                              <span>
                                {" "}
                                {prod.meta_tag_title} | Part No. {prod.id}
                              </span>
                            </div>
                            <div className="product__title">
                              <span>{prod.product_name}</span>
                            </div>
                            <div className="product__price flex justify-between items-center my-2">
                              <div className="price">
                                <div>
                                  <span className="letters">Your price</span>
                                </div>
                                <div>
                                  <span className="rupees">
                                    ₹{prod.price}{" "}
                                    <span className="net">net</span>
                                  </span>
                                </div>
                              </div>
                              <div className="price__dis">
                                <span>
                                  <strike>
                                    ₹{parseFloat(prod.price, 2) + 100}.00 net
                                  </strike>
                                </span>
                              </div>
                            </div>
                          </a>
                          <div className="flex justify-between">
                            <div
                              className="w-20 cart1 cursor-pointer"
                              onClick={async () => {
                                if (prod["quantity"]) {
                                  prod["quantity"] = prod["quantity"] - 1;
                                  // await callAddtoCart(
                                  //   prod,
                                  //   prod.id,
                                  //   prod["quantity"],
                                  //   0,
                                  //   1
                                  // );
                                } else {
                                  prod["quantity"] = 1;
                                  prod["buynow"] = true;
                                  prod["quantity"] = 1;
                                  // await callAddtoCart(prod, prod.id);
                                }
                                if (prod?.quantity <= 1) prod["quantity"] = 1;
                                await peppermint(
                                  PK.CACHEDATAWRITECARTITEMS,
                                  prod
                                );
                                setCount({ ...count, count: count + 1 });
                              }}
                            >
                              <button>-</button>
                            </div>
                            <div className="cartbox">
                              <input
                                type="text"
                                readOnly
                                value={prod?.quantity}
                                placeholder="1"
                                className="w-5"
                              />
                            </div>
                            <div
                              className="w-20 cart2 cursor-pointer"
                              onClick={async () => {
                                if (prod["quantity"]) {
                                  prod["quantity"] = prod["quantity"] + 1;
                                  // await callAddtoCart(
                                  //   prod,
                                  //   prod.id,
                                  //   prod["quantity"],
                                  //   1,
                                  //   0
                                  // );
                                } else {
                                  prod["buynow"] = true;
                                  prod["quantity"] = 1;
                                  // await callAddtoCart(prod, prod.id);

                                  prod["quantity"] = 1;
                                }
                                await peppermint(
                                  PK.CACHEDATAWRITECARTITEMS,
                                  prod
                                );
                                setCount({ ...count, count: count + 1 });
                              }}
                            >
                              <button>+</button>
                            </div>
                          </div>
                          <div className="product__btnn mt-3">
                            <button
                              onClick={async () => {
                                if (prod?.buynow) {
                                  navigate("/cart");
                                }
                                prod["buynow"] = true;
                                prod["quantity"] = 1;
                                // await callAddtoCart(prod, prod.id);
                                //await callCartData();
                                await peppermint(
                                  PK.CACHEDATAWRITECARTITEMS,
                                  prod
                                );
                                setCount({ ...count, count: count + 1 });
                                navigate("/cart");
                                //console.log(prod);
                              }}
                            >
                              BUY NOW
                            </button>
                          </div>
                          <div className="product__btnn1 mt-3">
                            {pepper?.cachedata?.cartitems?.[prod.id]
                              ?.quantity >= 1 ? (
                              <>
                                <button
                                  type="button"
                                  onClick={async () => {
                                    navigate("/cart");
                                  }}
                                >
                                  VIEW CART
                                </button>
                              </>
                            ) : (
                              <>
                                <button
                                  type="button"
                                  onClick={async () => {
                                    prod["buynow"] = true;
                                    prod["quantity"] = 1;
                                    await peppermint(
                                      PK.CACHEDATAWRITECARTITEMS,
                                      prod
                                    );
                                    // await callAddtoCart(prod, prod.id);
                                    //await callCartData();
                                    setCount({ ...count, count: count + 1 });
                                    //console.log(prod);
                                  }}
                                >
                                  ADD TO CART
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                </div>
              </div>
            </section>
          </div>
          <div className="flex justify-center gap-5 mt-5 w-full xl:w-full text-center text-sm sm:text-xs md:text-md lg:text-xl xl:text-3xl">
            <div className="mt-1">
              <IoIosArrowBack />
            </div>
            <div className="flex gap-5 pagenumbers">
              <div className="numberbox w-7 text-white">1</div>
              <div>2</div>
              <div>3</div>
              <div>4</div>
              <div>5</div>
              <div>6</div>
              <div className="pagefrom">from 200</div>
            </div>
            <div className="mt-1">
              <IoIosArrowForward />
            </div>
          </div>
          {/* PRODUCTS YOU MAY LIKE  */}
          <section>
            <section className="container mx-auto">
              <div className="product__header md:flex justify-between md:p-3 mt-10 md:mt-24 mb-6 md:mb-12 text-center text-sm sm:text-xs md:text-md lg:text-xl xl:text-3xl">
                <div className="product__titlee text-center md:text-left">
                  <span>Products You May Like</span>
                </div>
                <div className="product__show text-center md:text-right mt-3 md:mt-0">
                  <span>
                    Show all recommended products{" "}
                    <i class="fa-solid fa-arrow-right"></i>
                  </span>
                </div>
              </div>
              <div className="product__containerr grid place-content-center grid-cols-1 sm:grid-cols-2 md:grid-cols-2 md:p-5 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-5 gap-7 text-center text-sm sm:text-xs md:text-md lg:text-xl xl:text-lg md:text-start lg:text-start xl:text-start">
                <div className="productt py-4">
                  <div className="product__discount flex justify-between">
                    <div className="product__imgg">
                      <img
                        src={`${AppKeys.IMGURL}website/product-listing-images/desktop/produ-list-img-d8.webp`}
                        alt="img"
                      />
                    </div>
                    <div className="number__per grid place-content-center">
                      <span>-30%</span>
                    </div>
                    <div className="product__star grid place-content-center">
                      <i class="fa-regular fa-star"></i>
                    </div>
                  </div>
                  <div className="product__contentt px-4">
                    <div className="product__stock flex justify-between">
                      <div className="stock">
                        <i class="fa-solid fa-circle-check mr-2"></i>
                        <span>In stock </span>
                      </div>
                      <div className="share">
                        <button>
                          <i class="fa-solid fa-share-nodes"></i>
                        </button>
                      </div>
                    </div>
                    <div className="product__para my-2">
                      <span>Omnires | Part No. 2123532</span>
                    </div>
                    <div className="product__title">
                      <span>Connection with a handle Omnires round</span>
                    </div>
                    <div className="product__price flex justify-between items-center my-2">
                      <div className="price">
                        <div>
                          <span className="letters">Your price</span>
                        </div>
                        <div>
                          <span className="rupees">
                            ₹350.00 <span className="net">net</span>
                          </span>
                        </div>
                      </div>
                      <div className="price__dis">
                        <span>
                          <strike>₹450.00 net</strike>
                        </span>
                      </div>
                    </div>
                    <div className="product__btnn">
                      <button>BUY NOW</button>
                    </div>
                  </div>
                </div>
                <div className="productt py-4">
                  <div className="product__discount flex justify-between">
                    <div className="product__imgg">
                      <img
                        src={`${AppKeys.IMGURL}website/product-listing-images/desktop/produ-list-img-d8.webp`}
                        alt="img"
                      />
                    </div>
                    <div className="number__per grid place-content-center">
                      <span>-30%</span>
                    </div>
                    <div className="product__star grid place-content-center">
                      <i class="fa-regular fa-star"></i>
                    </div>
                  </div>
                  <div className="product__contentt px-4">
                    <div className="product__stock flex justify-between">
                      <div className="stock">
                        <i class="fa-solid fa-circle-check mr-2"></i>
                        <span>In stock </span>
                      </div>
                      <div className="share">
                        <button>
                          <i class="fa-solid fa-share-nodes"></i>
                        </button>
                      </div>
                    </div>
                    <div className="product__para my-2">
                      <span>Omnires | Part No. 2123532</span>
                    </div>
                    <div className="product__title">
                      <span>Connection with a handle Omnires round</span>
                    </div>
                    <div className="product__price flex justify-between items-center my-2">
                      <div className="price">
                        <div>
                          <span className="letters">Your price</span>
                        </div>
                        <div>
                          <span className="rupees">
                            ₹350.00 <span className="net">net</span>
                          </span>
                        </div>
                      </div>
                      <div className="price__dis">
                        <span>
                          <strike>₹450.00 net</strike>
                        </span>
                      </div>
                    </div>
                    <div className="product__btnn">
                      <button>BUY NOW</button>
                    </div>
                  </div>
                </div>
                <div className="productt py-4">
                  <div className="product__discount flex justify-between">
                    <div className="product__imgg">
                      <img
                        src={`${AppKeys.IMGURL}website/product-listing-images/desktop/produ-list-img-d8.webp`}
                        alt="img"
                      />
                    </div>
                    <div className="number__per grid place-content-center">
                      <span>-30%</span>
                    </div>
                    <div className="product__star grid place-content-center">
                      <i class="fa-regular fa-star"></i>
                    </div>
                  </div>
                  <div className="product__contentt px-4">
                    <div className="product__stock flex justify-between">
                      <div className="stock">
                        <i class="fa-solid fa-circle-check mr-2"></i>
                        <span>In stock </span>
                      </div>
                      <div className="share">
                        <button>
                          <i class="fa-solid fa-share-nodes"></i>
                        </button>
                      </div>
                    </div>
                    <div className="product__para my-2">
                      <span>Omnires | Part No. 2123532</span>
                    </div>
                    <div className="product__title">
                      <span>Connection with a handle Omnires round</span>
                    </div>
                    <div className="product__price flex justify-between items-center my-2">
                      <div className="price">
                        <div>
                          <span className="letters">Your price</span>
                        </div>
                        <div>
                          <span className="rupees">
                            ₹350.00 <span className="net">net</span>
                          </span>
                        </div>
                      </div>
                      <div className="price__dis">
                        <span>
                          <strike>₹450.00 net</strike>
                        </span>
                      </div>
                    </div>
                    <div className="product__btnn">
                      <button>BUY NOW</button>
                    </div>
                  </div>
                </div>
                <div className="productt py-4">
                  <div className="product__discount flex justify-between">
                    <div className="product__imgg">
                      <img
                        src={`${AppKeys.IMGURL}website/product-listing-images/desktop/produ-list-img-d8.webp`}
                        alt="img"
                      />
                    </div>
                    <div className="number__per grid place-content-center">
                      <span>-30%</span>
                    </div>
                    <div className="product__star grid place-content-center">
                      <i class="fa-regular fa-star"></i>
                    </div>
                  </div>
                  <div className="product__contentt px-4">
                    <div className="product__stock flex justify-between">
                      <div className="stock">
                        <i class="fa-solid fa-circle-check mr-2"></i>
                        <span>In stock </span>
                      </div>
                      <div className="share">
                        <button>
                          <i class="fa-solid fa-share-nodes"></i>
                        </button>
                      </div>
                    </div>
                    <div className="product__para my-2">
                      <span>Omnires | Part No. 2123532</span>
                    </div>
                    <div className="product__title">
                      <span>Connection with a handle Omnires round</span>
                    </div>
                    <div className="product__price flex justify-between items-center my-2">
                      <div className="price">
                        <div>
                          <span className="letters">Your price</span>
                        </div>
                        <div>
                          <span className="rupees">
                            ₹350.00 <span className="net">net</span>
                          </span>
                        </div>
                      </div>
                      <div className="price__dis">
                        <span>
                          <strike>₹450.00 net</strike>
                        </span>
                      </div>
                    </div>
                    <div className="product__btnn">
                      <button>BUY NOW</button>
                    </div>
                  </div>
                </div>
                <div className="productt py-4">
                  <div className="product__discount flex justify-between">
                    <div className="product__imgg">
                      <img
                        src={`${AppKeys.IMGURL}website/product-listing-images/desktop/produ-list-img-d8.webp`}
                        alt="img"
                      />
                    </div>
                    <div className="number__per grid place-content-center">
                      <span>-30%</span>
                    </div>
                    <div className="product__star grid place-content-center">
                      <i class="fa-regular fa-star"></i>
                    </div>
                  </div>
                  <div className="product__contentt px-4">
                    <div className="product__stock flex justify-between">
                      <div className="stock">
                        <i class="fa-solid fa-circle-check mr-2"></i>
                        <span>In stock </span>
                      </div>
                      <div className="share">
                        <button>
                          <i class="fa-solid fa-share-nodes"></i>
                        </button>
                      </div>
                    </div>
                    <div className="product__para my-2">
                      <span>Omnires | Part No. 2123532</span>
                    </div>
                    <div className="product__title">
                      <span>Connection with a handle Omnires round</span>
                    </div>
                    <div className="product__price flex justify-between items-center my-2">
                      <div className="price">
                        <div>
                          <span className="letters">Your price</span>
                        </div>
                        <div>
                          <span className="rupees">
                            ₹350.00 <span className="net">net</span>
                          </span>
                        </div>
                      </div>
                      <div className="price__dis">
                        <span>
                          <strike>₹450.00 net</strike>
                        </span>
                      </div>
                    </div>
                    <div className="product__btnn">
                      <button>BUY NOW</button>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </section>
        </div>

        <EmptyCategoryEmoji hidden={productsDatabyCategory.length !== 0} />
        {/* FOOTER  */}

        <Footer />
      </section>
    </div>
  );
});

export default ListingPage;
