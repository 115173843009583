import React from 'react'

const Rivaform = () => {
  return (
    <div>
        <section class="rivaForm pt-32">
            <div class="rivaForm__container container mx-auto flex flex-wrap justify-center">
                <div class="rivaForm__containerLeft w-full  xl:w-3/5 flex justify-center flex-wrap xl:justify-normal">
                    <div class="rivaForm__left">
                        <div class="rivaForm__leftImage flex justify-center lg:block">
                            <div class="rivaForm__leftImg">
                                <img src="/images/riva.webp" alt="img" />
                             </div>                
                        </div>
                      
                        <div class="rivaForm__leftDetail">
                            <div class="rivaForm__trade">
                                <div class="rivaForm__leftAddress">
                                    <div class="rivaForm-leftAddress__icon"><i class="fa-solid fa-location-dot"></i></div>
                                    <div class="rivaForm-leftAddress__content">
                                        <span>Address: 2nd Floor, 3rd Phase,Spencer Plaza, Chennai</span>
                                    </div>
                                </div>
                                <div class="rivaForm__leftContact">
                                    <div class="rivaForm__leftContact__icon"><i class="fa-solid fa-phone"></i></div>
                                    <div class="rivaForm__leftContact__content">
                                        <span>Call Us: 9042521580</span>
                                    </div>
                                </div>
                                <div class="rivaForm__leftEmail">
                                    <div class="rivaForm-leftEmail__icon"><i class="fa-solid fa-envelope"></i></div>
                                    <div class="rivaForm-leftEmail__content">
                                        <span>Email: abulovely@gmail.com</span>
                                    </div>
                                </div>
                                <div class="rivaForm__leftSocial-icons">
                                    <i class="fa-brands fa-facebook"></i> 
                                    <i class="fa-brands fa-linkedin"></i>
                                    <i class="fa-brands fa-instagram"></i>
                                    <i class="fa-brands fa-x-twitter"></i>              
                                 </div>                    
                            </div>
         
                            <div class="rivaForm__list">
                                <ul class="rivaForm-ul__list flex justify-center flex-wrap gap-x-6 lg:block">
                                    <li>Our Product</li>
                                    <li>About Us</li>
                                    <li>Contact Us</li>
                                    <li>Site Map</li>
                                    <li>Download Brochure</li>
                                </ul>
                            </div>
                          
                        </div>
                      
                        
                    </div>
                        
                </div>
         
                <div class="rivaForm__right w-full xl:w-2/5">
                    <div class="rivaForm-right__title text-center lg:text-start">
                        <span>Leave a Message, we will call you back!</span>
                    </div>
                    <label>Mobile Number</label>
                    <div class="rivaForm-right__mobile">
                        <input type="text" placeholder="Enter your Mobile Number" />
                    </div>
                    <label>Requirement Details</label>
                    <div class="rivaForm-right__detail">
                        <input type="text" placeholder="Additional details about your requirement" />
                    </div>
                    <button>SUBMIT</button>
                </div>
         
            </div>
        </section>
    </div>
  )
}

export default Rivaform