import React, { useEffect } from "react";
import "./nav.css";
import { useLocation, useNavigate } from "react-router-dom";
import { PK } from "../../../constants/PepperKeys";

const Nav = (props) => {
  const { count, setCount, pepper, setPepper, peppermint } = props;
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {}, [count, pepper]);
  return (
    <section className="nav__section w-full flex lg:justify-between items-center px-5 md:px-0">
      <nav className="container mx-auto w-full flex items-center justify-center lg:justify-between">
        <ul className="gap-x-4 xl:gap-x-5 2xl:gap-x-11 hidden lg:flex">
          {pepper[PK.CATEGORYINFODATA]
            ?.filter((cate) => cate?.is_featured != 0)
            .map((cate) => (
              <li>
                <a
                  href={() => {
                    return false;
                  }}
                  style={{ cursor: "pointer" }}
                  onClick={(event) => {
                    navigate("/category/" + cate.seourl, {
                      state: {
                        categoryinfo: cate,
                      },
                    });
                    window.location.reload();
                  }}
                >
                  {cate.name} <i class="fa-solid fa-chevron-down"></i>
                </a>
              </li>
            ))}
          {/* <li>
            <a href="/product">
              HOME & GARDEN <i class="fa-solid fa-chevron-down"></i>
            </a>
          </li>
          <li>
            <a href="/product">
              CLOTHING <i class="fa-solid fa-chevron-down"></i>
            </a>
          </li>
          <li>
            <a href="/product">ELECTRONICS</a>
          </li>
          <li>
            <a href="/product">OFFICE EQUIPMENT</a>
          </li>
          <li>
            <a href="/product">MACHINES</a>
          </li> */}
        </ul>
        <div className="search__box flex items-center">
          <i class="fa-solid fa-magnifying-glass mr-3"></i>
          <input type="text" placeholder="Search by product" />
        </div>
      </nav>
    </section>
  );
};

export default Nav;
