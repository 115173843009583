import React from 'react'

const Rivafooter = () => {

    const Year = new Date().getFullYear();

  return (
    <div>
       <section class="rivafooter">
            <div class="rivafooter__container container mx-auto flex justify-center items-center flex-wrap lg:justify-between gap-y-4 gap-x-10 lg:gap-x-0">
                <div class="rivafooter__rights">
                    <div>Riva Trading © { Year }, All rights reserved</div>
                    <div>Developed and Managed by HaodaMart</div>
                </div>
                <div class="rivafooter__containerImg">
                    <img src="/images/Payment.webp" alt="img" />
                </div>
                <div class="rivafooter__logo">
                    <img src="/images/Logo.webp" alt="img" />
                </div>
            </div>
       </section> 
    </div>
  )
}

export default Rivafooter