import React, { useState } from 'react'
import './SellerDashboard.css';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
const KYCDetails = () => {
  const initialValues = {
    pan: '',
    gst: '',
    tanNumber: '',
    file: null,
    file2: null,
    file3: null,
    file4: null,
    file5: null,
    file6: null,
    file7: null,
    file8: null,
  };
  const validationSchema = Yup.object({
    pan: Yup.string()
      .matches(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/, 'Invalid PAN format')
      .required('PAN is required'),
    gst: Yup.string().matches(/^d{15}$/, 'GST number requires 15 digits number').required('GST number is required'),
    tanNumber: Yup.string()
      .matches(/^([A-Z]){4}([0-9]){5}([A-Z]){1}?$/, 'Invalid TAN Number format')
      .required('TAN Number is required'),
    file: Yup.mixed()
      .test('file', 'Please select a file', (value) => value !== null)
      .required('File is required'),
    file2: Yup.mixed()
      .test('file', 'Please select a file', (value) => value !== null)
      .required('File is required'),
    file3: Yup.mixed()
      .test('file', 'Please select a file', (value) => value !== null)
      .required('File is required'),
    file4: Yup.mixed()
      .test('file', 'Please select a file', (value) => value !== null)
      .required('File is required'),
    file5: Yup.mixed()
      .test('file', 'Please select a file', (value) => value !== null)
      .required('File is required'),
    file6: Yup.mixed()
      .test('file', 'Please select a file', (value) => value !== null)
      .required('File is required'),
    file7: Yup.mixed()
      .test('file', 'Please select a file', (value) => value !== null)
      .required('File is required'),
    file8: Yup.mixed()
      .test('file', 'Please select a file', (value) => value !== null)
      .required('File is required'),

  });

  const onSubmit = (values) => {
    console.log('Form submitted with values:', values);
  };

  const displayFileName = (event, fieldNo) => {
    const fileInput = event.target;
    const fileNameDisplay = document.getElementById(`fileNameDisplay${fieldNo}`);

    if (fileInput.files.length > 0) {
      const fileName = fileInput.files[0].name;
      fileNameDisplay.innerHTML = fileName;
    } else {
      fileNameDisplay.innerHTML = 'No file chosen';
    }
  };
  return (
    <div className='container mx-auto bg-white pb-5 rounded-xl mt-10'>
      <div className='p-5'>
        <div className='text-blue-500 accounttxt'>KYC</div>
        <Formik
          initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
          <Form>
            <div className='sm:grid grid-col-1 xl:grid grid-cols-2 gap-10 mt-5'>
              <div class="relative w-full ">
                <Field
                  type="text"
                  name='pan'
                  className="w-full px-4 py-5  border border-gray-500 rounded-lg  duration-300 ease-out text-base outline-none"
                  id="myInput"
                  placeholder=" " />
                <ErrorMessage name='pan' component='p' className='text-red-500' />
                <label
                  className="absolute top-5 left-4 text-base text-gray-600 pointer-events-none  duration-300 ease-out accinputtxt"
                  for="myInput">
                  Individual PAN
                </label>
              </div>
              <div class=" mt-5 sm:mt-0 relative cursor-pointer border border-gray-500 rounded-lg text-base h-16 flex">
                <Field
                  type="file"
                  name="file"
                  id="fileInput1"
                  onChange={(e) => displayFileName(e, 1)}
                  className="hidden"
                  required />
                <ErrorMessage name='file' component='p' className='text-red-500' />
                <label className="absolute -mt-4 left-4 text-base text-gray-600 accinputtxt">AOA</label>
                <div id="fileNameDisplay1" className='absolute pt-5 p-5 w-5/6'></div>
                <div className='border border-l-black rounded-r-lg ml-auto  cursor-pointer'>
                  <label htmlFor="fileInput1" className='cursor-pointer choosefile h-full flex items-center w-28 sm:w-36 justify-center'>Choose File</label>
                </div>
              </div>
              <div className="mt-5 sm:mt-0 relative w-full ">
                <Field
                  type="text"
                  name="gst"
                  maxlength={15}
                  className="w-full px-4 py-5  border border-gray-500 rounded-lg  duration-300 ease-out text-base outline-none"
                  id="myInput"
                  placeholder=" " />
                <ErrorMessage name='gst' component='p' className='text-red-500' />
                <label className="absolute top-5 left-4 text-base text-gray-600 pointer-events-none  duration-300 ease-out accinputtxt" for="myInput">GST Number</label>
              </div>
              <div class=" mt-5 sm:mt-0 relative cursor-pointer border border-gray-500 rounded-lg text-base h-16 flex">
                <Field
                  type="file"
                  name="file2"
                  id="fileInput2"
                  onChange={(e) => displayFileName(e, 2)}
                  className="hidden" />
                <ErrorMessage name='file2' component='p' className='text-red-500' />
                <label className="absolute  -mt-4 left-4 text-base text-gray-600 accinputtxt">Declaration</label>
                <div id="fileNameDisplay2" className='absolute pt-5 p-5 w-5/6'></div>
                <div className='border border-l-black rounded-r-lg ml-auto  cursor-pointer'>
                  <label htmlFor="fileInput2" className='cursor-pointer choosefile h-full flex items-center w-28 sm:w-36 justify-center'>Choose File</label>
                </div>
              </div>
              <div className="mt-5 sm:mt-0 relative w-full ">
                <Field
                  type="text"
                  name='tanNumber'
                  className="w-full px-4 py-5  border border-gray-500 rounded-lg  duration-300 ease-out text-base outline-none"
                  id="myInput"
                  placeholder="" />
                <ErrorMessage name='tanNumber' component='p' className='text-red-500' />
                <label className="absolute top-5 left-4 text-base text-gray-600 pointer-events-none  duration-300 ease-out accinputtxt" for="myInput">TAN Number </label>
              </div>
              <div class=" mt-5 sm:mt-0 relative cursor-pointer border border-gray-500 rounded-lg text-base h-16 flex">
                <Field
                  type="file"
                  name="file3"
                  id="fileInput3"
                  onChange={(e) => displayFileName(e, 3)}
                  className="hidden"
                />
                <ErrorMessage name='file3' component='p' className='text-red-500' />
                <label className="absolute  -mt-4 left-4 text-base text-gray-600 accinputtxt">Director KYC</label>
                <div id="fileNameDisplay3" className='absolute pt-5 p-5 w-5/6'></div>
                <div className='border border-l-black rounded-r-lg ml-auto  cursor-pointer'>
                  <label htmlFor="fileInput3" className='cursor-pointer choosefile h-full flex items-center w-28 sm:w-36 justify-center'>Choose File</label>
                </div>
              </div>
              <div class="mt-5 sm:mt-0 relative cursor-pointer border border-gray-500 rounded-lg text-base h-16 flex">
                <Field
                  type="file"
                  name="file4"
                  id="fileInput4"
                  onChange={(e) => displayFileName(e, 4)}
                  className="hidden"
                />
                <ErrorMessage name='file4' component='p' className='text-red-500' />
                <label className="absolute  -mt-4 left-4 text-base text-gray-600 accinputtxt">MOA</label>
                <div id="fileNameDisplay4" className='absolute pt-5 p-5 w-5/6'></div>
                <div className='border border-l-black rounded-r-lg ml-auto  cursor-pointer'>
                  <label htmlFor="fileInput4" className='cursor-pointer choosefile h-full flex items-center w-28 sm:w-36 justify-center'>Choose File</label>
                </div>
              </div>
              <div class="mt-5 sm:mt-0 relative  cursor-pointer border border-gray-500 rounded-lg text-base h-16 flex">
                <Field
                  type="file"
                  name="file5"
                  id="fileInput5"
                  onChange={(e) => displayFileName(e, 5)}
                  className="hidden"
                />
                <ErrorMessage name='file5' component='p' className='text-red-500' />
                <label className="absolute  -mt-4 left-4 text-base text-gray-600 accinputtxt">Attach Individual PAN</label>
                <div id="fileNameDisplay5" className='absolute pt-5 p-5 w-5/6'></div>
                <div className='border border-l-black rounded-r-lg ml-auto  cursor-pointer'>
                  <label htmlFor="fileInput5" className='cursor-pointer choosefile h-full flex items-center w-28 sm:w-36 justify-center'>Choose File</label>
                </div>
              </div>
              <div class="mt-5 sm:mt-0 relative cursor-pointer border border-gray-500 rounded-lg text-base h-16 flex">
                <Field
                  type="file"
                  name='file6'
                  id="fileInput6"
                  onChange={(e) => displayFileName(e, 6)}
                  className="hidden"
                />
                <ErrorMessage name='file6' component='p' className='text-red-500' />
                <label className="absolute  -mt-4 left-4 text-base text-gray-600 accinputtxt">Attach GST file</label>
                <div id="fileNameDisplay6" className='absolute pt-5 p-5 w-5/6'></div>
                <div className='border border-l-black rounded-r-lg ml-auto  cursor-pointer'>
                  <label htmlFor="fileInput6" className='cursor-pointer choosefile h-full flex items-center w-28 sm:w-36 justify-center'>Choose File</label>
                </div>
              </div>
              <div class="mt-5 sm:mt-0 relative cursor-pointer border border-gray-500 rounded-lg text-base h-16 flex">
                <input
                  type="file"
                  name='file7'
                  id="fileInput7"
                  onChange={(e) => displayFileName(e, 7)}
                  className="hidden"
                />
                <ErrorMessage name='file7' component='p' className='text-red-500' />
                <label className="absolute  -mt-4 left-4 text-base text-gray-600 accinputtxt">Attach Cancelled Cheque</label>
                <div id="fileNameDisplay7" className='absolute pt-5 p-5 w-5/6'></div>
                <div className='border border-l-black rounded-r-lg ml-auto  cursor-pointer'>
                  <label htmlFor="fileInput7" className='cursor-pointer choosefile h-full flex items-center w-28 sm:w-36 justify-center'>Choose File</label>
                </div>
              </div>
              <div class="mt-5 sm:mt-0 relative cursor-pointer border border-gray-500 rounded-lg text-base h-16 flex">
                <Field
                  type="file"
                  name="file8"
                  id="fileInput8"
                  onChange={(e) => displayFileName(e, 8)}
                  className="hidden"
                />
                <ErrorMessage name='file8' component='p' className='text-red-500' />
                <label className="absolute  -mt-4 left-4 text-base text-gray-600 accinputtxt">Attach Aadhar file</label>
                <div id="fileNameDisplay8" className='absolute pt-5 p-5 w-5/6'></div>
                <div className='border border-l-black rounded-r-lg ml-auto  cursor-pointer'>
                  <label htmlFor="fileInput8" className='cursor-pointer choosefile h-full flex items-center w-28 sm:w-36 justify-center'>Choose File</label>
                </div>
              </div>
              <div className='mt-5 sm:mt-0 bg-blue-500 w-full lg:w-56 lg:ml-auto  p-5 rounded-md xl:ml-auto 2xl:ml-[68%] '>
                <button className='text-base savebtn text-white w-full h-full'>Save</button>
              </div>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  )
}

export default KYCDetails
