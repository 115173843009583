import React, { useState } from 'react'
import { MdEdit } from "react-icons/md";
import "./accountInfom.css"


const AccountInform = () => {

    const [isBottomBoxOpen, setIsBottomBoxOpen] = useState(false);

    const handleBoxClick = () => {
      setIsBottomBoxOpen(!isBottomBoxOpen);
    };

    
  return (
    <div>
        <div className='accountInform__container'>
            <div className='accountInform__header'>
                <div className='accountInform__title'>
                   <span>Account Information</span>
                </div>
                <div>
                  <MdEdit className='accountInform__editIcon'/>
                </div>
            </div>
            <div className='accountInform__form'>
                <div className='mt-5'>
                    <span className='accountInform__formName'>Name :</span><span className='accountInform__nameBold'> John </span>
                </div>
                <div className='my-2'>
                    <span className='accountInform__formName'>Surname :</span><span className='accountInform__nameBold'> Doe</span>
                </div>
                <div>
                    <span className='accountInform__formName'>Login/e-mail :</span><span className='accountInform__nameBold'> johndoe@gmail.com</span>
                </div>
                <div className='my-2'>
                    <span className='accountInform__formName'>Phone :</span><span className='accountInform__nameBold'> +48 665 876 123</span>
                </div>
                <div className='accountInform__password'>
                    <div className='accountInform__passChange my-2'>
                        <span>Password :</span> <span onClick={handleBoxClick} className='passChange__color'>change password</span>
                    </div>
                    {isBottomBoxOpen && (
                    <div>
                       <form className='passChange__form'>
                           <div className='passChange__input'>
                               <label className='mb-2'>Old password</label>
                               <input type='password'/>
                           </div>
                           <div className='passChange__input my-2'>
                               <label className='mb-2'>New password</label>
                               <input type='password'/>
                           </div>
                           <div className='passChange__input'>
                               <label className='mb-2'>Repeat new password</label>
                               <input type='password'/>
                           </div>
                           <div className='passChange__btn flex justify-center gap-x-10 items-center flex-cols w-full mt-8'>
                               <button className='passChange__cancel'>Cancel</button>
                               <button className='passChange__change'>Change Password</button>
                           </div>
                       </form>
                    </div>
                    )}
                </div>
            </div>
        </div>
    </div>
  )
}

export default AccountInform