import React, { useState } from "react";
import { Stepper, Step, StepLabel, Button } from "@mui/material";
import Billing from "./billing/billing";
import Payment from "./payment/payment";
import Order from "./order/order";
import "../checkout/billing/billing.css";
import { IoArrowForward } from "react-icons/io5";
import { IoArrowBack } from "react-icons/io5";
import { AppKeys } from "../../constants/AppKeys";
import { AxiosPost } from "../../utilities/axioscall";

const LinearStepper = () => {
  const [activeStep, setActiveStep] = useState(0);

  // const handleNext = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
  // };

  const handleNext = () => {
    const isStepValid = validateStep();

    if (isStepValid) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  function getSteps() {
    return ["Welcome", "Payment", "Order"];
  }

  const steps = getSteps();

  // const isStepOptional = (step) => {
  //   return step === 1 || step === 2;
  // };

  // const isStepSkipped = (step) => {
  //   return skippedSteps.includes(step);
  // };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const [values, setValues] = useState({
    step1Field1: "",
    step1Field2: "",
    step1Field3: "",
    step1Field4: "",
    step1Field5: "",
    step1Field6: "",
    step1Field7: "",
    step1Field8: "",

    step2Field1: "",
  });

  const [errors, setErrors] = useState({
    step1Field1: "",
    step1Field2: "",
    step1Field3: "",
    step1Field4: "",
    step1Field4: "",
    step1Field5: "",
    step1Field6: "",
    step1Field7: "",
    step1Field8: "",

    step2Field1: "",
  });

  const validateStep1 = () => {
    let isValid = true;

    const newErrors = {
      step1Field1: "",
      step1Field2: "",
      step1Field3: "",
      step1Field4: "",
      step1Field5: "",
      step1Field6: "",
      step1Field7: "",
      step1Field8: "",
    };

    // Validation logic for Step 1 fields
    if (!values.step1Field1.trim()) {
      isValid = false;
      newErrors.step1Field1 = "First name required";
    } else if (!/^[a-zA-Z0-9]+$/.test(values.step1Field1)) {
      newErrors.step1Field1 = "Invalid name";
    }

    if (!values.step1Field2.trim()) {
      isValid = false;
      newErrors.step1Field2 = "Last name required";
    } else if (!/^[a-zA-Z0-9]+$/.test(values.step1Field2)) {
      newErrors.step1Field2 = "Invalid name";
    }

    if (!values.step1Field3.trim()) {
      isValid = false;
      newErrors.step1Field3 = "Email is required";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.step1Field3)) {
      newErrors.step1Field3 = "Invalid email address";
    }

    if (!values.step1Field4.trim()) {
      isValid = false;
      newErrors.step1Field4 = "Address is required";
    } else if (values.step1Field4.length < 3) {
      newErrors.step1Field4 = "Minimum 3 words required";
    }

    if (!values.step1Field5.trim()) {
      isValid = false;
      newErrors.step1Field5 = "City  is required";
    } 

    
    if (!values.step1Field6.trim()) {
      isValid = false;
      newErrors.step1Field6 = "State is required";
    }

    if (!values.step1Field7.trim()) {
      isValid = false;
      newErrors.step1Field7 = "Postal code required";
    }
    // }else if (!/^\d+$/.test(values.step1Field7)) {
    //   newErrors.step1Field7 = "Please Enter Number Only";
    // }

    if (!values.step1Field8.trim()) {
      isValid = false;
      newErrors.step1Field8 = "Number is required";
    } else if (!/^\d+$/.test(values.step1Field8)) {
      newErrors.step1Field8 = "Please Enter Number Only";
    }

    setErrors(newErrors);
    return isValid;
  };

  const validateStep2 = () => {
    let isValid = true;

    const newErrors = {
      step2Field1: "",
    };

    //if (!values.step2Field1.trim()) {
    //isValid = false;
    //newErrors.step2Field1 = "Field is required";
    //}

    setErrors(newErrors);
    return isValid;
  };

  const validateStep3 = () => {};

  const validateStep = () => {
    switch (activeStep) {
      case 0:
        return validateStep1();
      case 1:
        return validateStep2();
      case 2:
        return validateStep3();

      default:
        return true;
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleCreateOrder = async () => {
    console.log("createorder");
    let _errors = {};
    let _success = {};
    let fields = {
      payment_method: 1,
      first_name: "John",
      last_name: "Doe",
      city: "Sample City",
      state: 3,
      pincode: "123456",
      address: "123 Main St",
      locality: "Sample Locality",
      email: "john.doe@example.com",
      mobile: "1234567890",
    };
    //Strong@Password123\",\r\n    \"username\": \"Test.Test@example.com
    await AxiosPost(AppKeys.CREATEORDERAPI, fields, true)
      .then((resp) => {
        if (resp != typeof undefined && resp.data != typeof undefined) {
          window.location.href = resp.data.payment_link;
        }
      })
      .catch((errresp) => {});
  };

  return (
    <div className="container mx-auto flex justify-center items-center flex-col">
      <div className="MuiContainer-maxWidthLg my-10">
        <Stepper activeStep={activeStep} alternativeLabel>
          <Step key="Step 1">
            <StepLabel>Welcome</StepLabel>
          </Step>
          <Step key="Step 2">
            <StepLabel>Payment</StepLabel>
          </Step>
          <Step key="Step 3">
            <StepLabel>Order</StepLabel>
          </Step>
        </Stepper>
      </div>

      <form className="billing__container p-8">
        <div>
          {activeStep === 0 && (
            <Billing
              values={values}
              handleChange={handleChange}
              errors={errors}
            />
          )}
          {activeStep === 1 && (
            <Payment
              values={values}
              handleChange={handleChange}
              errors={errors}
            />
          )}
          {activeStep === 2 && <Order handleCreateOrder={handleCreateOrder} />}
        </div>

        <div className="MuiContainer-maxWidthLg flex justify-center items-center gap-x-10 my-8">
          {activeStep > 0 && (
            <Button onClick={handleBack} className="checkBack__btn">
              <IoArrowBack className="ml-3" />
              Back
            </Button>
          )}
          {activeStep < 1 && (
            <Button onClick={handleNext} className="checkNext__btn">
              Next
              <IoArrowForward className="ml-3" />
            </Button>
          )}
          {activeStep === 1 && (
            <Button onClick={handleNext} className="checkNext__btn">
              Next
              <IoArrowForward className="ml-3" />
            </Button>
          )}
        </div>
      </form>
    </div>
  );
};

export default LinearStepper;
