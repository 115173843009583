import React from 'react'
import "./productTab.css"

const AboutProductTab = () => {
  return (
    <div className='aboutProductTab__container p-10'>
        <div className='aboutProductTab__title'>
            <span>Are you curious about the product? Do you have question about using it? Ask us!</span>
        </div>
        <form>
            <div className='aboutProductTab__input mt-10'>
                <label className='mb-3'>Name</label>
                <input type='text'/>
            </div>
            <div className='aboutProductTab__input my-5'>
                <label className='mb-3'>E-mail</label>
                <input type='email'/>
            </div>
            <div className='aboutProductTab__inputReq'>
                <label className='mb-3'>Your request</label>
                <input type='text'/>
            </div>
        </form>
        <div className='aboutProductTab__btn w-full grid place-content-center mt-9'>
            <button>Sent request</button>
        </div>
    </div>
  )
}

export default AboutProductTab