export const PK = Object.freeze({
  CARTINFODATA: "cartinfoData",
  ADDTOCART: "addtoCart",
  REMOVECART: "removeCart",
  CACHEDATAREAD: "cachedataRead",
  CACHEDATAWRITE: "cachedataWrite",
  CACHEDATAWRITECARTITEMS: "cachedataWritecartitems",
  CACHEDATAREMOVECARTITEMS: "cachedataRemovecartitems",
  LOCALSERVERCACHECARTITEMSMERGE: "localservercachecartitemsMerge",
  LOCALCACHEDATAREAD: "localcachedataRead",
  LOCALCACHEDATACARTITEMSEMPTY: "localcachedatacartitemsEmpty",
  CATEGORYINFODATA: "categoryinfoData",
  PRODUCTSINFODATA: "productsinfoData",
  SIZEINFODATA: "sizeinfoData",
  PRODUCTSSIZEINFODATA: "productssizeinfoData",
  PRODUCTSCOLORCODESINFODATA: "productscolorcodeinfoData",
  COLORCODESINFODATA: "colorcodeinfoData",
  VERSIONMANAGERINFODATA: "versionmanagerinfoData",
  VERSIONINFODATA: "versioninfoData",
  STORESMANAGERINFODATA: "storesmanagerinfoData",
  DELIVERYCONTENTINFODATA: "deliverycontentinfoData",
  checkProductInCartData: "checkProductInCartData",
  isUserLoggedin: "isUserLoggedin",
});
