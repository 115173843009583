import React, { memo, useState } from "react";
import {  useNavigate, Link } from "react-router-dom";
import "./Register.css";
import { AppKeys } from "../../constants/AppKeys";
import { Button } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { AxiosPost } from "../../utilities/axioscall";
import { setUser } from "../../store/user/actions";
import Loader from "../loader/loader";


const Register = memo(() => {


  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState({});
  const [loader, setLoader] = useState(false);

  const [fieldsName, SetFieldsName] = useState({
    username : "",
    emailormobilenumber: "username",
    password: "password",
    errorresponse: "errorresponse",
    successresponse: "successresponse",
  });
  
  let navigate = useNavigate();
  const handleChange = (field, e) => {
    fields[field] = e.target.value;
    setFields(fields);
  };

  const handleValidation = () => {
    let formIsValid = true;
    let _errors = {};
     
    if (!fields[fieldsName.username]) {
      formIsValid = false;
      _errors[fieldsName.username] = "Name field is required";
    }

    if (!fields[fieldsName.emailormobilenumber]) {
      formIsValid = false;
      _errors[fieldsName.emailormobilenumber] = "Email or Mobile Number is required";
    } 
    if (!fields[fieldsName.password]) {
      formIsValid = false;
      _errors[fieldsName.password] = "Password field is required.";
    }else if (fieldsName.password.length < 6) {
      formIsValid = false;
      _errors[fields.password] = "Password must be at least 6 characters"
    }

    setErrors(_errors);

    return formIsValid;
  };

  let dispatch = useDispatch();

  const handleSubmit = () => {
    console.log(errors);
    if (handleValidation()) {

      setLoader(true); 

      let _errors = {};
      let _success = {};

      //Strong@Password123\",\r\n    \"username\": \"Test.Test@example.com
      AxiosPost(AppKeys.LOGINAPI, fields, null, false)
        .then((resp) => {
          if (resp != typeof undefined && resp.data != typeof undefined) {
            let userdata = JSON.parse(JSON.stringify(resp.data));
            dispatch(setUser(userdata));
            navigate("/");
          }
        })
        .catch((errresp) => {
          _errors[fieldsName.errorresponse] =
            "These credentials do not match our records.";
          setErrors(_errors);
        }).finally(() => {
          setTimeout(() => {
            setLoader(false); 
          },[2000])
        });
    }
  };

  const date = new Date();

  return (
    <div className="bg-white container mx-auto flex items-center lg:justify-center xl:justify-between w-full xl:h-screen px-5 lg:px-0">
      <div className=" w-full lg:w-1/2 xl:w-4/12	flex  flex-col justify-center items-center mt-20 lg:mt-0">
        <form className="">
            <div className="welcome text-xl lg:text-3xl">
              Register your Account 👋
            </div>
            <div className="mt-10">
              <label className="email text-base">
               Name
              </label>
            </div>
            <input
              type="text"
              id="name"

              name="name"
              onChange={(e) => handleChange(fieldsName.username, e)}
              placeholder="Enter your name"
              className="emailbox mt-5 w-full  outline-none pt-1 pl-3 emailplaceholder bg-transparent text-base"
            />
            <span className="block text-red-500">
              {errors[fieldsName.emailormobilenumber]}
            </span>


          <div className="mt-10">
            <label className="email text-base">
              Email or Mobile Number
            </label>
          </div>
          <input
            className=" emailbox mt-5 pt-1 p-1 text-start md:text-start lg:text-start xl:text-start md:w-full outline-none  bg-transparent pr-5 pl-3 emailplaceholder"
            type="text"
            onChange={(e) => handleChange(fieldsName.emailormobilenumber, e)}
            placeholder="Example@email.com"
          />
          <span className="block text-red-500">
            {errors[fieldsName.emailormobilenumber]}
          </span>
          <span className="block text-red-500">
            {errors[fieldsName.errorresponse]}
          </span>


          <div className="mt-5">
            <label className="password">
              Password
            </label>
          </div>
          <input
            className="passwordbox mt-5 p-1 w-full md:w-full outline-none  bg-transparent passwordplaceholder pt-3 pl-3"
            type="password"
            id="password"
            name="password"
            placeholder="Enter your password"
            onChange={(e) => handleChange(fieldsName.password, e)}
          />
          <span className="block text-red-500">
            {errors[fieldsName.password]}
          </span>


          <div className="signinbox outline-none  cursor-pointer mt-5">
            <Button
              className="w-full signintext text-center text-base"
              type="button"
              onClick={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              Sign Up
            </Button>

          </div>

          { loader && <Loader /> }

            <div className="signupbox text-center mt-5 text-base">
              Already you have an account?{" "}
              <span className="signup text-base">
               <Link to={"/login"}>Sign in</Link> 
              </span>
            </div>
        </form>

        <div className="copyrights mt-32 text-center text-base">
          © {date.getFullYear()} ALL RIGHTS RESERVED
        </div>
      </div>
      <div className="hidden lg:block lg:w-1/2 xl:w-8/12	grid place-content-end">
        <div className="background__login">
           <img
             src={`${AppKeys.IMGURL}website/landing-images/login.webp`}
             alt="img"
           />
        </div>
      </div>
    </div>
  );
});

export default Register;
