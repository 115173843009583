import React from 'react'
import "./productTab.css"

const TechnicalTab = () => {
  return (
    <div className='technical__contanier p-10 flex justify-between items-center flex-wrap md:flex-nowrap'>
      <div className='technical__left w-full md:w-2/5'>
        <div className='parameter__row flex justify-between items-center px-5'>
          <div className='parameter1'><span>Parameter 1 </span></div>
          <div className='parameter2'><span>Parameter 1 </span></div>
        </div>
        <div className='parameter__rowColor flex justify-between items-center px-5'>
          <div className='parameter1'><span>Parameter 1 </span></div>
          <div className='parameter2'><span>Parameter 1 </span></div>
        </div>
        <div className='parameter__row flex justify-between items-center px-5'>
          <div className='parameter1'><span>Parameter 1 </span></div>
          <div className='parameter2'><span>Parameter 1 </span></div>
        </div>
        <div className='parameter__rowColor flex justify-between items-center px-5'>
          <div className='parameter1'><span>Parameter 1 </span></div>
          <div className='parameter2'><span>Parameter 1 </span></div>
        </div>
      </div>

      <div className='technical__right w-full md:w-2/5 mt-10 md:mt-0'>
        <div className='parameter__row flex justify-between items-center px-5'>
          <div className='parameter1'><span>Parameter 1 </span></div>
          <div className='parameter2'><span>Parameter 1 </span></div>
        </div>
        <div className='parameter__rowColor flex justify-between items-center px-5'>
          <div className='parameter1'><span>Parameter 1 </span></div>
          <div className='parameter2'><span>Parameter 1 </span></div>
        </div>
        <div className='parameter__row flex justify-between items-center px-5'>
          <div className='parameter1'><span>Parameter 1 </span></div>
          <div className='parameter2'><span>Parameter 1 </span></div>
        </div>
        <div className='parameter__rowColor flex justify-between items-center px-5 '>
          <div className='parameter1'><span>Parameter 1 </span></div>
          <div className='parameter2'><span>Parameter 1 </span></div>
        </div>
      </div>
    </div>
  )
}

export default TechnicalTab