import React from 'react'
import "./loader.css"

const Loader = () => {
  return (
    <div className="loader__modal">
       <div className="loader"></div>
    </div>  
  )
}

export default Loader