import React, { useState } from 'react';
import "./productTab.css"
import DescriptionTab from './descriptionTab';
import TechnicalTab from './technicalTab';
import ReviewTab from './reviewTab';
import AboutProductTab from './aboutProductTab';
import { IoIosArrowDown } from "react-icons/io";
import { IoIosStar } from "react-icons/io";
import { MdAttachFile } from "react-icons/md";
import { BiDislike } from "react-icons/bi";
import { AiOutlineLike } from "react-icons/ai";


const ProductTab = () => {

    const [activeTab, setActiveTab] = useState(0);

    const handleTabChange = (index) => {
      setActiveTab(index);
    };
  
    const handleNextTab = () => {
      setActiveTab(activeTab + 1);
    };
  
    const [isBottomBoxOpen, setIsBottomBoxOpen] = useState(false);
    const [rotateIcon, setRotateIcon] = useState(false);
  
    const [isBottomBoxOpen2, setIsBottomBoxOpen2] = useState(false);
    const [rotateIcon2, setRotateIcon2] = useState(false);
  
    const [isBottomBoxOpen3, setIsBottomBoxOpen3] = useState(false);
    const [rotateIcon3, setRotateIcon3] = useState(false);
    
    const [isBottomBoxOpen4, setIsBottomBoxOpen4] = useState(false);
    const [rotateIcon4, setRotateIcon4] = useState(false);
  
  
    const handleBoxClick = () => {
      setIsBottomBoxOpen(!isBottomBoxOpen);
      setRotateIcon(!rotateIcon);
    };
  
    const handleBoxClick2 = () => {
      setIsBottomBoxOpen2(!isBottomBoxOpen2);
      setRotateIcon2(!rotateIcon2);
    };
  
    const handleBoxClick3 = () => {
      setIsBottomBoxOpen3(!isBottomBoxOpen3);
      setRotateIcon3(!rotateIcon3);
    };
  
    const handleBoxClick4 = () => {
      setIsBottomBoxOpen4(!isBottomBoxOpen4);
      setRotateIcon4(!rotateIcon4);
    };
  


  return (
    <div className='container mx-auto mb-24 sm:grid place-content-center'>
      <div className='productTab__container grid place-content-center w-full hidden sm:block'>
        <div className="productTab__navigation flex gap-x-4">
            <button className='description__Tab' onClick={() => handleTabChange(0)} style={{ backgroundColor: activeTab === 0 ? '#1071FF' : '#F6F8FB', color: activeTab === 0 ? '#fff' : '#0C0C0C',}}>
                Description
            </button>
            <button className='technical__Tab' onClick={() => handleTabChange(1)} style={{ backgroundColor: activeTab === 1 ? '#1071FF' : '#F6F8FB', color: activeTab === 1 ? '#fff' : '#0C0C0C',}}>
                Technical Details
            </button>
            <button className='review__Tab' onClick={() => handleTabChange(2)} style={{ backgroundColor: activeTab === 2 ? '#1071FF' : '#F6F8FB', color: activeTab === 2 ? '#fff' : '#0C0C0C',}}>
                Reviews (0)
            </button>
            <button className='aboutProduct__Tab' onClick={() => handleTabChange(3)} style={{ backgroundColor: activeTab === 3 ? '#1071FF' : '#F6F8FB', color: activeTab === 3 ? '#fff' : '#0C0C0C',}}>
                Ask about product
            </button>
        </div>
      </div>
      <div className='flex justify-center items-center hidden sm:block'>

        <div className="productTab__content p-6">
          {activeTab === 0 && <div onNextTab={handleNextTab}><DescriptionTab /></div>}
          {activeTab === 1 && <div onNextTab={handleNextTab}><TechnicalTab /></div>}
          {activeTab === 2 && <div onNextTab={handleNextTab}><ReviewTab /></div>}
          {activeTab === 3 && <div onNextTab={handleNextTab}><AboutProductTab /></div>}
        </div>

      </div>





      <div className='sm:hidden px-5'>
      <div className='productMobile__container'>
        <div onClick={handleBoxClick} className='productMobile__desContent flex items-center justify-between'>
             Description
             <IoIosArrowDown           style={{
            transform: rotateIcon ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: 'transform 0.3s ease',
          }}
/>
         </div>
      {isBottomBoxOpen && (
        <div className='desContent p-4'>
          <div className='desConent__para'>
            <span>Ecommerce, also known as electronic commerce or internet commerce, refers to the buying and selling of goods or services using the internet, and the transfer of money and data to execute these transactions. Ecommerce, also known as electronic commerce or internet commerce, refers to the buying and selling of goods or services using the internet, and the transfer of money and data to execute these transactions.</span>
          </div>
          <div className='desConent__para mt-10'>
            <span>Ecommerce, also known as electronic commerce or internet commerce, refers to the buying and selling of goods or services using the internet, and the transfer of money and data to execute these transactions. Ecommerce, also known as electronic commerce or internet commerce, refers to the buying and selling of goods or services using the internet, and the transfer of money and data to execute these transactions.</span>
          </div>
          <div className='grid place-content-center mt-10'>
            <div className='desContent__detail p-4 '>
            <div className='desContent__detailTitle'>
                <div>Details:</div>
            </div>
            <div className='desContent__detailList'>
                <li>EAN</li>
                <li>Color</li>
                <li>Atribute 3</li>
                <li>Atribute 4</li>
                <li>Atribute 6</li>
            </div>
            </div>
          </div>
        </div>
      )}
     </div>



     <div className='productMobile__container'>
        <div onClick={handleBoxClick2} className='productMobile__desContent flex items-center justify-between'>
            Technical Details
             <IoIosArrowDown           style={{
            transform: rotateIcon2 ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: 'transform 0.3s ease',
          }}
/>
         </div>
      {isBottomBoxOpen2 && (
        <div className='desContent px-10 py-5'>
           <div className='tech__Content flex justify-between'>
               <div className='techTitle'>Parameter 1 </div>
               <div className='techTitle__dark'>Parameter’s value</div>
           </div>
           <div className='tech__titleContent flex justify-between'>
               <div className='techTitle'>Parameter 1 </div>
               <div className='techTitle__dark'>Parameter’s value</div>
           </div>        
           <div className='tech__Content flex justify-between'>
               <div className='techTitle'>Parameter 1 </div>
               <div className='techTitle__dark'>Parameter’s value</div>
           </div>
           <div className='tech__titleContent flex justify-between'>
               <div className='techTitle'>Parameter 1 </div>
               <div className='techTitle__dark'>Parameter’s value</div>
           </div>  
           <div className='tech__Content flex justify-between'>
               <div className='techTitle'>Parameter 1 </div>
               <div className='techTitle__dark'>Parameter’s value</div>
           </div>
           <div className='tech__titleContent flex justify-between'>
               <div className='techTitle'>Parameter 1 </div>
               <div className='techTitle__dark'>Parameter’s value</div>
           </div>  
        </div>
      )}
     </div>



     <div className='productMobile__container'>
        <div onClick={handleBoxClick3} className='productMobile__desContent flex items-center justify-between'>
            Reviews (18)
             <IoIosArrowDown           style={{
            transform: rotateIcon3 ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: 'transform 0.3s ease',
          }}
/>
         </div>
      {isBottomBoxOpen3 && (
        <div className='desContent px-10 py-5'>
           <div className='reviewTab__content w-full'>
            <div className='reviewTab__title'>
                <span>Opinions (18)</span>
            </div>
            <div className='reviewTab__Rating my-2'>
                <span>Overall rating</span>
            </div>
            <div className='reviewTab__star flex items-center gap-x-2'>
               <IoIosStar className='reviewTab__IosStar'/>
               <IoIosStar className='reviewTab__IosStar'/>
               <IoIosStar className='reviewTab__IosStar'/>
               <IoIosStar className='reviewTab__IosStar'/>
               <IoIosStar className='reviewTab__IosStar'/>
               <span className='text-sm	font-semibold'>5.0</span>
            </div>

            <div className='reviewTab__detail'>
                <div className='reviewTab__reviews p-6 flex flex-col justify-between mt-3'>
                    <div className='reviewTab__userDetail flex justify-between'>
                        <div className='reviewTab__userName'>
                            <span>User 1</span>
                        </div>
                        <div className='reviewTab__star flex items-center gap-x-2'>
                           <IoIosStar className='reviewTab__IosStar'/>
                           <IoIosStar className='reviewTab__IosStar'/>
                           <IoIosStar className='reviewTab__IosStar'/>
                           <IoIosStar className='reviewTab__IosStar'/>
                           <IoIosStar className='reviewTab__IosStar'/>
                           <span className='text-sm	font-semibold'>5.0</span>
                        </div>
                    </div>
                    <div className='reviews__date'>
                        <span>3th January 2020</span>
                    </div>
                    <div className='reviews__para'>
                        <span>Produkt jest świetny. Ze sklepu postanowiłem skorzystać ze względu na bardzo atrakcyjną cenę i duży wybór produktów. To był mój pierwszy i na pewno nie ostatni zakup u Was.</span>
                    </div>
                    <div className='reviewTab__reviewsFile flex items-center gap-x-2'>
                       <MdAttachFile className='like'/>1 file attached
                    </div>
                    <div className='reviews__comments flex items-center justify-between'>
                        <div className='reviews__addComment'>
                           Add comment
                        </div>
                        <div className='comment__icons flex items-center gap-x-4'>
                            <span className='flex items-center'><BiDislike className='like mr-2'/>5</span>
                            <span className='flex items-center'><AiOutlineLike className='like mr-2'/>114</span>
                        </div>
                    </div>
                </div>

                <div className='reviewTab__reviews p-6 flex flex-col justify-between mt-3'>
                    <div className='reviewTab__userDetail flex justify-between'>
                        <div className='reviewTab__userName'>
                            <span>User 1</span>
                        </div>
                        <div className='reviewTab__star flex items-center gap-x-2'>
                           <IoIosStar className='reviewTab__IosStar'/>
                           <IoIosStar className='reviewTab__IosStar'/>
                           <IoIosStar className='reviewTab__IosStar'/>
                           <IoIosStar className='reviewTab__IosStar'/>
                           <IoIosStar className='reviewTab__IosStar'/>
                           <span className='text-sm	font-semibold'>5.0</span>
                        </div>
                    </div>
                    <div className='reviews__date'>
                        <span>3th January 2020</span>
                    </div>
                    <div className='reviews__para'>
                        <span>Produkt jest świetny. Ze sklepu postanowiłem skorzystać ze względu na bardzo atrakcyjną cenę i duży wybór produktów. To był mój pierwszy i na pewno nie ostatni zakup u Was.</span>
                    </div>
                    <div className='reviewTab__reviewsFile flex items-center gap-x-2'>
                       <MdAttachFile className='like'/>1 file attached
                    </div>
                    <div className='reviews__comments flex items-center justify-between'>
                        <div className='reviews__addComment'>
                           Add comment
                        </div>
                        <div className='comment__icons flex items-center gap-x-4'>
                            <span className='flex items-center'><BiDislike className='like mr-2'/>5</span>
                            <span className='flex items-center'><AiOutlineLike className='like mr-2'/>114</span>
                        </div>
                    </div>
                </div>
                <div className='reviewTab__reviews p-6 flex flex-col justify-between mt-3'>
                    <div className='reviewTab__userDetail flex justify-between'>
                        <div className='reviewTab__userName'>
                            <span>User 1</span>
                        </div>
                        <div className='reviewTab__star flex items-center gap-x-2'>
                           <IoIosStar className='reviewTab__IosStar'/>
                           <IoIosStar className='reviewTab__IosStar'/>
                           <IoIosStar className='reviewTab__IosStar'/>
                           <IoIosStar className='reviewTab__IosStar'/>
                           <IoIosStar className='reviewTab__IosStar'/>
                           <span className='text-sm	font-semibold'>5.0</span>
                        </div>
                    </div>
                    <div className='reviews__date'>
                        <span>3th January 2020</span>
                    </div>
                    <div className='reviews__para'>
                        <span>Produkt jest świetny. Ze sklepu postanowiłem skorzystać ze względu na bardzo atrakcyjną cenę i duży wybór produktów. To był mój pierwszy i na pewno nie ostatni zakup u Was.</span>
                    </div>
                    <div className='reviewTab__reviewsFile flex items-center gap-x-2'>
                       <MdAttachFile className='like'/>1 file attached
                    </div>
                    <div className='reviews__comments flex items-center justify-between'>
                        <div className='reviews__addComment'>
                           Add comment
                        </div>
                        <div className='comment__icons flex items-center gap-x-4'>
                            <span className='flex items-center'><BiDislike className='like mr-2'/>5</span>
                            <span className='flex items-center'><AiOutlineLike className='like mr-2'/>114</span>
                        </div>
                    </div>
                </div>

            </div>
            <div className='technicalTab__form w-full mt-10 lg:mt-0'>
            <div className='technicalTab__formTitle'>
                <span>Rate us</span>
            </div>
            <div className='flex items-center gap-x-4 my-6'>
                <IoIosStar className='reviewTab__formStar'/>
                <IoIosStar className='reviewTab__formStar'/>
                <IoIosStar className='reviewTab__formStar'/>
                <IoIosStar className='reviewTab__formStar'/>
                <IoIosStar className='reviewTab__formStar'/>
            </div>
            <form>
                <div className='technicalTab__input'>
                    <label className='form__labelName mb-3'>Name</label>
                    <input type='text'/>
                </div>
                <div className='technicalTab__input my-4'>
                    <label className='form__labelName mb-3'>Tell about the product in few words</label>
                    <input type='text'/>
                </div>
                <div>
                    <div><span className='form__labelName mb-3'>Attach file</span></div>
                    <div className='technical__dragDrop grid place-content-center'>
                        + Drag a file here
                    </div>
                </div>
                <button className='technicalTab__formBtn mt-5'>Save opinion</button>
            </form>
        </div>

           </div>
        </div>
      )}
     </div>


     <div className='productMobile__container'>
        <div onClick={handleBoxClick4} className='productMobile__desContent flex items-center justify-between'>
            Ask about product
             <IoIosArrowDown           style={{
            transform: rotateIcon4 ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: 'transform 0.3s ease',
          }}
/>
         </div>
      {isBottomBoxOpen4 && (
            <div className='aboutProductTab__container p-10'>
            <div className='aboutProductTab__title'>
                <span>Are you curious about the product? Do you have question about using it? Ask us!</span>
            </div>
            <form>
                <div className='aboutProductTab__input mt-10'>
                    <label className='mb-3'>Name</label>
                    <input type='text'/>
                </div>
                <div className='aboutProductTab__input my-5'>
                    <label className='mb-3'>E-mail</label>
                    <input type='email'/>
                </div>
                <div className='aboutProductTab__inputReq'>
                    <label className='mb-3'>Your request</label>
                    <input type='text'/>
                </div>
            </form>
            <div className='aboutProductTab__btn w-full grid place-content-center mt-9'>
                <button>Sent request</button>
            </div>
          </div>
          )}
     </div>



    </div>
    </div>
  );
}

export default ProductTab;
