import React, { useState } from "react";
import "../create/create.css";

const Address = ({ onNextTab, formData, setFormData }) => {
  const [errors, setErrors] = useState({
    pincode: "",
    city: "",
    state: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" }); 
  };

  const validateForm = () => {
    
    let formValid = true
    
    const newErrors = {};

    // Email validation
    if (!formData.pincode) {
      formValid = false
      newErrors.pincode = "Enter pincode";
    } else if (!/^\d+$/.test(formData.pincode)){
      formValid = false;
      newErrors.pincode = "Enter number only"
    } else if (formData.pincode.length < 6) {
      formValid = false;
      newErrors.pincode = "Maximum 6 number required"
    }

    // Mobile number validation
    if (!formData.city) {
      formValid = false;
      newErrors.city = "Enter city";
    } else if (formData.city.length < 3){
      formValid = false;
      newErrors.city = "Minimum 3 letter required"
    }

    // state validation
    if (!formData.state) {
      formValid = false;
      newErrors.state = "Enter state";
    } else if (formData.state.length < 3) {
      formValid = false;
      newErrors.state = "Minimum 3 letter required";
    }


    setErrors(newErrors);

    // Check if there are no errors
    return formValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      onNextTab(formData);
      setFormData({})
    }
  };

  return (
    <div className="create">
      <div className="create__account container mx-auto md:grid place-content-center">
        <div className="create__container py-5 lg:py-10">
          <div className="create__content px-4 lg:px-0">
            <div className="create__title mb-6 lg:mb-10 text-center lg:text-left">
              <span>Address Details</span>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="email mb-5">
                <label>Pincode</label>
                <input
                  type="text"
                  name="pincode"
                  value={formData.pincode}
                  onChange={handleInputChange}
                  className="mt-3"
                  maxLength={6}
                />
                {errors.pincode && (
                  <span className="text-red-500 mt-3 block">{errors.pincode}</span>
                )}
              </div>
              <div className="email mb-5">
                <label>City</label>
                <input
                  type="text"
                  name="city"
                  value={formData.city}
                  onChange={handleInputChange}
                  className="mt-3"
                  maxLength={15}
                />
                {errors.city && (
                  <span className="text-red-500 mt-3 block">{errors.city}</span>
                )}
              </div>

              <label>State</label>

              <div className="select__state mt-3">
                <select
                    name="state"
                    value={formData.state}
                    onChange={handleInputChange}
                > 
                  <option>Select state</option>
                  <option id="29">TAMIL NADU</option>
                  <option id="02">ANDHRA PRADESH</option>
                  <option id="04">ASSAM</option>
                  <option id="05">BIHAR</option>
                  <option id="33">CHHATTISGARH</option>
                  <option id="11">GUJARAT</option>
                  <option id="34">JHARKHAND</option>
                  <option id="15">KARNATAKA</option>
                  <option id="16">KERALA</option>
                  <option id="17">MADHYA PRADESH</option>
                  <option id="18">MAHARASHTRA</option>
                  <option id="24">ODISHA</option>
                  <option id="27">RAJASTHAN</option>
                  <option id="36">TELANGANA</option>
                  <option id="31">UTTAR PRADESH</option>
                  <option id="32">WEST BENGAL</option>
                  <option id="12">HARYANA</option>
                  <option id="13">HIMACHAL PRADESH</option>
                  <option id="14">JAMMU AND KASHMIR</option>
                  <option id="26">PUNJAB</option>
                  <option id="35">UTTARAKHAND</option>
                  <option id="03">ARUNACHAL PRADESH</option>
                  <option id="20">MANIPUR</option>
                  <option id="21">MEGHALAYA</option>
                  <option id="22">MIZORAM</option>
                  <option id="23">NAGALAND</option>
                  <option id="28">SIKKIM</option>
                  <option id="30">TRIPURA</option>
                  <option id="01">ANDAMAN AND NICOBAR ISLANDS</option>
                  <option id="10">GOA</option>
                  <option id="37">LADAKH</option>
                  <option id="19">LAKSHADWEEP</option>
                  <option id="25">PUDUCHERRY</option>
                </select>
              </div>
              {errors.state && (
                  <span className="text-red-500 mt-3 block">{errors.state}</span>
                )} 

              <div className="subBtn mt-12">
                <button type="submit">Next</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Address;
