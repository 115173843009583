import React from "react";
import "./order.css";

const Order = ({ handleCreateOrder }) => {
  return (
    <div className="order__container">
      <div className="order__review p-3">
        <div className="order__reviewTitle">
          <span>Order Review</span>
        </div>
        <div className="order__items flex justify-between items-center mt-2">
          <div>
            <span className="text-xs font-normal">14 items in cart</span>
          </div>
          <div>
            <span className="order__itemsRupees">₹ 30.00</span>
          </div>
        </div>
      </div>
      <div className="check__summary p-3 my-3">
        <div className="check__summaryTitle font-medium	text-sm">
          <span>Check out Summary</span>
        </div>
        <div className="check__summaryContent">
          <div className="flex justify-between items-center my-2">
            <div className="text-xs font-normal">Subtotal</div>
            <div className="order__itemsRupees">₹ 5,51</div>
          </div>
          <div className="flex justify-between items-center">
            <div className="text-xs font-normal">Discount</div>
            <div className="order__itemsRupees">₹ 14,47</div>
          </div>
          <div className="flex justify-between items-center my-2">
            <div className="text-xs font-normal">Extra Fee</div>
            <div className="order__itemsRupees">₹ 8,12</div>
          </div>
          <div className="flex justify-between items-center">
            <div className="text-xs font-normal">Shipping</div>
            <div className="order__itemsRupees">₹ 2,02</div>
          </div>
          <div className="flex justify-between items-center my-2">
            <div className="text-xs font-normal">Tax</div>
            <div className="order__itemsRupees">₹ 3,03</div>
          </div>
        </div>
        <div className="total flex justify-between items-center mt-2">
          <div className="font-medium	text-sm">Total</div>
          <div className="font-medium	text-sm">₹ 30.00</div>
        </div>
      </div>
      <div className="order__check">
        <input type="checkbox" />
        <span className="font-medium text-sm ml-2">
          Please check to acknowledge our
        </span>{" "}
        <span className="font-bold	text-sm">Privacy & Terms Policy</span>
      </div>
      <div className="order__btn mt-8">
        <button
          type="button"
          onClick={async () => {
            await handleCreateOrder();
          }}
        >
          Buy ₹30.00
        </button>
      </div>
    </div>
  );
};

export default Order;
