import React from 'react'
import Rivaheader from './rivaComponent/rivaheader'
import Rivaslider from './rivaComponent/mainslider'
import HeaderV from '../../home/header/headerV2';
import Header from '../../home/header/header';
import Nav from '../../home/navbar/nav';
import Rivaproduct from './rivaComponent/rivaproduct';
import Rivadetail from './rivaComponent/rivadetail';
import Rivaform from './rivaComponent/rivaform';
import Rivafooter from './rivaComponent/rivafooter';

const Riva = (props) => {

  const { count, setCount, pepper, setPepper, peppermint } = props;

  return (
    <div>
      {pepper?.isUserLoggedin ? (
        <>
          <HeaderV
            count={count}
            setCount={setCount}
            pepper={pepper}
            setPepper={setPepper}
            peppermint={peppermint}
          />
        </>
      ) : (
        <>
          <Header
            count={count}
            setCount={setCount}
            pepper={pepper}
            setPepper={setPepper}
            peppermint={peppermint}
          />
        </>
      )}

        <Rivaheader />
        <Rivaslider />
        <Rivaproduct />
        <Rivadetail />
        <Rivaform />
        <Rivafooter />
    </div>
  )
}

export default Riva