import React, { useState } from 'react'
import { FaRegCheckCircle } from "react-icons/fa";
import "./orderHistory.css"
import { IoIosArrowDown } from "react-icons/io";
import { GiSandsOfTime } from "react-icons/gi";




const OrderHistory = () => {

    const [isBottomBoxOpen, setIsBottomBoxOpen] = useState(false);
    const [rotateIcon, setRotateIcon] = useState(false);
  
    const handleBoxClick = () => {
        setIsBottomBoxOpen(!isBottomBoxOpen);
        setRotateIcon(!rotateIcon);
    };

    const [isBottomBoxOpen1, setIsBottomBoxOpen1] = useState(false);
    const [rotateIcon1, setRotateIcon1] = useState(false);
  
    const handleBoxClick1 = () => {
        setIsBottomBoxOpen1(!isBottomBoxOpen1);
        setRotateIcon1(!rotateIcon1);
    };


      
  return (
    <div>
        <div className='orderHistory'>
            <div>
                <table className='orderHistory__table'>
                    <tr className='orderHistory__title'>
                        <td>Order Number</td>
                        <td>Date</td>
                        <td>Status</td>
                        <td>Total price</td>
                    </tr>

                    <tr onClick={handleBoxClick} className='orderHistory__amountDetail'>
                        <td className='orderHistory__noBold'>1235 2345 345605</td>
                        <td className='orderHistory__date'>15.08.2023</td>
                        <td>
                            <div className='flex items-center gap-x-2 checkCircle'>
                              <FaRegCheckCircle className='checkCircle__icon'/> DELIVERED
                            </div>
                        </td>
                        <td className='orderHistory__amountDetailBlue'>₹120.00</td>
                        <td>
                           <IoIosArrowDown style={{transform: rotateIcon ? 'rotate(180deg)' : 'rotate(0deg)',transition: 'transform 0.3s ease'}}/> 
                        </td>
                    </tr>
                </table>
            </div>

            {isBottomBoxOpen && (
            <div className='shippingAddress__container'>
                <div className='flex gap-x-40 items-center p-6'>
                    <div className='shipping__address'>
                        <div className='shippingAdd__title'>
                            <span>Shipping address</span>
                        </div>
                        <div className='shippingAdd__detail'>
                            <span>JKT Company</span>
                            <span>177 Kent St</span>
                            <span>Parry Corner, NY 11222</span>
                            <span>Chennai - 600 001</span>
                        </div>
                    </div>

                    <div className='billing__address'>
                        <div className='billingAddress__title'>
                            <span>Billing address</span>
                        </div>
                        <div className='billingAddress__detail'>
                            <span>JKT Company</span>
                            <span>177 Kent St</span>
                            <span>Parry Corner, NY 11222</span>
                            <span>Chennai - 600 001</span>
                        </div>
                    </div>
                </div>


                <div className='flex gap-x-20 items-center px-6'>
                    <div className='shipping__method'>
                        <div className='shippingMethod__title'>
                            <span>Shipping method and cost</span>
                        </div>
                        <div className='shippingMethod__cost mt-5'>
                            <span>DHL - </span><span className='shippingMethod__cost__bold'>₹ 1240.00</span>
                        </div>
                    </div>
                    <div className='shipping__paymentMethod'>
                        <div className='shippingPay__title'>
                            <span>Payment method</span>
                        </div>
                        <div className='shippingPay__para mt-5'>
                            <span>Bank Transfer</span>
                        </div>
                    </div>
                </div>

                <div className='order__Listproducts'>
                   <div className='orderListproducts__title p-6'>
                      <span>List of products :</span>
                   </div>
                  <div className='orderListproducts__detail px-2'>
                    <table>
                        <tr className='orderListproducts__tableTitle'>
                            <th>No</th>
                            <th>Product Name</th>
                            <th>Quantity</th>
                            <th>1 Pcs</th>
                            <th>Price</th>
                        </tr>
                        <tr className='orderHistory__height'>
                            <td>1</td>
                            <td  className='orderListproductsdetail__image flex'>
                               <div className='orderListproductsdetail__img'>
                                   <img src='/images/bulb.png' alt='img' />
                               </div>
                               <div className='orderListproductsdetail__img__title'>
                                   <span>AMBER DECOR Small Lamp 60W, E27 590</span>
                               </div>
                            </td>
                            <td>
                                <span className='block sm:hidden'>Quantity :</span>10 items
                            </td>
                            <td>
                                <span className='block sm:hidden'>Price :</span>₹ 110.00
                            </td>
                            <td>
                                <span className='block sm:hidden'>Price :</span> <spa className='orderListproductsdetail__bold'>₹1100.00</spa>
                            </td>
                        </tr>

                        <tr className='orderHistory__height'>
                            <td>2</td>
                            <td  className='orderListproductsdetail__image flex items-center'>
                               <div className='orderListproductsdetail__img'>
                                   <img src='/images/bulb.png' alt='img' />
                               </div>
                               <div className='orderListproductsdetail__img__title'>
                                   <span>AMBER DECOR Small Lamp 60W, E27 590</span>
                               </div>
                            </td>
                            <td>
                                <span className='block sm:hidden'>Quantity :</span>10 items
                            </td>
                            <td>
                                <span className='block sm:hidden'>Price :</span>₹ 110.00
                            </td>
                            <td>
                                <span className='block sm:hidden'>Price :</span> <spa className='orderListproductsdetail__bold'>₹1100.00</spa>
                            </td>
                        </tr>
                    </table>

                    <div className='orderHistory__print flex justify-between items-center pr-6 py-5'>
                        <div className='print__blue pl-10'>
                            Print
                        </div>
                        <div className='orderHistoryPrint__total flex items-center gap-x-10'>
                            <div className='orderHistoryPrint__totalTitle'>
                                <span>Total Price</span>
                            </div>
                            <div className='orderHistoryPrint__totalprice'>
                                <span>₹3300.00</span>
                            </div>
                        </div>
                    </div>

                    <div className='orderHistory__track'></div>

                  </div>
                </div>

            </div>
            )}

        </div>





        <div className='orderHistory'>
            <div>
                <table className='orderHistory__table'>
                    <tr onClick={handleBoxClick1} className='orderHistory__amountDetail'>
                        <td className='orderHistory__noBold'>1235 2345 345605</td>
                        <td className='orderHistory__date'>15.08.2023</td>
                        <td>
                            <div className='flex items-center gap-x-2 checkpending'>
                              <GiSandsOfTime className='pending__icon'/>PENDING
                            </div>
                        </td>
                        <td className='orderHistory__amountDetailBlue'>₹120.00</td>
                        <td>
                           <IoIosArrowDown style={{transform: rotateIcon1 ? 'rotate(180deg)' : 'rotate(0deg)',transition: 'transform 0.3s ease'}}/> 
                        </td>
                    </tr>
                </table>
            </div>

            {isBottomBoxOpen1 && (
            <div className='shippingAddress__container '>
                <div className='flex gap-x-40 items-center p-6'>
                    <div className='shipping__address'>
                        <div className='shippingAdd__title'>
                            <span>Shipping address</span>
                        </div>
                        <div className='shippingAdd__detail'>
                            <span>JKT Company</span>
                            <span>177 Kent St</span>
                            <span>Parry Corner, NY 11222</span>
                            <span>Chennai - 600 001</span>
                        </div>
                    </div>

                    <div className='billing__address'>
                        <div className='billingAddress__title'>
                            <span>Billing address</span>
                        </div>
                        <div className='billingAddress__detail'>
                            <span>JKT Company</span>
                            <span>177 Kent St</span>
                            <span>Parry Corner, NY 11222</span>
                            <span>Chennai - 600 001</span>
                        </div>
                    </div>
                </div>


                <div className='flex gap-x-20 items-center px-6'>
                    <div className='shipping__method'>
                        <div className='shippingMethod__title'>
                            <span>Shipping method and cost</span>
                        </div>
                        <div className='shippingMethod__cost mt-5'>
                            <span>DHL - </span><span className='shippingMethod__cost__bold'>₹ 1240.00</span>
                        </div>
                    </div>
                    <div className='shipping__paymentMethod'>
                        <div className='shippingPay__title'>
                            <span>Payment method</span>
                        </div>
                        <div className='shippingPay__para mt-5'>
                            <span>Bank Transfer</span>
                        </div>
                    </div>
                </div>

                <div className='order__Listproducts'>
                   <div className='orderListproducts__title p-6'>
                      <span>List of products :</span>
                   </div>
                  <div className='orderListproducts__detail px-2'>
                    <table>
                        <tr className='orderListproducts__tableTitle'>
                            <th>No</th>
                            <th>Product Name</th>
                            <th>Quantity</th>
                            <th>1 Pcs</th>
                            <th>Price</th>
                        </tr>
                        <tr className='orderHistory__height'>
                            <td>1</td>
                            <td  className='orderListproductsdetail__image flex'>
                               <div className='orderListproductsdetail__img'>
                                   <img src='/images/bulb.png' alt='img' />    
                               </div>
                               <div className='orderListproductsdetail__img__title'>
                                   <span>AMBER DECOR Small Lamp 60W, E27 590</span>
                               </div>
                            </td>
                            <td>
                                <span className='block sm:hidden'>Quantity :</span>10 items
                            </td>
                            <td>
                                <span className='block sm:hidden'>Price :</span>₹ 110.00
                            </td>
                            <td>
                                <span className='block sm:hidden'>Price :</span> <spa className='orderListproductsdetail__bold'>₹1100.00</spa>
                            </td>
                        </tr>

                        <tr className='orderHistory__height'>
                            <td>2</td>
                            <td  className='orderListproductsdetail__image flex items-center'>
                               <div className='orderListproductsdetail__img'>
                                   <img src='/images/bulb.png' alt='img' />
                               </div>
                               <div className='orderListproductsdetail__img__title'>
                                   <span>AMBER DECOR Small Lamp 60W, E27 590</span>
                               </div>
                            </td>
                            <td>
                                <span className='block sm:hidden'>Quantity :</span>10 items
                            </td>
                            <td>
                                <span className='block sm:hidden'>Price :</span>₹ 110.00
                            </td>
                            <td>
                                <span className='block sm:hidden'>Price :</span> <spa className='orderListproductsdetail__bold'>₹1100.00</spa>
                            </td>
                        </tr>
                    </table>

                    <div className='orderHistory__print flex justify-between items-center pr-6 py-5'>
                        <div className='print__blue pl-10'>
                            Print
                        </div>
                        <div className='orderHistoryPrint__total flex items-center gap-x-10'>
                            <div className='orderHistoryPrint__totalTitle'>
                                <span>Total Price</span>
                            </div>
                            <div className='orderHistoryPrint__totalprice'>
                                <span>₹3300.00</span>
                            </div>
                        </div>
                    </div>

                    <div className='orderHistory__track'></div>

                  </div>
                </div>

            </div>
            )}

        </div>

    
    </div>
  )
}

export default OrderHistory