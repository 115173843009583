import React from 'react'
import ApexCharts from 'apexcharts'
import ProgressBar from '@ramonak/react-progress-bar';
import ReactApexChart from 'react-apexcharts';
import { FaRupeeSign } from "react-icons/fa";
import { useState } from 'react';
import './SellerDashboard.css';
<style>
    {`
    @media (max-width: 375px) {
      .flex-nowrap-sm {
        flex-wrap: nowrap;
      }
    }
  `}
</style>

const Overview = () => {
    const [chartData, setChartData] = useState({
        series: [
            {
                name: 'Net Profit',
                data: [85, 40, 65, 25, 80, 45, 80, 45, 50],
            },

        ],
        options: {
            chart: {
                type: 'bar',
                height: 350,
            },
            plotOptions: {
                bar: {
                    columnWidth: '20%',
                    endingShape: 'rounded',
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent'],
            },
            xaxis: {
                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
            },
            yaxis: {
                title: {
                    text: '$ (thousands)',
                },
            },
            fill: {
                opacity: 1,
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return '$' + val + 'hundreds';
                    },
                },
            },
        },
    });
    return (
        <div className='bg-gray-100 pb-7'>
            <div className=''>
                <div className='flex justify-center gap-10 pt-5 flex-wrap sm:flex-nowrap'>
                    <div className='selleroverview w-32 sm:w-48 bg-white rounded-xl  p-6'>
                        <div className='text-2xl font-bold'>09</div>
                        <div className='text-base text-gray-400'>Units Sold</div>
                    </div>
                    <div className=' w-32 sm:w-48 bg-white rounded-xl  p-6 '>
                        <div className='text-2xl flex'>
                            <FaRupeeSign />
                            <span className='selleroverview text-2xl font-bold'>12,968</span>
                        </div>
                        <div className='selleroverview'>
                            <div className='text-base text-gray-400'>Sales</div>
                        </div>
                    </div>
                    <div className='w-32 sm:w-48 bg-white rounded-xl p-6 selleroverview '>
                        <div className='text-2xl font-bold'>28</div>
                        <div className='text-base text-gray-400'>New Orders</div>
                    </div>
                </div>
                <div className='flex justify-center gap-10 mt-10 flex-wrap md:flex-nowrap'>
                    <div className='selleroverview w-32 sm:w-48  bg-white rounded-xl  p-6'>
                        <div className='text-2xl font-bold text-blue-500'>125</div>
                        <div className='text-base text-gray-400'>Total Listing</div>
                    </div>
                    <div className='selleroverview w-32 sm:w-48 bg-white rounded-xl  p-6'>
                        <div className='text-2xl font-bold text-green-700'>72</div>
                        <div className='text-base text-gray-400'>Active Listing</div>
                    </div>
                    <div className='selleroverview w-32 sm:w-48 bg-white rounded-xl  p-6'>
                        <div className='text-2xl font-bold text-orange-500'>53</div>
                        <div className='text-base text-gray-400'>On Hold</div>
                    </div>
                </div>
                <div className='flex justify-center mt-10'>
                    <div className='bg-white rounded-xl barchart p-6'>
                        <div id="chart">
                            <ReactApexChart options={chartData.options} series={chartData.series} type="bar" height={300} />

                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Overview
