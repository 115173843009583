import React, { memo } from "react";
import "./frequentlyProduct.css";
import { BiCheckCircle } from "react-icons/bi";
import { FiShare2 } from "react-icons/fi";
import { MdStarOutline } from "react-icons/md";
import { AppKeys } from "../../../constants/AppKeys";

const FrequentlyProduct = memo(() => {
  return (
    <div className="frequentlyProduct__content w-full xl:w-1/2 flex items-center justify-center flex-wrap md:flex-nowrap xl:flex-wrap 2xl:flex-nowrap p-3 lg:p-5 gap-5">
      <div className="fre flex sm:flex-wrap md:flex-nowrap items-center justify-around md:justify-center w-full gap-5 md:gap-10 xl:gap-5">
        <div className="frequentlyProduct__Image">
          <div className="frequentlyProduct__share">
            <div className="frequentlyProduct__star grid place-content-center">
              <div className="frequently__star grid place-content-center">
                <MdStarOutline />
              </div>
              <FiShare2 className="fre__share" />
            </div>
            <img
              src={`${AppKeys.IMGURL}website/product-view-images/desktop/produ-view-img-d8.webp`}
              alt="Watch"
            />
          </div>
          <div className="px-2 pb-3">
            <div className="productDetail__stock flex items-center gap-x-1">
              <BiCheckCircle />
              <span>In stock </span>
            </div>
            <div className="brandName my-1 md:my-3">
              <span>Brand Name | Part No. 234565</span>
            </div>
            <div className="frequentlyProduct__title">
              <span>AMBER DECOR Small Lamp 60W, E27 590</span>
            </div>
          </div>
        </div>
        <div className="frequentlyProduct__Image">
          <div className="frequentlyProduct__share">
            <div className="frequentlyProduct__star grid place-content-center">
              <div className="frequently__star grid place-content-center">
                <MdStarOutline />
              </div>
              <FiShare2 className="fre__share" />
            </div>
            <img
              src={`${AppKeys.IMGURL}website/product-view-images/desktop/produ-view-img-d9.webp`}
              alt="Watch"
            />
          </div>
          <div className="px-2 pb-3">
            <div className="productDetail__stock flex items-center gap-x-1">
              <BiCheckCircle />
              <span>In stock </span>
            </div>
            <div className="brandName my-1 md:my-3">
              <span>Brand Name | Part No. 234565</span>
            </div>
            <div className="frequentlyProduct__title">
              <span>AMBER DECOR Small Lamp 60W, E27 590</span>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="frequentlyProduct__price">
          <div className="frequentlyProduct__priceTitle text-center md:text-left">
            <span>Price for bundle</span>
          </div>
          <div className="frequentlyProduct__priceDetail flex items-center gap-x-4 my-2">
            <div className="frequentlyProduct__priceRate">
              <span>₹45.00 net </span>
            </div>
            <div className="frequentlyProduct__stricke">
              <strike>₹55.00 net</strike>
            </div>
          </div>
          <button className="frequentlyProduct__addBtn">Add to cart</button>
        </div>
      </div>
    </div>
  );
});

export default FrequentlyProduct;
