import React from 'react'
import { MdOutlineEdit, MdDeleteOutline } from "react-icons/md";
import { FaRupeeSign } from "react-icons/fa";
import './SellerDashboard.css';
const Active = () => {
    return (
        <div className='bg-gray-100'>
            <div className='grid place-content-center'>
                <div className='hidden lg:flex justify-between bg-white text-black active p-3'>
                    <div>Product Details</div>
                    <div className='lg:flex md:gap-32 lg:gap-32 xl:gap-28  xl:mr-40 2xl:mr-60'>
                        <div className='lg:-mr-16  2xl:-mr-3'>Price</div>
                        <div className='xl:-mr-10 2xl:mr-0'>Final Price</div>
                        <div className='2xl:mr-10'>Stock</div>
                        <div className='lg:mr-24 xl:mr-0 2xl:ml-0'>Category</div>
                        <div className='2xl:ml-0'>Action</div>
                    </div>
                </div>
                <div className='w-full lg:w-full xl:w-full bg-blue-100 pt-1 pb-5 p-3 mt-1 sm:mt-0'>
                    <div className='card w-72 md:w-auto lg:w-full xl:full bg-white mx-auto mt-1 p-5 rounded-lg'>
                        <div className='w-full lg:flex lg:justify-between flex-wrap lg:flex-nowrap'>
                            <div className='flex md:mr-20 lg:mr-0 sm:justify-center  lg:justify-normal'>
                                <div className='lampimg'>
                                    <img src="/images/Lamp.css" alt='Lamp' />
                                </div>
                                <div className=''>
                                    <div className='tabtext1 text-sm text-gray-500 font-medium'>
                                        Brand Name | Part No.234565
                                    </div>
                                    <div className='tabtext2 text-base text-black font-medium'>AMBER DECOR Small Lamp 60W, E27 590</div>
                                    <div className='tabtext1 text-base text-gray-500 font-medium'>
                                        SKU ID - AP-KPN-09U-9765
                                    </div>
                                </div>
                            </div>
                            <div className='md:flex md:gap-10 items-center lg:ml-32 lg:gap-20 xl:gap-20  sm:justify-center lg:justify-normal '>
                                <div className='flex md:gap-1'>
                                    <div className='tabrate sm:block md:block lg:hidden xl:hidden ml-16 md:ml-0'>Price:</div>
                                    <span className='tabrate text-base text-black font-medium ml-10 sm:ml-12 md:ml-0 lg:mr-7 xl:mr-9'>
                                        <div className='flex'>
                                            <div className='mt-1'>
                                                <FaRupeeSign />
                                            </div>
                                            <div>
                                                250
                                            </div>
                                        </div></span>
                                </div>
                                <div className='flex md:gap-1'>
                                    <div className='tabrate sm:block md:block lg:hidden xl:hidden ml-16 md:ml-0'>Final Price:</div>
                                    <span className='tabrate text-base text-black font-medium ml-1 sm:ml-3 md:ml-0 lg:mr-7 xl:mr-9'>
                                        <div className='flex'>
                                            <div className='mt-1'>
                                                <FaRupeeSign />
                                            </div>
                                            <div>
                                                250
                                            </div>
                                        </div></span>
                                </div>
                                <div className='flex md:gap-1'>
                                    <div className='tabrate sm:block md:block lg:hidden xl:hidden ml-16 md:ml-0'>Stock:</div>
                                    <span className='tabrate text-base text-black font-medium ml-1 sm:ml-3 md:ml-0 lg:mr-7 xl:mr-9'>
                                        <div>
                                            98Nos
                                        </div>
                                    </span>
                                </div>
                                <div className='flex md:gap-3'>
                                    <span className='tabrate sm:block md:block lg:hidden xl:hidden ml-16 md:ml-0'>Category:</span>
                                    <div className='tabcat text-base text-black font-medium ml-10 md:ml-0 lg:mr-0 xl:mr-0 sm:ml-10'>
                                        Electric Equipment</div>
                                </div>
                                <div className='flex md:gap-3'>
                                    <span className='tabrate sm:block md:block lg:hidden xl:hidden ml-16 md:ml-0'>Action:</span>
                                    <div className='flex gap-2 cursor-pointer'>
                                        <div className='mt-1 ml-16 md:ml-0'>
                                            <MdOutlineEdit style={{ color: '#1071FF' }} />
                                        </div>
                                        <span className='symtext text-base text-blue-500 font-medium'>Edit</span>
                                    </div>
                                </div>
                                <div className='flex md:gap-3'>
                                    <span className='tabrate sm:block md:block lg:hidden xl:hidden ml-16 md:ml-0'>Action:</span>
                                    <div className='flex gap-2 cursor-pointer'>
                                        <div className='mt-1 ml-16 md:ml-0'>
                                            <MdDeleteOutline style={{ color: '#434447' }} />
                                        </div>
                                        <span className='symtext text-base text-black-500 font-medium'>Remove Product</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='card w-72 md:w-auto lg:w-full xl:full bg-white mx-auto mt-1 p-5 rounded-lg'>
                        <div className='w-full lg:flex lg:justify-between flex-wrap lg:flex-nowrap'>
                            <div className='flex md:mr-20 lg:mr-0 sm:justify-center  lg:justify-normal'>
                                <div className='lampimg'>
                                <img src="/images/Lamp.css" alt='Lamp' />
                                </div>
                                <div className=''>
                                    <div className='tabtext1 text-sm text-gray-500 font-medium'>
                                        Brand Name | Part No.234565
                                    </div>
                                    <div className='tabtext2 text-base text-black font-medium'>AMBER DECOR Small Lamp 60W, E27 590</div>
                                    <div className='tabtext1 text-base text-gray-500 font-medium'>
                                        SKU ID - AP-KPN-09U-9765
                                    </div>
                                </div>
                            </div>
                            <div className='md:flex md:gap-10 items-center lg:ml-32 lg:gap-20 xl:gap-20  sm:justify-center lg:justify-normal '>
                                <div className='flex md:gap-1'>
                                    <div className='tabrate sm:block md:block lg:hidden xl:hidden ml-16 md:ml-0'>Price:</div>
                                    <span className='tabrate text-base text-black font-medium ml-10 sm:ml-12 md:ml-0 lg:mr-7 xl:mr-9'>
                                        <div className='flex'>
                                            <div className='mt-1'>
                                                <FaRupeeSign />
                                            </div>
                                            <div>
                                                250
                                            </div>
                                        </div></span>
                                </div>
                                <div className='flex md:gap-1'>
                                    <div className='tabrate sm:block md:block lg:hidden xl:hidden ml-16 md:ml-0'>Final Price:</div>
                                    <span className='tabrate text-base text-black font-medium ml-1 sm:ml-3 md:ml-0 lg:mr-7 xl:mr-9'>
                                        <div className='flex'>
                                            <div className='mt-1'>
                                                <FaRupeeSign />
                                            </div>
                                            <div>
                                                250
                                            </div>
                                        </div></span>
                                </div>
                                <div className='flex md:gap-1'>
                                    <div className='tabrate sm:block md:block lg:hidden xl:hidden ml-16 md:ml-0'>Stock:</div>
                                    <span className='tabrate text-base text-black font-medium ml-1 sm:ml-3 md:ml-0 lg:mr-7 xl:mr-9'>
                                        <div>
                                            98Nos
                                        </div>
                                    </span>
                                </div>
                                <div className='flex md:gap-3'>
                                    <span className='tabrate sm:block md:block lg:hidden xl:hidden ml-16 md:ml-0'>Category:</span>
                                    <div className='tabcat text-base text-black font-medium ml-10 md:ml-0 lg:mr-0 xl:mr-0 sm:ml-10'>
                                        Electric Equipment</div>
                                </div>
                                <div className='flex md:gap-3'>
                                    <span className='tabrate sm:block md:block lg:hidden xl:hidden ml-16 md:ml-0'>Action:</span>
                                    <div className='flex gap-2 cursor-pointer'>
                                        <div className='mt-1 ml-16 md:ml-0'>
                                            <MdOutlineEdit style={{ color: '#1071FF' }} />
                                        </div>
                                        <span className='symtext text-base text-blue-500 font-medium'>Edit</span>
                                    </div>
                                </div>
                                <div className='flex md:gap-3'>
                                    <span className='tabrate sm:block md:block lg:hidden xl:hidden ml-16 md:ml-0'>Action:</span>
                                    <div className='flex gap-2 cursor-pointer'>
                                        <div className='mt-1 ml-16 md:ml-0'>
                                            <MdDeleteOutline style={{ color: '#434447' }} />
                                        </div>
                                        <span className='symtext text-base text-black-500 font-medium'>Remove Product</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='card w-72 md:w-auto lg:w-full xl:full bg-white mx-auto mt-1 p-5 rounded-lg'>
                        <div className='w-full lg:flex lg:justify-between flex-wrap lg:flex-nowrap'>
                            <div className='flex md:mr-20 lg:mr-0 sm:justify-center  lg:justify-normal'>
                                <div className='lampimg'>
                                <img src="/images/Lamp.css" alt='Lamp' />
                                </div>
                                <div className=''>
                                    <div className='tabtext1 text-sm text-gray-500 font-medium'>
                                        Brand Name | Part No.234565
                                    </div>
                                    <div className='tabtext2 text-base text-black font-medium'>AMBER DECOR Small Lamp 60W, E27 590</div>
                                    <div className='tabtext1 text-base text-gray-500 font-medium'>
                                        SKU ID - AP-KPN-09U-9765
                                    </div>
                                </div>
                            </div>
                            <div className='md:flex md:gap-10 items-center lg:ml-32 lg:gap-20 xl:gap-20  sm:justify-center lg:justify-normal '>
                                <div className='flex md:gap-1'>
                                    <div className='tabrate sm:block md:block lg:hidden xl:hidden ml-16 md:ml-0'>Price:</div>
                                    <span className='tabrate text-base text-black font-medium ml-10 sm:ml-12 md:ml-0 lg:mr-7 xl:mr-9'>
                                        <div className='flex'>
                                            <div className='mt-1'>
                                                <FaRupeeSign />
                                            </div>
                                            <div>
                                                250
                                            </div>
                                        </div></span>
                                </div>
                                <div className='flex md:gap-1'>
                                    <div className='tabrate sm:block md:block lg:hidden xl:hidden ml-16 md:ml-0'>Final Price:</div>
                                    <span className='tabrate text-base text-black font-medium ml-1 sm:ml-3 md:ml-0 lg:mr-7 xl:mr-9'>
                                        <div className='flex'>
                                            <div className='mt-1'>
                                                <FaRupeeSign />
                                            </div>
                                            <div>
                                                250
                                            </div>
                                        </div></span>
                                </div>
                                <div className='flex md:gap-1'>
                                    <div className='tabrate sm:block md:block lg:hidden xl:hidden ml-16 md:ml-0'>Stock:</div>
                                    <span className='tabrate text-base text-black font-medium ml-1 sm:ml-3 md:ml-0 lg:mr-7 xl:mr-9'>
                                        <div>
                                            98Nos
                                        </div>
                                    </span>
                                </div>
                                <div className='flex md:gap-3'>
                                    <span className='tabrate sm:block md:block lg:hidden xl:hidden ml-16 md:ml-0'>Category:</span>
                                    <div className='tabcat text-base text-black font-medium ml-10 md:ml-0 lg:mr-0 xl:mr-0 sm:ml-10'>
                                        Electric Equipment</div>
                                </div>
                                <div className='flex md:gap-3'>
                                    <span className='tabrate sm:block md:block lg:hidden xl:hidden ml-16 md:ml-0'>Action:</span>
                                    <div className='flex gap-2 cursor-pointer'>
                                        <div className='mt-1 ml-16 md:ml-0'>
                                            <MdOutlineEdit style={{ color: '#1071FF' }} />
                                        </div>
                                        <span className='symtext text-base text-blue-500 font-medium'>Edit</span>
                                    </div>
                                </div>
                                <div className='flex md:gap-3'>
                                    <span className='tabrate sm:block md:block lg:hidden xl:hidden ml-16 md:ml-0'>Action:</span>
                                    <div className='flex gap-2 cursor-pointer'>
                                        <div className='mt-1 ml-16 md:ml-0'>
                                            <MdDeleteOutline style={{ color: '#434447' }} />
                                        </div>
                                        <span className='symtext text-base text-black-500 font-medium'>Remove Product</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='card w-72 md:w-auto lg:w-full xl:full bg-white mx-auto mt-1 p-5 rounded-lg'>
                        <div className='w-full lg:flex lg:justify-between flex-wrap lg:flex-nowrap'>
                            <div className='flex md:mr-20 lg:mr-0 sm:justify-center  lg:justify-normal'>
                                <div className='lampimg'>
                                <img src="/images/Lamp.css" alt='Lamp' />
                                </div>
                                <div className=''>
                                    <div className='tabtext1 text-sm text-gray-500 font-medium'>
                                        Brand Name | Part No.234565
                                    </div>
                                    <div className='tabtext2 text-base text-black font-medium'>AMBER DECOR Small Lamp 60W, E27 590</div>
                                    <div className='tabtext1 text-base text-gray-500 font-medium'>
                                        SKU ID - AP-KPN-09U-9765
                                    </div>
                                </div>
                            </div>
                            <div className='md:flex md:gap-10 items-center lg:ml-32 lg:gap-20 xl:gap-20  sm:justify-center lg:justify-normal '>
                                <div className='flex md:gap-1'>
                                    <div className='tabrate sm:block md:block lg:hidden xl:hidden ml-16 md:ml-0'>Price:</div>
                                    <span className='tabrate text-base text-black font-medium ml-10 sm:ml-12 md:ml-0 lg:mr-7 xl:mr-9'>
                                        <div className='flex'>
                                            <div className='mt-1'>
                                                <FaRupeeSign />
                                            </div>
                                            <div>
                                                250
                                            </div>
                                        </div></span>
                                </div>
                                <div className='flex md:gap-1'>
                                    <div className='tabrate sm:block md:block lg:hidden xl:hidden ml-16 md:ml-0'>Final Price:</div>
                                    <span className='tabrate text-base text-black font-medium ml-1 sm:ml-3 md:ml-0 lg:mr-7 xl:mr-9'>
                                        <div className='flex'>
                                            <div className='mt-1'>
                                                <FaRupeeSign />
                                            </div>
                                            <div>
                                                250
                                            </div>
                                        </div></span>
                                </div>
                                <div className='flex md:gap-1'>
                                    <div className='tabrate sm:block md:block lg:hidden xl:hidden ml-16 md:ml-0'>Stock:</div>
                                    <span className='tabrate text-base text-black font-medium ml-1 sm:ml-3 md:ml-0 lg:mr-7 xl:mr-9'>
                                        <div>
                                            98Nos
                                        </div>
                                    </span>
                                </div>
                                <div className='flex md:gap-3'>
                                    <span className='tabrate sm:block md:block lg:hidden xl:hidden ml-16 md:ml-0'>Category:</span>
                                    <div className='tabcat text-base text-black font-medium ml-10 md:ml-0 lg:mr-0 xl:mr-0 sm:ml-10'>
                                        Electric Equipment</div>
                                </div>
                                <div className='flex md:gap-3'>
                                    <span className='tabrate sm:block md:block lg:hidden xl:hidden ml-16 md:ml-0'>Action:</span>
                                    <div className='flex gap-2 cursor-pointer'>
                                        <div className='mt-1 ml-16 md:ml-0'>
                                            <MdOutlineEdit style={{ color: '#1071FF' }} />
                                        </div>
                                        <span className='symtext text-base text-blue-500 font-medium'>Edit</span>
                                    </div>
                                </div>
                                <div className='flex md:gap-3'>
                                    <span className='tabrate sm:block md:block lg:hidden xl:hidden ml-16 md:ml-0'>Action:</span>
                                    <div className='flex gap-2 cursor-pointer'>
                                        <div className='mt-1 ml-16 md:ml-0'>
                                            <MdDeleteOutline style={{ color: '#434447' }} />
                                        </div>
                                        <span className='symtext text-base text-black-500 font-medium'>Remove Product</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='card w-72 md:w-auto lg:w-full xl:full bg-white mx-auto mt-1 p-5 rounded-lg'>
                        <div className='w-full lg:flex lg:justify-between flex-wrap lg:flex-nowrap'>
                            <div className='flex md:mr-20 lg:mr-0 sm:justify-center  lg:justify-normal'>
                                <div className='lampimg'>
                                <img src="/images/Lamp.css" alt='Lamp' />
                                </div>
                                <div className=''>
                                    <div className='tabtext1 text-sm text-gray-500 font-medium'>
                                        Brand Name | Part No.234565
                                    </div>
                                    <div className='tabtext2 text-base text-black font-medium'>AMBER DECOR Small Lamp 60W, E27 590</div>
                                    <div className='tabtext1 text-base text-gray-500 font-medium'>
                                        SKU ID - AP-KPN-09U-9765
                                    </div>
                                </div>
                            </div>
                            <div className='md:flex md:gap-10 items-center lg:ml-32 lg:gap-20 xl:gap-20  sm:justify-center lg:justify-normal '>
                                <div className='flex md:gap-1'>
                                    <div className='tabrate sm:block md:block lg:hidden xl:hidden ml-16 md:ml-0'>Price:</div>
                                    <span className='tabrate text-base text-black font-medium ml-10 sm:ml-12 md:ml-0 lg:mr-7 xl:mr-9'>
                                        <div className='flex'>
                                            <div className='mt-1'>
                                                <FaRupeeSign />
                                            </div>
                                            <div>
                                                250
                                            </div>
                                        </div></span>
                                </div>
                                <div className='flex md:gap-1'>
                                    <div className='tabrate sm:block md:block lg:hidden xl:hidden ml-16 md:ml-0'>Final Price:</div>
                                    <span className='tabrate text-base text-black font-medium ml-1 sm:ml-3 md:ml-0 lg:mr-7 xl:mr-9'>
                                        <div className='flex'>
                                            <div className='mt-1'>
                                                <FaRupeeSign />
                                            </div>
                                            <div>
                                                250
                                            </div>
                                        </div></span>
                                </div>
                                <div className='flex md:gap-1'>
                                    <div className='tabrate sm:block md:block lg:hidden xl:hidden ml-16 md:ml-0'>Stock:</div>
                                    <span className='tabrate text-base text-black font-medium ml-1 sm:ml-3 md:ml-0 lg:mr-7 xl:mr-9'>
                                        <div>
                                            98Nos
                                        </div>
                                    </span>
                                </div>
                                <div className='flex md:gap-3'>
                                    <span className='tabrate sm:block md:block lg:hidden xl:hidden ml-16 md:ml-0'>Category:</span>
                                    <div className='tabcat text-base text-black font-medium ml-10 md:ml-0 lg:mr-0 xl:mr-0 sm:ml-10'>
                                        Electric Equipment</div>
                                </div>
                                <div className='flex md:gap-3'>
                                    <span className='tabrate sm:block md:block lg:hidden xl:hidden ml-16 md:ml-0'>Action:</span>
                                    <div className='flex gap-2 cursor-pointer'>
                                        <div className='mt-1 ml-16 md:ml-0'>
                                            <MdOutlineEdit style={{ color: '#1071FF' }} />
                                        </div>
                                        <span className='symtext text-base text-blue-500 font-medium'>Edit</span>
                                    </div>
                                </div>
                                <div className='flex md:gap-3'>
                                    <span className='tabrate sm:block md:block lg:hidden xl:hidden ml-16 md:ml-0'>Action:</span>
                                    <div className='flex gap-2 cursor-pointer'>
                                        <div className='mt-1 ml-16 md:ml-0'>
                                            <MdDeleteOutline style={{ color: '#434447' }} />
                                        </div>
                                        <span className='symtext text-base text-black-500 font-medium'>Remove Product</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Active
