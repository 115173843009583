import React, { useState } from "react";
import "../create/create.css";
import { formToJSON } from "axios";

const Business = ({ onNextTab, formData, setFormData }) => {
  const [errors, setErrors] = useState({
    name: "",
    companyName: "",
    mobileNumber: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const validateForm = () => {

    let formValid = true; // Change from const to let
  
    const newErrors = {};
  
    // Email validation
    if (!formData.name) {
      formValid = false;
      newErrors.name = "Your name is required";
    } 


     // Company validation
     if (!formData.companyName) {
      formValid = false;
      newErrors.companyName = "Company/Business/Shop Name is required";
    } else if (formData.companyName.length < 3) {
      formValid = false;
      newErrors.companyName = "Minimum 3 letters required";
    }

    
    // Mobile number validation
    if (!formData.mobileNumber) {
      formValid = false;
      newErrors.mobileNumber = "Business Mobile number is required";
    } else if (!/^\d{10}$/.test(formData.mobileNumber)) {
      formValid = false;
      newErrors.mobileNumber = "Please Enter a valid 10-digit number";
    } else if (formData.mobileNumber.length < 10) {
      formValid = false;
      newErrors.mobileNumber = "10 numbers enter"
    }
  
   
  
    setErrors(newErrors);
  
    // Check if there are no errors
    return formValid;
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate the form before moving to the next tab
    if (validateForm()) {
      onNextTab(formData);
      setFormData({})
    }
  };

  return (
    <div className="create">
      <div className="create__account container mx-auto md:grid place-content-center">
        <div className="create__container py-5 lg:py-10">
          <div className="create__content px-4 lg:px-0">
            <div className="create__title mb-6 lg:mb-10 text-center lg:text-left">
              <span>Business Details</span>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="email mb-5">
                <label>Your Name</label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  className="mt-3"
                />
                {errors.name && (
                  <span className="text-red-500 mt-3 block">{errors.name}</span>
                )}
              </div>

              <div className="email mb-5">
                <label>Company / Business / Shop Name</label>
                <input
                  type="text"
                  name="companyName"
                  value={formData.companyName}
                  onChange={handleInputChange}
                  className="mt-3"
                />
                {errors.companyName && (
                  <span className="text-red-500 mt-3 block">{errors.companyName}</span>
                )}
              </div>
              <div className="email mb-12">
                <label>Business Mobile Number</label>
                <input
                  type="text"
                  name="mobileNumber"
                  value={formData.mobileNumber}
                  onChange={handleInputChange}
                  className="mt-3"
                  maxLength={10}
                />
                {errors.mobileNumber && (
                  <span className="text-red-500 mt-3 block">{errors.mobileNumber}</span>
                )}
              </div>
              <div className="subBtn">
                <button type="submit">Next</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Business;
