import React from 'react'

const DescriptionTab = () => {
  return (
    <div className='description__container w-full flex flex-wrap p-10'>
        <div className='description__left w-full lg:w-1/2'>
            <div>Ecommerce, also known as electronic commerce or internet commerce, refers to the buying and selling of goods or services using the internet, and the transfer of money and data to execute these transactions. Ecommerce, also known as electronic commerce or internet commerce, refers to the buying and selling of goods or services using the internet, and the transfer of money and data to execute these transactions.</div>
            <div className='mt-6'>Ecommerce, also known as electronic commerce or internet commerce, refers to the buying and selling of goods or services using the internet, and the transfer of money and data to execute these transactions.Ecommerce, also known as electronic commerce or internet commerce, refers to the buying and selling of goods or services using the internet.</div>
        </div>
        <div className='description__right w-full lg:w-1/2 grid place-content-center mt-10 lg:mt-0'>
            <div className='right__content p-5'>
                <div className='right__title'>
                    <span>Details:</span>
                </div>
                <div className='description__list'>
                    <li>EAN</li>
                    <li>Color</li>
                    <li>Atribute 3</li>
                    <li>Atribute 4</li>
                    <li>Atribute 5</li>
                    <li>Atribute 6</li>
                </div>
            </div>
        </div>
    </div>
  )
}

export default DescriptionTab