import React, { useEffect, useState } from "react";
import "./productMain.css";
import { useNavigate } from "react-router-dom";
import { PK } from "../../../constants/PepperKeys";
import Loader from "../../loader/loader";
import { BsCurrencyRupee } from "react-icons/bs";


const ProductMain = (props) => {
  const { count, setCount, pepper, setPepper, peppermint } = props;

  let navigate = useNavigate();

  const [loader, setLoader] = useState(false);


  useEffect(() => {}, [count, pepper]);

  return (
    <section className="container mx-auto">

      {/* Loader */}

            {loader && (
        <Loader />
      )}

      <div className="product__header md:flex justify-between mt-10 md:mt-24 mb-6 md:mb-12">
        <div className="product__titlee text-center md:text-left">
          <span>Products You May Like</span>
        </div>
        <div className="product__show text-center md:text-right mt-3 md:mt-0">
          <span>
            Show all recommended products{" "}
            <i class="fa-solid fa-arrow-right"></i>
          </span>
        </div>
      </div>

       {/* Loader */}

        {loader ? (
          <Loader />
      ) : (

      <div className="product__containerr grid place-content-center grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-7">
        {pepper[PK.PRODUCTSINFODATA]?.slice(0, 5).map((prod) => (
          <>
            <div className="productt py-4">
              <a
                className="product__discount flex justify-between"
                href={() => {
                  return false;
                }}
                style={{ cursor: "pointer" }}
                onClick={(event) => {
                  navigate("/product/" + prod.seourl, {
                    state: {
                      productinfo: prod,
                    },
                  });
                }}
              >
                <div className="product__imgg">
                  <img
                    src={`https://haodamart.blr1.cdn.digitaloceanspaces.com/HAODAMART/product/${prod.desktop_image}`}
                    alt="img"
                  />
                </div>
                <div className="number__per grid place-content-center">
                  <span>-30%</span>
                </div>
                <div className="product__star grid place-content-center">
                  <i class="fa-regular fa-star"></i>
                </div>
              </a>
              <div className="product__contentt px-4">
                <a
                  href={() => {
                    return false;
                  }}
                  style={{ cursor: "pointer" }}
                  onClick={(event) => {
                    navigate("/product/" + prod.seourl, {
                      state: {
                        productinfo: prod,
                      },
                    });
                  }}
                >
                  <div className="product__stock flex justify-between">
                    <div className="stock">
                      <i class="fa-solid fa-circle-check mr-2"></i>
                      <span>In stock </span>
                    </div>
                    <div className="share">
                      <button>
                        <i class="fa-solid fa-share-nodes"></i>
                      </button>
                    </div>
                  </div>
                  <div className="product__para my-2">
                    <span>
                      {prod.meta_tag_title} | Part No. {prod.id}
                    </span>
                  </div>
                  <div className="product__title">
                    <span>{prod.product_name}</span>
                  </div>
                  <div className="product__price flex justify-between items-center my-2">
                    <div className="price">
                      <div>
                        <span className="letters">Your price</span>
                      </div>
                      <div>
                        <span className="rupees flex items-center">
                        <BsCurrencyRupee />{prod.price} <span className="net">net</span>
                        </span>
                      </div>
                    </div>
                    <div className="price__dis">
                      <span>
                        <strike>₹{parseFloat(prod.mrp, 2) + 100}.00 net</strike>
                      </span>
                    </div>
                  </div>
                </a>

                <div className="product__btnn">
                  {/* checkProductInCartData(prod) >= 1  */}

                  {pepper?.cachedata?.cartitems?.[prod.id]?.quantity >= 1 ? (
                    <>
                      <button
                        type="button"
                        onClick={async () => {
                          navigate("/cart");
                        }}
                      >
                        VIEW CART
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        type="button"
                        onClick={async () => {

                          setLoader(true);

                          prod["buynow"] = true;
                          prod["quantity"] = 1;
                          await peppermint(PK.CACHEDATAWRITECARTITEMS, prod);
                          //await callAddtoCart(prod, prod.id);
                          //await callCacheCartDataWrite(prod);
                          //await callCartData();
                          setCount({ ...count, count: count + 1 });
                          //console.log(prod);

                          setTimeout(() => {
                            setLoader(false);
                          },[500])


                        }}
                      >
                        BUY NOW
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        ))}
      </div>
      )}
    </section>
  );
};

export default ProductMain;
